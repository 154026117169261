.container{
    display: flex;
    justify-content: center;
}
.bullet span{
  font-size: 16px;
}

.step{
  display: flex;
  align-items: center;
  grid-gap: 1rem;
  gap: 1rem;
  flex-direction: column;
  width: 270px;
  text-align: center;
}

.bullet{
    position: relative;
    width: 6rem;
    height: 6rem;
    background-color: #E5E5E5;
    border: 12px solid #E5E5E5;
    color: white;
    font-size: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
  }

  .step p{
    font-size: 12px;
    text-transform: uppercase;
}
  
  /* .step:last-child .bullet::before,
  .step:last-child .bullet::after{
    display: none;
  } */
  
  .bullet::before,
  .bullet::after{
    position: absolute;
    content: '';
    bottom: 14px;
    right: 20px;
    height: 0.5px;
    width: 240px;
    background: #E5E5E5;
    z-index: -1;

  }
  
  .step:first-child .bullet::before,
  .step:first-child .bullet::after{
    display: none;
  }

  .Complete .bullet{
    /* background-color: var(--primary);
    border: 2px solid var(--primary);
    color: #fff; */
    width: 6rem;
    height: 6rem;
    background-color: red;
    color: #fff;
    font-size: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
    border: 12px solid #0000;
  }
  
  .Complete .bullet::after{
    position: absolute;
    content: '';
    bottom: 14px;
    right: 20px;
    height: 0.5px;
    width: 240px;
    background: red;
    transform: scaleX(0);
    transform-origin: left;
    animation: animate 0.8s linear forwards;
  }
  
  @keyframes animate {
    100%{
      transform: scaleX(1)
    }
  }