.footer {
	grid-row: 4 / -1;
	align-self: center;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding-top: 3rem;
	background-color: var(--dark-grey);
	color: #fefefe;
}
.footer__navigation {
	padding: 0 20rem;
	/* width: 100%; */
	margin-bottom: 4rem;
	display: flex;
	justify-content: space-around;
	align-items: flex-start;
	gap: 10rem;
}
.footer__navigation--links {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
}
.footer__navigation--linkIconCallContainer {
	display: flex;
	gap: .5rem;
	margin-bottom: 1rem;
}
.footer__navigation--heading {
	font-size: 1.3rem;
	font-weight: 500;
}
.footer__navigation--link {
	display: flex;
	gap: .5rem;
	align-items: center;
	text-decoration: none;
	color: #fefefe;
	font-size: 1rem;
	font-family: 'Regular';
	transition: all .4s;
	padding-left: 1rem;
}
.footer__navigation--link:hover {
	/* color: var(--red); */
	transform: translateX(.2rem);
}
.footer__navigation--linkIcon {
	height: 1rem;
	width: 1rem;
	fill: #fefefe;
	display: none;
}
.footer__navigation--linkIconCall {
	height: 2.5rem;
	width: 2.5rem;
	fill: #fefefe;
}
.footer__social {
	border-top: 1px solid #5e5e68;
	padding-top: 2rem;
	align-self: flex-start;
	width: 100%;
	display: flex;
	gap: 1rem;
	justify-content: center;
	margin-bottom: 1rem;
}
.footer__copyright {
	font-size: 1rem;
	font-family: 'Regular';
}
.footer__links {
	display: flex;
	gap: 1rem;
	align-items: center;
}
.footer__links--link {
	text-decoration: none;
	color: #fefefe;
	font-size: 1rem;
	font-family: 'Regular';
	transition: all .4s;
}
.footer__links--link:hover {
	transform: translate(-.1rem);
}
.footer__social--btn {
	transition: all .4s;
}
.footer__social--btn:hover {
	transform: scale(1.2);
}
.footer__social--icon {
	height: 2rem;
	width: 2rem;
}
.icon-fb {
	fill: #3a5897;
}
.icon-ln {
	fill: #0e76a8;
}
.icon-tw {
	fill: #25a6d1;
}
.icon-in {
	fill: #b032ac;
}
.footer__text {
	margin: 0 1rem;
	margin-top: .5rem;
	margin-bottom: 3rem;
	font-family: 'Regular';
	font-size: 1rem;
	text-align: center;
}
@media only screen and (max-width: 37.5em) {
	.footer {
		display: flex;
		width: 100%;
	}
	.footer__links--link {
		font-size: 1.5rem;
	}
	.footer__social--icon {
		height: 3.5rem;
		width: 3.5rem;
	}
	.footer__copyright {
		font-size: 1.6rem;
	}
	.footer__text {
		font-size: 1.3rem;
		flex-wrap: wrap;
		justify-content: flex-start;
		text-align: center;
		display: flex-start;
	}
	.footer__navigation--heading {
		font-size: 1.8rem;
	}
	.footer__navigation--link {
		font-size: 1.5rem;
	}
}
