.container{
  padding: 2rem 15rem;
}

.formTitle{
  font-weight: 600;
  font-size: 1.4rem;
}

.inputBox .inputTitle{
  padding-bottom: 0.5rem;
  display: block;
  color: #2E2E2E;
  font-size: 1.4rem;
}
.inputBox1 .inputTitle{
  padding-bottom: 0.5rem;
  display: block;
  color: #2E2E2E;
  font-size: 1.4rem;
}

.inputBox{
  padding-bottom: 2rem;
}

.inputBox1{
  padding-bottom: 2rem;
}

.tooltip {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: #D9D9D9;
  color: #000000;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: 125%; /* Position above the element */
  left: 50%;
  margin-left: -60px; /* Center the tooltip */
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

.tooltip .tooltiptext1 {
  visibility: hidden;
  width: 120px;
  background-color: #D9D9D9;
  color: #000000;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: -65%; /* Position above the element */
  left: 0;
  margin-left: -85px; /* Center the tooltip */
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip:hover .tooltiptext1 {
  visibility: visible;
  opacity: 1;
}

.inputBox:nth-child(2){
  padding-bottom: 0rem;
}

.searchBox .inputTitle{
  padding-bottom: 1rem;
  display: block;
  color: #2E2E2E;
  font-size: 1.2rem;
}

.searchBox{
  margin-top: 1.2rem;
  display: flex;
  border-bottom: 1px solid #00000027;
}

.checkbox {
  opacity: 0;
}

.checkbox:checked + .slider {
  background-color: rgb(255, 255, 255);
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  border-radius: 32%;
  right: 0;
  bottom: 0;
  background-color:white;
  box-shadow: var(--box-shadow-input);
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: '';
  height: 1.8rem;
  width: 1.82rem;
  left: 0px;
  bottom: 0.8px;
  background-color:#bcb5b5bf;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 50%;
}

.publish_icon_button{
  text-decoration: none;
  cursor: pointer;
  background: none;
  border: none;
}

.checkbox:checked + .slider:before {
  background-color: var(--red);
  -webkit-transform: translateX(1.3rem);
  -ms-transform: translateX(1.3rem);
  transform: translateX(1.3rem);
  left: 9px;
}


.switch {
  position: relative;
  display: inline-block;
  box-shadow: 11px 21px 42px -6px #57575826;
  min-width: 4rem;
  height: 2rem;
}

.searchIcon{
  height: 6rem;
  width: 6rem;
  padding-top: 1.2rem;
  margin-bottom: -1.4rem;
}

.mainForm .formTitle{
  padding-bottom: 2rem;
  display: block;
}

.select_box{
  width: 40%;
  -webkit-box-decoration-break: none;
  box-decoration-break: none;
  min-height: 4rem;
  margin-bottom: 4rem;
  font-size: 1.4rem;
  background-color: #f2f2f200;
  border-bottom: 1px solid #00000027;
  outline: none;
  border-top: none;
  border-left: none;
  width: 40rem;
  border-right: none;
}

.multi_dropdown {
  border-style: none!important;
  width: 37rem;
  border: none;
  border-bottom: 1px solid #00000027 !important;
  border-radius: 0rem !important;
}


.multi_dropdown div{
  border-style: none!important;
}

.multi_dropdown .css-1rhbuit-multiValue div {
  font-size: 1.2rem;
}

.multi_dropdown div {
  margin-left: 0rem;
}

.multi_dropdown .css-1wa3eu0-placeholder {
  text-transform: none !important;
  font-size: 1.4rem;
}

.upload_border{
  border: 1px dotted rgb(78, 78, 78);
  display: flex;
  flex-direction: column;
  height: 11rem;
  text-align: center;
  justify-content: center;
  width: 34rem;
}

.subform{
  justify-content: space-between;
  display: flex;
  gap: 4rem;
}

.searchSubform{
  display: flex;
  align-items: end;
  justify-content: space-between;
  gap: 4rem;
}

.input{
  border-top: none;
  outline: none;
  width: 40rem;
  border-left: none;
  font-size: 1.4rem ;
  padding-bottom: 1rem;
  border-right: none;
  border-bottom: 1px solid #00000027;
}
.input_textarea{
  border-top: none;
  outline: none;
  width: 40rem;
  resize: none;
  font-size: 1.4rem ;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid #00000027;
}

.input_textarea::placeholder{
  font-size: 1.3rem ;
}

.input::placeholder{
  font-size: 1.3rem;
}


.buttonContainer{
  display: flex;
  gap: 2rem;
  justify-content: space-between;
}

.buttonContainer button{
  text-decoration: none;
  color: var(--white);
  text-transform: uppercase;
  background-color: var(--red);
  border: 1px solid var(--red);
  padding: 1.2rem 7.6rem;
  font-family: 'Medium';
  font-size: 1rem;
  border-radius: 0.5rem;
  cursor: pointer;
  transition: all 0.4s;
}

.buttonContainer button:hover {
  background-color: transparent;
  border: 1px solid var(--red);
  color: var(--red);
}

.searchContainer{
  margin-top: 6rem;
}

.searchContainer1{
  margin-top: 10rem;
}

@media only screen and (max-width: 1450px) {
  .container{
    padding: 2rem 10rem!important;
  }
}