.our-community-main-section {
	display: flex;
	justify-content: center;
	margin-top: 5rem;
	padding: 1rem 33rem;
	height: 70rem;
	gap: 8rem;
}

.our-community-heading {
	font-size: 2.5rem;
	margin-bottom: .5rem;
}
.text-about__heading_2 {
	font-size: 1.6rem;
}
.our-community-highlight {
	font-size: 1.8rem;
	font-weight: 900;
	margin-top: 2rem;
}
.text-about_heading_5 {
	color: var(--red);
	text-decoration: none;
	font-size: 1.8rem;
}
.fbf__image__Community {
	height: 35vw;
}
.our_community_divider {
	display: flex;
	height: .2rem;
	border-width: .5rem;
	color: var(--dark-grey);
	background-color: var(--dark-grey);
	margin: 3rem 33rem;
	margin-top: 2rem;
}
.our-community-charities-content {
	text-align: center;
	justify-content: center;
}
.our-community-charities-heading {
	font-size: 2.5rem;
	color: var(--dark-grey);
	justify-content: center;
	text-transform: uppercase;
	font-weight: 900;
	margin-bottom: -1rem;
}
.charity_images {
	display: flex;
	padding: 3rem 33rem;
	justify-content: space-between;
	align-items: center;
	/* gap: 5rem; */
}
.our_community_charities_image {
	margin-bottom: 2rem;
	width: 20rem;
	cursor: pointer;
}
.mwfbi_products {
	background-color: var(--light-grey2);
	padding-top: 2rem;
	padding-bottom: 2rem;
}
.our-community-heading2 {
	font-size: 2.5rem;
	color: var(--red);
	font-weight: 900;
}
.our-community-shred-section {
	padding: 0rem 33rem;
	display: flex;
	flex-direction: column;
	align-items: center;
}
.our-community-text-about {
	font-size: 1.6rem;
	padding-bottom: 2rem;
}
.our-community-shred-footer {
	font-size: 1.6rem;
	font-weight: 900;
}
.our-community-shred-card {
	margin-top: 1.5rem;
	background-color: var(--red);
	display: flex;
	justify-content: center;
	align-items: center;
	color: white;
	padding: 2rem 3rem;
	gap: 3rem;
}
.our-community-recycleimage {
	width: 10rem;
}
.our-community-recycle {
	font-size: 1rem;
	font-weight: 400;
}
.our_community_shred_recycle {
	font-size: 3rem;
	font-weight: 700;
}
@media only screen and (max-width: 1500px) {
	.our-community-main-section {
		padding: 1rem 15rem;
	}
	.our_community_divider {
		margin: 1rem 15rem;
	}
	.our-community-shred-section {
		padding: 0rem 15rem;
	}
	.charity_images {
		padding: 1rem 15rem;
	}
}
@media only screen and (max-width: 1400px) {
	.our-community-main-section {
		padding: 1rem 15rem;
	}
	.our_community_divider {
		margin: 1rem 15rem;
	}
	.our-community-shred-section {
		padding: 0rem 15rem;
	}
	.charity_images {
		padding: 1rem 15rem;
	}

	/* .fbf__image__Community {
		height: 40vw;
	} */
}
@media only screen and (max-width: 1200px) {
	.our-community-main-section {
		padding: 1rem 10rem;
	}
	.our_community_divider {
		margin: 1rem 10rem;
	}
	.our-community-shred-section {
		padding: 0rem 10rem;
	}
	.charity_images {
		padding: 1rem 8rem;
	}
}

@media only screen and (max-width: 37.5em) {
	.our-community-main-section {
		padding: 1rem 1rem;
		flex-direction: column;
		height: auto;
		gap: 4rem;
	}
	.fbf__image__Community {
		width: 90vw;
	}
	.our_community_divider {
		margin: 1rem 1rem;
	}
	.our-community-shred-section {
		padding: 0rem 1rem;
	}
	.mwfbi_products {
		margin-top: 2rem;
	}
	.charity_images {
		display: flex;
		flex-direction: column;
		padding: 1rem 1rem;
	}
	.our_community_charities_image {
		margin-bottom: 2rem;
		width: 70vw;
	}
	.fbf__image__Community {
		height: 50vh;
		margin-right: -1.5rem;
		margin-top: -2rem;
	}
}
