.container {
  display: grid;
  grid-template-rows: 3rem 7rem 1fr auto;
  /* gap: 2rem; */
  position: relative;
  margin: 2rem;
  margin-top: -3rem;
}

.top__space {
  grid-row: 1 / 2;
  background-color: #808080;
}

.content {
  grid-row: 3 / 4;
  min-height: 56vh;
  /* min-height: 84vh; */
}

.flex {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.flex2 {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.page_content {
  padding: 0 15rem;
}

.page__heading {
  font-size: 1.5rem;
  font-weight: 600;
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;
}

.page__heading span {
  display: block;
  font-size: 1.2rem;
  color: var(--red);
}

.page__heading::after {
  content: "";
  height: 0.5rem;
  width: 3rem;
  background-color: var(--black);
}

.modal_custom_error {
  height: 2rem;
  color: red;
  font-size: 1.3rem !important;
}

/* .paginationTop {
	margin-bottom: '-4rem';
}

.paginationBttns {
	margin-top: 2rem;
	width: 100%;
	height: 0rem;
	list-style: none;
	justify-content: end;
	font-family: Poppins;
	font-style: normal;
	font-weight: 500;
	font-size: 1rem;
	line-height: 6rem;
	flex-direction: row;
	display: flex;
	flex-wrap: wrap;
}

.paginationBttns a {
	padding: 1.3rem 2rem;
	margin: 8px;
	background-color: #464646;
	color: white;
	cursor: pointer;
	border-radius: 40px !important;
	box-shadow: 0px 4px 15px 5px rgb(0 0 0 / 16%);
	font-size: 1.5rem;
}

.paginationBttns a:hover {
	color: white;
	background-color: #e02b21;
}

.paginationActive a {
	color: white;
	background-color: #3f3f3f;
	width: 43px;
	height: 43px;
	left: 1181px;
	top: 933px;
	background: #e02b21;
	border-radius: 5px;
}

.previousBttn,
.nextBttn {
	background: #000000 !important;
}

.paginationDisabled a {
	color: grey;
	background-color: #000000;
} */

.searchListContainer::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
}

.searchListContainer::-webkit-scrollbar {
  width: 12px;
  background-color: #f5f5f5;
}

.searchListContainer::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #000000;
}

/* Toggle Button Start */

.switch {
  position: relative;
  display: inline-block;
  min-width: 5rem;
  max-width: 5rem;
  height: 3.05rem;
}

.switch-slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #c4c4c4;
  box-shadow: 0.5rem 0.2rem 1rem 2px rgb(0 0 0 / 14%);
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 20px;
}

.switch-slider:before {
  position: absolute;
  content: "";
  height: 2.3rem;
  width: 2.3rem;
  left: 4px;
  bottom: 4px;
  background-color: #676767;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 10px;
}

.checkbox {
  opacity: 0;
}

.checkbox:checked + .switch-slider {
  background-color: #c4c4c4;
}

.checkbox:focus + .switch-slider {
  box-shadow: 0 0 1px #e02b21;
}

.checkbox:checked + .switch-slider:before {
  -webkit-transform: translateX(1.8rem);
  -ms-transform: translateX(1.8rem);
  transform: translateX(1.8rem);
  background-color: var(--red);
}

.switch-slider .round {
  border-radius: 3.4rem;
}

.switch-slider .round:before {
  border-radius: 50%;
}

@media only screen and (max-width: 37.5em) {
  .top__space {
    display: none;
  }

  .container {
    margin: 2rem;
    max-width: 100vw;
    overflow: hidden;
    display: block;
    grid-template-rows: 5rem 1fr auto;
  }

  .content {
    grid-row: 2 / 3;
  }
}

.css-yk16xz-control {
  height: 4.5rem;
  border: 0px solid;
  margin-left: 8rem;
  border-radius: 4px;
  border-style: solid;
  border-width: 0px !important;
}

.css-2b097c-container {
  position: relative;
  box-sizing: border-box;
  border-radius: 0.5rem;
  border-top: 2px solid;
  border-left: 2px solid;
  border-right: 2px solid #707070;
  border-bottom: 2px solid #707070;
  border-radius: 0.5rem;
}

.css-1hb7zxy-IndicatorsContainer {
  display: none !important;
}

.css-1pahdxg-control {
  margin-left: 8rem;
  height: 4.5rem !important;
  border: none !important;
  box-shadow: none !important;
}

.css-1pahdxg-control:hover {
  border: none !important;
  box-shadow: none !important;
}
.css-1wa3eu0-placeholder {
  font-weight: 400;
  font-size: 1.2rem;
  text-transform: uppercase !important;
}
.css-1uccc91-singleValue {
  max-width: calc(80% - 8px) !important;
}

.react-tel-input .form-control {
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
  border: 1px solid rgb(163, 159, 159) !important;
  background-color: rgb(238, 234, 228) !important;
  width: 100% !important;
  height: 5rem !important;
  outline: none !important;
  padding: 0 1rem !important;
  border-radius: 0 !important;
  font-size: 14px !important;
  padding-left: 5rem !important;
}

.text-input-prefix__formgroup--input {
  background-color: transparent;
  border: none;
  outline: none;
  font-family: inherit;
  width: 100%;
  height: 5vh;
  padding-left: 0.5rem;
}
.textBox {
  position: relative;
}
.text-input-prefix__formgroup--inputBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid rgb(163, 159, 159);
  background-color: rgb(238, 234, 228);
  /* box-shadow: var(--box-shadow-input); */
  /* overflow: hidden; */
  width: 100%;
  height: 5rem;
  outline: none;
  padding: 0 1rem;
}
.text-input-prefix__formgroup--inputBox--date {
  /* display: none; */
  opacity: 0;
}
.text-input-prefix__formgroup--dateBox {
  position: relative;
}
.text-input-prefix__formgroup--dateBox label {
  position: absolute;
  top: 0;
  left: 0;
  border: 1px solid rgb(163, 159, 159);
  background-color: rgb(238, 234, 228);
  width: 100%;
  outline: none;
  padding: 0 1rem;
  /* padding-top: 1rem; */
  font-size: 1.2rem;

  display: flex;
  align-items: center;
  justify-content: space-between;
}
.text-input-prefix__formgroup--dateBox label svg {
  height: 1.5rem;
  width: 1.5rem;
  fill: var(--black);
}

.edit-feature {
  width: 100%;
  position: relative;
}
.search_blog {
  padding: 5px 2rem;
  width: 20vw;
  outline: none;
  border-left: none;
  border-right: none;
  border-top: none;
  border-bottom: rgb(254, 208, 208) solid 1px;
}
.date-icon-over:hover {
  cursor: pointer;
}
.icon-bar {
  position: fixed;
  top: 20%;
  right: 1%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.icon-bar a {
  display: block;
  text-align: center;
  padding-right: 10px;
  padding-bottom: 2px;
  transition: all 0.3s ease;
  color: black;
  font-size: 20px;
}
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="8px"]::before {
  content: "8";
  font-size: 8px !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="10px"]::before {
  content: "10";
  font-size: 10px !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="12px"]::before {
  content: "12";
  font-size: 12px !important;
}
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="14px"]::before {
  content: "14";
  font-size: 14px !important;
}
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="16px"]::before {
  content: "16";
  font-size: 16px !important;
}
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="18px"]::before {
  content: "18";
  font-size: 18px !important;
}
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="20px"]::before {
  content: "20";
  font-size: 20px !important;
}
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="22px"]::before {
  content: "22";
  font-size: 22px !important;
}
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="24px"]::before {
  content: "24";
  font-size: 24px !important;
}
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="30px"]::before {
  content: "30";
  font-size: 30px !important;
}
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="35px"]::before {
  content: "35";
  font-size: 35px !important;
}
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="40px"]::before {
  content: "40";
  font-size: 40px !important;
}
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="45px"]::before {
  content: "45";
  font-size: 45px !important;
}
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="50px"]::before {
  content: "50";
  font-size: 50px !important;
}
.search_blog_container {
  display: flex;
  justify-content: center;
  padding: 2rem 2rem;
}
.search_blog {
  padding: 5px 2rem;
  width: 20vw;
  outline: none;
  border-left: none;
  border-right: none;
  border-top: none;
  border-bottom: rgb(254, 208, 208) solid 1px;
}
