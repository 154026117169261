.mw_slider {
	display: flex;
	justify-content: space-between;
	align-items: center;
	color: var(--white);
	min-height: 45vh;
	height: 45vh;
	background-position-y: 30%;
	background-size: cover;
	background-repeat: no-repeat;
	padding: 0 15rem;
}

.mw_slider__right {
	display: flex;
	flex-direction: column;
	row-gap: 0.5rem;
}

.mw_slider__heading {
	font-family: 'SemiBold';
	font-size: 4rem;
	font-weight: bold !important;
}

.mw_slider__subheading {
	font-size: 1.4rem;
	width: 30rem;
}

.mw_button {
	text-decoration: none;
	color: var(--white);
	text-transform: uppercase;
	background-color: var(--red);
	border: 1px solid var(--red);
	padding: 1.6rem 4rem;
	font-family: 'Medium';
	font-size: 1.3rem;
	border-radius: .5rem;
	cursor: pointer;
	transition: all .4s;
	text-align: center;
}

.mw_button:hover {
	background-color: var(--red-transparent);
	border: 1px solid var(--red);
}

.mw_button_small {
	text-decoration: none;
	color: var(--white);
	text-transform: uppercase;
	background-color: var(--red);
	border: 1px solid var(--red);
	padding: .5rem 2rem;
	font-family: 'Medium';
	font-size: 1rem;
	border-radius: .5rem;
	cursor: pointer;
	transition: all .4s;
	display: flex;
	align-items: center;
}

.mw_button_small1 {
	text-decoration: none;
	color: var(--white);
	text-transform: uppercase;
	background-color: var(--red);
	border: 1px solid var(--red);
	padding: .5rem 2rem;
	font-family: 'Medium';
	font-size: 1rem;
	border-radius: .5rem;
	cursor: pointer;
	transition: all .4s;
	display: flex;
	align-items: center;
}

.mw_button_small:hover {
	background-color: transparent;
	border: 1px solid var(--red);
	color: var(--red);
}

.mw_button_small1:hover {
	background-color: transparent;
	border: 1px solid var(--red);
	color: var(--red);
}

.quote {
	height: 10rem;
	background-color: var(--dark-grey);
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0 15rem;
}

.quote__image {
	height: 4rem;
}

.quote__text {
	font-size: 1.6rem;
	font-weight: bold;
	font-family: 'Regular';
	text-transform: uppercase;
	color: var(--white);
}

.quote2 {
	height: 10rem;
	background-color: var(--dark-grey);
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0 33rem;
}

.quote__image2 {
	height: 6rem;
}

.quote__text2 {
	font-size: 1.6rem;
	font-weight: 800;
	color: var(--white);
}

.header_buttons {
	display: flex;
	gap: 2rem;
}

.mw_products {
	padding: 3rem 15rem;
	background-color: var(--light-grey);
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.mw_products__item {
	text-decoration: none;
	transition: all .4s;
}

.mw_products__item:hover {
	background-color: var(--black-hover);
	transform: translateY(-.2rem);
}

.mw_products__heading {
	font-size: 2rem;
	padding-top: 1.5rem;
}

.mw_products__heading span {
	display: block;
	font-size: 1.9rem;
	font-family: 'Regular';
	color: var(--dark-grey);
}

.mw_products__right {
	display: flex;
	gap: 2rem;
}

.mw_products__item {
	border: 2px solid var(--light-border);
	border-radius: .2rem;
	/* padding: 2rem 3rem; */
	height: 10rem;
	width: 12rem;
	display: flex;
	gap: 1rem;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.mw_products__item--image {
	height: 4rem;
}

.mw_products__item--name {
	font-size: 1.2rem;
	font-family: 'Bold';
	text-transform: uppercase;
	color: var(--dark-grey);
}

.mw_about {
	margin: 3rem 15rem;
	display: flex;
	gap: 5rem;
}

.mw_about__image {
	height: 30rem;
	width: 30vw;
}

.mw_about__image3 {
	height: 24rem;
	width: 45rem;
}

.tabset {
	width: 100%;
}

.tabset>input[type="radio"] {
	position: absolute;
	opacity: 0;
	visibility: hidden;
}

.tabset .tab-panel {
	display: none;
}

.tabset>input:first-child:checked~.tab-panels>.tab-panel:first-child,
.tabset>input:nth-child(3):checked~.tab-panels>.tab-panel:nth-child(2),
.tabset>input:nth-child(5):checked~.tab-panels>.tab-panel:nth-child(3),
.tabset>input:nth-child(7):checked~.tab-panels>.tab-panel:nth-child(4),
.tabset>input:nth-child(9):checked~.tab-panels>.tab-panel:nth-child(5),
.tabset>input:nth-child(11):checked~.tab-panels>.tab-panel:nth-child(6) {
	display: block;
}

.tabset>label {
	position: relative;
	display: inline-block;
	margin-right: .5rem;
	padding: 1rem;
	border: 1px solid var(--grey-border);
	border-bottom: 0;
	cursor: pointer;
	font-family: 'Bold';
	font-size: 1.5rem;
	justify-content: center;
	text-align: center;
}

.tabset>label:hover,
.tabset>input:focus+label {
	color: var(--red);
}

.tabset>input:checked+label {
	border-color: var(--grey-border);
	border-bottom: 1px solid var(--white);
	margin-bottom: -1px;
	color: var(--red);
}

.tab-panel {
	border-top: 1px solid var(--grey-border);
	padding-top: 1rem;
}

.tab_blog__data {
	font-size: 1.3rem;
	border-bottom: 1px dotted var(--light-grey);
	margin-bottom: .5rem;
}

.tab_about__data {
	font-size: 1.6rem;
	margin-bottom: 2rem;
}

.mw_services {
	/* background-color: var(--light-grey2); */
	background-color: var(--white);
	padding: 3rem 15rem;
	display: flex;
	/* gap: 5rem; */
	justify-content: space-between;
}

.mw_services__item {
	position: relative;
}

.mw_services__item--info {
	position: absolute;
	bottom: .6rem;
	left: 0;
	width: 100%;
	background-color: rgba(0, 0, 0, .4);
	/* background-color: rgba(256, 256, 256, .4); */
	padding: 1rem 2rem;
	color: var(--white);
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.mw_services__item--name {
	font-size: 1.5rem;
	line-height: 1;
}

.mw_services__item--data {
	font-size: 1.3rem;
	font-family: 'Regular';
}

.mw_services__item--image {
	height: 25rem;
	width: 45rem;
}

.contact_us {
	padding: 3rem 15rem;
	background-position-y: 75%;
	background-size: cover;
	background-repeat: no-repeat;
	display: flex;
	justify-content: flex-start;
}

.contact_us__box {
	background-color: rgba(0, 0, 0, 0.499);
	border-radius: .5rem;
	padding: 3rem;
}

.contact_us__heading {
	font-size: 2rem;
	font-family: 'bold';
	color: var(--white);
}

.contact_us__subheading {
	font-size: 1.5rem;
	color: var(--white);
}

.contact_us__tag {
	font-size: 1.7rem;
	font-family: 'SemiBold';
	margin: 2rem 0;
	color: var(--white);
}

.alert-message {
	font-size: 2rem;
	text-align: center;
	color: var(--red);
}

.hide_button1 {
	display: none;
}

/* @media only screen and (max-width: 600px) {

} */
@media only screen and (max-width: 1500px) {
	.quote2 {
		padding: 2rem 15rem;
	}
}

@media only screen and (max-width: 75em) {

	.mw_slider,
	.quote {
		padding: 0 10rem;
	}

	.mw_products,
	.mw_services,
	.contact_us,
	.footer__navigation {
		padding: 3rem 10rem;
	}

	.mw_about {
		margin: 3rem 10rem;
	}

	.quote2 {
		padding: 0 10rem;
	}
}

@media only screen and (max-width: 56.25em) {

	.mw_slider,
	.quote {
		padding: 0 5rem;
	}

	.quote2 {
		padding: 0 5rem;
	}

	.mw_products,
	.mw_services,
	.contact_us,
	.footer__navigation {
		padding: 3rem 5rem;
	}

	.mw_about {
		margin: 3rem 5rem;
	}

	.mw_services__item--image {
		width: 41vw;
	}

	.mw_button {
		padding: 1.6rem 2rem;
	}
}

@media (max-width: 767px) {
	.mw_button_small1 {
		display: none;
	}
}

@media only screen and (max-width: 37.5em) {
	.megaMenu {
		padding: 1rem 2rem;
	}

	.mw_slider,
	.quote {
		padding: 0 2rem;
	}

	.quote2 {
		padding: 0 2rem;
	}

	.mw_products,
	.mw_services,
	.footer__navigation {
		padding: 3rem 2rem;
	}

	.footer__navigation {
		flex-wrap: wrap;
		justify-content: flex-start;
		gap: 2rem;
	}

	.mw_about {
		margin: 3rem 2rem;
		flex-direction: column;
		gap: 1rem;
	}

	.mw_services {
		flex-direction: column;
	}

	.megaMenu {
		padding: 1rem 1rem;
	}

	.mw_button {
		height: 4rem;
		display: flex;
		align-items: center;
	}

	.mw_slider {
		min-height: 10%;
		padding: 0rem;
		flex-direction: column;
		justify-content: flex-start;
		padding-top: 3rem;
		padding-bottom: 3rem;
	}

	.mw_slider__left {
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	.mw_slider__right {
		margin-top: 3rem;
	}

	.quote {
		height: 10rem;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		gap: 1rem;
	}

	.quote__text {
		font-size: 1.5rem;
	}

	.quote__image {
		display: none;
	}

	.mw_products {
		flex-direction: column;
	}

	.mw_products__heading,
	.mw_products__heading span {
		display: flex;
		flex-direction: row;
		font-size: 1.5rem;
		justify-content: center;
		color: var(--dark-grey);
		font-family: bold;
		gap: .3rem;
		text-transform: uppercase;
	}

	.mw_products__right {
		display: flex;
		flex-direction: column;
		gap: .1rem;
		width: 100%;
	}

	.mw_products__item {
		border: none;
		gap: 2rem;
		flex-direction: row;
		width: 100%;
		justify-content: flex-start;
	}

	.mw_products__item--name {
		border: 1px solid var(--grey-border);
		padding: 1rem 0;
		width: 100%;
		text-align: center;
		font-size: 2rem;
	}

	.mw_products__item--image {
		width: 6rem;
		height: 6rem;
	}

	.hide_button {
		display: none;
	}

	.hide_button1 {
		display: flex;
		justify-content: center;
	}

	.mw_button2 {
		display: flex;
		justify-content: center;
	}

	.mw_about {
		margin: 3rem 1rem;
	}

	.mw_about__image {
		display: none;
	}

	.mw_services__item--image {
		width: 80vw;
	}

	.contact_us {
		display: flex;
		justify-content: center;
		padding: 3rem 0;
	}

	.contact_us__box {
		padding: 3rem 2rem;
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	.contact_us__subheading {
		font-size: 1.2rem;
	}

	.quote2 {
		height: 15rem;
		align-items: center;
		flex-direction: column;
		justify-content: center;
		padding: 0 1rem;
		gap: 1.5rem;
	}

	.quote__text2 {
		font-size: 1.6rem;
		font-weight: 700;
		color: var(--white);
		text-align: center;
	}

	.mw_about__image3 {
		width: 80VW;
	}

	.mw_services__item--info {
		width: 80vw;
	}

	/* .exo-menu li a.mw_button_small1 {
		display: none !important;
	} */
}

@media only screen and (min-width: 93.75em) {

	.mw_slider,
	.quote {
		padding: 0 33rem;
	}

	.quote2 {
		padding: 0 33rem;
	}

	.mw_slider {
		min-height: 35vh;
		background-position-y: 32%;
	}

	.mw_products,
	.mw_services,
	.contact_us,
	.footer__navigation {
		padding: 3rem 33rem;
	}

	.mw_about {
		margin: 3rem 33rem;
	}
}

.btn_space {
	margin-left: 5px;
}