.mwfbi_banner {
	background-size: cover;
	background-repeat: no-repeat;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 0 5rem;
	height: 45vh;
	background-color: var(--white);
	/* background-image: linear-gradient(rgba(0, 0, 0, .1), #d13531),
		url(https://upload.wikimedia.org/wikipedia/commons/6/6e/Golfer_swing.jpg); */
}
.mwfbi_banner__text {
	font-size: 1.6rem;
	font-weight: 200;
	color: white;
	text-align: center;
	
	text-shadow: 2px 3px #1b1b1b;
}

.mwfbi_banner__heading {
	font-weight: 700;
	text-align: center;
	color: white;
	
	text-shadow: 2px 3px #1b1b1b;
}

.mwfbi_banner3 {
	background-size: cover;
	background-repeat: no-repeat;
	background-position-y: -20rem;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 0 5rem;
	height: 60vh;
}
.mwfbi_banner__text2 {
	font-size: 1.6rem;
	color: white;
	text-align: center;
	text-shadow: 1px 1px 2px black;
}

.mwfbi_banner__heading2 {
	font-weight: 700;
	text-align: center;
	color: white;
	text-shadow: 1px 1px 2px black;
}

.mwfbi_banner2 {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 0 5rem;
	height: 30vh;
	background-color: var(--white);
}
.mwfbi_banner__text--black {
	font-size: 1.6rem;
	font-weight: 200;
	color: grey;
	text-align: center;
}

.mwfbi_banner__heading--black {
	font-weight: 700;
	text-align: center;
	color: var(--red);
}

.mwfbi_banner__header {
	display: flex;
	gap: 1rem;
	align-items: center;
	justify-content: center;
}
.mwfbi_banner__header--date {
	display: flex;
	gap: .5rem;
	align-items: center;
	color: var(--white);
	font-size: 1.7rem;
	text-shadow: 1px 1px 2px black;
	line-height: 1;
}
.mwfbi_banner__header--date_icon {
	height: 1.5rem;
	width: 1.5rem;
	fill: var(--white);
	filter: drop-shadow(1px 1px 2px black);
}

@media (max-width: 990px) {
	.mwfbi_banner {
		background-size: cover;
		/* width: auto; */
		display: flex;
	}
	.mwfbi_banner__text {
		font-weight: 300;
		color: white;
		text-align: center;
		justify-content: center;
		font-style: italic;
	}
	.mwfbi_banner__heading {
		font-weight: 700;
		padding-bottom: 1rem;
		text-align: center;
		justify-content: center;
		/* padding-top: 25rem; */
		color: white;
	}
}
@media (max-width: 760px) {
	.mwfbi_banner {
		background-size: cover;

		display: flex;
		height: 30vh;
	}
	.mwfbi_banner3 {
		height: 30vh;
		background-position-y: 0;
	}
	.mwfbi_banner__header {
		flex-wrap: wrap;
	}
	.mwfbi_banner__text {
		font-weight: 300;
		color: white;
		text-align: center;
		justify-content: center;
		font-style: italic;
		padding: 0 1rem;
	}
	.mwfbi_banner__heading {
		font-weight: 700;
		padding-bottom: 1rem;
		text-align: center;
		justify-content: center;
		padding-top: 5rem;
		color: white;
	}
}
