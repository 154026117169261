.button {
	font-family: inherit;
	border: none;
	background-color: transparent;
	position: relative;
}
/* .button:hover .button__icon {
	background-color: var(--bg-primary);
} */
/* .button:hover .button__icon--icon {
	fill: var(--red);
} */
.button__red {
	background-color: var(--red);
	color: var(--bg-primary);
}
.button__black {
	background-color: var(--black);
	color: var(--bg-primary);
}
.button__icon {
	position: absolute;
	left: 0;
	z-index: 10;
	background-color: #ebb853;
	padding: 1rem;
	display: flex;
	transition: all .4s;
	cursor: pointer;
}
.button__black .button__icon {
	background-color: rgb(134, 131, 131);
}
.button__black:hover {
	background-color: transparent;
	color: var(--black);
	transition: 4s;
}
.button__black:hover .button__icon {
	background-color: grey;
	transition: all .4s;
}
.button__red:hover .button__icon {
	background-color: var(--red);
	transition: 1s;
}
.button__red:hover {
	background-color: transparent;
	color: var(--red);
	transition: 1s;
}
.button__icon--icon {
	height: 3rem;
	width: 3rem;
	fill: var(--dark-grey);
	transition: all .4s;
}
.button:hover .button__text {
	background-color: var(--bg-primary);
	color: var(--black);
	transition: 1s;
}
.button__text {
	color: var(--bg-primary);
	text-transform: uppercase;
	font-weight: 400;
	box-shadow: var(--box-shadow1);
	overflow: hidden;
	margin-left: 4rem;
	padding: 2rem 4rem;
	cursor: pointer;
	transition: all .4s;
	height: 5rem;
	border-radius: 5px;
}
