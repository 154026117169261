/* Modal */

.modalContainer {
	display: block;
	pointer-events: auto;
	background-color: rgba(0, 0, 0, 0.4) !important;
	-moz-transition: all 0.5s ease-out;
	-webkit-transition: all 0.5s ease-out;
	-o-transition: all 0.5s ease-out;
	transition: all 0.5s ease-out;
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background: rgba(0, 0, 0, 0.4);
	z-index: 99999;
}

.modal {
	position: absolute;
	z-index: 1000;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	background: var(--white);
	border-radius: 2rem;
	overflow: hidden;
	transition: 1.1s ease-out;
	box-shadow: var(--box-shadow1);
	filter: blur(0);
	opacity: 1;
	visibility: visible;
	justify-content: center;
	min-width: 30vw;
}
.modal__container {
	padding: 2rem;

	display: flex;
	flex-direction: column;
	gap: 2rem;
	align-items: flex-start;
}
.modal_heading__container {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
}
.modal_heading__text {
	font-size: 1.3rem;
	font-weight: 600;
}
.modal__heading--button {
	background-color: transparent;
	border: none;
	outline: none;
	cursor: pointer;
	transition: all .4s;
}
.modal_heading__icon {
	height: 2rem;
	width: 2rem;
	transition: all .4s;
}
.modal__heading--button:hover {
	transform: scale(1.1);
}
.modal__heading--button:hover .modal_heading__icon {
	fill: var(--black);
}
.modal__form {
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: .5rem;
}
.modal__form--label {
	font-size: 1.2rem;
	font-weight: 500;
	color: var(--light-border);
}
.modal__form--input {
	border: none;
	background-color: transparent;
	font-family: inherit;
	border-bottom: 1px solid var(--light-border);
	outline: none;
}
.modal__button {
	font-family: inherit;
	border: 1px solid var(--red);
	background-color: var(--red);
	color: var(--white);
	font-size: 1.3rem;
	padding: .5rem 1rem;
	border-radius: .5rem;
	cursor: pointer;
	transition: all .4s;
}
.modal__button:hover {
	background-color: transparent;
	color: var(--red);
}
.modal__form--error {
	font-size: 1.2rem;
	color: var(--red);
}
