.other_offerings_options {
	padding: 3rem 33rem;
}
.other_offerings_services {
	padding: 3rem 33rem;
	background-color: var(--light-grey2);
}

.discounts_options {
	padding: 3rem 33rem;
}

@media only screen and (max-width: 1500px) {
	.other_offerings_options,
	.other_offerings_services {
		padding: 3rem 15rem;
	}
}

@media only screen and (max-width: 1400px) {
	.other_offerings_options,
	.other_offerings_services {
		padding: 3rem 15rem;
	}
	/* .homeowner_insurance_discounts-grid {
		width: 100%;
		justify-content: space-between;
	} */
}
@media only screen and (max-width: 1400px) {
	.other_offerings_options,
	.other_offerings_services {
		padding: 3rem 10rem;
	}
}

@media only screen and (max-width: 780px) {
	.other_offerings_options,
	.other_offerings_services {
		padding: 3rem 1rem;
	}
}
