.auto_insurance {
	padding: 2rem 33rem;
	padding-bottom: 0rem;
}
.auto_insurance_roadside {
	padding: 2rem 33rem;
	background-color: var(--light-grey2);
	margin-top: 3rem;
}
.tab_comprehensive {
	display: flex;
}
.auto_insuracne_image {
	display: 100%;
	padding-top: 1rem;
	width: 63vw;
}
.auto_insurance_services {
	padding: 2rem 0;
}
.auto_inurance_emergency {
	display: flex;
	flex-direction: column;
	text-align: center;
}
.contact_no {
	color: var(--red);
	font-size: 2rem;
	font-weight: 800;
}
.auto_insurance_discounts {
	padding: 2rem 33rem;
	display: flex;
	flex-direction: column;
}
.auto_insurance_discounts-types {
	display: flex;
	gap: 3rem;
	flex-direction: column;
	padding: 2rem 0;
}
.discounts-types {
	display: flex;
	width: 20vw;
	/* border-right: 1px solid var(--light-grey); */
	/* padding-right: 7rem; */
}
.discounts_images_nails {
	width: 4vw;
	height: 4vw;
}
.discount_image_text {
	display: flex;
	flex-direction: column;
}
.auto_insurance_discounts-grid {
	display: flex;
	gap: 3rem;
}
.premier_auto {
	padding: 2rem 1rem;
	/* justify-content: start; */
}
.premier_auto_coverage {
	display: flex;
	/* gap: 5rem; */
	align-items: center;
	justify-content: space-between;
	width: 100%;
}

.auto_insurance_premier {
	padding: 3rem 33rem;
	background-color: var(--light-grey2);
}
.auto_insurance_equipment {
	padding: 2rem 33rem;
}
.speical_equipment_outer {
	padding: 2rem 1rem;
}
.phase2_subheading ul li {
	margin-left: 2rem;
	list-style-type: circle;
}
.special_equipment_headers {
	line-height: 3.5rem;
	width: 20rem;
}

.special_equipment_list {
	display: flex;
	gap: 8rem;
	padding: 2rem 1rem;
}
.special_equipment_uses {
	padding: 2rem;
	border: 2px solid var(--light-grey);
}
/* @media only screen and (min-width: 1500px) {

	.auto_insurance_roadside {
		padding: 2rem 15rem;
	}
	.auto_insurance_premier {
		padding: 2rem 15rem;
	}
	.auto_insurance_equipment {
		padding: 2rem 15rem;
	}
	.quote2 {
		padding: 0 15rem;
	}
} */
@media only screen and (max-width: 1500px) {
	.auto_insurance_roadside {
		padding: 2rem 15rem;
	}
	.auto_insurance_premier {
		padding: 2rem 15rem;
	}
	.auto_insurance_equipment {
		padding: 2rem 15rem;
	}
	.auto_insurance_discounts {
		padding: 2rem 15rem;
	}
	.auto_insurance {
		padding: 1rem 15rem;
	}
}
@media only screen and (max-width: 1400px) {
	.auto_insurance_roadside {
		padding: 2rem 15rem;
	}
	.auto_insurance_premier {
		padding: 2rem 15rem;
	}
	.auto_insurance_equipment {
		padding: 2rem 15rem;
	}
	.auto_insurance_discounts {
		padding: 2rem 15rem;
	}
	.auto_insurance {
		padding: 1rem 15rem;
	}
}
@media only screen and (max-width: 1200px) {
	.auto_insurance_roadside {
		padding: 2rem 10rem;
	}
	.auto_insurance_premier {
		padding: 2rem 10rem;
	}
	.auto_insurance_equipment {
		padding: 2rem 10rem;
	}
	.auto_insurance_discounts {
		padding: 2rem 10rem;
	}
	.auto_insurance {
		padding: 1rem 10rem;
	}
}
@media only screen and (max-width: 780px) {
	.auto_insurance {
		padding: 1rem 0;
	}
	.auto_insurance_roadside {
		padding: 2rem 1rem;
	}

	.auto_insuracne_image {
		width: 90vw;
	}
	.auto_insurance_discounts {
		padding: 2rem 1rem;
	}
	.auto_insurance_discounts-types {
		display: flex;
		flex-direction: column;
	}
	.auto_insurance_discounts-grid {
		display: flex;
		flex-direction: column;
	}
	.discounts-types {
		width: 100%;
	}
	.discounts_images_nails {
		width: 12vw;
		height: 10vw;
	}
	.auto_insurance_premier {
		padding: 2rem 1rem;
	}
	.auto_insurance_equipment {
		padding: 2rem 1rem;
	}

	.special_equipment_headers {
		line-height: 3.2rem;
		width: 30rem;
	}

	.special_equipment_list {
		display: flex;
		gap: 1rem;
		flex-direction: column;
		padding: 2rem 0;
	}
}
