.chipContainer {
  background-color: #ECECEC;
  border-radius: 20px;
  margin-bottom: 1.4rem;
  cursor: pointer;
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 14px 2px 14px 20px;
}

.title {
  font-size: 1.4rem;
  display: block;
}

.link_button {
  height: 4rem;
  width: 4rem;
  display: block;
  fill: black;
}

@media (min-width: 767px) {
  .title{
    font-size: 1.6rem;
  }
}

@media (max-width: 767px) {
  .title{
    font-size: 2.4rem;
  }
}