.simpleButton {
	border: none;
	outline: none;
	background-color: transparent;
	padding: .5rem 2rem;
	font-family: inherit;
	border-radius: .5rem;
	cursor: pointer;
	transition: all .4s;
	height: 5rem;
}
.simpleButton__red {
	background-color: var(--red);
	border: 1px solid var(--red);
	/* border-bottom-right-radius: 10px; */

	color: var(--bg-primary);
	/* margin-right: 5rem; */
}
.simpleButton__red:hover {
	background-color: transparent;
	border: 1px solid var(--red);
	color: var(--red);
}
.simpleButton__black {
	background-color: var(--black);
	border: 1px solid var(--black);
	color: var(--bg-primary);
}
.simpleButton__black:hover {
	background-color: transparent;
	border: 1px solid var(--black);
	color: var(--black);
}
