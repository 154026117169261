.findanagent-search-main-section {
	display: flex;
	flex-direction: column;
	justify-content: center;
	text-align: center;
	background-color: rgb(238, 238, 238);
	justify-content: center;
	padding-bottom: 4rem;
	position: relative;
  }
  
  .findanagent-search-main-section>label {
	font-size: 2.5rem;
	font-weight: 600;
	padding: 1rem;
  }
  
  .findanagent-search-main-section-input {
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
  }
  
  .input_container  {
	position: relative;
	padding: 0rem 0rem 0rem 1rem;
	width: 40rem;
  }
  
  .input_container>input {
	height: 5rem;
	font-size: 1.2rem;
	margin: 0;
	padding: 0rem 0rem 0rem 2rem;
	width: 100%;
	outline: none;
	border-radius: 5px;
	padding-left: 10rem;
  }
  
  .input_container>.right_image {
	position: absolute;
	right: 1rem;
	width: 3rem;
	height: 3rem;
	margin-top: 10px;
	border-top-right-radius: 5px;
  }
  
  .input_container>.left_image {
	position: absolute;
	margin-top: 2px;
	margin-left: 2px;
	width: 8rem;
	height: 4.6rem;
	border-top-left-radius: 5px;
	padding: 4px;
	background-color: rgb(228, 228, 228);
	fill: var(--black);
  }
  
  .findanagent-search-main-section-input>input {}
  
  .findanagentContainer__formgroup--input {
	background-color: transparent;
	border: none;
	outline: none;
	font-family: inherit;
	width: 100%;
	height: 5vh;
	padding-left: 8rem;
  }
  
  .logoContainer__formgroup--iconBox {
	padding: 2rem;
	border-radius: 3rem;
	box-shadow: var(--box-shadow-input);
	height: 6.5rem;
	width: 6.5rem;
	color: white;
	background-color: rgb(255, 255, 255);
	border: none;
	outline: none;
	position: absolute;
	z-index: 999;
	cursor: pointer;
	transform: translateX(2px);
	transition: 0.3s;
	display: flex;
	align-items: center;
	text-align: center;
  }
  
  .findanagentContainer__formgroup--inputBox {
	display: flex;
	align-items: center;
	gap: 1rem;
	border-radius: 10rem;
	background-color: var(--bg-primary);
	box-shadow: var(--box-shadow-input);
	overflow: hidden;
	/* width: 35rem; */
	height: 5rem;
  }
  
  .logoContainer__formgroup_postfix--iconBox {
	padding: 1.5rem 2rem;
	display: flex;
	align-items: center;
	border-radius: 3.5rem;
  }
  
  .findanagentContainer__formgroup--icon {
	height: 2rem;
	width: 2rem;
	fill: var(--white);
  }
  
  .searchListContainer .findanagentContainer__formgroup--icon {
	height: 1.5rem;
	width: 1.5rem;
	fill: var(--white);
	margin: 6px;
  }
  
  .search_heading {
	font-family: Poppins;
	font-style: normal;
	font-weight: bold;
	line-height: 36px;
	color: #e02b21;
  }
  
  .user_profile_1 {
	display: inline-block;
	width: 100px;
	height: 100px;
	object-fit: cover;
  }
  
  .findanagent__content2 {
	width: 30%;
	position: relative;
	background-color: var(--white);
	margin-right: 2.5rem;
	border-radius: 1rem;
	padding: 2rem 3rem;
	padding-bottom: 0rem;
	display: flex;
	flex-direction: column;
	grid-gap: 2rem;
	gap: 2rem;
  }
  
  .findanagent__content1 {
	background-color: var(--white);
	border-radius: 1rem;
	padding: 2rem 3rem;
	padding-bottom: 0;
	display: flex;
	grid-gap: 2rem;
	gap: 2rem;
  }
  
  .search__button--iconBox {
	background-color: #e02b21;
	display: flex;
	align-items: center;
	padding: 1rem;
	border-radius: 50%;
	transition: all 1s;
  }
  
  .search__button--iconBox:hover {
	border: 1px solid;
	color: var(--black);
	background-color: var(--black);
  }
  
  .search__button--icon {
	fill: white;
	height: 3rem;
	width: 3rem;
	transition: all 1s;
  }
  
  .search__button {
	width: 6rem;
	display: flex;
	background: #ffffff00;
	border: 0px solid;
	cursor: pointer;
  }
  
  .main-section {
	display: flex;
	/* margin: 5rem 45rem 15rem 45rem; */
	margin-top: 5rem;
	text-align: center;
	justify-content: space-between;
	margin: 2rem 33rem;
	/* border: 1px solid var(--light-grey); */
	/* border-top: 1px solid var(--dark-grey); */
	position: relative;
  }
  
  .find-an-agent-sections {
	width: 60%;
	display: flex;
	gap: 2rem;
	margin-top: 1rem;
  }
  
  .main-section-left {
	flex: 1;
	height: 60vh;
	display: flex;
	flex-direction: column;
	overflow: auto;
	position: relative;
  }
  
  .main-section-left-content {
	display: flex;
  
	flex-direction: column;
  }
  
  .main-section-left-content>.ms-cart {
	display: flex;
	margin-bottom: 1rem;
	border-bottom: solid rgb(202, 202, 202) 1px;
	padding-bottom: 1rem;
  }
  
  .main-section-left-content>.ms-cart>img {
	height: 16rem;
  }
  
  .main-section-left-content>.ms-cart>.ms-content-details {
	flex: 1;
	display: flex;
	flex-direction: column;
	text-align: left;
	padding: 0 1rem;
  }
  
  .main-section-left-content>.ms-cart>.ms-content-details>.title {
	color: red;
	font-size: 1.7rem;
	font-weight: 600;
  }
  
  .main-section-left-content>.ms-cart>.ms-content-details>.subtitle {
	color: rgb(136, 136, 136);
	font-size: 1.4rem;
	font-weight: 500;
	display: flex;
	/* flex: 1; */
  }
  
  .main-section-left-content>.ms-cart>.ms-content-details>.subtitle>.ms--icon {
	color: rgb(136, 136, 136);
	fill: rgb(114, 114, 114);
	height: 2rem;
	width: 2rem;
	padding: 0.2rem;
	justify-self: center;
  }
  
  .main-section-left-content>.ms-cart>.ms-content-details>.subtitle-email {
	color: red;
	font-size: 1.5rem;
	font-weight: 500;
	display: flex;
	flex: 1;
  }
  
  .main-section-left-content>.ms-cart>.ms-content-details>.subtitle-email>.ms--icon {
	color: red;
	fill: red;
	height: 2rem;
	width: 2rem;
	padding: 0.2rem;
	justify-self: center;
  }
  
  .main-section-right {
	flex: 1;
	height: 100%;
  }
  
  .main-section-right>iframe {
	flex: 1;
	height: 60vh;
	width: 100%;
	border: none;
  }
  
  .ms-pagination {
	padding: 0 33rem;
	color: var(--dark-grey);
	margin-bottom: 3rem;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
  }
  
  .ms-pagination2 {
	margin-top: 1rem;
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	color: var(--dark-grey);
  }
  
  .main-section-right h2 {
	display: flex;
  }
  
  .main-section .previousBttn,
  .nextBttn {
	padding: 0.5rem 1.2rem !important;
	font-size: 1rem !important;
  }
  
  .google-map-section {
	display: flex;
	align-items: center;
	justify-content: center;
  }
  
  .fa-bottom-main {
	display: flex;
	/* height: 50rem; */
	background-color: var(--light-grey2);
	padding: 4rem 33rem;
	gap: 5rem;
	justify-content: center;
	position: relative;
  }
  
  .find-an-agent-image {
	width: 20vw;
  }
  
  .heading1_an_agent {
	font-size: 2.5rem;
	font-weight: 700;
	color: var(--red);
  }
  
  .subtitle_an_agent {
	font-size: 1.8rem;
	font-weight: 800;
  }
  
  .description {
	font-size: 1.5rem;
  }
  
  .ms-pagination1 {
	display: none;
  }
  
  .paginator_head {
	font-size: 1.3rem;
	color: rgb(107, 103, 103);
	font-weight: 300;
  }
  
  .pagenator {
	display: flex;
	/* gap: 1rem; */
	cursor: pointer;
  
	/* border: 1px solid var(--light-grey); */
  }
  
  .paginationActive {
	background-color: var(--light-grey2);
  }
  
  .paginationActive a {
	color: black;
  }
  
  .paginationBttns {
	list-style: none;
	font-family: Poppins;
	font-style: normal;
	font-weight: 500;
	font-size: 1rem;
	display: flex;
	align-items: center;
	color: black;
  }
  
  .paginationBttns li {
	height: 3rem;
	width: 3rem;
	border: 1px solid var(--light-grey2);
	display: flex;
	align-items: center;
	justify-content: center;
	transition: all 0.4s;
	overflow: hidden;
	color: black;
  }
  
  .paginationBttns a {
	padding: 0.5rem 0.4rem;
	/* margin: .8rem; */
	border: 1px solid var(--primary-color);
	color: black;
	cursor: pointer;
	transition: all 0.4s;
  }
  
  .paginationBttns li:hover {
	color: var(--white);
	border: 1px solid var(--dark-grey);
	background-color: var(--dark-grey);
  }
  
  .paginationBttns a:hover {
	color: var(--white);
  }
  
  .agent_details_section {
	display: flex;
	width: 100%;
	justify-content: space-between;
  }
  
  .agent_details {
	display: flex;
	padding: 3rem 33rem;
  }
  
  .agent_image {
	height: 20vh;
  }
  
  .agent_contact_details {
	margin-left: 2rem;
	align-self: center;
  }
  
  .agent_name {
	font-size: 3.5rem;
	color: var(--dark-grey);
  }
  
  .agent-content-details {
	flex: 1;
	display: flex;
	flex-direction: column;
	text-align: left;
	padding: 0 1rem;
  }
  
  .agent--icon {
	color: rgb(136, 136, 136);
	fill: rgb(114, 114, 114);
	height: 2rem;
	width: 2rem;
	padding: 0.2rem;
	justify-self: center;
  }
  
  .quote_minibox {
	width: 30rem;
	background-color: var(--light-grey2);
	padding: 1rem 2rem;
	border-radius: 10px;
	text-align: center;
	justify-content: space-between;
  }
  
  .quote_mini_heading {
	font-size: 2rem;
	font-weight: 700;
	padding-bottom: 1.5rem;
  }
  
  .quote_mini_subheading {
	font-size: 1.2rem;
	padding-bottom: 1.5rem;
  }
  
  .quote_mini_button {
	background-color: var(--red);
	padding: 1rem 5rem;
	border: none;
	outline: none;
	font-size: 1.4rem;
	border-radius: 5px;
	color: var(--white);
	font-weight: 500;
	text-decoration: none;
  }
  
  .about_me_outer {
	padding: 2rem 33rem;
	display: flex;
	gap: 2rem;
  }
  
  .about_me_section {
	/* padding: 2rem 33rem; */
	padding-bottom: 1rem;
	margin-bottom: 5rem;
  }
  
  .about_me_heading {
	font-size: 2rem;
	padding-bottom: 1rem;
  }
  
  .about_me_subheading {
	font-size: 1.6rem;
	font-weight: 300;
  }
  
  .contact_me_button {
	background-color: var(--dark-grey);
	color: var(--white);
	text-decoration: none;
	padding: 1rem 2rem;
	border-radius: 5px;
	font-weight: 500;
	cursor: pointer;
  }
  
  .office_hours_section {
	text-align: center;
  }
  
  /* .hours_section {
	  border: 1px solid black;
  } */
  
  .ofiice_houres_table {
	width: 35rem;
	height: 30rem;
  }
  
  .ofiice_houres_table,
  tr,
  td {
	border: 1px solid black;
	font-size: 1.5rem;
	font-weight: 600;
	border-collapse: collapse;
  }
  
  .agent_location {
	padding: 2rem 35rem;
  }
  
  .agent_location>iframe {
	height: 40vh;
	width: 100%;
	border: none;
  }
  
  /* .gm-style .place-card-large {
	  width: 100%;
	  padding: 1rem 2rem;
	  background-color: var(--red);
  } */
  .subtitle1 {
	color: rgb(136, 136, 136);
	font-size: 1.4rem;
	font-weight: 500;
	display: flex;
	/* flex: 1; */
  }
  
  .subtitle-email1 {
	font-size: 1.4rem;
	font-weight: 400;
	color: var(--red);
  }
  
  .quote_minibox1 {
	display: none;
	width: 30rem;
	background-color: var(--light-grey2);
	padding: 1rem 2rem;
	border-radius: 10px;
	text-align: center;
  }
  
  .no_data_message {
	font-size: 1.5rem;
	color: var(--dark-grey);
	margin-top: 5remx;
  }
  
  .agentemail {
	color: var(--red);
	text-decoration: none;
  }
  
  .agentemail:hover {
	text-decoration: underline;
  }
  
  .agentwebsite {
	color: var(--red);
	font-size: 1.4rem;
	text-decoration: none;
  }
  
  .agentwebsite:hover {
	text-decoration: underline;
  }
  
  .office_hours {
	text-decoration: none;
	justify-content: center;
	align-items: center;
	text-align: center;
	align-self: center;
	outline: none;
	border: none;
  }
  
  .modal_container_heading1 {
	display: flex;
	justify-content: space-between;
	padding: 0.5rem 2rem;
	background-color: var(--light-grey2);
  }
  
  .form_input__error {
	font-size: 1.2rem;
	color: #e02b21;
	display: flex;
	position: relative;
  }
  
  /* li {
	  border: .5px solid var(--light-grey);
	  padding: 0 .3rem;
  } */
  
  /* .fa-bottom-main > .left {
	  flex: 1;
	  justify-content: center;
	  text-align: center;
	  align-content: center;
  }
  
  .fa-bottom-main > .right {
	  flex: 1;
  }
  
  .fa-bottom-main > .right {
	  display: flex;
	  flex-direction: column;
	  float: left !important;
  }
  
  .fa-bottom-main > .right > .title {
	  font-size: 2.5rem;
	  color: red;
	  font-weight: 500;
	  font-family: inherit;
  }
  
  .fa-bottom-main > .right > .subtitle {
	  font-size: 2rem;
	  font-weight: 500;
	  font-family: inherit;
  }
  
  .fa-bottom-main > .right > .desc {
	  font-size: 1.5rem;
	  font-weight: 500;
	  font-family: inherit;
  } */
  .office_hours_css {
	border: none !important;
  }
  
  @media only screen and (max-width: 75em) {
	.findanagent-search-main-section {
	  display: flex;
	  flex-direction: column;
	  background-color: rgb(238, 238, 238);
	  padding-bottom: 1rem;
	}
  
	.findanagent-search-main-section>label {
	  font-size: 1.5rem;
	  font-weight: 600;
	  padding: 1rem;
	  text-align: left;
	}
  
	.findanagent-search-main-section-input {
	  display: flex;
	  justify-content: center;
	  text-align: center;
	  flex-direction: column;
	}
  
	.input_container {
	  position: relative;
	  padding: 0rem 0rem 0rem 1rem;
	  margin-top: 1rem;
	  width: 90vw;
	}
  
	.input_container>input {
	  height: 5rem;
	  margin: 0;
	  padding: 0rem 0rem 0rem 1rem;
	  width: 100%;
	  outline: none;
	  border-radius: 5px;
	  padding-left: 10rem;
	}
  
	.input_container>.right_image {
	  position: absolute;
	  justify-content: center;
	  align-content: center;
	  align-self: center;
	  margin-top: 7px;
	  border-top-right-radius: 5px;
	}
  
	.input_container>.left_image {
	  position: absolute;
	  margin-top: 2px;
	  margin-left: 2px;
	  width: 8rem;
	  height: 4.6rem;
	  border-top-left-radius: 5px;
	  padding: 4px;
	  background-color: rgb(228, 228, 228);
	  fill: var(--black);
	}
  
	.findanagent-search-main-section-input>input {}
  
	.main-section {
	  display: flex;
	  flex-direction: column;
	  margin-top: 5rem;
	  text-align: left;
	  padding: 0 -48rem;
	  width: 100%;
	}
  
	.main-section-left {
	  height: 60vh;
	  display: flex;
	  flex-direction: column;
	  overflow: auto;
	  width: 90vw;
	  margin-left: -46rem;
	}
  
	.main-section-left-content {
	  display: flex;
	  flex-direction: column;
	  width: 90vw;
	  flex: 1;
	}
  
	.main-section-left-content>.ms-cart {
	  display: flex;
	  margin-bottom: 1rem;
	  border-bottom: solid rgb(202, 202, 202) 1px;
	  padding-bottom: 1rem;
	}
  
	.main-section-left-content>.ms-cart>img {
	  height: 16vw;
	}
  
	.main-section-left-content>.ms-cart>.ms-content-details {
	  flex: 1;
	  display: flex;
	  flex-direction: column;
	  text-align: left;
	  padding: 0 1rem;
	}
  
	.main-section-left-content>.ms-cart>.ms-content-details>.title {
	  color: red;
	  font-size: 1.4rem;
	  font-weight: 600;
	}
  
	.main-section-left-content>.ms-cart>.ms-content-details>.subtitle {
	  color: rgb(136, 136, 136);
	  font-size: 1.1rem;
	  font-weight: 500;
	  display: flex;
	  flex: 1;
	}
  
	.main-section-left-content>.ms-cart>.ms-content-details>.subtitle>.ms--icon {
	  color: rgb(136, 136, 136);
	  fill: rgb(114, 114, 114);
	  height: 1.5rem;
	  width: 1.5rem;
	  padding: 0.2rem;
	  justify-self: center;
	}
  
	.main-section-left-content>.ms-cart>.ms-content-details>.subtitle-email {
	  color: red;
	  font-size: 1.2rem;
	  font-weight: 500;
	  display: flex;
	  flex: 1;
	}
  
	.main-section-left-content>.ms-cart>.ms-content-details>.subtitle-email>.ms--icon {
	  color: red;
	  fill: red;
	  height: 2rem;
	  width: 2rem;
	  padding: 0.2rem;
	  justify-self: center;
	}
  
	.main-section-right {
	  margin-left: -46rem;
	  width: 90vw;
	}
  
	.main-section-right>iframe {
	  flex: 1;
	  height: 60vh;
	  width: 100%;
	  border: none;
	}
  
	.ms-pagination {
	  /* padding: 0 48rem; */
	}
  
	.main-section-right h2 {
	  display: flex;
	}
  
	.main-section .previousBttn,
	.nextBttn {
	  padding: 0.5rem 1.2rem !important;
	  font-size: 1.8rem !important;
	}
  
	.google-map-section {
	  display: flex;
	  align-items: center;
	  justify-content: center;
	}
  
	.fa-bottom-main {
	  display: flex;
	  background-color: var(--light-grey2);
	  padding: 4rem 45rem;
	  gap: 5rem;
	  flex-direction: column;
	}
  
	.heading1_an_agent {
	  font-size: 2.2rem;
	  font-weight: 700;
	  color: var(--red);
	}
  
	.subtitle_an_agent {
	  font-size: 1.8rem;
	  font-weight: 400;
	}
  
	.description {
	  font-size: 1.5rem;
	}
  
	.find-an-agent-image {
	  width: 90vw;
	}
  
	/*
	  .fa-bottom-main > .left {
		  height: 30vh;
		  width: 90vw;
	  }
	  .fa-bottom-main > .left > img {
		  height: 30vh;
	  }
  
	  .fa-bottom-main > .right {
		  flex: 1;
		  height: 30vh;
		  width: 90vw;
	  }
  
	  .fa-bottom-main > .right {
		  display: flex;
		  flex-direction: column;
		  float: left !important;
	  }
  
	  .fa-bottom-main > .right > .title {
		  font-size: 2rem;
		  color: red;
		  font-weight: 500;
		  font-family: inherit;
	  }
  
	  .fa-bottom-main > .right > .subtitle {
		  font-size: 1.5rem;
		  font-weight: 500;
		  font-family: inherit;
	  }
  
	  .fa-bottom-main > .right > .desc {
		  font-size: 1.2rem;
		  font-weight: 500;
		  font-family: inherit;
	  } */
  }
  
  @media only screen and (max-width: 1500px) {
	.main-section {
	  margin: 2rem 15rem;
	}
  
	.ms-pagination {
	  padding: 0 15rem;
	}
  
	.fa-bottom-main {
	  padding: 4rem 20rem;
	}
  
	.agent_location {
	  padding: 1rem 15rem;
	}
  
	.about_me_outer {
	  padding: 1rem 15rem;
	}
  
	.agent_details {
	  padding: 1rem 15rem;
	}
  }
  
  @media only screen and (max-width: 1400px) {
	.main-section {
	  margin: 2rem 10rem;
	}
  
	.fa-bottom-main {
	  padding: 4rem 10rem;
	}
  
	.find-an-agent-image {
	  width: 40vw;
	}
  
	.agent_location {
	  padding: 1rem 15rem;
	}
  
	.about_me_outer {
	  padding: 1rem 15rem;
	}
  
	.agent_details {
	  padding: 1rem 15rem;
	}
  }
  
  @media only screen and (max-width: 1200px) {
	.main-section {
	  margin: 2rem 10rem;
	}
  
	.fa-bottom-main {
	  padding: 4rem 10rem;
	}
  
	.find-an-agent-image {
	  width: 40vw;
	}
  
	.agent_location {
	  padding: 1rem 10rem;
	}
  
	.about_me_outer {
	  padding: 1rem 10rem;
	}
  
	.agent_details {
	  padding: 1rem 10rem;
	}
  }
  
  @media only screen and (max-width: 760px) {
	.fa-bottom-main {
	  display: flex;
	  background-color: var(--light-grey2);
	  padding: 4rem 1rem;
	  gap: 5rem;
	  flex-direction: column;
	}
  
	.main-section {
	  display: flex;
	  flex-direction: column;
	  padding: 3rem 1rem;
	  margin: 0;
	}
  
	.main-section-left {
	  display: flex;
	  width: 85vw;
	  margin: 0;
	  padding: 0;
	}
  
	.main-section-right {
	  display: flex;
	  width: 85vw;
	  padding: 0;
	  margin: 0;
	}
  
	.ms-pagination {
	  display: flex;
	  flex-direction: column;
	  padding: 0;
	}
  
	.ms-pagination {
	  display: none;
	}
  
	.ms-pagination1 {
	  display: flex;
	  flex-direction: row;
	  justify-content: space-between;
	  padding: 2rem 1rem;
	}
  
	.agent_details {
	  padding: 1rem 0;
	}
  
	.quote_minibox {
	  display: none;
	}
  
	.hz_line {
	  width: 100%;
	}
  
	.quote_minibox1 {
	  display: flex;
	  flex-direction: column;
	  width: 30rem;
	}
  
	.about_me_outer {
	  padding: 1rem 1rem;
	  flex-direction: column;
	}
  
	.office_hours_section {
	  display: flex;
	  flex-direction: column;
	  align-items: center;
	  gap: 2rem;
	  margin-top: -5rem;
	}
  
	.agent_location {
	  width: 100%;
	  padding: 2rem 0;
	}
  }