.learning_content {
	margin: 1rem 33rem;
	font-family: "Poppins", sans-serif;
	font-weight: 400;
  }
  .learning__filters {
	display: grid;
	gap: 1rem;
	grid-template-columns: repeat(5, 1fr);
  }
  .learning__filters--btn {
	font-family: inherit;
	border: none;
	background-color: transparent;
	text-align: left;
	color: grey;
	padding: 1rem;
	/* min-width: 23.5rem; */
	cursor: pointer;
	box-shadow: 1px 1px 1rem rgba(0, 0, 0, 0.1);
	transition: all 0.4s;
  }
  .learning__filters--active {
	background-color: var(--red);
	color: var(--bg-primary);
  }
  .learning__filters--btn:hover {
	transform: scale(1.03);
  }
  .learning_content__cards {
	margin-top: 5rem;
	display: grid;
	grid-template-columns: repeat(3, 1fr);
  }
  .learning_content__card {
	background-size: cover;
	background-repeat: no-repeat;
	/* width: 40.5rem; */
	height: 25rem;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	padding-bottom: 2rem;
	padding-left: 2rem;
	color: var(--bg-primary);
	text-decoration: none;
	transition: all 0.4s;
  }
  .learning_content__card:hover .learning_content__card--title,
  .learning_content__card:hover .learning_content__card--date {
	transform: translateY(-1rem);
  }
  .learning_content__card--date {
	font-weight: 400;
	color: lightgray;
	display: flex;
	align-items: center;
	gap: 0.5rem;
	transition: all 0.4s;
  }
  .learning_content__card--date_icon {
	height: 1.5rem;
	width: 1.5rem;
	fill: lightgray;
  }
  .learning_content__card--title {
	font-weight: 400;
	transition: all 0.4s;
  }
  
  /* Blog Details */
  .blog__red_section {
	background-color: var(--red);
	padding: 3rem 33rem;
	font-family: "Poppins", sans-serif;
	font-weight: 400;
  }
  .blog__red_section--text {
	font-size: 3rem;
	text-align: center;
	color: var(--white);
	font-weight: 600;
  }
  .blog__data {
	padding: 3rem 40rem;
	font-family: "Poppins", sans-serif;
	font-weight: 400;
  }
  .blog__data--text {
	font-size: 1.5rem;
	font-weight: 500;
	color: grey;
  }
  .blog__data--heading {
	margin: 1rem 0;
	font-size: 2.5rem;
	font-weight: 600;
	color: var(--black);
  }
  .blog__data--source {
	margin-top: 1rem;
	display: flex;
	gap: 0.5rem;
	align-items: center;
  }
  .blog__data--source p {
	font-size: 1.5rem;
	font-weight: 500;
	color: grey;
  }
  .blog__data--source a {
	text-decoration: none;
	color: var(--red);
	font-size: 1.5rem;
	font-weight: 500;
	transition: all 0.4s;
  }
  .blog__data--source a:hover {
	/* border-bottom: 1px solid var(--red); */
	text-decoration: underline;
  }
  .blog__related {
	margin: 4rem 0;
	padding: 0 33rem;
  }
  .blog__related--title {
	font-size: 2rem;
	color: var(--black);
	font-weight: 600;
  }
  .blog__related--posts {
	margin: 2rem 0;
	display: grid;
	gap: 2rem;
	grid-template-columns: repeat(3, 1fr);
  }
  .blog__related--post {
	display: flex;
	flex-direction: column;
	gap: 1rem;
	align-items: flex-start;
	text-decoration: none;
  }
  .blog__related--image {
	/* height: fit-content; */
	width: 100%;
	height: 20rem;
  }
  .blog__related--heading {
	font-size: 1.5rem;
	font-weight: 600;
	color: var(--black);
  }
  .blog__related--link {
	background-color: var(--black);
	border: 1px solid var(--black);
	font-size: 1.3rem;
	color: var(--white);
	padding: 1rem 2rem;
	transition: all 0.4s;
  }
  .blog__related--link:hover {
	background-color: transparent;
	border: 1px solid var(--black);
	color: var(--black);
  }
  
  @media only screen and (max-width: 1500px) {
	.learning_content {
	  margin: 1rem 15rem;
	}
	.blog__red_section {
	  padding: 3rem 15rem;
	}
	.blog__data {
	  padding: 3rem 20rem;
	}
	.blog__related {
	  padding: 0 15rem;
	}
  }
  @media only screen and (max-width: 1200px) {
	.learning_content {
	  margin: 1rem 10rem;
	}
	.blog__related {
	  padding: 0 10rem;
	}
	.blog__red_section {
	  padding: 3rem 10rem;
	}
	.blog__data {
	  padding: 3rem 15rem;
	}
  }
  @media only screen and (max-width: 900px) {
	.learning_content {
	  margin: 1rem 5rem;
	}
	.blog__related {
	  padding: 0 5rem;
	}
	.blog__red_section {
	  padding: 3rem 5rem;
	}
	.blog__data {
	  padding: 3rem 10rem;
	}
  }
  
  @media only screen and (max-width: 765px) {
	.learning_content {
	  margin: 1rem 5rem;
	}
	.blog__data {
	  padding: 3rem 5rem;
	}
	.blog__related {
	  padding: 0 5rem;
	}
  }
  @media only screen and (max-width: 500px) {
	.learning_content {
	  margin: 1rem 0rem;
	}
	.blog__related {
	  padding: 0;
	}
	.blog__related--posts {
	  grid-template-columns: 1fr;
	}
	.learning__filters {
	  grid-template-columns: repeat(3, 1fr);
	}
  
	.learning_content__cards {
	  grid-template-columns: repeat(2, 1fr);
	}
  }
  @media only screen and (max-width: 30em) {
	.learning_content__cards {
	  margin-top: 5rem;
	  display: grid;
	  grid-template-columns: repeat(2, 1fr);
	}
	.learning__filters {
	  grid-template-columns: repeat(2, 1fr);
	}
  }
  @media only screen and (max-width: 20em) {
	.learning_content__cards {
	  margin-top: 5rem;
	  display: grid;
	  grid-template-columns: repeat(1, 1fr);
	}
  }
  .search_blog_container{
	text-align: center;
  }