.business_insurance {
	padding: 3rem 33rem;
	/* margin-top: 3rem; */
	padding-bottom: 0;
}
.business_insurance_coverage {
	padding: 3rem 33rem;
	background-color: var(--light-grey2);
	margin-top: 2rem;
}
.business_coverage_insurance {
	padding: 0 1rem;
	padding-top: 1rem;
}
.business_insurance-cover {
	display: flex;
	gap: 10rem;
	line-height: 3.5rem;
	padding: 0 0;
}
.business_insurance_discounts {
	padding: 3rem 33rem;
}
.line {
	background-color: var(--light-grey);
	color: var(--light-grey);
	display: flex;
	margin-left: 1rem;
	margin-right: -1rem;
	flex-direction: column;
	width: 5px;
	height: 100%;
}
@media only screen and (max-width: 1500px) {
	.business_insurance {
		padding: 3rem 15rem;
	}
	.business_insurance_coverage {
		padding: 3rem 15rem;
	}
	.business_insurance_discounts {
		padding: 3rem 15rem;
	}
}
@media only screen and (max-width: 1400px) {
	.business_insurance {
		padding: 3rem 15rem;
	}
	.business_insurance_coverage {
		padding: 3rem 15rem;
	}

	.business_insurance_discounts {
		padding: 3rem 15rem;
	}
	.quote2 {
		padding: 0 15rem;
	}
}
@media only screen and (max-width: 780px) {
	.business_insurance {
		padding: 3rem 1rem;
	}
	.business_insurance_coverage {
		padding: 3rem 1rem;
	}
	.business_insurance-cover {
		flex-direction: column;
		gap: 0;
		line-height: 4rem;
	}
	.business_insurance_discounts {
		padding: 3rem 1rem;
	}
	.line {
		display: none;
	}
}
