.icon_division {
  padding: 2rem 0rem 4rem;
  width: 30%;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.icon {
  height: 8rem;
}

.icon_division span {
  display: block;
  font-size: 1.4rem;
  font-weight: 600;
  margin-top: 1.8rem;
}

.divisionText {
  text-align: center;
  display: block;
}

.infoCard {
  background: #f0f0f0;
  border-radius: 12px;
  padding: 2rem 4rem;
  min-height: 18rem;
  min-width: 20rem;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.icon {
  width: 130px;
  height: 10rem;
}

@media (max-width: 767px) {
  .icon_division {
    width: 100%;
  }
  .infoCard {
    padding: 4rem 6rem;
  }
  .icon_division span {
    display: block;
    font-size: 2rem;
  }
}
