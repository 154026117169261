.life_insurance {
	padding: 3rem 33rem;
	padding-bottom: 0;
}
.Life_insurance_link {
	color: var(--red);
}
.life_insurance_starter {
	padding: 3rem 33rem;
	margin: 2rem 0;
	margin-bottom: 0;
	width: 100%;
	background-color: var(--light-grey2);
}
.life_insurance_starter-outer {
	padding: 2rem 1rem;
	display: flex;
	gap: 20rem;
}
.life_insurance_starter-left {
	width: 50%;
}
.lifebutton {
	margin-top: 5rem;
}
.life_insurance_button {
	background-color: var(--dark-grey);
	color: white;
	padding: 1.5rem 4rem;
	font-size: 1.6rem;
	font-weight: 300;
	outline: none;
	border: none;
	margin-top: 5rem;
	width: 100%;
	border-radius: 5px;
	cursor: pointer;
	text-decoration: none;
}
.life_insurance_button:hover {
	background-color: var(--black);
}
.life_insurance_starter-right {
	width: 50%;
}
.life_insurance_options {
	padding: 3rem 33rem;	
	background-color: var(--light-grey2);
	margin-bottom: 4rem;
}
.nail_icon_center{
	margin-top: 10rem;
}
@media only screen and (max-width: 1200px) {
	.life_insurance {
		padding: 3rem 10rem;
	}
	.life_insurance_starter {
		padding: 3rem 13rem;
	}

	.life_insurance_options {
		padding: 3rem 10rem;
	}
	/* .life_insurance_starter-outer {
		flex-direction: column;
		gap: 2rem;
	} */

	.life_insurance_starter-left {
		width: 100%;
	}
	.quote2 {
		padding: 0 10rem;
	}
	.homeowner_insurance_discounts-grid {
		width: 100%;
		justify-content: space-between;
	}

	/* .life_insurance_button {
		margin-top: 2rem;
		width: 80%;
		justify-content: center;
	} */
	.life_insurance_starter-right {
		width: 100%;
	}
}
@media only screen and (max-width: 760px) {
	.life_insurance {
		padding: 3rem 1rem;
	}
	.life_insurance_starter {
		padding: 3rem 3rem;
	}

	.life_insurance_options {
		padding: 3rem 1rem;
	}
	.life_insurance_starter-outer {
		flex-direction: column;
		gap: 2rem;
	}

	.life_insurance_starter-left {
		width: 100%;
	}
	.life_insurance_button {
		margin-top: 2rem;
		width: 80%;
		justify-content: center;
	}
	.life_insurance_starter-right {
		width: 100%;
	}
	.quote2 {
		padding: 0 1rem;
	}
}
