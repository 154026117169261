.send_message-contact--maincontact--main{
    padding-left: 10vw;
    padding-right: 10vw;
    display: flex;
    gap: 10rem;
}
.send_message-contact--container{
    position: absolute;
    top: 10%;
    max-width: 15vw;
    /* padding: 2rem; */
    background-color: var(--dark-grey);
    color: var(--bg-primary);

}

.send_message--container{
    margin-top: 2rem;
    margin-bottom: 2rem;
margin-left: 20vw;
    width: 100%;
}



.send_message-flex--container{
    display: flex;
    gap: 5rem;
    align-items: flex-end;
    /* background-color: purple; */
}
.checkbox-round {
    width: 1.3em;
    height: 1.3em;
    background-color: white;
    border-radius: 50%;
    vertical-align: middle;
    border: 1px solid #ddd;
    appearance: none;
    -webkit-appearance: none;
    outline: none;
    cursor: pointer;
}
.send_message-input--checkbox--label{
    font-weight: 600;
    font-size: 1.6rem;
    color: rgb(158, 158, 158);
    margin-top: 1.8rem;
}
.checkbox--container{
    display: flex;
    color: rgb(158, 158, 158);
    font-size: 1.2rem;
    gap: 2rem;
}
.actual--box{
    display: flex ;
    gap: 1rem;
}
.send_message-commentBox{
    display: flex;
    flex-direction: column;
}
.send_message-input--text--area{
    width: 100%;
    min-height: 15vh;
    font-family: inherit;
	border: 1px solid rgb(207, 207, 207);
    background-color: rgb(247, 246, 255)
}
.submit--button{
    color: rgb(158, 158, 158);
    border: 1px solid rgb(207, 207, 207);
    width: 7rem;
    height: 4rem;
    margin-top: 2rem;
}
.send_message-contact--image {
    width: 100%;
    height: 300px;
    object-fit: cover;
  }