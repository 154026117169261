.Container {
	display: flex;
	flex-direction: column;
	padding-top: 4rem;
	/* width: 100%;
	height: 100%; */
}
.textEditor {
	/* overflow: auto !important; */
	background-color: var(--white);
	border-radius: 1.5rem;
	height: 50vh !important;
	min-height: 50vh;
	overflow: hidden;
}

.button {
	display: flex;
	align-items: center;
	justify-content: space-between;
	/* margin-right: .5rem; */
	background-color: var(--white);
	height: 4rem;
	border-radius: 1rem;
}

.bcc_Button {
	background-color: transparent;
	border: none;
	font-size: 15px;
	margin-right: 1rem;
	text-decoration: underline;
	color: gray;
	cursor: pointer;
}

.react-multi-email {
	border: none !important;
	border-radius: 1.5rem !important;
	padding: 1rem 2rem !important;
	height: 3rem !important;
}
.react-multi-email > input {
	/* font-family: inherit !important; */
	width: 100% !important;
}
.react-multi-email-data {
	font-size: 1.2rem !important;
}
.react-multi-email > span[data-placeholder] {
	/* top: 1rem !important;
	left: 1.5rem !important; */
}
.react-multi-email [data-tag] [data-tag-handle] {
	margin-left: 0.5rem !important;
	display: flex;
	align-items: center;
	justify-content: center;
}
.react-multi-email-data-close-icon {
	height: 1.3rem;
	width: 1.3rem;
	fill: var(--grey);
}
.form_input__error {
	font-size: 1.2rem;
	color: #ff3366;
	margin-top: 0.5rem;
	margin-left: 0.5rem;
}

.Text {
	padding-left: 2rem;
	font-size: 15px;
	font-weight: 600;
	text-align: center;
}

.user_search_container{
	display: flex;
	position: relative;
	width: 100%;
	margin-left: 1rem;
}

.user_serach_input{
	width: 100%;
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	gap: 8px;
}

.user_serach_input input {
	border: none;
	height:  20px;
	padding: 5px;
}

.user_serach_input input:focus {
	outline: none;
}

.Suggestion_List{
	max-height: 300px;
	max-width: max-content;
	overflow-y: scroll;
	list-style: none;
	margin-left: 2rem;
	position: absolute;
	background-color: #FFF;
	border: 1px solid #ccc;
	border-radius: 1rem;
	/* z-index: 99; */
}

.Image{
	background-color: var(--white);
		box-shadow: 0px 0px 0.5rem 1px rgba(0, 0, 0, 0.5);
		border-radius: 50%;
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;
		height: 35px;
		width: 35px;
}

.Suggestion_List li:hover{
	background-color: #ccc;
}

.user_pill {
	height: 28px;
	display: flex;
	align-items: center;
	gap: 5px;
	background-color: #e7e7e7;
	color: rgb(80, 80, 80);
	font-size: 1.3rem;
	padding: 5px 10px;
	cursor: pointer;
}