.slider {
	position: relative;
	height: 60vh;
	background-size: cover;
	background-repeat: no-repeat;

	display: flex;
	flex-direction: column;
	justify-content: center;
	padding: 0 5rem;
}
.sider__heading {
	font-size: 2rem;
	font-weight: 400;
}
.sider__subheading {
	font-size: 1.3rem;
	font-weight: 500;
}
.slider__img {
	position: absolute;
	top: 0;
	width: 100%;
	z-index: 1;
}

/* section CSS */
.sections {
	margin-top: 8rem;
	margin-left: 5rem;
}
.section__heading {
	font-size: 1.4rem;
	font-weight: 600;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}
.section__heading::after {
	content: '';
	height: .5rem;
	width: 3rem;
	background-color: var(--black);
}
.section__contents {
	margin-top: 2rem;
	display: flex;
	gap: 2rem;
	flex-wrap: wrap;
}
.section {
	text-decoration: none;
	color: var(--black);
	border: none;
	font-family: inherit;
	background-color: var(--bg-primary);
	box-shadow: var(--box-shadow1);
	padding: 2rem 3rem;
	border-radius: 1rem;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	transition: all .4s;
}
.section:hover {
	transform: scale(1.05);
}
.section__image {
	height: 5rem;
}
.section__title {
	margin: .5rem 0;
	font-size: 1.3rem;
	font-weight: 500;
}
.section__logo {
	height: 3.5rem;
}

/* Contact CSS */
.contact {
	margin-top: 10rem;
	background-size: cover;
	padding: 0rem 15rem;
	height: 35rem;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
}
.contact__heading {
	font-size: 1.4rem;
	font-weight: 500;
	color: var(--red);
}
.contact__subheading { 
	margin-bottom: 2rem;
}
.contact__text { 
	font-weight: 500;
	margin-bottom: 1rem;
}
.contact__btn {
	border: 1px solid var(--red);
	background-color: var(--red);
	border-radius: 1rem;
	font-family: inherit;
	padding: 1rem 2rem;
	color: var(--bg-primary);
	cursor: pointer;
	transition: all .4s;
}
.contact__btn:hover {
	background-color: transparent;
	color: var(--red);
}
