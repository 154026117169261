.working_at__main-content-margin {
  padding: 3rem 33rem;
  background-color: var(--dark-grey);
  justify-content: center;
  display: flex;
  flex-direction: column;
  padding-top: 2rem;
}

.working_at__heading__heading_1 {
  font-weight: 500;
  font-size: 2.5rem;
  color: white;
  display: flex;
}
.working_at_home_outer {
  display: flex;
  width: 100%;
  gap: 5rem;
  /* justify-content: space-between; */
  align-items: center;
  margin-bottom: 2rem;
}
.working_at_home {
  display: flex;
  width: 70%;
}
.career_image_outer {
  width: 30%;
  display: flex;
}
.career_image {
  display: flex;
  width: 15vw;
  /* margin-top: -3rem; */
}
.working_at__texts-heading_2 {
  font-size: 1.6rem;
  font-weight: 300;
  color: white;
}

.why_choose {
  padding: 3rem 33rem;
  display: flex;
  /* flex-direction: column; */
  width: 100%;
  gap: 2rem;
  /* align-items: center; */
  /* position: relative; */
}
.why_choose__heading-heading_1 {
  font-size: 2.5rem;
}
.why_choose__subheading {
  font-size: 1.8rem;
  font-weight: 700;
  padding-bottom: 1.2rem;
}
.why_choose__text {
  font-size: 1.6rem;
  font-weight: 300;
}
.why_choose__section {
  z-index: 100;
  display: flex;
  width: 50%;
  flex-direction: column;
}

.testimonials {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50%;
}

.testimonials__heading {
  font-weight: 600;
  font-size: 2.5rem;
  text-align: center;
}

.testimonials__subheading {
  font-weight: 500;
}

.testemonials__data {
  display: flex;
  gap: 2rem;
  margin-top: 2rem;
  width: 100%;
  height: 35vh;
}
.testimonial {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.testimonial__image {
  width: 12rem;
  border-radius: 50%;
}
.testimonial__text {
  padding: 2rem 5rem;
  text-align: center;
  font-size: 1.6rem;
}
.testimonial__name {
  font-weight: 600;
  line-height: 1;
}
.testimonial__subtext {
  font-weight: 500;
  line-height: 1;
  font-style: italic;
}
.testimonial__hr {
  width: 20rem;
  height: 3px;
  background-color: black;
  margin-top: 0rem;
}
.testimonial__hr1 {
  width: 20rem;
  height: 3px;
  background-color: black;
  margin-top: 1rem;
}

.application {
  background-color: var(--light-grey2);
  padding: 1rem 33rem;
  display: flex;
  width: 100%;
  justify-content: space-between;
  /* gap: 20rem; */
}

.application__card {
  /* width: 50%; */
  padding: 2rem 0rem;
  display: flex;
  gap: 2rem;
}
.application__card--image {
  width: 8rem;
}
.application_card_text {
  display: flex;
  flex-direction: column;
}
.application__card--heading {
  /* margin-top: 1rem; */
  font-weight: 600;
  font-size: 2.5rem;
}
.application__card--text {
  font-weight: 500;
  font-size: 1.5rem;
}
.working_at .button__text {
  text-transform: capitalize;
}
.button_text1 {
  margin-top: 2.5rem;
}
.humanresource__link {
  color: red;
  text-decoration: none;
}
.why_choose__department {
  font-family: inherit;
  border: none;
  cursor: pointer;
  margin-top: 2rem;
  background-color: var(--bg-primary);
  padding: 2rem 1rem;
  border-radius: 1rem;
  transition: all 0.4s;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.why_choose__department:hover {
  transform: translateX(0.5rem);
  background-color: var(--light-border);
  box-shadow: var(--box-shadow1);
}

.why_choose__department--text {
  font-weight: 500;
  text-align: center;
}
.main-content-department {
  padding-right: 35rem;
  padding-left: 35rem;
}

.department {
  padding: 1.5rem 35rem;
}
.department__heading1 {
  justify-content: center;
  display: flex;
  font-size: 2.5rem;
  font-weight: 800;
  margin-bottom: 2rem;
}
.department__heading2 {
  font-size: 1.8rem;
  font-weight: 700;
  display: inline;
  padding-top: 0rem;
}
.department__heading3 {
  font-size: 1.5rem;
  font-weight: 500;
  position: relative;
}
.department__gridcard__image {
  padding: 0.8rem;
  background-color: red;
  border-radius: 10%;
  height: 80px;
  width: 80px;
  fill: white;
  color: white;
  align-self: center;
}
.depatment__labels {
  display: flex;
  flex-direction: column;
  margin-left: 2rem;
  margin-top: 1.3rem;
}
.department__gridcard {
  height: 10rem;
  width: 35rem;
  display: flex;
  position: relative;
}
.department__maingrid {
  display: flex;
  flex-direction: row;
  position: relative;
  /* gap: 3rem; */
  justify-content: space-between;
  margin-bottom: 2rem;
}
@import url(https://fonts.googleapis.com/css?family=Varela+Round);

.slider1 {
  background-color: var(--pure-white);
}
.slides {
  padding: 0;
  display: block;
  margin: 0 0;
  width: 60rem;
  height: 38rem;
  position: relative;
}
.slides * {
  user-select: none;
  -ms-user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -webkit-touch-callout: none;
}
.slides input {
  display: none;
}

.slide-container {
  display: block;
  display: flex;
  justify-content: center;
}
.slide {
  top: 0;
  opacity: 0;
  display: block;
  position: absolute;
  transform: scale(0);
  transition: all 0.7s ease-in-out;
}

input:checked + .slide-container .slide {
  opacity: 1;
  transform: scale(1);
  transition: opacity 1s ease-in-out;
}

input:checked + .slide-container .nav label {
  display: block;
}

.nav-dots {
  position: absolute;
  left: 50%;
  transform: translateX(-55%);
  bottom: 30px;
  height: 11px;
  display: flex;
  justify-content: center;
}

.nav-dots .nav-dot {
  top: -5px;
  width: 2rem;
  height: 2rem;
  margin: 0 4px;
  position: relative;
  border-radius: 100%;
  /* display: inline-block; */
  background-color: rgba(241, 11, 11, 0.6);
}

.nav-dots .nav-dot:hover {
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.8);
}

input#img-1:checked ~ .nav-dots label#img-dot-1,
input#img-2:checked ~ .nav-dots label#img-dot-2,
input#img-3:checked ~ .nav-dots label#img-dot-3,
input#img-4:checked ~ .nav-dots label#img-dot-4,
input#img-5:checked ~ .nav-dots label#img-dot-5,
input#img-6:checked ~ .nav-dots label#img-dot-6 {
  background: rgba(0, 0, 0, 0.8);
}
.slider_video {
  width: 100%;
  border-radius: 10px;
}

@media only screen and (max-width: 1500px) {
  .working_at__main-content-margin {
    padding: 3rem 15rem;
  }
  .why_choose {
    padding: 3rem 15rem;
  }
  .application {
    padding: 1rem 15rem;
  }
  .department {
    padding: 1.5rem 15rem;
  }
}
@media only screen and (max-width: 1400px) {
  .working_at__main-content-margin {
    padding: 3rem 15rem;
  }
  .why_choose {
    padding: 3rem 15rem;
  }
  .application {
    padding: 1rem 15rem;
  }
  .department {
    padding: 1.5rem 15rem;
  }
  .slides {
    padding: 0;
    display: block;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
}
@media only screen and (max-width: 1200px) {
  .working_at__main-content-margin {
    padding: 3rem 10rem;
  }
  .why_choose {
    padding: 3rem 10rem;
  }
  .application {
    padding: 1rem 10rem;
  }
  .department {
    padding: 1.5rem 10rem;
  }
  .department__gridcard {
    margin-bottom: 3rem;
  }

  .slides {
    padding: 0;
    display: block;
  }
  .nav-dots {
    position: absolute;
    width: 100%;
    left: 55%;
    transform: translateX(center);

    bottom: 10px;
    height: 11px;
    display: flex;
  }
}
@media only screen and (max-width: 900px) {
  .department__gridcard {
    margin-bottom: 5rem;
  }
}

@media only screen and (max-width: 760px) {
  .working_at__main-content-margin {
    padding: 3rem 1rem;
    width: 100%;
    flex-direction: column;
  }

  .working_at_home {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .career_image_outer {
    display: none;
  }
  .career_image {
    display: none;
  }
  .why_choose {
    padding: 3rem 1rem;
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .why_choose__section {
    width: 100%;
  }
  .testimonials {
    padding: 2rem 0;
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .testimonials__heading {
    width: 100%;
    text-align: center;
  }
  .testimonial__text {
    padding: 2rem 0;
    display: flex;
  }
  .testemonials__data {
    display: flex;
    gap: 2rem;
    margin-top: 2rem;
    width: 80vw;
  }
  .application {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: auto;
    padding: 2rem 1rem;
  }
  .department__maingrid {
    display: flex;
    flex-direction: column;
    gap: 3rem;
    padding-bottom: 1.5rem;
  }
  .department {
    padding: 1.5rem 2rem;
    justify-content: start;
  }
  .department__gridcard {
    margin-bottom: 5rem;
  }
  .application__card--image {
    width: 6rem;
    height: 6rem;
  }
  .slider1 {
    display: flex;
    align-self: center;
    align-items: center;
    text-align: center;
    justify-content: center;
  }
  .nav-dots {
    /* position: absolute;
		width: 85vw; */
    left: 0;
    transform: translateX(0);
    width: 85vw;
    bottom: 10px;
    height: 11px;
    display: flex;
    justify-content: center;
  }
}
