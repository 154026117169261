.inputHeading {
  font-size: 1.6rem;
  color: grey;
}

.main {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid rgba(162, 162, 162, 0.32);
  padding-bottom: 2rem;
}