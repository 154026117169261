.modal_container_heading {
	display: flex;
	justify-content: space-between;
	padding: 1rem 2rem;
}
.modal_heading_text {
	font-size: 2rem;
	font-weight: 400;
}
.modalContainer_subheading {
	display: flex;
	font-size: 1.4rem;
	padding: 1rem 2rem;
	font-weight: 400;
}
.pre_heading1 {
	display: flex;
	padding: 0 2rem;
	flex-wrap: wrap;
	gap: 3rem;
	align-items: flex-end;
	padding-bottom: 2rem;
}
.label_heading_quote1 {
	font-weight: 500;
	padding: 0 2rem;
	font-size: 1.6rem;
	color: grey;
	margin-top: 1.8rem;
}
.form_textbox1 {
	width: 115%;
}
.submit_button {
	padding: 0 2rem;
}
.main__form_button1 {
	/* padding: 2rem 2rem; */
	width: 10vh;
	height: 4vh;
	font-size: 2rem;
	margin-bottom: 2rem;
	margin-top: 2rem;
}
