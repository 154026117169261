.login_main_content {
	padding: 3rem 33rem;
	width: 100%;
	display: flex;
	gap: 10rem;
}
.login_tabset {
	width: 50%;
}
.login_container {
	width: 100%;
}
.login_button {
	margin-top: 2rem;
}
@media only screen and (max-width: 1500px) {
	.login_main_content {
		padding: 3rem 15rem;
	}
}
@media only screen and (max-width: 1400px) {
	.login_main_content {
		padding: 3rem 10rem;

		gap: 10rem;
	}
	.mw_products {
		padding: 3rem 10rem;
	}
}
@media only screen and (max-width: 767px) {
	.login_main_content {
		padding: 3rem 1rem;
		display: flex;
		flex-direction: column-reverse;
		gap: 2rem;
	}
}
/* .login_container {
	margin: 1rem 0;
	padding: 5rem 7rem;
	border-radius: 2rem;
	background-color: var(--bg-primary);
	box-shadow: var(--box-shadow1);
		display: flex;
	gap: 2rem;
	flex-direction: column;
	align-items: flex-start;
}
.login_container__heading {
	font-size: 1.5rem;
	font-weight: 600;
}
.login_container--forgot {
	align-self: flex-end;
	background-color: transparent;
	border: none;
	font-family: inherit;
	outline: none;
	font-size: 1.3rem;
} */
