.textBox {
	position: relative;
}
.textBox__label {
	position: absolute;
	top: -.5rem;
	left: 0;
	z-index: 10;
	background-color: var(--bg-primary);
	box-shadow: var(--box-shadow1);
	border-radius: 50%;
	padding: 1.3rem;
	display: flex;
}
.textBox__label--icon {
	height: 2.5rem;
	width: 2.5rem;
	fill: var(--black);
}
.textBox__input {
	background-color: var(--bg-primary);
	box-shadow: var(--box-shadow1);
	border-radius: 2rem;
	overflow: hidden;
	width: 30rem;
	display: flex;
	transition: all 0.4s;
}
.textBox__input:focus-within {
	box-shadow: var(--box-shadow2);
}
.textBox__input--box {
	width: 100%;
	margin-left: 5rem;
	background-color: var(--bg-primary);
	font-family: inherit;
	border: none;
	outline: none;
	padding: 1rem 1rem;
}
.textBox__input--show {
	margin-right: 1rem;
	border: none;
	background-color: transparent;
	cursor: pointer;
}
.textBox__input--show:hover .textBox__input--showIcon {
	fill: var(--red);
}
.textBox__input--showIcon {
	height: 2rem;
	width: 2rem;
	fill: var(--dark-grey);
	transition: all .4s;
}
