.cardContainer {
  background-color: #ffffff;
  border-radius: 20px;
  padding: 20px 22px;
  margin-bottom: 6rem;
}

.title {
  font-size: 16px;
  display: block;
  font-weight: 500;
  padding-bottom: 1rem;
}

@media (max-width: 767px) {
 .cardContainer{
  padding: 5px 20px;
  margin-bottom: 0rem;
 }
}