@import url(https://fonts.googleapis.com/css?family=Varela+Round);
:root {
  --bg-primary: #fff;
  --white: #fff;
  --black: rgba(0, 0, 0);
  --black-hover: rgb(17, 17, 17, 0.2);
  /* --dark-grey: #36363F; */
  --dark-grey: #2f2362;
  --grey-border: #36363f;
  /* --light-grey: #e6e6e6; */
  --light-grey: #f2f2f2;
  --light-grey2: #f2f2f2;
  --light-border: #9e8d8d;
  --menu-item: #555555;
  --menu-item-hover: #555555;
  --menu-item-selected: #3b3b3b;
  --dark-green-border: #46812b;
  --red: #e49f15;
  --red-transparent: #e49f15b6;
  --pure-white: #ffffff;
  --box-shadow1: 0.5rem 0.5rem 2rem 2px rgb(0 0 0 / 14%);
  --box-shadow-input: 0.5rem 0.2rem 1rem 2px rgb(0 0 0 / 14%);
  --bp-largest: 75em;
  /* 1200px/16 */
  --bp-large: 68.75em;
  /* 1100px/16 */
  --bp-medium: 56.25em;
  /* 900px/16 */
  --bp-small: 37.5em;
  /* 600px/16 */
  --bp-smallest: 25em;
  /* 400px/16 */
}

*,
*::before,
*::after {
  margin: 0px;
  padding: 0px;
  box-sizing: inherit;
}

@font-face {
  font-family: 'Black';
  src: local('Metropolis-Black'),
    url(/static/media/Metropolis-Black.1510951e.otf) format('opentype');
}

@font-face {
  font-family: 'ExtraBold';
  src: local('Metropolis-ExtraBold'),
    url(/static/media/Metropolis-ExtraBold.f179dd5f.otf) format('opentype');
}

@font-face {
  font-family: 'SemiBold';
  src: local('Metropolis-SemiBold'),
    url(/static/media/Metropolis-SemiBold.5e856013.otf) format('opentype');
}

@font-face {
  font-family: 'Bold';
  src: local('Metropolis-Bold'),
    url(/static/media/Metropolis-Bold.c5e04faf.otf) format('opentype');
}

@font-face {
  font-family: 'Medium';
  src: local('Metropolis-Medium'),
    url(/static/media/Metropolis-Medium.cdcce862.otf) format('opentype');
}

@font-face {
  font-family: 'Regular';
  src: local('Metropolis-Regular'),
    url(/static/media/Metropolis-Regular.67a1988d.otf) format('opentype');
}

html {
  font-size: 62.5%;
}

.mwfbi_banner__edit {
  font-size: 1.6rem;
  font-weight: 200;
  color: white;
  text-align: center;
  position: absolute;
  right: 3rem;
  top: 12rem;
  height: 3rem;
  width: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  border-radius: 0.5rem;
  transition: all 0.4s;
}

.mwfbi_banner__edit:hover {
  cursor: pointer;
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

.mwfbi_banner__edit:hover ~ .edit-effect {
  color: red !important;
}

.mwfbi_banner__edit > svg {
  fill: none;
  color: black;
  height: 2rem;
  width: 2rem;
}

.mwfbi_banner__edit2 {
  font-size: 1.6rem;
  font-weight: 200;
  color: white;
  text-align: center;
  position: absolute;
  right: 3rem;
  top: 1rem;
  height: 3rem;
  width: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  border-radius: 0.5rem;
  transition: all 0.4s;
}

.mwfbi_banner__edit2:hover {
  cursor: pointer;
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

.mwfbi_banner__edit2:hover ~ .edit-effect {
  color: red !important;
}

.mwfbi_banner__edit2 > svg {
  fill: none;
  color: black;
  height: 2rem;
  width: 2rem;
}

/* @media only screen and (max-width: 68.75em) {
	html {
		font-size: 40%;
	}
}

@media only screen and (max-width: 56.25em) {
	html {
		font-size: 40%;
	}
} */

@media only screen and (max-width: 68.75em) {
  html {
    font-size: 40%;
  }
}

@media only screen and (max-width: 56.25em) {
  html {
    font-size: 40%;
  }
}

@media only screen and (min-width: 670px) and (max-width: 1000px) {
  html {
    font-size: 40%;
  }
}

@media only screen and (min-width: 1001px) and (max-width: 1083px) {
  html {
    font-size: 40%;
  }
}

@media only screen and (min-width: 1084px) and (max-width: 1200px) {
  html {
    font-size: 50%;
  }
}

@media only screen and (min-width: 1201px) and (max-width: 1300px) {
  html {
    font-size: 55%;
  }
}

@media only screen and (min-width: 1301px) and (max-width: 1490px) {
  html {
    font-size: 60%;
  }
}

@media only screen and (min-width: 1491px) and (max-width: 1700px) {
  html {
    font-size: 56%;
  }
}

@media only screen and (min-width: 1701px) and (max-width: 1950px) {
  html {
    font-size: 62%;
  }
}

@media only screen and (min-width: 1951px) and (max-width: 2000px) {
  html {
    font-size: 68%;
  }
}

@media only screen and (min-width: 2001px) and (max-width: 2150px) {
  html {
    font-size: 72%;
  }
}

@media only screen and (min-width: 2151px) and (max-width: 2300px) {
  html {
    font-size: 78%;
  }
}

@media only screen and (min-width: 2301px) and (max-width: 2500px) {
  html {
    font-size: 80%;
  }
}

@media only screen and (min-width: 2501px) and (max-width: 2800px) {
  html {
    font-size: 88%;
  }
}

@media only screen and (min-width: 2801px) and (max-width: 2900px) {
  html {
    font-size: 92%;
  }
}

@media only screen and (min-width: 2801px) and (max-width: 3000px) {
  html {
    font-size: 98%;
  }
}

@media only screen and (min-width: 3001px) and (max-width: 3200px) {
  html {
    font-size: 104%;
  }
}

@media only screen and (min-width: 3201px) and (max-width: 3400px) {
  html {
    font-size: 108%;
  }
}

@media only screen and (min-width: 3401px) and (max-width: 3600px) {
  html {
    font-size: 114%;
  }
}

@media only screen and (min-width: 3601px) and (max-width: 3800px) {
  html {
    font-size: 120%;
  }
}

@media only screen and (min-width: 3801px) and (max-width: 4000px) {
  html {
    font-size: 128%;
  }
}

@media only screen and (min-width: 4001px) and (max-width: 4600px) {
  html {
    font-size: 140%;
  }
}

@media only screen and (min-width: 4601px) and (max-width: 5000px) {
  html {
    font-size: 160%;
  }
}

@media only screen and (min-width: 5001px) {
  html {
    font-size: 200%;
  }
}

@media only screen and (min-width: 670px) and (max-width: 1000px) {
	html {
		font-size: 40%;
	}
}

@media only screen and (min-width: 1001px) and (max-width: 1083px) {
	html {
		font-size: 40%;
	}
}

@media only screen and (min-width: 1084px) and (max-width: 1200px) {
	html {
		font-size: 50%;
	}
}

@media only screen and (min-width: 1201px) and (max-width: 1300px) {
	html {
		font-size: 55%;
	}
}

@media only screen and (min-width: 1301px) and (max-width: 1490px) {
	html {
		font-size: 60%;
	}
}

@media only screen and (min-width: 1491px) and (max-width: 1700px) {
	html {
		font-size: 56%;
	}
}

@media only screen and (min-width: 1701px) and (max-width: 1950px) {
	html {
		font-size: 62%;
	}
}

@media only screen and (min-width: 1951px) and (max-width: 2000px) {
	html {
		font-size: 68%;
	}
}

@media only screen and (min-width: 2001px) and (max-width: 2150px) {
	html {
		font-size: 72%;
	}
}

@media only screen and (min-width: 2151px) and (max-width: 2300px) {
	html {
		font-size: 78%;
	}
}

@media only screen and (min-width: 2301px) and (max-width: 2500px) {
	html {
		font-size: 80%;
	}
}

@media only screen and (min-width: 2501px) and (max-width: 2800px) {
	html {
		font-size: 88%;
	}
}

@media only screen and (min-width: 2801px) and (max-width: 2900px) {
	html {
		font-size: 92%;
	}
}

@media only screen and (min-width: 2801px) and (max-width: 3000px) {
	html {
		font-size: 98%;
	}
}

@media only screen and (min-width: 3001px) and (max-width: 3200px) {
	html {
		font-size: 104%;
	}
}

@media only screen and (min-width: 3201px) and (max-width: 3400px) {
	html {
		font-size: 108%;
	}
}

@media only screen and (min-width: 3401px) and (max-width: 3600px) {
	html {
		font-size: 114%;
	}
}

@media only screen and (min-width: 3601px) and (max-width: 3800px) {
	html {
		font-size: 120%;
	}
}

@media only screen and (min-width: 3801px) and (max-width: 4000px) {
	html {
		font-size: 128%;
	}
}

@media only screen and (min-width: 4001px) and (max-width: 4600px) {
	html {
		font-size: 140%;
	}
}

@media only screen and (min-width: 4601px) and (max-width: 5000px) {
	html {
		font-size: 160%;
	}
}

@media only screen and (min-width: 5001px) {
	html {
		font-size: 200%;
	}
}

body {
  box-sizing: border-box;
  font-family: 'Medium';
  line-height: 1.7;
  background-color: #fff;
  background-color: var(--bg-primary);
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

body::-webkit-scrollbar {
  /* display: none; */
}

.main-content-margin {
  padding: 3rem 20rem;
}

.main-content-padding {
  padding-left: 15rem !important;
  padding-right: 15rem !important;
}

label {
  font-weight: 500;
}

.heading_1 {
  font-size: 4rem;
}

.dash_heading_1 {
  font-size: 1.8rem;
}

.heading_2 {
  font-size: 2rem;
}

.heading_3 {
  font-size: 1.3rem;
}

.heading_4 {
  font-size: 1.3rem;
}

.heading_5 {
  font-size: 2rem;
  color: #2e1363;
  text-decoration: none;
}

.phase1_heading {
  font-size: 3rem;
  font-weight: 700;
}

.phase2_heading {
  font-size: 2rem;
  font-weight: 800;
  /* color: green; */
}

.phase2_heading1 {
  font-size: 1.6rem;
  font-weight: 800;
  /* color: rebeccapurple; */
}

.phase2_heading2 {
  font-size: 0.75rem;
  font-weight: bold;
}

.phase2_heading3 {
  color: #e49f15;
  color: var(--red);
  width: 30%;
  font-size: 1.6rem;
  font-weight: 700;
  cursor: pointer;
  /* padding-right: 10rem; */
}

.phase2_subheading {
  font-size: 1.6rem;
  font-weight: 300;
  /* color: rgb(238, 16, 146); */
}

.phase2_subheading2 {
  color: #e49f15;
  color: var(--red);
  cursor: pointer;
  text-transform: uppercase;
  font-size: 1.6rem;
  font-weight: 300;
  text-decoration: none;
}

.phase2_subheading1 {
  font-size: 1.6rem;
  font-weight: 300;
  width: 70%;
  /* color: rgb(238, 16, 146); */
}

/* .phase2_subheading1 {
	font-size: 0.75rem;
	font-weight: 400;
} */

@media only screen and (max-width: 75em) {
  .main-content-margin {
    display: flex;
    margin-left: 0rem !important;
    margin-right: 0rem !important;
  }

  .main-content-padding {
    display: flex;
    /* justify-content: start; */
    padding-left: 0rem !important;
    padding-right: 0rem !important;
  }
}

.header {
	grid-row: 1 / 2;
	background-color: var(--bg-primary);
	box-shadow: var(--box-shadow1);
	padding: 2rem 5rem;
	position: -webkit-sticky;
	position: sticky;
	width: 100%;
	z-index: 1000;
	display: flex;
	align-items: center;
  }
  .tooltipViewNaviagtion {
	position: relative;
	z-index: 1;
	right: 14.4rem;
	top: 1.4rem;
  }
  .tooltipViewNaviagtion1 {
	display: none;
  }
  .logo {
	height: 4.5rem;
	margin-top: 1rem;
  }
  .navigation {
	margin-left: 2rem;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
  }
  .navigation__links {
	list-style: none;
	font-size: 1.2rem;
	display: flex;
	grid-gap: 1rem;
	gap: 1rem;
	align-items: center;
  }
  .navigation__link {
	text-decoration: none;
	color: currentColor;
	font-weight: 600;
  }
  .navigation__active {
	color: var(--red) !important;
  }
  .navigation__link2 {
	text-decoration: none;
	color: currentColor;
	font-family: "Medium";
	font-size: 1.1rem;
	background-color: var(--red);
	border: 1px solid var(--red);
	color: var(--bg-primary);
	padding: 0.3rem 2.5rem;
	border-radius: 0.5rem;
	transition: all 0.4s;
  }
  
  .navigation__link2:hover {
	background-color: transparent;
	color: var(--red);
  }
  @media only screen and (max-width: 37.5em) {
	.logo {
	  height: 4rem;
	  margin-top: 0;
	}
  }
  
  @media only screen and (min-width: 1500px) {
	.logo {
	  height: 4.8rem;
	}
  }
  
  @media only screen and (max-width: 669px) {
	.tooltipViewNaviagtion {
	  left: 26rem;
	  top: 1.8rem;
	  display: none;
	}
	.tooltipViewNaviagtion1 {
	  display: block;
	  position: relative;
	  z-index: 1;
	  left: 28rem;
	  top: 2.2rem;
	}
  }
  
.footer {
	grid-row: 4 / -1;
	align-self: center;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding-top: 3rem;
	background-color: var(--dark-grey);
	color: #fefefe;
}
.footer__navigation {
	padding: 0 20rem;
	/* width: 100%; */
	margin-bottom: 4rem;
	display: flex;
	justify-content: space-around;
	align-items: flex-start;
	grid-gap: 10rem;
	gap: 10rem;
}
.footer__navigation--links {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
}
.footer__navigation--linkIconCallContainer {
	display: flex;
	grid-gap: .5rem;
	gap: .5rem;
	margin-bottom: 1rem;
}
.footer__navigation--heading {
	font-size: 1.3rem;
	font-weight: 500;
}
.footer__navigation--link {
	display: flex;
	grid-gap: .5rem;
	gap: .5rem;
	align-items: center;
	text-decoration: none;
	color: #fefefe;
	font-size: 1rem;
	font-family: 'Regular';
	transition: all .4s;
	padding-left: 1rem;
}
.footer__navigation--link:hover {
	/* color: var(--red); */
	-webkit-transform: translateX(.2rem);
	        transform: translateX(.2rem);
}
.footer__navigation--linkIcon {
	height: 1rem;
	width: 1rem;
	fill: #fefefe;
	display: none;
}
.footer__navigation--linkIconCall {
	height: 2.5rem;
	width: 2.5rem;
	fill: #fefefe;
}
.footer__social {
	border-top: 1px solid #5e5e68;
	padding-top: 2rem;
	align-self: flex-start;
	width: 100%;
	display: flex;
	grid-gap: 1rem;
	gap: 1rem;
	justify-content: center;
	margin-bottom: 1rem;
}
.footer__copyright {
	font-size: 1rem;
	font-family: 'Regular';
}
.footer__links {
	display: flex;
	grid-gap: 1rem;
	gap: 1rem;
	align-items: center;
}
.footer__links--link {
	text-decoration: none;
	color: #fefefe;
	font-size: 1rem;
	font-family: 'Regular';
	transition: all .4s;
}
.footer__links--link:hover {
	-webkit-transform: translate(-.1rem);
	        transform: translate(-.1rem);
}
.footer__social--btn {
	transition: all .4s;
}
.footer__social--btn:hover {
	-webkit-transform: scale(1.2);
	        transform: scale(1.2);
}
.footer__social--icon {
	height: 2rem;
	width: 2rem;
}
.icon-fb {
	fill: #3a5897;
}
.icon-ln {
	fill: #0e76a8;
}
.icon-tw {
	fill: #25a6d1;
}
.icon-in {
	fill: #b032ac;
}
.footer__text {
	margin: 0 1rem;
	margin-top: .5rem;
	margin-bottom: 3rem;
	font-family: 'Regular';
	font-size: 1rem;
	text-align: center;
}
@media only screen and (max-width: 37.5em) {
	.footer {
		display: flex;
		width: 100%;
	}
	.footer__links--link {
		font-size: 1.5rem;
	}
	.footer__social--icon {
		height: 3.5rem;
		width: 3.5rem;
	}
	.footer__copyright {
		font-size: 1.6rem;
	}
	.footer__text {
		font-size: 1.3rem;
		flex-wrap: wrap;
		justify-content: flex-start;
		text-align: center;
		display: flex-start;
	}
	.footer__navigation--heading {
		font-size: 1.8rem;
	}
	.footer__navigation--link {
		font-size: 1.5rem;
	}
}

/* Modal */

.modalContainer-dark {
  display: block;
  pointer-events: auto;
  background-color: rgba(0, 0, 0, 0.4) !important;
  transition: all 0.5s ease-out;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.4);
  z-index: 99999;
}

.modal-dark {
  position: absolute;
  z-index: 1000;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  background: var(--white);
  border-radius: 2rem;
  overflow: hidden;
  transition: 1.1s ease-out;
  box-shadow: var(--box-shadow1);
  -webkit-filter: blur(0);
          filter: blur(0);
  opacity: 1;
  visibility: visible;
  justify-content: center;
  min-width: 50vw;
}

.modal__container-dark {
  display: flex;
  flex-direction: column;
  /* gap: 2rem; */
  align-items: flex-start;
}

.modal_heading__container-dark {
  width: 100%;
  display: flex;
  color: white;
  align-items: center;
  justify-content: space-between;
}

.modal_heading__text-dark {
  font-size: 1.3rem;
  font-weight: 600;
}

.modal__heading--button-dark {
  background-color: white;
  height: 2.5rem;
  width: 2.5rem;
  border-radius: 0.5rem;
  border: none;
  outline: none;
  cursor: pointer;
  transition: all 0.4s;
  display: flex;
  justify-items: center;
  align-items: center;
  justify-content: center;
}

.modal_heading__icon-dark {
  height: 2rem;
  width: 2rem;
  fill: black;
  transition: all 0.4s;
}

.modal_upload__icon-dark {
  height: 3rem;
  width: 3rem;
  fill: black;
  transition: all 0.4s;
  justify-self: center;
  align-self: center;
}

.modal__heading--button-dark:hover {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

.modal__heading--button-dark:hover .modal_heading__icon-dark {
  fill: none;
}

.modal__form-dark {
  width: 100%;
  display: flex;
  flex-direction: column;
  grid-gap: 0.5rem;
  gap: 0.5rem;

  padding: 2rem;
}

.modal__form_line {
  width: 100%;
  height: 0.2rem;
  background-color: var(--grey-border);
}

.modal__form--label-dark {
  font-size: 1.2rem;
  font-weight: 500;
  color: var(--light-border);
}

.modal__form--input-dark {
  border: none;
  background-color: transparent;
  font-family: inherit;
  border-bottom: 1px solid var(--light-border);
  outline: none;
}

.modal__button-dark {
  font-family: inherit;
  border: 1px solid var(--red);
  background-color: var(--red);
  color: var(--white);
  font-size: 1.5rem;
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  cursor: pointer;
  transition: all 0.4s;
}

.modal__button-dark:hover {
  background-color: transparent;
  color: var(--red);
}

.modal__button-reset-dark {
  font-family: inherit;
  border: 1px solid var(--red);
  background-color: white;
  color: var(--red);
  font-size: 1.5rem;
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  cursor: pointer;
  transition: all 0.4s;
}

.modal__button-reset-dark:hover {
  background-color: transparent;
  color: var(--red);
}

.modal__form--error-dark {
  font-size: 1.2rem;
  color: var(--red);
}

.modal_dark_buttons {
  display: flex;
  width: 100%;
  grid-column-gap: 1rem;
  -webkit-column-gap: 1rem;
          column-gap: 1rem;
  justify-content: right;
  padding: 2rem;
}

.modal_heading__container-dark {
  width: 100%;
  height: 5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #343b45;
  padding: 2rem;
}

.modal_inner_content {
  display: flex;
}

.modal_inner_content--left {
  display: flex;
  flex-direction: column;
  flex: 1.5 1;
}

.modal_inner_content--equal {
  display: flex;
  flex-direction: column;
  flex: 1 1;
  grid-row-gap: 1rem;
  row-gap: 1rem;
  margin-left: 1rem;
}

.modal_inner_content--right {
  display: flex;
  flex-direction: column;
  flex: 1 1;
  justify-items: center;
  text-align: center;
  padding: 1rem;
  grid-row-gap: 1rem;
  row-gap: 1rem;
}

.modal_inner_content--right2 {
  display: flex;
  flex-direction: column;
  flex: 1.5 1;
}

.modal_inner_content--right3 {
  display: flex;
  flex-direction: column;
  flex: 1 1;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  grid-gap: 1rem;
  gap: 1rem;
}

.modal_inner_content--left4 {
  display: flex;
  flex-direction: column;
  width: 44%;
}

.modal_inner_content--right4 {
  display: flex;
  flex-direction: column;
  width: 56%;
  justify-content: space-between;
  align-items: center;
}

.upload_preview_modal {
  height: 5rem;
}

.modal_form_container {
  width: 100%;
  height: 15rem;
  overflow-y: auto;
}

.modal__form-table {
  width: 100%;
  border-collapse: collapse;
  border: none !important;
  outline: none;
  -webkit-appearance: none;
          appearance: none;
  position: relative;
}

.modal__form-sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 0;  
  z-index: 1;
}

.modal__form-tr {
  height: 3rem;
  background-color: #c4c4c4;
  border: none !important;
  outline: none;
  -webkit-appearance: none;
          appearance: none;
}

.modal__form-tr2 {
  height: 3rem;
  background-color: #c4c4c4;
  border: none !important;
  outline: none;
  -webkit-appearance: none;
          appearance: none;
}

.modal__form-tr2:nth-child(odd) {
  background-color: #e4e4e4;
  border: none !important;
  outline: none;
  -webkit-appearance: none;
          appearance: none;
}

.modal__form-tr2:nth-child(even) {
  background-color: #fff;
  border: none !important;
  outline: none;
  -webkit-appearance: none;
          appearance: none;
}

.modal__form-tr th {
  /* font-family: 'Inter'; */
  font-style: normal;
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 1.6rem;
  letter-spacing: 0.01em;
  color: #4b4b4b;
  text-align: center;
  border: none !important;
  outline: none;
  -webkit-appearance: none;
          appearance: none;
}

.modal__form-tr2 td {
  /* font-family: 'Inter'; */
  font-style: normal;
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 1.4rem;
  letter-spacing: 0.01em;
  color: #4b4b4b;
  text-align: center;

  border: none !important;
  outline: none;
  -webkit-appearance: none;
          appearance: none;
}

.modal__button-edit {
  font-family: inherit;
  background-color: black;
  color: var(--white);
  font-size: 1.2rem;
  padding: 0.4rem 1.4rem;
  border-radius: 0.5rem;
  cursor: pointer;
  transition: all 0.4s;
  text-transform: capitalize;
}

.modal__button-delete {
  font-family: inherit;
  border: 1px solid var(--red);
  background-color: var(--white);
  color: var(--red);
  font-size: 1.2rem;
  padding: 0.4rem 1.4rem;
  border-radius: 0.5rem;
  cursor: pointer;
  transition: all 0.4s;
  text-transform: capitalize;
}

.modal__button-delete:hover {
  background-color: var(--red);
  color: var(--white);
}

.modal__form-buttons {
  display: flex;
  grid-gap: 1rem;
  gap: 1rem;
  justify-content: right;
  align-items: center;
  align-self: center;
  padding-top: 0.1rem;
  padding-right: 0.5rem;
}

.modal__form_description {
  width: 35rem;
  word-break: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  line-height: 16px;
  /* fallback */
  max-height: 32rem;
  /* fallback */
  -webkit-line-clamp: 1;
  /* number of lines to show */
  -webkit-box-orient: vertical;
}

.modal__form_link {
  word-break: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 16px;
  /* fallback */
  max-height: 32px;
  /* fallback */
  -webkit-line-clamp: 1;
  /* number of lines to show */
  -webkit-box-orient: vertical;
  padding: 0.2rem;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 1.4rem;
  letter-spacing: 0.01em;
  color: #4b4b4b;
}

table,
thead,
tbody,
tr,
td,
th {
  border: none;
}


/* Modal */

.modalContainer {
	display: block;
	pointer-events: auto;
	background-color: rgba(0, 0, 0, 0.4) !important;
	transition: all 0.5s ease-out;
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background: rgba(0, 0, 0, 0.4);
	z-index: 99999;
}

.modal {
	position: absolute;
	z-index: 1000;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	        transform: translate(-50%, -50%);
	background: var(--white);
	border-radius: 2rem;
	overflow: hidden;
	transition: 1.1s ease-out;
	box-shadow: var(--box-shadow1);
	-webkit-filter: blur(0);
	        filter: blur(0);
	opacity: 1;
	visibility: visible;
	justify-content: center;
	min-width: 30vw;
}
.modal__container {
	padding: 2rem;

	display: flex;
	flex-direction: column;
	grid-gap: 2rem;
	gap: 2rem;
	align-items: flex-start;
}
.modal_heading__container {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
}
.modal_heading__text {
	font-size: 1.3rem;
	font-weight: 600;
}
.modal__heading--button {
	background-color: transparent;
	border: none;
	outline: none;
	cursor: pointer;
	transition: all .4s;
}
.modal_heading__icon {
	height: 2rem;
	width: 2rem;
	transition: all .4s;
}
.modal__heading--button:hover {
	-webkit-transform: scale(1.1);
	        transform: scale(1.1);
}
.modal__heading--button:hover .modal_heading__icon {
	fill: var(--black);
}
.modal__form {
	width: 100%;
	display: flex;
	flex-direction: column;
	grid-gap: .5rem;
	gap: .5rem;
}
.modal__form--label {
	font-size: 1.2rem;
	font-weight: 500;
	color: var(--light-border);
}
.modal__form--input {
	border: none;
	background-color: transparent;
	font-family: inherit;
	border-bottom: 1px solid var(--light-border);
	outline: none;
}
.modal__button {
	font-family: inherit;
	border: 1px solid var(--red);
	background-color: var(--red);
	color: var(--white);
	font-size: 1.3rem;
	padding: .5rem 1rem;
	border-radius: .5rem;
	cursor: pointer;
	transition: all .4s;
}
.modal__button:hover {
	background-color: transparent;
	color: var(--red);
}
.modal__form--error {
	font-size: 1.2rem;
	color: var(--red);
}

ul {
	list-style: none;
	margin: 0;
	padding: 0;
}
.logout_css{
	display: block;
	font-size: 1.3rem;
	color: black;
	-webkit-text-decoration-style: none;
	        text-decoration-style: none;
	text-decoration: none;
	font-weight: 600;
}
.mega-menu__link:link,
.mega-menu__link:visited {
	color: var(--black);
	font-size: 1.3rem;
	font-weight: 500;
	text-decoration: none;
	margin-top: 2rem;
	display: flex;
	grid-gap: .7rem;
	gap: .7rem;
	flex-direction: column;
	transition: all .4s;
}

.mega-menu__link:hover,
.mega-menu__link:active,
.mega-menu__link:focus {
	-webkit-transform: translateX(.3rem);
	        transform: translateX(.3rem);
}

.megaMenu {
	grid-row: 2 / 3;
	z-index: 1000;
	background-color: var(--bg-primary);
	/* box-shadow: var(--box-shadow1); */
	padding: 1rem 33rem;
	position: -webkit-sticky;
	position: sticky;
	top: 0rem;
	width: 100%;
}

.menu {
	/* background-color: Var(--bg-primary); */
	/* display: flex; */
	justify-content: space-between;
	align-items: center;
	/* padding-left: 18rem; */
	width: 100%;
	/* gap: 3rem; */
}
.left__menu {
	/* margin-left: 5rem; */
	display: flex;
}
.exo-menu {
	background-color: var(--bg-primary);
	list-style: none;
	display: flex;
	grid-gap: 1rem;
	gap: 1rem;
	justify-content: space-between;
	width: 100%;
	margin-left: 12rem;
	align-self: center;
}

.exo-menu > li {
	display: inline-block;
}
.exo-menu > li > .exo-menu__head,
li.drop-down a {
	font-size: 1.4rem;
	font-weight: 600;
	color: black;
	text-decoration: none;
	transition: color 0.2s linear, background 0.2s linear;
	cursor: pointer;
	width: 100%;

	border: none;
	background-color: transparent;
	font-family: inherit;
}
.exo-menu > li > a.active,
.exo-menu > li > .exo-menu__head:hover,
li.drop-down a:hover {
	color: var(--red);
}

.user__data {
	font-size: 1.3rem;
	font-weight: 600;
}

/*mega menu*/
.mega-menu {
	left: 0;
	right: 0;
	/* top: 4rem; */
	display: none;
	min-height: 100%;
	padding-top: 2rem;

	-webkit-animation: fadeInAnimation ease 1s;

	        animation: fadeInAnimation ease 1s;
	-webkit-animation-iteration-count: 1;
	        animation-iteration-count: 1;
	-webkit-animation-fill-mode: forwards;
	        animation-fill-mode: forwards;
}
.mega-menu__item {
	width: 100%;
}
.drop-down-ul {
	display: none;
	box-shadow: var(--box-shadow1);
	border-radius: 1.5rem;
	background-color: var(--bg-primary);
}

@-webkit-keyframes fadeInAnimation {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@keyframes fadeInAnimation {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

.row {
	/* display: grid;
	grid-template-columns: repeat(4, 1fr); */
	/* justify-content: space-between; */

	display: flex;
	/* gap: 1rem; */
	justify-content: space-between;
	padding-bottom: 4rem;
	margin-left: 2rem;
	margin-right: 2rem;
	background-color: var(--bg-primary);
	box-shadow: 0 5px 3rem 1rem rgba(0, 0, 0, 0.2);
	border-radius: 1.5rem;
	overflow: hidden;
}

.mega-title {
	color: var(--bg-primary);
	margin-bottom: 2rem;
	font-size: 1.2rem;
	padding-left: 1.5rem;
	padding-bottom: 1.3rem;
	text-transform: uppercase;
	border-bottom: 1px solid var(--black);
	padding-top: 1.5rem;
	background-color: var(--black);
}
.mega-menu ul li a {
	font-size: 1.2rem;
}
ul.mega-menu__data li a {
	padding: 3px 0px;
}

ul.description li {
	margin-left: 1.5rem;
	padding-bottom: 1.2rem;
	line-height: .8rem;
}

ul.description li span {
	color: var(--black);
	font-size: 1.1rem;
	font-weight: 500;
}

ul.icon-des li {
	width: 100%;
	display: table;
	margin-bottom: 11px;
}

/*hover*/
.mega-menu:hover,
.flyout-mega-wrap:hover,
.mega-drop-down a:hover + .mega-menu {
	display: block;
	width: 100vw;
}
.drop-down-ul:hover,
li.drop-down > a:hover + .drop-down-ul {
	display: block;
}

li.drop-down,
.flyout-right,
.flyout-left {
	position: relative;
}
li.drop-down a {
	padding-right: 1rem;
}
/* li.drop-down::after {
	content: "\2193";
	color: var(--black);
	color: var(--red);
	font-size: 1.2rem;
	position: absolute;
	top: 0;
	right: 0;
} */
li.drop-down > ul {
	left: 0px;
	min-width: 230px;
}

li.drop-down > ul > li > a,
.flyout-right ul > li > a,
.flyout-left ul > li > a {
	color: var(--black);
	display: block;
	padding: 2rem 2rem;
	text-decoration: none;
}
li.drop-down > ul > li:not(:last-child) {
	border-bottom: 1px solid var(--light-grey);
}

li.drop-down > ul > li > a {
	top: 0;
	min-width: 230px;
	display: block;
}
.flyout-right > ul,
.flyout-left > ul {
	top: 0;
	min-width: 230px;
	display: none;
	background-color: var(--bg-primary);
}
.flyout-right > ul {
	left: 100%;
}
.flyout-left > ul {
	right: 100%;
}
.flyout-right ul > li > a,
.flyout-left ul > li > a {
	color: #fff;
	display: block;
	padding: 20px 22px;
	text-decoration: none;
	background-color: #365670;
	border-bottom: 1px solid #547787;
	transition: color 0.2s linear, background 0.2s linear;
}
.flyout-right ul > li > a,
.flyout-left ul > li > a {
	border-bottom: 1px solid #b8c7bc;
}
.flyout-right ul > li > a,
.flyout-left ul > li > a {
	background-color: #547787;
}

li.drop-down > a:hover + ul > li > a,
li.flyout-left > ul:hover,
li.flyout-right > ul:hover,
li.flyout-left a:hover + ul,
li.flyout-right a:hover + ul {
	display: block;
}
.exo-menu__buttons_mobile {
	display: none;
	position: absolute;
	right: 0px;
	top: 1rem;
	display: flex;
	grid-gap: 1rem;
	gap: 1rem;
	align-items: center;
}
.exo-menu__buttons_mobile--btn {
	font-family: inherit;
	font-size: 1.1rem;
	background-color: var(--red);
	color: var(--bg-primary);
	padding: 0.5rem 2.5rem;
	border-radius: 0.5rem;
	transition: all .4s;
	display: none;
	border: none;
	display: none;
}
.toggle-menu {
	text-decoration: none;
	background-color: transparent;
	border: none;
	display: none;

	cursor: pointer;
	/* transform: rotate(90deg); */
}
.toggle-menu-line {
	margin: .5rem;
	height: .3rem;
	width: 5rem;
	background-color: var(--dark-grey);
}

.menu__right {
	display: flex;
	grid-gap: 1rem;
	gap: 1rem;
	align-items: center;
	margin-right: 5rem;
}

.menu__input {
	background-color: transparent;
	color: var(--bg-primary);
	border: none;
	border-bottom: 1px solid var(--black);
	padding-bottom: .3rem;
	padding-left: .5rem;
	font-family: inherit;
	font-size: 1rem;
	outline: none;
	transition: all .4s;
}

.menu__input::-webkit-input-placeholder {
	text-transform: uppercase;
	color: var(--black);
}

.menu__input:focus,
.menu__input:hover {
	border-bottom: 1px solid var(--bg-primary);
}

.menu__link {
	background-color: transparent;
	border: none;
	font-size: 1rem;
	color: var(--light-grey);
	text-decoration: none;
	text-transform: uppercase;
	cursor: pointer;
	transition: all .4s;
}

.menu__link:hover {
	color: var(--bg-primary);
}

.logout_css {

}

.logout_css:hover {
	cursor: pointer;
}
/*responsive*/

@media only screen and (max-width: 1499px) {
	.megaMenu {
		padding: 1rem 10rem;
	}
	.exo-menu {
		width: 100%;
		grid-gap: 1rem;
		gap: 1rem;
		margin-left: 6rem;

		justify-content: space-between;
	}
}

@media only screen and (max-width: 1200px) {
	.megaMenu {
		padding: 1rem 10rem;
	}
	.exo-menu {
		width: 100%;
		grid-gap: 1rem;
		gap: 1rem;
		margin-left: 2rem;

		justify-content: space-between;
	}
}


@media (max-width: 767px) {
	.logout_css{
		display: block;
		padding: 1rem 22px;
		border-bottom: 1px solid var(--light-grey);
		font-size: 1.8rem;
		color: black;
		-webkit-text-decoration-style: none;
		        text-decoration-style: none;
		text-decoration: none;
		font-weight: 600;
	}
	.left__menu {
		width: 100%;
		grid-gap: 0rem;
		gap: 0rem;
		margin-top: 1rem;
	}
	.megaMenu {
		grid-row: 1 / 2;
		padding: 1rem 1rem;
		height: 8rem;
	}
	.menu {
		padding-left: 0;
	}
	.exo-menu {
		width: 100%;
		grid-gap: 0;
		gap: 0;
	}
	.exo-menu li a.navigation__link2 {
		display: none !important;
	}

	.display.exo-menu {
		margin-right: -1.5rem;
		margin-top: 3rem;
		flex-direction: column;
		align-items: stretch;
		grid-gap: 0;
		gap: 0;
		overflow: scroll;
	}
	.display.exo-menu > li > a {
		display: block;
		padding: 20px 22px;
	}
	.display.exo-menu .drop-down .drop-down_link,
	.display.exo-menu .mega-drop-down .exo-menu__head {
		position: relative;
	}
	.display.exo-menu .drop-down .drop-down_link::after,
	.display.exo-menu .mega-drop-down .exo-menu__head::after {
		content: '+';
		font-size: 2.5rem;
		position: absolute;
		top: .5rem;
		right: 2rem;
	}
	.display.exo-menu .drop-down .dropdown-visible.drop-down_link::after,
	.display.exo-menu .mega-drop-down .dropdown-visible.exo-menu__head::after {
		content: '-';
	}
	.mega-menu:hover,
	.flyout-mega-wrap:hover,
	.mega-drop-down a:hover + .mega-menu {
		display: none;
		width: 100%;
		padding-top: 0;
	}
	.display .mega-drop-down .dropdown-visible + .mega-menu {
		display: block !important;
		width: 100%;
		padding-top: 0;
	}
	.row {
		flex-direction: column;
		margin-right: 0;
		margin-left: 0;
		padding-bottom: 0;
		background-color: transparent;
		border-radius: 0;
		box-shadow: unset;
	}
	.mega-menu__item {
		padding: 1rem 0;
		padding-left: 2rem;
		border-bottom: 1px solid var(--light-grey);
		background-color: var(--light-grey2);
	}
	.mega-title {
		background-color: transparent;
		color: var(--black);
		border-bottom: none;
		padding-top: 0;
		margin-bottom: 0;
		font-size: 1.6rem;
	}
	.mega-menu__link:link,
	.mega-menu__link:visited {
		margin-top: 0;
		margin-bottom: 2.5rem;
		font-size: 1.6rem;
	}
	ul.description li span {
		/* font-size: 1.4rem; */
		display: none;
	}
	ul.description li {
		padding-bottom: 0;
	}

	.drop-down-ul:hover,
	li.drop-down > a:hover + .drop-down-ul {
		display: none;
	}
	.display .dropdown-visible + .drop-down-ul {
		display: block !important;
	}
	li.drop-down > ul,
	li.drop-down > ul > li > a {
		min-width: 10rem;
		background-color: var(--light-grey2);
	}
	li.drop-down > ul > li > a {
		padding: 1rem 0;
		padding-left: 3rem;
		font-size: 1.6rem;
		font-weight: 500;
	}
	.drop-down-ul {
		box-shadow: unset;
		border-radius: 0;
		background-color: transparent;
	}
	li.drop-down > ul > li {
		border-bottom: 1px solid var(--light-grey);
	}

	.visible-xs-block {
		display: block !important;
	}
	.exo-menu > li > a {
		width: 100%;
		display: none;
	}
	.display.exo-menu > li > a {
		display: block;
		padding: 1rem 22px;
		border-bottom: 1px solid var(--light-grey);
		font-size: 1.8rem;
	}
	.display.mega-menu > li:hover {
		display: flex;
	}

	.flyout-right > ul,
	.flyout-left > ul,
	li.drop-down > ul {
		position: relative;
	}

	.exo-menu__buttons_mobile--btn {
		display: inline;
	}
	.mega-menu__link .description > li {
		display: flex;
	}
}
@media (min-width: 767px) {
	.exo-menu > li > a {
		display: block;
	}
	.mega-menu,
	.flyout-mega-wrap,
	.flyout-right > ul,
	.flyout-left > ul,
	li.drop-down > ul {
		position: absolute;
	}
	.flyout-right > ul {
		left: 100%;
	}
	.flyout-left > ul {
		right: 100%;
	}
	.mega-menu__link {
		display: flex;
	}
}
@media only screen and (min-width: 1500px) {
	.megaMenu {
		padding: 1rem 22rem;
	}

	.exo-menu {
		width: 100%;
		grid-gap: 1rem;
		gap: 1rem;
		margin-left: 10rem;
		align-items: end;
		justify-content: space-between;
	}
}

.Tooltip_tooltip__1uhVW {
  height: 38rem;
  padding: 2rem 3rem 6.8rem;
  width: 84rem;
  position: absolute;
  z-index: 999;
  left: 50%;
  -webkit-transform: translate(-95%, 4%);
          transform: translate(-95%, 4%);
  background: white;
  box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.3);
}

.Tooltip_tooltip__arrow__2Eq0b {
  width: 5rem;
  height: 2.5rem;
  position: absolute;
  top: 100%;
  left: 20%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  overflow: hidden;
}

.Tooltip_tooltip__1uhVW::after {
  content: "";
  z-index: -1;
  position: absolute;
  width: 2rem;
  height: 2rem;
  background: white;
  -webkit-transform: translateX(-50%) translateY(-40%) rotate(45deg);
          transform: translateX(-50%) translateY(-40%) rotate(45deg);
  box-shadow: -15px -8px 29px 0px rgba(0, 0, 0, 0.2);
  top: 0;
  left: 94%;
}

@media only screen and (max-width: 669px) {
  .Tooltip_tooltip__1uhVW {
    height: 58rem;
    width: 58rem;
  }
  .Tooltip_tooltip__1uhVW::after {
    -webkit-transform: none;
            transform: none;
  }
  .Tooltip_tooltip__arrow__2Eq0b {
    display: none;
  }
}

.LoginTooltip_mainView__VKwmB {
  display: flex;
  width: "100%";
  justify-content: space-between;
}

.LoginTooltip_modal_close_icon__oLpnx {
  height: 3rem;
  width: 3rem;
  cursor: pointer;
  fill: var(--red);
  transition: all 0.4s;
  justify-self: center;
  align-self: flex-end;
}

.LoginTooltip_mainTitle__25EdK {
  font-size: 2.2rem;
  line-height: 3.2rem;
  font-weight: 300;
  margin-bottom: 1.6rem;
  color: var(--red);
  display: block;
}

.LoginTooltip_infoContainer__3SMR8 {
  text-align: center;
  padding-top: 1rem;
  width: 48%;
}

.LoginTooltip_loginDescription__1GMrM {
  font-size: 1.8rem;
  line-height: 3.4rem;
  margin-bottom: 1.4rem;
  font-weight: 100;
  color: #5d5d5d;
}

.LoginTooltip_loginButton__2y92M {
  display: inline-block;
  padding: 1.2rem 2.4rem;
  margin-top: 2rem;
  background-color: var(--red);
  border: 0;
  border-radius: 0.6rem;
  font-size: 1.6rem;
  font-weight: 600;
  transition: background 0.4s, color 0.4s;
  color: #fff;
  text-align: center;
  -webkit-user-select: none;
          user-select: none;
  vertical-align: middle;
  cursor: pointer;
  transition: background-color 0.3s;
}

.LoginTooltip_loginButton__2y92M:hover {
  background-color: #b4811b;
}

.LoginTooltip_dash__c5amp {
  background-color: hsla(0, 0%, 36%, 0.347);
  height: 30.2rem;
  padding: 0 0.4px;
}

@media only screen and (max-width: 669px) {
  .LoginTooltip_mainView__VKwmB {
    flex-direction: column;
    grid-gap: 3rem;
    gap: 3rem;
  }
  .LoginTooltip_mainTitle__25EdK {
    font-size: 3.4rem;
  }
  .LoginTooltip_infoContainer__3SMR8 {
    text-align: left;
    width: 100%;
  }
  .LoginTooltip_dash__c5amp {
    height: 0.2rem;
  }
  .LoginTooltip_loginDescription__1GMrM {
    font-size: 2rem;
    margin-bottom: 0rem;
    line-height: 3.4rem;
  }
}

.button {
	font-family: inherit;
	border: none;
	background-color: transparent;
	position: relative;
}
/* .button:hover .button__icon {
	background-color: var(--bg-primary);
} */
/* .button:hover .button__icon--icon {
	fill: var(--red);
} */
.button__red {
	background-color: var(--red);
	color: var(--bg-primary);
}
.button__black {
	background-color: var(--black);
	color: var(--bg-primary);
}
.button__icon {
	position: absolute;
	left: 0;
	z-index: 10;
	background-color: #ebb853;
	padding: 1rem;
	display: flex;
	transition: all .4s;
	cursor: pointer;
}
.button__black .button__icon {
	background-color: rgb(134, 131, 131);
}
.button__black:hover {
	background-color: transparent;
	color: var(--black);
	transition: 4s;
}
.button__black:hover .button__icon {
	background-color: grey;
	transition: all .4s;
}
.button__red:hover .button__icon {
	background-color: var(--red);
	transition: 1s;
}
.button__red:hover {
	background-color: transparent;
	color: var(--red);
	transition: 1s;
}
.button__icon--icon {
	height: 3rem;
	width: 3rem;
	fill: var(--dark-grey);
	transition: all .4s;
}
.button:hover .button__text {
	background-color: var(--bg-primary);
	color: var(--black);
	transition: 1s;
}
.button__text {
	color: var(--bg-primary);
	text-transform: uppercase;
	font-weight: 400;
	box-shadow: var(--box-shadow1);
	overflow: hidden;
	margin-left: 4rem;
	padding: 2rem 4rem;
	cursor: pointer;
	transition: all .4s;
	height: 5rem;
	border-radius: 5px;
}

.login_main_content {
	padding: 3rem 33rem;
	width: 100%;
	display: flex;
	grid-gap: 10rem;
	gap: 10rem;
}
.login_tabset {
	width: 50%;
}
.login_container {
	width: 100%;
}
.login_button {
	margin-top: 2rem;
}
@media only screen and (max-width: 1500px) {
	.login_main_content {
		padding: 3rem 15rem;
	}
}
@media only screen and (max-width: 1400px) {
	.login_main_content {
		padding: 3rem 10rem;

		grid-gap: 10rem;

		gap: 10rem;
	}
	.mw_products {
		padding: 3rem 10rem;
	}
}
@media only screen and (max-width: 767px) {
	.login_main_content {
		padding: 3rem 1rem;
		display: flex;
		flex-direction: column-reverse;
		grid-gap: 2rem;
		gap: 2rem;
	}
}
/* .login_container {
	margin: 1rem 0;
	padding: 5rem 7rem;
	border-radius: 2rem;
	background-color: var(--bg-primary);
	box-shadow: var(--box-shadow1);
		display: flex;
	gap: 2rem;
	flex-direction: column;
	align-items: flex-start;
}
.login_container__heading {
	font-size: 1.5rem;
	font-weight: 600;
}
.login_container--forgot {
	align-self: flex-end;
	background-color: transparent;
	border: none;
	font-family: inherit;
	outline: none;
	font-size: 1.3rem;
} */

.slider {
	position: relative;
	height: 60vh;
	background-size: cover;
	background-repeat: no-repeat;

	display: flex;
	flex-direction: column;
	justify-content: center;
	padding: 0 5rem;
}
.sider__heading {
	font-size: 2rem;
	font-weight: 400;
}
.sider__subheading {
	font-size: 1.3rem;
	font-weight: 500;
}
.slider__img {
	position: absolute;
	top: 0;
	width: 100%;
	z-index: 1;
}

/* section CSS */
.sections {
	margin-top: 8rem;
	margin-left: 5rem;
}
.section__heading {
	font-size: 1.4rem;
	font-weight: 600;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}
.section__heading::after {
	content: '';
	height: .5rem;
	width: 3rem;
	background-color: var(--black);
}
.section__contents {
	margin-top: 2rem;
	display: flex;
	grid-gap: 2rem;
	gap: 2rem;
	flex-wrap: wrap;
}
.section {
	text-decoration: none;
	color: var(--black);
	border: none;
	font-family: inherit;
	background-color: var(--bg-primary);
	box-shadow: var(--box-shadow1);
	padding: 2rem 3rem;
	border-radius: 1rem;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	transition: all .4s;
}
.section:hover {
	-webkit-transform: scale(1.05);
	        transform: scale(1.05);
}
.section__image {
	height: 5rem;
}
.section__title {
	margin: .5rem 0;
	font-size: 1.3rem;
	font-weight: 500;
}
.section__logo {
	height: 3.5rem;
}

/* Contact CSS */
.contact {
	margin-top: 10rem;
	background-size: cover;
	padding: 0rem 15rem;
	height: 35rem;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
}
.contact__heading {
	font-size: 1.4rem;
	font-weight: 500;
	color: var(--red);
}
.contact__subheading { 
	margin-bottom: 2rem;
}
.contact__text { 
	font-weight: 500;
	margin-bottom: 1rem;
}
.contact__btn {
	border: 1px solid var(--red);
	background-color: var(--red);
	border-radius: 1rem;
	font-family: inherit;
	padding: 1rem 2rem;
	color: var(--bg-primary);
	cursor: pointer;
	transition: all .4s;
}
.contact__btn:hover {
	background-color: transparent;
	color: var(--red);
}

.mw_slider {
	display: flex;
	justify-content: space-between;
	align-items: center;
	color: var(--white);
	min-height: 45vh;
	height: 45vh;
	background-position-y: 30%;
	background-size: cover;
	background-repeat: no-repeat;
	padding: 0 15rem;
}

.mw_slider__right {
	display: flex;
	flex-direction: column;
	grid-row-gap: 0.5rem;
	row-gap: 0.5rem;
}

.mw_slider__heading {
	font-family: 'SemiBold';
	font-size: 4rem;
	font-weight: bold !important;
}

.mw_slider__subheading {
	font-size: 1.4rem;
	width: 30rem;
}

.mw_button {
	text-decoration: none;
	color: var(--white);
	text-transform: uppercase;
	background-color: var(--red);
	border: 1px solid var(--red);
	padding: 1.6rem 4rem;
	font-family: 'Medium';
	font-size: 1.3rem;
	border-radius: .5rem;
	cursor: pointer;
	transition: all .4s;
	text-align: center;
}

.mw_button:hover {
	background-color: var(--red-transparent);
	border: 1px solid var(--red);
}

.mw_button_small {
	text-decoration: none;
	color: var(--white);
	text-transform: uppercase;
	background-color: var(--red);
	border: 1px solid var(--red);
	padding: .5rem 2rem;
	font-family: 'Medium';
	font-size: 1rem;
	border-radius: .5rem;
	cursor: pointer;
	transition: all .4s;
	display: flex;
	align-items: center;
}

.mw_button_small1 {
	text-decoration: none;
	color: var(--white);
	text-transform: uppercase;
	background-color: var(--red);
	border: 1px solid var(--red);
	padding: .5rem 2rem;
	font-family: 'Medium';
	font-size: 1rem;
	border-radius: .5rem;
	cursor: pointer;
	transition: all .4s;
	display: flex;
	align-items: center;
}

.mw_button_small:hover {
	background-color: transparent;
	border: 1px solid var(--red);
	color: var(--red);
}

.mw_button_small1:hover {
	background-color: transparent;
	border: 1px solid var(--red);
	color: var(--red);
}

.quote {
	height: 10rem;
	background-color: var(--dark-grey);
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0 15rem;
}

.quote__image {
	height: 4rem;
}

.quote__text {
	font-size: 1.6rem;
	font-weight: bold;
	font-family: 'Regular';
	text-transform: uppercase;
	color: var(--white);
}

.quote2 {
	height: 10rem;
	background-color: var(--dark-grey);
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0 33rem;
}

.quote__image2 {
	height: 6rem;
}

.quote__text2 {
	font-size: 1.6rem;
	font-weight: 800;
	color: var(--white);
}

.header_buttons {
	display: flex;
	grid-gap: 2rem;
	gap: 2rem;
}

.mw_products {
	padding: 3rem 15rem;
	background-color: var(--light-grey);
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.mw_products__item {
	text-decoration: none;
	transition: all .4s;
}

.mw_products__item:hover {
	background-color: var(--black-hover);
	-webkit-transform: translateY(-.2rem);
	        transform: translateY(-.2rem);
}

.mw_products__heading {
	font-size: 2rem;
	padding-top: 1.5rem;
}

.mw_products__heading span {
	display: block;
	font-size: 1.9rem;
	font-family: 'Regular';
	color: var(--dark-grey);
}

.mw_products__right {
	display: flex;
	grid-gap: 2rem;
	gap: 2rem;
}

.mw_products__item {
	border: 2px solid var(--light-border);
	border-radius: .2rem;
	/* padding: 2rem 3rem; */
	height: 10rem;
	width: 12rem;
	display: flex;
	grid-gap: 1rem;
	gap: 1rem;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.mw_products__item--image {
	height: 4rem;
}

.mw_products__item--name {
	font-size: 1.2rem;
	font-family: 'Bold';
	text-transform: uppercase;
	color: var(--dark-grey);
}

.mw_about {
	margin: 3rem 15rem;
	display: flex;
	grid-gap: 5rem;
	gap: 5rem;
}

.mw_about__image {
	height: 30rem;
	width: 30vw;
}

.mw_about__image3 {
	height: 24rem;
	width: 45rem;
}

.tabset {
	width: 100%;
}

.tabset>input[type="radio"] {
	position: absolute;
	opacity: 0;
	visibility: hidden;
}

.tabset .tab-panel {
	display: none;
}

.tabset>input:first-child:checked~.tab-panels>.tab-panel:first-child,
.tabset>input:nth-child(3):checked~.tab-panels>.tab-panel:nth-child(2),
.tabset>input:nth-child(5):checked~.tab-panels>.tab-panel:nth-child(3),
.tabset>input:nth-child(7):checked~.tab-panels>.tab-panel:nth-child(4),
.tabset>input:nth-child(9):checked~.tab-panels>.tab-panel:nth-child(5),
.tabset>input:nth-child(11):checked~.tab-panels>.tab-panel:nth-child(6) {
	display: block;
}

.tabset>label {
	position: relative;
	display: inline-block;
	margin-right: .5rem;
	padding: 1rem;
	border: 1px solid var(--grey-border);
	border-bottom: 0;
	cursor: pointer;
	font-family: 'Bold';
	font-size: 1.5rem;
	justify-content: center;
	text-align: center;
}

.tabset>label:hover,
.tabset>input:focus+label {
	color: var(--red);
}

.tabset>input:checked+label {
	border-color: var(--grey-border);
	border-bottom: 1px solid var(--white);
	margin-bottom: -1px;
	color: var(--red);
}

.tab-panel {
	border-top: 1px solid var(--grey-border);
	padding-top: 1rem;
}

.tab_blog__data {
	font-size: 1.3rem;
	border-bottom: 1px dotted var(--light-grey);
	margin-bottom: .5rem;
}

.tab_about__data {
	font-size: 1.6rem;
	margin-bottom: 2rem;
}

.mw_services {
	/* background-color: var(--light-grey2); */
	background-color: var(--white);
	padding: 3rem 15rem;
	display: flex;
	/* gap: 5rem; */
	justify-content: space-between;
}

.mw_services__item {
	position: relative;
}

.mw_services__item--info {
	position: absolute;
	bottom: .6rem;
	left: 0;
	width: 100%;
	background-color: rgba(0, 0, 0, .4);
	/* background-color: rgba(256, 256, 256, .4); */
	padding: 1rem 2rem;
	color: var(--white);
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.mw_services__item--name {
	font-size: 1.5rem;
	line-height: 1;
}

.mw_services__item--data {
	font-size: 1.3rem;
	font-family: 'Regular';
}

.mw_services__item--image {
	height: 25rem;
	width: 45rem;
}

.contact_us {
	padding: 3rem 15rem;
	background-position-y: 75%;
	background-size: cover;
	background-repeat: no-repeat;
	display: flex;
	justify-content: flex-start;
}

.contact_us__box {
	background-color: rgba(0, 0, 0, 0.499);
	border-radius: .5rem;
	padding: 3rem;
}

.contact_us__heading {
	font-size: 2rem;
	font-family: 'bold';
	color: var(--white);
}

.contact_us__subheading {
	font-size: 1.5rem;
	color: var(--white);
}

.contact_us__tag {
	font-size: 1.7rem;
	font-family: 'SemiBold';
	margin: 2rem 0;
	color: var(--white);
}

.alert-message {
	font-size: 2rem;
	text-align: center;
	color: var(--red);
}

.hide_button1 {
	display: none;
}

/* @media only screen and (max-width: 600px) {

} */
@media only screen and (max-width: 1500px) {
	.quote2 {
		padding: 2rem 15rem;
	}
}

@media only screen and (max-width: 75em) {

	.mw_slider,
	.quote {
		padding: 0 10rem;
	}

	.mw_products,
	.mw_services,
	.contact_us,
	.footer__navigation {
		padding: 3rem 10rem;
	}

	.mw_about {
		margin: 3rem 10rem;
	}

	.quote2 {
		padding: 0 10rem;
	}
}

@media only screen and (max-width: 56.25em) {

	.mw_slider,
	.quote {
		padding: 0 5rem;
	}

	.quote2 {
		padding: 0 5rem;
	}

	.mw_products,
	.mw_services,
	.contact_us,
	.footer__navigation {
		padding: 3rem 5rem;
	}

	.mw_about {
		margin: 3rem 5rem;
	}

	.mw_services__item--image {
		width: 41vw;
	}

	.mw_button {
		padding: 1.6rem 2rem;
	}
}

@media (max-width: 767px) {
	.mw_button_small1 {
		display: none;
	}
}

@media only screen and (max-width: 37.5em) {
	.megaMenu {
		padding: 1rem 2rem;
	}

	.mw_slider,
	.quote {
		padding: 0 2rem;
	}

	.quote2 {
		padding: 0 2rem;
	}

	.mw_products,
	.mw_services,
	.footer__navigation {
		padding: 3rem 2rem;
	}

	.footer__navigation {
		flex-wrap: wrap;
		justify-content: flex-start;
		grid-gap: 2rem;
		gap: 2rem;
	}

	.mw_about {
		margin: 3rem 2rem;
		flex-direction: column;
		grid-gap: 1rem;
		gap: 1rem;
	}

	.mw_services {
		flex-direction: column;
	}

	.megaMenu {
		padding: 1rem 1rem;
	}

	.mw_button {
		height: 4rem;
		display: flex;
		align-items: center;
	}

	.mw_slider {
		min-height: 10%;
		padding: 0rem;
		flex-direction: column;
		justify-content: flex-start;
		padding-top: 3rem;
		padding-bottom: 3rem;
	}

	.mw_slider__left {
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	.mw_slider__right {
		margin-top: 3rem;
	}

	.quote {
		height: 10rem;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		grid-gap: 1rem;
		gap: 1rem;
	}

	.quote__text {
		font-size: 1.5rem;
	}

	.quote__image {
		display: none;
	}

	.mw_products {
		flex-direction: column;
	}

	.mw_products__heading,
	.mw_products__heading span {
		display: flex;
		flex-direction: row;
		font-size: 1.5rem;
		justify-content: center;
		color: var(--dark-grey);
		font-family: bold;
		grid-gap: .3rem;
		gap: .3rem;
		text-transform: uppercase;
	}

	.mw_products__right {
		display: flex;
		flex-direction: column;
		grid-gap: .1rem;
		gap: .1rem;
		width: 100%;
	}

	.mw_products__item {
		border: none;
		grid-gap: 2rem;
		gap: 2rem;
		flex-direction: row;
		width: 100%;
		justify-content: flex-start;
	}

	.mw_products__item--name {
		border: 1px solid var(--grey-border);
		padding: 1rem 0;
		width: 100%;
		text-align: center;
		font-size: 2rem;
	}

	.mw_products__item--image {
		width: 6rem;
		height: 6rem;
	}

	.hide_button {
		display: none;
	}

	.hide_button1 {
		display: flex;
		justify-content: center;
	}

	.mw_button2 {
		display: flex;
		justify-content: center;
	}

	.mw_about {
		margin: 3rem 1rem;
	}

	.mw_about__image {
		display: none;
	}

	.mw_services__item--image {
		width: 80vw;
	}

	.contact_us {
		display: flex;
		justify-content: center;
		padding: 3rem 0;
	}

	.contact_us__box {
		padding: 3rem 2rem;
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	.contact_us__subheading {
		font-size: 1.2rem;
	}

	.quote2 {
		height: 15rem;
		align-items: center;
		flex-direction: column;
		justify-content: center;
		padding: 0 1rem;
		grid-gap: 1.5rem;
		gap: 1.5rem;
	}

	.quote__text2 {
		font-size: 1.6rem;
		font-weight: 700;
		color: var(--white);
		text-align: center;
	}

	.mw_about__image3 {
		width: 80VW;
	}

	.mw_services__item--info {
		width: 80vw;
	}

	/* .exo-menu li a.mw_button_small1 {
		display: none !important;
	} */
}

@media only screen and (min-width: 93.75em) {

	.mw_slider,
	.quote {
		padding: 0 33rem;
	}

	.quote2 {
		padding: 0 33rem;
	}

	.mw_slider {
		min-height: 35vh;
		background-position-y: 32%;
	}

	.mw_products,
	.mw_services,
	.contact_us,
	.footer__navigation {
		padding: 3rem 33rem;
	}

	.mw_about {
		margin: 3rem 33rem;
	}
}

.btn_space {
	margin-left: 5px;
}
.switchContainer {
	display: flex;
	align-items: center;
	margin-top: 1rem;
}

.checkboxContainer {
	font-size: small;
	width: 80%;
}

.switch-text {
	margin-left: 1.5rem;
	font-weight: 400;
}
.mwfbi_banner {
	background-size: cover;
	background-repeat: no-repeat;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 0 5rem;
	height: 45vh;
	background-color: var(--white);
	/* background-image: linear-gradient(rgba(0, 0, 0, .1), #d13531),
		url(https://upload.wikimedia.org/wikipedia/commons/6/6e/Golfer_swing.jpg); */
}
.mwfbi_banner__text {
	font-size: 1.6rem;
	font-weight: 200;
	color: white;
	text-align: center;
	
	text-shadow: 2px 3px #1b1b1b;
}

.mwfbi_banner__heading {
	font-weight: 700;
	text-align: center;
	color: white;
	
	text-shadow: 2px 3px #1b1b1b;
}

.mwfbi_banner3 {
	background-size: cover;
	background-repeat: no-repeat;
	background-position-y: -20rem;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 0 5rem;
	height: 60vh;
}
.mwfbi_banner__text2 {
	font-size: 1.6rem;
	color: white;
	text-align: center;
	text-shadow: 1px 1px 2px black;
}

.mwfbi_banner__heading2 {
	font-weight: 700;
	text-align: center;
	color: white;
	text-shadow: 1px 1px 2px black;
}

.mwfbi_banner2 {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 0 5rem;
	height: 30vh;
	background-color: var(--white);
}
.mwfbi_banner__text--black {
	font-size: 1.6rem;
	font-weight: 200;
	color: grey;
	text-align: center;
}

.mwfbi_banner__heading--black {
	font-weight: 700;
	text-align: center;
	color: var(--red);
}

.mwfbi_banner__header {
	display: flex;
	grid-gap: 1rem;
	gap: 1rem;
	align-items: center;
	justify-content: center;
}
.mwfbi_banner__header--date {
	display: flex;
	grid-gap: .5rem;
	gap: .5rem;
	align-items: center;
	color: var(--white);
	font-size: 1.7rem;
	text-shadow: 1px 1px 2px black;
	line-height: 1;
}
.mwfbi_banner__header--date_icon {
	height: 1.5rem;
	width: 1.5rem;
	fill: var(--white);
	-webkit-filter: drop-shadow(1px 1px 2px black);
	        filter: drop-shadow(1px 1px 2px black);
}

@media (max-width: 990px) {
	.mwfbi_banner {
		background-size: cover;
		/* width: auto; */
		display: flex;
	}
	.mwfbi_banner__text {
		font-weight: 300;
		color: white;
		text-align: center;
		justify-content: center;
		font-style: italic;
	}
	.mwfbi_banner__heading {
		font-weight: 700;
		padding-bottom: 1rem;
		text-align: center;
		justify-content: center;
		/* padding-top: 25rem; */
		color: white;
	}
}
@media (max-width: 760px) {
	.mwfbi_banner {
		background-size: cover;

		display: flex;
		height: 30vh;
	}
	.mwfbi_banner3 {
		height: 30vh;
		background-position-y: 0;
	}
	.mwfbi_banner__header {
		flex-wrap: wrap;
	}
	.mwfbi_banner__text {
		font-weight: 300;
		color: white;
		text-align: center;
		justify-content: center;
		font-style: italic;
		padding: 0 1rem;
	}
	.mwfbi_banner__heading {
		font-weight: 700;
		padding-bottom: 1rem;
		text-align: center;
		justify-content: center;
		padding-top: 5rem;
		color: white;
	}
}

.simpleButton {
	border: none;
	outline: none;
	background-color: transparent;
	padding: .5rem 2rem;
	font-family: inherit;
	border-radius: .5rem;
	cursor: pointer;
	transition: all .4s;
	height: 5rem;
}
.simpleButton__red {
	background-color: var(--red);
	border: 1px solid var(--red);
	/* border-bottom-right-radius: 10px; */

	color: var(--bg-primary);
	/* margin-right: 5rem; */
}
.simpleButton__red:hover {
	background-color: transparent;
	border: 1px solid var(--red);
	color: var(--red);
}
.simpleButton__black {
	background-color: var(--black);
	border: 1px solid var(--black);
	color: var(--bg-primary);
}
.simpleButton__black:hover {
	background-color: transparent;
	border: 1px solid var(--black);
	color: var(--black);
}

.fbf__content {
	display: flex;
	grid-gap: 10rem;
	gap: 10rem;
	padding: 10rem 33rem;
	justify-content: center;
}
.fbf_heading {
	font-size: 2.5rem;
	font-weight: 600;
	margin-bottom: 2.5rem;
}
.fbf__desc {
	font-size: 1.6rem;
	/* flex: 1; */
	font-weight: 500;
	padding-bottom: 3.5rem;
}

.fbf__image {
	width: 50rem;
}
.mwfbi__buttons {
	display: flex;
	flex-direction: row;
	grid-gap: 1rem;
	gap: 1rem;
	margin-top: 2rem;
	padding: 0rem;
	width: 100%;
	justify-content: center;
	align-items: center;
	text-align: center;
	align-self: center;
}

.mwfbi__quote {
	background-color: var(--dark-grey);
	padding: 2.5rem 0;
	font-size: 2.5rem;
	color: var(--white);
	justify-content: center;
	text-align: center;
	width: '100%';
}
.learn_more {
	margin-top: 2rem;
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
}
.learn_more__heading {
	color: red;
	margin-bottom: 2rem;
	font-size: 2.5rem;
	font-weight: 600;
}
.learn_more__subheading {
	max-width: 45vw;
	font-size: 1.6rem;
	font-weight: 300;
	text-align: center;
	margin-bottom: 5rem;
}
.learn_more__logos {
	margin-top: 2rem;
	display: flex;
	grid-gap: 12rem;
	gap: 12rem;
	align-items: center;
	padding-bottom: 4rem;
}
.learn_more__logo {
	width: 40rem;
	cursor: pointer;
}
.learn_more__logo1 {
	width: 30rem;
	cursor: pointer;
}

@media only screen and (max-width: 1400px) {
	.fbf__content {
		padding: 2rem 15rem;
	}
}
@media only screen and (max-width: 1200px) {
	.fbf__content {
		padding: 2rem 10rem;
	}
}
@media only screen and (max-width: 56em) {
	.fbf__content {
		padding: 2rem 5rem;
	}
}

@media only screen and (max-width: 768px) {
	.fbf__content {
		padding: 2rem 0rem;
		display: inline-flex;
		flex-direction: column;
		/* max-width: 100%; */
	}

	.mwfbi__buttons {
		display: flex;
		flex-direction: column;
		width: 100%;
		justify-content: center;
		align-items: center;
	}
	.fbf__image {
		width: 90vw;
		margin-top: -5rem;
	}
	.mwfbi__quote {
		max-width: 100%;
	}
	.learn_more {
		max-width: 100%;
	}
	.learn_more__logos {
		/* margin-top: 2rem; */
		display: inline;
		grid-gap: 1rem;
		gap: 1rem;
		/* flex-direction: column; */
		/* align-items: center; */
		/* padding-bottom: 4rem; */
		/* max-width: 100%; */
		width: 90vw;
	}
	.learn_more__logo {
		display: flex;
		width: 90vw;
	}
}
/* @media only screen and (min-width: 1500px) {
	.fbf__content {
		padding: 2rem 15rem;
	}
} */

/* @media only screen and (max-width: 17.5em) {
	.fbf__content {
		padding: 2rem 0rem;
		display: inline-flex;
		
	}

	.mwfbi__buttons {
		display: flex;
		flex-direction: column;
		width: 100%;
	}
	.fbf__image {
		width: 100%;
	}
	.mwfbi__quote {
		max-width: 60%;
	}
	.learn_more {
		max-width: 60%;
	}
	.learn_more__logos {
		
		display: inline;
		gap: 1rem;
	
		max-width: 100%;
	}
	.learn_more__logo {
		display: flex;
		max-width: 100%;
	}
}

@media only screen and (max-width: 22.5em) {
	.fbf__content {
		padding: 2rem 0rem;
		display: inline-flex;
		flex-direction: column;
		max-width: 80%;
	}

	.mwfbi__buttons {
		display: flex;
		flex-direction: column;
		width: 100%;
	}
	.fbf__image {
		width: 100%;
	}
	.mwfbi__quote {
		max-width: 80%;
	}
	.learn_more {
		max-width: 90%;
	}
	.learn_more__logos {
		
		display: inline;
		gap: 1rem;
		
		max-width: 90%;
	}
	.learn_more__logo {
		display: flex;
		max-width: 90%;
	}
} */

.faqs {
	margin: 2rem 0;
	display: flex;
	flex-direction: column;
	align-items: center;
	display: flex;
	flex-direction: column;
	grid-gap: 2rem;
	gap: 2rem;
	padding: 3rem 40rem;
}
.faq {
	background-color: var(--light-grey2);
	/* box-shadow: var(--box-shadow1); */
	border-radius: 1rem;
	overflow: hidden;
	transition: all .4s;
	width: 55vw;
	display: flex;
	grid-gap: 1rem;
	gap: 1rem;
	align-items: center;
	position: relative;
}
.faq__number {
	font-size: 4rem;
	font-weight: 600;
	color: var(--dark-grey);
	padding: 4.5rem 2rem;
	background-color: var(--light-grey);
	/* width: 9%; */
	text-align: center;
}
.accordion {
	color: var(--dark-grey);
	cursor: pointer;
	font-size: 1.7rem;
	font-weight: 600;
	transition: all .4s;

	/* margin-top: -5rem; */
}
.accordion::after {
	content: '+';
	font-size: 2.5rem;
	position: absolute;
	top: .5rem;
	right: .5rem;
}
.accordion.active::after {
	content: '-';
	font-size: 2.5rem;
	top: .5rem;
	right: .5rem;
}

.accordion_icon {
	height: 2.5rem;
	width: 2.5rem;
	color: var(--light-grey);
	position: absolute;
	top: -2rem;
	right: .5rem;
}
.accordion_heading {
	color: var(--dark-grey);
	font-size: 1.7rem;
	font-weight: 600;
	transition: all .4s;
}
.active {
	/* margin-top: 2rem; */
}
p.accordion:hover {
	color: var(--red);
}
/* .faq:hover {
	color: red;
} */
.faq_side {
	display: flex;
	flex-direction: row-reverse;
	justify-content: space-around;
}
.FAQ_button {
	background-color: var(--dark-grey);
	padding: 1rem 2rem;
	border: none;
	outline: none;
	color: var(--pure-white);
	text-transform: uppercase;
	font-size: 1.3rem;
	font-weight: 700;
	border-radius: 5px;
	cursor: pointer;
	text-decoration: none;
	/* margin-right: 2rem; */
}

/* Unicode character for "plus" sign (+) */
/* p.accordion:after {
	content: '\2795';
	font-size: 13px;
	color: #777;
	float: right;
	margin-left: 5px;
} */

/* Unicode character for "minus" sign (-) */
/* p.accordion.active:after {
	content: "\2796";
} */

/* Style the element that is used for the panel class */

.panel {
	font-size: 1.3rem;
	font-weight: 500;
	max-height: 0;
	/* overflow: hidden; */
	transition: 0.4s ease-in-out;
	opacity: 0;
	display: flex;
	flex-direction: column;
	grid-gap: 1rem;
	gap: 1rem;
	align-items: flex-start;
}

.panel.show {
	opacity: 1;
	max-height: 500px;
	/* padding: 1.5rem 0; */
}
.link-to-claims {
	font-size: 1.6rem;
	text-decoration: none;
}
@media only screen and (max-width: 1400px) {
	.faqs {
		padding: 3rem 10rem;
	}
}

@media only screen and (max-width: 780px) {
	.faqs {
		padding: 3rem 1rem;
	}
	.faq {
		width: 100%;
	}
	.faq__number {
		padding: 7rem 2rem;
	}
}

.working_at__main-content-margin {
  padding: 3rem 33rem;
  background-color: var(--dark-grey);
  justify-content: center;
  display: flex;
  flex-direction: column;
  padding-top: 2rem;
}

.working_at__heading__heading_1 {
  font-weight: 500;
  font-size: 2.5rem;
  color: white;
  display: flex;
}
.working_at_home_outer {
  display: flex;
  width: 100%;
  grid-gap: 5rem;
  gap: 5rem;
  /* justify-content: space-between; */
  align-items: center;
  margin-bottom: 2rem;
}
.working_at_home {
  display: flex;
  width: 70%;
}
.career_image_outer {
  width: 30%;
  display: flex;
}
.career_image {
  display: flex;
  width: 15vw;
  /* margin-top: -3rem; */
}
.working_at__texts-heading_2 {
  font-size: 1.6rem;
  font-weight: 300;
  color: white;
}

.why_choose {
  padding: 3rem 33rem;
  display: flex;
  /* flex-direction: column; */
  width: 100%;
  grid-gap: 2rem;
  gap: 2rem;
  /* align-items: center; */
  /* position: relative; */
}
.why_choose__heading-heading_1 {
  font-size: 2.5rem;
}
.why_choose__subheading {
  font-size: 1.8rem;
  font-weight: 700;
  padding-bottom: 1.2rem;
}
.why_choose__text {
  font-size: 1.6rem;
  font-weight: 300;
}
.why_choose__section {
  z-index: 100;
  display: flex;
  width: 50%;
  flex-direction: column;
}

.testimonials {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50%;
}

.testimonials__heading {
  font-weight: 600;
  font-size: 2.5rem;
  text-align: center;
}

.testimonials__subheading {
  font-weight: 500;
}

.testemonials__data {
  display: flex;
  grid-gap: 2rem;
  gap: 2rem;
  margin-top: 2rem;
  width: 100%;
  height: 35vh;
}
.testimonial {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.testimonial__image {
  width: 12rem;
  border-radius: 50%;
}
.testimonial__text {
  padding: 2rem 5rem;
  text-align: center;
  font-size: 1.6rem;
}
.testimonial__name {
  font-weight: 600;
  line-height: 1;
}
.testimonial__subtext {
  font-weight: 500;
  line-height: 1;
  font-style: italic;
}
.testimonial__hr {
  width: 20rem;
  height: 3px;
  background-color: black;
  margin-top: 0rem;
}
.testimonial__hr1 {
  width: 20rem;
  height: 3px;
  background-color: black;
  margin-top: 1rem;
}

.application {
  background-color: var(--light-grey2);
  padding: 1rem 33rem;
  display: flex;
  width: 100%;
  justify-content: space-between;
  /* gap: 20rem; */
}

.application__card {
  /* width: 50%; */
  padding: 2rem 0rem;
  display: flex;
  grid-gap: 2rem;
  gap: 2rem;
}
.application__card--image {
  width: 8rem;
}
.application_card_text {
  display: flex;
  flex-direction: column;
}
.application__card--heading {
  /* margin-top: 1rem; */
  font-weight: 600;
  font-size: 2.5rem;
}
.application__card--text {
  font-weight: 500;
  font-size: 1.5rem;
}
.working_at .button__text {
  text-transform: capitalize;
}
.button_text1 {
  margin-top: 2.5rem;
}
.humanresource__link {
  color: red;
  text-decoration: none;
}
.why_choose__department {
  font-family: inherit;
  border: none;
  cursor: pointer;
  margin-top: 2rem;
  background-color: var(--bg-primary);
  padding: 2rem 1rem;
  border-radius: 1rem;
  transition: all 0.4s;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.why_choose__department:hover {
  -webkit-transform: translateX(0.5rem);
          transform: translateX(0.5rem);
  background-color: var(--light-border);
  box-shadow: var(--box-shadow1);
}

.why_choose__department--text {
  font-weight: 500;
  text-align: center;
}
.main-content-department {
  padding-right: 35rem;
  padding-left: 35rem;
}

.department {
  padding: 1.5rem 35rem;
}
.department__heading1 {
  justify-content: center;
  display: flex;
  font-size: 2.5rem;
  font-weight: 800;
  margin-bottom: 2rem;
}
.department__heading2 {
  font-size: 1.8rem;
  font-weight: 700;
  display: inline;
  padding-top: 0rem;
}
.department__heading3 {
  font-size: 1.5rem;
  font-weight: 500;
  position: relative;
}
.department__gridcard__image {
  padding: 0.8rem;
  background-color: red;
  border-radius: 10%;
  height: 80px;
  width: 80px;
  fill: white;
  color: white;
  align-self: center;
}
.depatment__labels {
  display: flex;
  flex-direction: column;
  margin-left: 2rem;
  margin-top: 1.3rem;
}
.department__gridcard {
  height: 10rem;
  width: 35rem;
  display: flex;
  position: relative;
}
.department__maingrid {
  display: flex;
  flex-direction: row;
  position: relative;
  /* gap: 3rem; */
  justify-content: space-between;
  margin-bottom: 2rem;
}

.slider1 {
  background-color: var(--pure-white);
}
.slides {
  padding: 0;
  display: block;
  margin: 0 0;
  width: 60rem;
  height: 38rem;
  position: relative;
}
.slides * {
  user-select: none;
  -ms-user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -webkit-touch-callout: none;
}
.slides input {
  display: none;
}

.slide-container {
  display: block;
  display: flex;
  justify-content: center;
}
.slide {
  top: 0;
  opacity: 0;
  display: block;
  position: absolute;
  -webkit-transform: scale(0);
          transform: scale(0);
  transition: all 0.7s ease-in-out;
}

input:checked + .slide-container .slide {
  opacity: 1;
  -webkit-transform: scale(1);
          transform: scale(1);
  transition: opacity 1s ease-in-out;
}

input:checked + .slide-container .nav label {
  display: block;
}

.nav-dots {
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-55%);
          transform: translateX(-55%);
  bottom: 30px;
  height: 11px;
  display: flex;
  justify-content: center;
}

.nav-dots .nav-dot {
  top: -5px;
  width: 2rem;
  height: 2rem;
  margin: 0 4px;
  position: relative;
  border-radius: 100%;
  /* display: inline-block; */
  background-color: rgba(241, 11, 11, 0.6);
}

.nav-dots .nav-dot:hover {
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.8);
}

input#img-1:checked ~ .nav-dots label#img-dot-1,
input#img-2:checked ~ .nav-dots label#img-dot-2,
input#img-3:checked ~ .nav-dots label#img-dot-3,
input#img-4:checked ~ .nav-dots label#img-dot-4,
input#img-5:checked ~ .nav-dots label#img-dot-5,
input#img-6:checked ~ .nav-dots label#img-dot-6 {
  background: rgba(0, 0, 0, 0.8);
}
.slider_video {
  width: 100%;
  border-radius: 10px;
}

@media only screen and (max-width: 1500px) {
  .working_at__main-content-margin {
    padding: 3rem 15rem;
  }
  .why_choose {
    padding: 3rem 15rem;
  }
  .application {
    padding: 1rem 15rem;
  }
  .department {
    padding: 1.5rem 15rem;
  }
}
@media only screen and (max-width: 1400px) {
  .working_at__main-content-margin {
    padding: 3rem 15rem;
  }
  .why_choose {
    padding: 3rem 15rem;
  }
  .application {
    padding: 1rem 15rem;
  }
  .department {
    padding: 1.5rem 15rem;
  }
  .slides {
    padding: 0;
    display: block;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
}
@media only screen and (max-width: 1200px) {
  .working_at__main-content-margin {
    padding: 3rem 10rem;
  }
  .why_choose {
    padding: 3rem 10rem;
  }
  .application {
    padding: 1rem 10rem;
  }
  .department {
    padding: 1.5rem 10rem;
  }
  .department__gridcard {
    margin-bottom: 3rem;
  }

  .slides {
    padding: 0;
    display: block;
  }
  .nav-dots {
    position: absolute;
    width: 100%;
    left: 55%;
    -webkit-transform: translateX(center);
            transform: translateX(center);

    bottom: 10px;
    height: 11px;
    display: flex;
  }
}
@media only screen and (max-width: 900px) {
  .department__gridcard {
    margin-bottom: 5rem;
  }
}

@media only screen and (max-width: 760px) {
  .working_at__main-content-margin {
    padding: 3rem 1rem;
    width: 100%;
    flex-direction: column;
  }

  .working_at_home {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .career_image_outer {
    display: none;
  }
  .career_image {
    display: none;
  }
  .why_choose {
    padding: 3rem 1rem;
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .why_choose__section {
    width: 100%;
  }
  .testimonials {
    padding: 2rem 0;
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .testimonials__heading {
    width: 100%;
    text-align: center;
  }
  .testimonial__text {
    padding: 2rem 0;
    display: flex;
  }
  .testemonials__data {
    display: flex;
    grid-gap: 2rem;
    gap: 2rem;
    margin-top: 2rem;
    width: 80vw;
  }
  .application {
    display: flex;
    flex-direction: column;
    grid-gap: 1rem;
    gap: 1rem;
    width: auto;
    padding: 2rem 1rem;
  }
  .department__maingrid {
    display: flex;
    flex-direction: column;
    grid-gap: 3rem;
    gap: 3rem;
    padding-bottom: 1.5rem;
  }
  .department {
    padding: 1.5rem 2rem;
    justify-content: start;
  }
  .department__gridcard {
    margin-bottom: 5rem;
  }
  .application__card--image {
    width: 6rem;
    height: 6rem;
  }
  .slider1 {
    display: flex;
    align-self: center;
    align-items: center;
    text-align: center;
    justify-content: center;
  }
  .nav-dots {
    /* position: absolute;
		width: 85vw; */
    left: 0;
    -webkit-transform: translateX(0);
            transform: translateX(0);
    width: 85vw;
    bottom: 10px;
    height: 11px;
    display: flex;
    justify-content: center;
  }
}


.Pagination_ms-pagination1__2sCTa {
    display: none;
  }
  
  .Pagination_paginator_head__3VCf1 {
    font-size: 1.3rem;
    color: rgb(107, 103, 103);
    font-weight: 300;
  }
  
  .Pagination_pagenator__4oq-m {
    display: flex;
    /* gap: 1rem; */
    cursor: pointer;
  
    /* border: 1px solid var(--light-grey); */
  }
  
  .Pagination_paginationActive__LCeDH {
    background-color: red;
    color: white;
  }
  
  .Pagination_paginationActive__LCeDH a {
    color: white!important;
  }
  
  .Pagination_paginationBttns__2FIIY {
    list-style: none;
    font-family: Poppins;
    grid-gap: 1rem;
    gap: 1rem;
    font-style: normal;
    font-weight: 500;
    font-size: 1rem;
    display: flex;
    justify-content: end;
    align-items: center;
    color: black;
  }

  .Pagination_previousBttn__3pDVr{
    color:  var(--red)
  }
  
  .Pagination_paginationBttns__2FIIY li {
    height: 3rem;
    width: 3rem;
    border: 1px solid #e7e5e5;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.4s;
    overflow: hidden;
    color: black;
    background-color:  var(--red);
  }
  
  .Pagination_paginationBttns__2FIIY a {
    padding: 0.5rem 0.4rem;
    /* margin: .8rem; */
    border: 1px solid var(--primary-color);
    color: black;
    cursor: pointer;
    transition: all 0.4s;
  }
  
  .Pagination_paginationBttns__2FIIY li:hover {
    color: var(--white)!important;
    background-color:  var(--red);
  }
  
  .Pagination_paginationBttns__2FIIY a:hover {
    color: var(--white);
  }
.learning_content {
	margin: 1rem 33rem;
	font-family: "Poppins", sans-serif;
	font-weight: 400;
  }
  .learning__filters {
	display: grid;
	grid-gap: 1rem;
	gap: 1rem;
	grid-template-columns: repeat(5, 1fr);
  }
  .learning__filters--btn {
	font-family: inherit;
	border: none;
	background-color: transparent;
	text-align: left;
	color: grey;
	padding: 1rem;
	/* min-width: 23.5rem; */
	cursor: pointer;
	box-shadow: 1px 1px 1rem rgba(0, 0, 0, 0.1);
	transition: all 0.4s;
  }
  .learning__filters--active {
	background-color: var(--red);
	color: var(--bg-primary);
  }
  .learning__filters--btn:hover {
	-webkit-transform: scale(1.03);
	        transform: scale(1.03);
  }
  .learning_content__cards {
	margin-top: 5rem;
	display: grid;
	grid-template-columns: repeat(3, 1fr);
  }
  .learning_content__card {
	background-size: cover;
	background-repeat: no-repeat;
	/* width: 40.5rem; */
	height: 25rem;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	padding-bottom: 2rem;
	padding-left: 2rem;
	color: var(--bg-primary);
	text-decoration: none;
	transition: all 0.4s;
  }
  .learning_content__card:hover .learning_content__card--title,
  .learning_content__card:hover .learning_content__card--date {
	-webkit-transform: translateY(-1rem);
	        transform: translateY(-1rem);
  }
  .learning_content__card--date {
	font-weight: 400;
	color: lightgray;
	display: flex;
	align-items: center;
	grid-gap: 0.5rem;
	gap: 0.5rem;
	transition: all 0.4s;
  }
  .learning_content__card--date_icon {
	height: 1.5rem;
	width: 1.5rem;
	fill: lightgray;
  }
  .learning_content__card--title {
	font-weight: 400;
	transition: all 0.4s;
  }
  
  /* Blog Details */
  .blog__red_section {
	background-color: var(--red);
	padding: 3rem 33rem;
	font-family: "Poppins", sans-serif;
	font-weight: 400;
  }
  .blog__red_section--text {
	font-size: 3rem;
	text-align: center;
	color: var(--white);
	font-weight: 600;
  }
  .blog__data {
	padding: 3rem 40rem;
	font-family: "Poppins", sans-serif;
	font-weight: 400;
  }
  .blog__data--text {
	font-size: 1.5rem;
	font-weight: 500;
	color: grey;
  }
  .blog__data--heading {
	margin: 1rem 0;
	font-size: 2.5rem;
	font-weight: 600;
	color: var(--black);
  }
  .blog__data--source {
	margin-top: 1rem;
	display: flex;
	grid-gap: 0.5rem;
	gap: 0.5rem;
	align-items: center;
  }
  .blog__data--source p {
	font-size: 1.5rem;
	font-weight: 500;
	color: grey;
  }
  .blog__data--source a {
	text-decoration: none;
	color: var(--red);
	font-size: 1.5rem;
	font-weight: 500;
	transition: all 0.4s;
  }
  .blog__data--source a:hover {
	/* border-bottom: 1px solid var(--red); */
	text-decoration: underline;
  }
  .blog__related {
	margin: 4rem 0;
	padding: 0 33rem;
  }
  .blog__related--title {
	font-size: 2rem;
	color: var(--black);
	font-weight: 600;
  }
  .blog__related--posts {
	margin: 2rem 0;
	display: grid;
	grid-gap: 2rem;
	gap: 2rem;
	grid-template-columns: repeat(3, 1fr);
  }
  .blog__related--post {
	display: flex;
	flex-direction: column;
	grid-gap: 1rem;
	gap: 1rem;
	align-items: flex-start;
	text-decoration: none;
  }
  .blog__related--image {
	/* height: fit-content; */
	width: 100%;
	height: 20rem;
  }
  .blog__related--heading {
	font-size: 1.5rem;
	font-weight: 600;
	color: var(--black);
  }
  .blog__related--link {
	background-color: var(--black);
	border: 1px solid var(--black);
	font-size: 1.3rem;
	color: var(--white);
	padding: 1rem 2rem;
	transition: all 0.4s;
  }
  .blog__related--link:hover {
	background-color: transparent;
	border: 1px solid var(--black);
	color: var(--black);
  }
  
  @media only screen and (max-width: 1500px) {
	.learning_content {
	  margin: 1rem 15rem;
	}
	.blog__red_section {
	  padding: 3rem 15rem;
	}
	.blog__data {
	  padding: 3rem 20rem;
	}
	.blog__related {
	  padding: 0 15rem;
	}
  }
  @media only screen and (max-width: 1200px) {
	.learning_content {
	  margin: 1rem 10rem;
	}
	.blog__related {
	  padding: 0 10rem;
	}
	.blog__red_section {
	  padding: 3rem 10rem;
	}
	.blog__data {
	  padding: 3rem 15rem;
	}
  }
  @media only screen and (max-width: 900px) {
	.learning_content {
	  margin: 1rem 5rem;
	}
	.blog__related {
	  padding: 0 5rem;
	}
	.blog__red_section {
	  padding: 3rem 5rem;
	}
	.blog__data {
	  padding: 3rem 10rem;
	}
  }
  
  @media only screen and (max-width: 765px) {
	.learning_content {
	  margin: 1rem 5rem;
	}
	.blog__data {
	  padding: 3rem 5rem;
	}
	.blog__related {
	  padding: 0 5rem;
	}
  }
  @media only screen and (max-width: 500px) {
	.learning_content {
	  margin: 1rem 0rem;
	}
	.blog__related {
	  padding: 0;
	}
	.blog__related--posts {
	  grid-template-columns: 1fr;
	}
	.learning__filters {
	  grid-template-columns: repeat(3, 1fr);
	}
  
	.learning_content__cards {
	  grid-template-columns: repeat(2, 1fr);
	}
  }
  @media only screen and (max-width: 30em) {
	.learning_content__cards {
	  margin-top: 5rem;
	  display: grid;
	  grid-template-columns: repeat(2, 1fr);
	}
	.learning__filters {
	  grid-template-columns: repeat(2, 1fr);
	}
  }
  @media only screen and (max-width: 20em) {
	.learning_content__cards {
	  margin-top: 5rem;
	  display: grid;
	  grid-template-columns: repeat(1, 1fr);
	}
  }
  .search_blog_container{
	text-align: center;
  }
.search_blog{
    outline: none;
}
.how-to-file-claim-main {
	width: 100%;
	position: absolute;
	top: 60%;
	display: flex;
	justify-content: center;
}

.how-to-file-claim-box {
	height: 12rem;
	background-color: #00000000;
	width: 80%;
	display: flex;
	justify-content: center;
	text-align: center;
	border-radius: 25px;
	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
	transition: 0.3s;
}

.how-to-file-claim-box:hover {
	box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

.how-to-file-claim-box-left {
	flex: 1 1;
	padding-top: 2rem;
	color: #ffffff;
	background-color: #000000;
	border-top-left-radius: 25px;
	border-bottom-left-radius: 25px;
	display: inline-table;
}

.how-to-file-claim-box-right {
	flex: 1 1;
	background-color: #ffffff;
	border-top-right-radius: 25px;
	border-bottom-right-radius: 25px;
	padding-top: 2rem;
	flex-direction: row;
	position: relative;
	justify-content: center;
}

.claim__icon {
	fill: white;
	height: 3.5rem;
	width: 3.5rem;
	transition: all 0.4s;
}

.dash {
	background-color: #000000;
	height: 5px;
	width: 30px;
}

.how-we-work-inside {
	width: 80%;
}

.how-we-work-main {
	padding: 3rem 33rem;
}
.how-we-work-main__heading {
	font-size: 2.5rem;
	text-transform: uppercase;
	font-weight: 800;
	text-align: center;
}
.how-we-work-main__steps {
	display: flex;
	grid-gap: 2rem;
	gap: 2rem;

	/* justify-content: space-between; */
	margin-top: 2rem;
}
.grid-card {
	display: flex;
	flex-direction: column;
	align-items: center;
	background-color: #e8e8e8;
	padding: 2rem 3rem;
	transition: all .4s;
	width: 100%;
}

.grid-card:hover {
	-webkit-transform: translateY(-.5rem);
	        transform: translateY(-.5rem);
}
.grid-card-image {
	height: 6rem;
}
.grid-card-title {
	margin-top: 2rem;
	font-size: 1.8rem;
	text-transform: uppercase;
	font-weight: 600;
}
.grid-card-sub-title {
	font-size: 1.3rem;
	/* font-weight: 600; */
	text-align: center;
	font-family: bold;
}

.grid-card-desc {
	font-size: 1.2rem;
	text-align: center;
}

.how-we-work-desc {
	padding: 6rem 33rem;
	display: flex;
	grid-gap: 18rem;
	gap: 18rem;
	justify-content: space-between;
	
	background-color: #e8e8e8;
}
.how-we-work-desc-heading {
	font-size: 2.5rem;
	font-weight: 600;
	color: var(--red);
	margin-bottom: .3rem;
}
.how-we-work-desc-heading span {
	display: block;
	color: var(--black);
	font-size: 1.8rem;
}
.how-we-work-desc-desc {
	font-size: 1.3rem;
	color: var(--dark-grey);
}
.fbf__image__bus {
	height: 30rem;
}

.contract-main {
	display: flex;
	margin-top: 2rem;
	padding: 2rem 33rem;
}
.how-we-work-desc-heading1 {
	font-size: 1.7rem;
	font-weight: 500;
	margin-bottom: 1rem;
}

/* .contract-content {
	padding: 3rem 20rem;
	display: flex;
} */

/* .spacing {
	padding: 2rem;
} */

/* .contract-card-list {
	width: 100%;
} */

.contract-card {
	/* height: 15rem; */
	background-color: #ffffff;
	transition: 0.3s;
	display: flex;
	flex-direction: column;
	position: relative;
	margin-bottom: 2rem;
	padding: 1rem;
	padding-left: 1.5rem;
	height: 9rem;
}

.contract-card:hover {
	box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

.contract-card > .title {
	font-family: inherit;
	font-size: 1.5rem;
	font-weight: 600;
}

.contract-card > .desc {
	font-family: inherit;
	font-weight: 500;
	/* width: 80%; */
}
@media only screen and (max-width: 1400px) {
	.how-we-work-main {
		padding: 3rem 15rem;
	}
	.how-we-work-desc {
		grid-gap: 5rem;
		gap: 5rem;
		padding: 3rem 15rem;
	}
	.contract-main {
		padding: 3rem 15rem;
	}
}

@media only screen and (max-width: 1200px) {
	.quote {
		padding: 3rem 10rem;
	}
	.how-we-work-main {
		padding: 3rem 10rem;
		grid-gap: 5rem;
		gap: 5rem;
	}
	.how-we-work-desc {
		padding: 3rem 10rem;
		grid-gap: 1rem;
		gap: 1rem;
	}
	.fbf__image__bus {
		height: 20vw;
	}
	.contract-main {
		padding: 3rem 10rem;
	}
	.contract-card {
		height: auto;
	}
	.how-we-work-main__steps {
		grid-gap: 5rem;
		gap: 5rem;
		width: 100%;
	}
	.grid-card {
		width: 30%;
	}
}
@media only screen and (max-width: 760px) {
	.quote {
		display: flex;
		flex-direction: column;
		padding: 3rem 1rem;
	}
	.how-we-work-main {
		padding: 3rem 1rem;
		display: flex;
		flex-direction: column;
	}
	.how-we-work-desc {
		padding: 3rem 1rem;
		grid-gap: 1rem;
		gap: 1rem;
		flex-direction: column;
	}
	.fbf__image__bus {
		height: 50vw;
	}
	.contract-main {
		padding: 3rem 1rem;
	}
	.contract-card {
		height: auto;
	}
	.how-we-work-main__steps {
		display: flex;
		flex-direction: column;
		grid-gap: 1rem;
		gap: 1rem;
		width: 100%;
	}
	.grid-card {
		width: auto;
	}
}
/* @media only screen and (min-width: 1500px) {
	.how-we-work-main {
		padding: 3rem 33rem;
	}
	.how-we-work-main__steps {
		width: 100%;
		width: 3rem 33rem;
	}
	.how-we-work-desc {
		padding: 3rem 33rem;
	}
	.contract-main {
		padding: 3rem 33rem;
	}
	.grid-card {
		width: 30rem;
	}
	.quote {
		padding: 3rem 33rem;
	}
} */

/* }

@media only screen and (min-width: 93.75em) {
	.how-we-work-main,
	.how-we-work-desc {
		padding: 3rem 33rem;
	}
	.contract-main {
		padding: 3rem 33rem;
	}
	.grid-card {
		width: 30rem;
	}
	.quote {
		padding: 3rem 33rem;
	}
}

@media only screen and (max-width: 48em) {
	.how-we-work-main {
		padding: 2rem 1rem;
		width: 100%;
		display: flex;
		flex-direction: column;
	}
	.how-we-work-main__steps {
		display: flex;
		flex-direction: column;
		padding: 2rem 0rem;
	}
	.how-we-work-desc {
		padding: 2rem 0rem;
		width: 100%;
		display: flex;
		flex-direction: column;
	}
	.fbf__image__bus {
		height: 40rem;
	}
	.contract-main {
		padding: 2rem 1rem;
	}
	.grid-card {
		display: flex;
		width: 100%;
	}
	.contract-card {
		height: 100%;
	}
	.quote {
		padding: 2rem 0rem;
		display: flex;
		flex-direction: column;
		gap: 1rem;
	}
	.Logo_container {
		background-color: aqua;
	}
} */

.findanagent-search-main-section {
	display: flex;
	flex-direction: column;
	justify-content: center;
	text-align: center;
	background-color: rgb(238, 238, 238);
	justify-content: center;
	padding-bottom: 4rem;
	position: relative;
  }
  
  .findanagent-search-main-section>label {
	font-size: 2.5rem;
	font-weight: 600;
	padding: 1rem;
  }
  
  .findanagent-search-main-section-input {
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
  }
  
  .input_container  {
	position: relative;
	padding: 0rem 0rem 0rem 1rem;
	width: 40rem;
  }
  
  .input_container>input {
	height: 5rem;
	font-size: 1.2rem;
	margin: 0;
	padding: 0rem 0rem 0rem 2rem;
	width: 100%;
	outline: none;
	border-radius: 5px;
	padding-left: 10rem;
  }
  
  .input_container>.right_image {
	position: absolute;
	right: 1rem;
	width: 3rem;
	height: 3rem;
	margin-top: 10px;
	border-top-right-radius: 5px;
  }
  
  .input_container>.left_image {
	position: absolute;
	margin-top: 2px;
	margin-left: 2px;
	width: 8rem;
	height: 4.6rem;
	border-top-left-radius: 5px;
	padding: 4px;
	background-color: rgb(228, 228, 228);
	fill: var(--black);
  }
  
  .findanagent-search-main-section-input>input {}
  
  .findanagentContainer__formgroup--input {
	background-color: transparent;
	border: none;
	outline: none;
	font-family: inherit;
	width: 100%;
	height: 5vh;
	padding-left: 8rem;
  }
  
  .logoContainer__formgroup--iconBox {
	padding: 2rem;
	border-radius: 3rem;
	box-shadow: var(--box-shadow-input);
	height: 6.5rem;
	width: 6.5rem;
	color: white;
	background-color: rgb(255, 255, 255);
	border: none;
	outline: none;
	position: absolute;
	z-index: 999;
	cursor: pointer;
	-webkit-transform: translateX(2px);
	        transform: translateX(2px);
	transition: 0.3s;
	display: flex;
	align-items: center;
	text-align: center;
  }
  
  .findanagentContainer__formgroup--inputBox {
	display: flex;
	align-items: center;
	grid-gap: 1rem;
	gap: 1rem;
	border-radius: 10rem;
	background-color: var(--bg-primary);
	box-shadow: var(--box-shadow-input);
	overflow: hidden;
	/* width: 35rem; */
	height: 5rem;
  }
  
  .logoContainer__formgroup_postfix--iconBox {
	padding: 1.5rem 2rem;
	display: flex;
	align-items: center;
	border-radius: 3.5rem;
  }
  
  .findanagentContainer__formgroup--icon {
	height: 2rem;
	width: 2rem;
	fill: var(--white);
  }
  
  .searchListContainer .findanagentContainer__formgroup--icon {
	height: 1.5rem;
	width: 1.5rem;
	fill: var(--white);
	margin: 6px;
  }
  
  .search_heading {
	font-family: Poppins;
	font-style: normal;
	font-weight: bold;
	line-height: 36px;
	color: #e02b21;
  }
  
  .user_profile_1 {
	display: inline-block;
	width: 100px;
	height: 100px;
	object-fit: cover;
  }
  
  .findanagent__content2 {
	width: 30%;
	position: relative;
	background-color: var(--white);
	margin-right: 2.5rem;
	border-radius: 1rem;
	padding: 2rem 3rem;
	padding-bottom: 0rem;
	display: flex;
	flex-direction: column;
	grid-gap: 2rem;
	grid-gap: 2rem;
	gap: 2rem;
  }
  
  .findanagent__content1 {
	background-color: var(--white);
	border-radius: 1rem;
	padding: 2rem 3rem;
	padding-bottom: 0;
	display: flex;
	grid-gap: 2rem;
	grid-gap: 2rem;
	gap: 2rem;
  }
  
  .search__button--iconBox {
	background-color: #e02b21;
	display: flex;
	align-items: center;
	padding: 1rem;
	border-radius: 50%;
	transition: all 1s;
  }
  
  .search__button--iconBox:hover {
	border: 1px solid;
	color: var(--black);
	background-color: var(--black);
  }
  
  .search__button--icon {
	fill: white;
	height: 3rem;
	width: 3rem;
	transition: all 1s;
  }
  
  .search__button {
	width: 6rem;
	display: flex;
	background: #ffffff00;
	border: 0px solid;
	cursor: pointer;
  }
  
  .main-section {
	display: flex;
	/* margin: 5rem 45rem 15rem 45rem; */
	margin-top: 5rem;
	text-align: center;
	justify-content: space-between;
	margin: 2rem 33rem;
	/* border: 1px solid var(--light-grey); */
	/* border-top: 1px solid var(--dark-grey); */
	position: relative;
  }
  
  .find-an-agent-sections {
	width: 60%;
	display: flex;
	grid-gap: 2rem;
	gap: 2rem;
	margin-top: 1rem;
  }
  
  .main-section-left {
	flex: 1 1;
	height: 60vh;
	display: flex;
	flex-direction: column;
	overflow: auto;
	position: relative;
  }
  
  .main-section-left-content {
	display: flex;
  
	flex-direction: column;
  }
  
  .main-section-left-content>.ms-cart {
	display: flex;
	margin-bottom: 1rem;
	border-bottom: solid rgb(202, 202, 202) 1px;
	padding-bottom: 1rem;
  }
  
  .main-section-left-content>.ms-cart>img {
	height: 16rem;
  }
  
  .main-section-left-content>.ms-cart>.ms-content-details {
	flex: 1 1;
	display: flex;
	flex-direction: column;
	text-align: left;
	padding: 0 1rem;
  }
  
  .main-section-left-content>.ms-cart>.ms-content-details>.title {
	color: red;
	font-size: 1.7rem;
	font-weight: 600;
  }
  
  .main-section-left-content>.ms-cart>.ms-content-details>.subtitle {
	color: rgb(136, 136, 136);
	font-size: 1.4rem;
	font-weight: 500;
	display: flex;
	/* flex: 1; */
  }
  
  .main-section-left-content>.ms-cart>.ms-content-details>.subtitle>.ms--icon {
	color: rgb(136, 136, 136);
	fill: rgb(114, 114, 114);
	height: 2rem;
	width: 2rem;
	padding: 0.2rem;
	justify-self: center;
  }
  
  .main-section-left-content>.ms-cart>.ms-content-details>.subtitle-email {
	color: red;
	font-size: 1.5rem;
	font-weight: 500;
	display: flex;
	flex: 1 1;
  }
  
  .main-section-left-content>.ms-cart>.ms-content-details>.subtitle-email>.ms--icon {
	color: red;
	fill: red;
	height: 2rem;
	width: 2rem;
	padding: 0.2rem;
	justify-self: center;
  }
  
  .main-section-right {
	flex: 1 1;
	height: 100%;
  }
  
  .main-section-right>iframe {
	flex: 1 1;
	height: 60vh;
	width: 100%;
	border: none;
  }
  
  .ms-pagination {
	padding: 0 33rem;
	color: var(--dark-grey);
	margin-bottom: 3rem;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
  }
  
  .ms-pagination2 {
	margin-top: 1rem;
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	color: var(--dark-grey);
  }
  
  .main-section-right h2 {
	display: flex;
  }
  
  .main-section .previousBttn,
  .nextBttn {
	padding: 0.5rem 1.2rem !important;
	font-size: 1rem !important;
  }
  
  .google-map-section {
	display: flex;
	align-items: center;
	justify-content: center;
  }
  
  .fa-bottom-main {
	display: flex;
	/* height: 50rem; */
	background-color: var(--light-grey2);
	padding: 4rem 33rem;
	grid-gap: 5rem;
	gap: 5rem;
	justify-content: center;
	position: relative;
  }
  
  .find-an-agent-image {
	width: 20vw;
  }
  
  .heading1_an_agent {
	font-size: 2.5rem;
	font-weight: 700;
	color: var(--red);
  }
  
  .subtitle_an_agent {
	font-size: 1.8rem;
	font-weight: 800;
  }
  
  .description {
	font-size: 1.5rem;
  }
  
  .ms-pagination1 {
	display: none;
  }
  
  .paginator_head {
	font-size: 1.3rem;
	color: rgb(107, 103, 103);
	font-weight: 300;
  }
  
  .pagenator {
	display: flex;
	/* gap: 1rem; */
	cursor: pointer;
  
	/* border: 1px solid var(--light-grey); */
  }
  
  .paginationActive {
	background-color: var(--light-grey2);
  }
  
  .paginationActive a {
	color: black;
  }
  
  .paginationBttns {
	list-style: none;
	font-family: Poppins;
	font-style: normal;
	font-weight: 500;
	font-size: 1rem;
	display: flex;
	align-items: center;
	color: black;
  }
  
  .paginationBttns li {
	height: 3rem;
	width: 3rem;
	border: 1px solid var(--light-grey2);
	display: flex;
	align-items: center;
	justify-content: center;
	transition: all 0.4s;
	overflow: hidden;
	color: black;
  }
  
  .paginationBttns a {
	padding: 0.5rem 0.4rem;
	/* margin: .8rem; */
	border: 1px solid var(--primary-color);
	color: black;
	cursor: pointer;
	transition: all 0.4s;
  }
  
  .paginationBttns li:hover {
	color: var(--white);
	border: 1px solid var(--dark-grey);
	background-color: var(--dark-grey);
  }
  
  .paginationBttns a:hover {
	color: var(--white);
  }
  
  .agent_details_section {
	display: flex;
	width: 100%;
	justify-content: space-between;
  }
  
  .agent_details {
	display: flex;
	padding: 3rem 33rem;
  }
  
  .agent_image {
	height: 20vh;
  }
  
  .agent_contact_details {
	margin-left: 2rem;
	align-self: center;
  }
  
  .agent_name {
	font-size: 3.5rem;
	color: var(--dark-grey);
  }
  
  .agent-content-details {
	flex: 1 1;
	display: flex;
	flex-direction: column;
	text-align: left;
	padding: 0 1rem;
  }
  
  .agent--icon {
	color: rgb(136, 136, 136);
	fill: rgb(114, 114, 114);
	height: 2rem;
	width: 2rem;
	padding: 0.2rem;
	justify-self: center;
  }
  
  .quote_minibox {
	width: 30rem;
	background-color: var(--light-grey2);
	padding: 1rem 2rem;
	border-radius: 10px;
	text-align: center;
	justify-content: space-between;
  }
  
  .quote_mini_heading {
	font-size: 2rem;
	font-weight: 700;
	padding-bottom: 1.5rem;
  }
  
  .quote_mini_subheading {
	font-size: 1.2rem;
	padding-bottom: 1.5rem;
  }
  
  .quote_mini_button {
	background-color: var(--red);
	padding: 1rem 5rem;
	border: none;
	outline: none;
	font-size: 1.4rem;
	border-radius: 5px;
	color: var(--white);
	font-weight: 500;
	text-decoration: none;
  }
  
  .about_me_outer {
	padding: 2rem 33rem;
	display: flex;
	grid-gap: 2rem;
	gap: 2rem;
  }
  
  .about_me_section {
	/* padding: 2rem 33rem; */
	padding-bottom: 1rem;
	margin-bottom: 5rem;
  }
  
  .about_me_heading {
	font-size: 2rem;
	padding-bottom: 1rem;
  }
  
  .about_me_subheading {
	font-size: 1.6rem;
	font-weight: 300;
  }
  
  .contact_me_button {
	background-color: var(--dark-grey);
	color: var(--white);
	text-decoration: none;
	padding: 1rem 2rem;
	border-radius: 5px;
	font-weight: 500;
	cursor: pointer;
  }
  
  .office_hours_section {
	text-align: center;
  }
  
  /* .hours_section {
	  border: 1px solid black;
  } */
  
  .ofiice_houres_table {
	width: 35rem;
	height: 30rem;
  }
  
  .ofiice_houres_table,
  tr,
  td {
	border: 1px solid black;
	font-size: 1.5rem;
	font-weight: 600;
	border-collapse: collapse;
  }
  
  .agent_location {
	padding: 2rem 35rem;
  }
  
  .agent_location>iframe {
	height: 40vh;
	width: 100%;
	border: none;
  }
  
  /* .gm-style .place-card-large {
	  width: 100%;
	  padding: 1rem 2rem;
	  background-color: var(--red);
  } */
  .subtitle1 {
	color: rgb(136, 136, 136);
	font-size: 1.4rem;
	font-weight: 500;
	display: flex;
	/* flex: 1; */
  }
  
  .subtitle-email1 {
	font-size: 1.4rem;
	font-weight: 400;
	color: var(--red);
  }
  
  .quote_minibox1 {
	display: none;
	width: 30rem;
	background-color: var(--light-grey2);
	padding: 1rem 2rem;
	border-radius: 10px;
	text-align: center;
  }
  
  .no_data_message {
	font-size: 1.5rem;
	color: var(--dark-grey);
	margin-top: 5remx;
  }
  
  .agentemail {
	color: var(--red);
	text-decoration: none;
  }
  
  .agentemail:hover {
	text-decoration: underline;
  }
  
  .agentwebsite {
	color: var(--red);
	font-size: 1.4rem;
	text-decoration: none;
  }
  
  .agentwebsite:hover {
	text-decoration: underline;
  }
  
  .office_hours {
	text-decoration: none;
	justify-content: center;
	align-items: center;
	text-align: center;
	align-self: center;
	outline: none;
	border: none;
  }
  
  .modal_container_heading1 {
	display: flex;
	justify-content: space-between;
	padding: 0.5rem 2rem;
	background-color: var(--light-grey2);
  }
  
  .form_input__error {
	font-size: 1.2rem;
	color: #e02b21;
	display: flex;
	position: relative;
  }
  
  /* li {
	  border: .5px solid var(--light-grey);
	  padding: 0 .3rem;
  } */
  
  /* .fa-bottom-main > .left {
	  flex: 1;
	  justify-content: center;
	  text-align: center;
	  align-content: center;
  }
  
  .fa-bottom-main > .right {
	  flex: 1;
  }
  
  .fa-bottom-main > .right {
	  display: flex;
	  flex-direction: column;
	  float: left !important;
  }
  
  .fa-bottom-main > .right > .title {
	  font-size: 2.5rem;
	  color: red;
	  font-weight: 500;
	  font-family: inherit;
  }
  
  .fa-bottom-main > .right > .subtitle {
	  font-size: 2rem;
	  font-weight: 500;
	  font-family: inherit;
  }
  
  .fa-bottom-main > .right > .desc {
	  font-size: 1.5rem;
	  font-weight: 500;
	  font-family: inherit;
  } */
  .office_hours_css {
	border: none !important;
  }
  
  @media only screen and (max-width: 75em) {
	.findanagent-search-main-section {
	  display: flex;
	  flex-direction: column;
	  background-color: rgb(238, 238, 238);
	  padding-bottom: 1rem;
	}
  
	.findanagent-search-main-section>label {
	  font-size: 1.5rem;
	  font-weight: 600;
	  padding: 1rem;
	  text-align: left;
	}
  
	.findanagent-search-main-section-input {
	  display: flex;
	  justify-content: center;
	  text-align: center;
	  flex-direction: column;
	}
  
	.input_container {
	  position: relative;
	  padding: 0rem 0rem 0rem 1rem;
	  margin-top: 1rem;
	  width: 90vw;
	}
  
	.input_container>input {
	  height: 5rem;
	  margin: 0;
	  padding: 0rem 0rem 0rem 1rem;
	  width: 100%;
	  outline: none;
	  border-radius: 5px;
	  padding-left: 10rem;
	}
  
	.input_container>.right_image {
	  position: absolute;
	  justify-content: center;
	  align-content: center;
	  align-self: center;
	  margin-top: 7px;
	  border-top-right-radius: 5px;
	}
  
	.input_container>.left_image {
	  position: absolute;
	  margin-top: 2px;
	  margin-left: 2px;
	  width: 8rem;
	  height: 4.6rem;
	  border-top-left-radius: 5px;
	  padding: 4px;
	  background-color: rgb(228, 228, 228);
	  fill: var(--black);
	}
  
	.findanagent-search-main-section-input>input {}
  
	.main-section {
	  display: flex;
	  flex-direction: column;
	  margin-top: 5rem;
	  text-align: left;
	  padding: 0 -48rem;
	  width: 100%;
	}
  
	.main-section-left {
	  height: 60vh;
	  display: flex;
	  flex-direction: column;
	  overflow: auto;
	  width: 90vw;
	  margin-left: -46rem;
	}
  
	.main-section-left-content {
	  display: flex;
	  flex-direction: column;
	  width: 90vw;
	  flex: 1 1;
	}
  
	.main-section-left-content>.ms-cart {
	  display: flex;
	  margin-bottom: 1rem;
	  border-bottom: solid rgb(202, 202, 202) 1px;
	  padding-bottom: 1rem;
	}
  
	.main-section-left-content>.ms-cart>img {
	  height: 16vw;
	}
  
	.main-section-left-content>.ms-cart>.ms-content-details {
	  flex: 1 1;
	  display: flex;
	  flex-direction: column;
	  text-align: left;
	  padding: 0 1rem;
	}
  
	.main-section-left-content>.ms-cart>.ms-content-details>.title {
	  color: red;
	  font-size: 1.4rem;
	  font-weight: 600;
	}
  
	.main-section-left-content>.ms-cart>.ms-content-details>.subtitle {
	  color: rgb(136, 136, 136);
	  font-size: 1.1rem;
	  font-weight: 500;
	  display: flex;
	  flex: 1 1;
	}
  
	.main-section-left-content>.ms-cart>.ms-content-details>.subtitle>.ms--icon {
	  color: rgb(136, 136, 136);
	  fill: rgb(114, 114, 114);
	  height: 1.5rem;
	  width: 1.5rem;
	  padding: 0.2rem;
	  justify-self: center;
	}
  
	.main-section-left-content>.ms-cart>.ms-content-details>.subtitle-email {
	  color: red;
	  font-size: 1.2rem;
	  font-weight: 500;
	  display: flex;
	  flex: 1 1;
	}
  
	.main-section-left-content>.ms-cart>.ms-content-details>.subtitle-email>.ms--icon {
	  color: red;
	  fill: red;
	  height: 2rem;
	  width: 2rem;
	  padding: 0.2rem;
	  justify-self: center;
	}
  
	.main-section-right {
	  margin-left: -46rem;
	  width: 90vw;
	}
  
	.main-section-right>iframe {
	  flex: 1 1;
	  height: 60vh;
	  width: 100%;
	  border: none;
	}
  
	.ms-pagination {
	  /* padding: 0 48rem; */
	}
  
	.main-section-right h2 {
	  display: flex;
	}
  
	.main-section .previousBttn,
	.nextBttn {
	  padding: 0.5rem 1.2rem !important;
	  font-size: 1.8rem !important;
	}
  
	.google-map-section {
	  display: flex;
	  align-items: center;
	  justify-content: center;
	}
  
	.fa-bottom-main {
	  display: flex;
	  background-color: var(--light-grey2);
	  padding: 4rem 45rem;
	  grid-gap: 5rem;
	  gap: 5rem;
	  flex-direction: column;
	}
  
	.heading1_an_agent {
	  font-size: 2.2rem;
	  font-weight: 700;
	  color: var(--red);
	}
  
	.subtitle_an_agent {
	  font-size: 1.8rem;
	  font-weight: 400;
	}
  
	.description {
	  font-size: 1.5rem;
	}
  
	.find-an-agent-image {
	  width: 90vw;
	}
  
	/*
	  .fa-bottom-main > .left {
		  height: 30vh;
		  width: 90vw;
	  }
	  .fa-bottom-main > .left > img {
		  height: 30vh;
	  }
  
	  .fa-bottom-main > .right {
		  flex: 1;
		  height: 30vh;
		  width: 90vw;
	  }
  
	  .fa-bottom-main > .right {
		  display: flex;
		  flex-direction: column;
		  float: left !important;
	  }
  
	  .fa-bottom-main > .right > .title {
		  font-size: 2rem;
		  color: red;
		  font-weight: 500;
		  font-family: inherit;
	  }
  
	  .fa-bottom-main > .right > .subtitle {
		  font-size: 1.5rem;
		  font-weight: 500;
		  font-family: inherit;
	  }
  
	  .fa-bottom-main > .right > .desc {
		  font-size: 1.2rem;
		  font-weight: 500;
		  font-family: inherit;
	  } */
  }
  
  @media only screen and (max-width: 1500px) {
	.main-section {
	  margin: 2rem 15rem;
	}
  
	.ms-pagination {
	  padding: 0 15rem;
	}
  
	.fa-bottom-main {
	  padding: 4rem 20rem;
	}
  
	.agent_location {
	  padding: 1rem 15rem;
	}
  
	.about_me_outer {
	  padding: 1rem 15rem;
	}
  
	.agent_details {
	  padding: 1rem 15rem;
	}
  }
  
  @media only screen and (max-width: 1400px) {
	.main-section {
	  margin: 2rem 10rem;
	}
  
	.fa-bottom-main {
	  padding: 4rem 10rem;
	}
  
	.find-an-agent-image {
	  width: 40vw;
	}
  
	.agent_location {
	  padding: 1rem 15rem;
	}
  
	.about_me_outer {
	  padding: 1rem 15rem;
	}
  
	.agent_details {
	  padding: 1rem 15rem;
	}
  }
  
  @media only screen and (max-width: 1200px) {
	.main-section {
	  margin: 2rem 10rem;
	}
  
	.fa-bottom-main {
	  padding: 4rem 10rem;
	}
  
	.find-an-agent-image {
	  width: 40vw;
	}
  
	.agent_location {
	  padding: 1rem 10rem;
	}
  
	.about_me_outer {
	  padding: 1rem 10rem;
	}
  
	.agent_details {
	  padding: 1rem 10rem;
	}
  }
  
  @media only screen and (max-width: 760px) {
	.fa-bottom-main {
	  display: flex;
	  background-color: var(--light-grey2);
	  padding: 4rem 1rem;
	  grid-gap: 5rem;
	  gap: 5rem;
	  flex-direction: column;
	}
  
	.main-section {
	  display: flex;
	  flex-direction: column;
	  padding: 3rem 1rem;
	  margin: 0;
	}
  
	.main-section-left {
	  display: flex;
	  width: 85vw;
	  margin: 0;
	  padding: 0;
	}
  
	.main-section-right {
	  display: flex;
	  width: 85vw;
	  padding: 0;
	  margin: 0;
	}
  
	.ms-pagination {
	  display: flex;
	  flex-direction: column;
	  padding: 0;
	}
  
	.ms-pagination {
	  display: none;
	}
  
	.ms-pagination1 {
	  display: flex;
	  flex-direction: row;
	  justify-content: space-between;
	  padding: 2rem 1rem;
	}
  
	.agent_details {
	  padding: 1rem 0;
	}
  
	.quote_minibox {
	  display: none;
	}
  
	.hz_line {
	  width: 100%;
	}
  
	.quote_minibox1 {
	  display: flex;
	  flex-direction: column;
	  width: 30rem;
	}
  
	.about_me_outer {
	  padding: 1rem 1rem;
	  flex-direction: column;
	}
  
	.office_hours_section {
	  display: flex;
	  flex-direction: column;
	  align-items: center;
	  grid-gap: 2rem;
	  gap: 2rem;
	  margin-top: -5rem;
	}
  
	.agent_location {
	  width: 100%;
	  padding: 2rem 0;
	}
  }
.quote-main-box {
	padding: 3rem 33rem;
	background-color: var(--light-grey2);
}

.quote_heading {
	font-size: 2rem;
	font-weight: 700;
}

.red_star {
	color: var(--red);
	font-weight: 200;
}

.quote_subheading {
	font-size: 1.8rem;
	font-weight: 300;
}

.main_outer_component {
	padding: 2rem 55rem;
}

.Select_agent_statewise {
	display: flex;
	grid-gap: 2rem;
	gap: 2rem;
}

.pre_heading_agentwise {
	/* margin-top: -2rem; */
}

.select_state {
	display: block;
}

.label_heading {
	font-weight: 900;
	font-size: 2rem;
	color: grey;
	margin-top: 1.8rem;
}

.label_heading_quote {
	font-weight: 500;
	font-size: 1.6rem;
	color: grey;
	margin-top: 1.8rem;
}

.select__state__textbox {
	width: 20rem;
	-webkit-box-decoration-break: none;
	        box-decoration-break: none;
	min-height: 4rem;
	margin-bottom: 4rem;
	font-size: 1.4rem;
	background-color: var(--light-grey2);
	border: 1px solid rgb(163, 159, 159);
	outline: none;
}

.select__state__textbox1 {
	width: 210%;
	-webkit-box-decoration-break: none;
	        box-decoration-break: none;
	min-height: 4rem;
	margin-bottom: 4rem;
	font-size: 1.4rem;
	background-color: var(--light-grey2);
	border: 1px solid rgb(163, 159, 159);
	outline: none;
}

.dash_heading_2 {
	font-size: 2.5rem;
	font-weight: 300;
	margin-bottom: 1rem;
}

.form_textbox {
	display: flex;
	grid-gap: 3rem;
	gap: 3rem;
}

.label_heading1 {
	display: flex;
	grid-gap: 33.7rem;
	gap: 33.7rem;
	flex-wrap: text;
	margin-top: 2rem;
}

.label_subheading {
	font-weight: 900;
	font-size: 2rem;
	color: grey;
}

.label_heading2 {
	display: flex;
	grid-gap: 38.8rem;
	gap: 38.8rem;
	color: grey;
	font-size: 1.2rem;
	margin-top: .2rem;
}

.label_heading3 {
	margin-top: .3rem;
	display: flex;
	color: grey;
	margin-bottom: .3rem;
	font-size: 1.2rem;
}

.horizontal_line {
	width: 100%;
	height: 2px;
	color: grey;
	background-color: grey;
	display: flex;
}

.Insurance__list {
	display: flex;
	flex-direction: column;
}

.list__of__contents {
	display: flex;
	flex-direction: row;
	grid-gap: 4rem;
	gap: 4rem;
}

.list__of__contents1 {
	grid-gap: 10rem;
	gap: 10rem;
	padding: 1rem;
	display: inline-flex;
	border: 1px solid #d5d5d5 !important;
	margin: 1rem;
	border-radius: 4rem;
	cursor: pointer;
}


.list__of__contents1_active {
	border: 1px solid #d5d5d5 !important;
	background: #000;
	color: #fff;
	cursor: pointer;
}

.list__of__contents1:hover {
	color: var(--red);
}

.list__of__contents2 li {
	grid-gap: 10rem;
	gap: 10rem;
	padding: 1rem;
	display: inline-flex;
}

.form_textarea {
	background-color: rgb(238, 234, 228);
	height: 6rem;
	width: 84%;
	border: none;
	outline: none;
	border: 1px solid rgb(163, 159, 159);
}

.main__form_button {
	width: 10vh;
	height: 4vh;
	font-size: 2rem;
	margin-bottom: 4rem;
	margin-top: 2rem;
}

.pre_heading {
	display: flex;
	flex-wrap: wrap;
	grid-gap: 3rem;
	gap: 3rem;
	align-items: flex-end;
	padding-bottom: 2rem;
}

.select__state__fieldtype {
	width: 40%;
	-webkit-box-decoration-break: none;
	        box-decoration-break: none;
	min-height: 4rem;
	margin-bottom: 4rem;
	font-size: 1.4rem;
	background-color: var(--light-grey2);
	border: 1px solid rgb(163, 159, 159);
	outline: none;
  }
  

@media only screen and (min-width: 1500px) {
	.quote-main-box {
		padding: 3rem 33rem;
	}

	.quote_heading {
		display: flex;
	}

	.quote_subheading {
		display: flex;
	}

	.main_outer_component {
		padding: 3rem 55rem;
		grid-gap: .5rem;
		gap: .5rem;
	}
}

@media only screen and (max-width: 1400px) {
	.quote-main-box {
		padding: 3rem 33rem;
	}

	.quote_heading {
		display: flex;
	}

	.quote_subheading {
		display: flex;
	}

	.main_outer_component {
		padding: 2rem 33rem;
		grid-gap: .5rem;
		gap: .5rem;
	}

	.pre_heading {
		grid-gap: 2.5rem;
		gap: 2.5rem;
		align-items: flex-end;
		padding-bottom: 2rem;
		width: 100%;
	}

	.select__state__textbox1 {
		width: 100%;
	}
}

@media only screen and (max-width: 900px) {
	.quote-main-box {
		padding: 2rem 10rem;
	}

	.quote_heading {
		display: flex;
		text-align: center;
	}

	.quote_subheading {
		display: flex;
		text-align: center;
	}

	.main_outer_component {
		padding: 2rem 1rem;
	}

	.label_heading2 {
		display: flex;
		flex-wrap: wrap;
	}

	.form_textbox {
		display: flex;
	}

	.pre_heading {
		grid-gap: 3.3rem;
		gap: 3.3rem;
		align-items: flex-end;
		padding-bottom: 2rem;
		width: 100%;
	}

	.main_outer_component {
		padding: 2rem 25rem;
		grid-gap: .5rem;
		gap: .5rem;
	}

	.select__state__textbox1 {
		width: 100%;
	}
}

@media only screen and (max-width: 760px) {
	.quote-main-box {
		padding: 2rem 2rem;
	}

	.quote_heading {
		display: flex;
		text-align: center;
	}

	.quote_subheading {
		display: flex;
		text-align: center;
	}

	.main_outer_component {
		padding: 2rem 1rem;
	}

	.label_heading2 {
		display: flex;
		flex-wrap: wrap;
	}

	.form_textbox {
		display: flex;
	}

	.pre_heading {
		grid-gap: 1.5rem;
		gap: 1.5rem;
		align-items: flex-end;
		padding-bottom: 2rem;
		width: 100%;
	}

	.select__state__textbox1 {
		width: 100%;
	}
}

.our-community-main-section {
	display: flex;
	justify-content: center;
	margin-top: 5rem;
	padding: 1rem 33rem;
	height: 70rem;
	grid-gap: 8rem;
	gap: 8rem;
}

.our-community-heading {
	font-size: 2.5rem;
	margin-bottom: .5rem;
}
.text-about__heading_2 {
	font-size: 1.6rem;
}
.our-community-highlight {
	font-size: 1.8rem;
	font-weight: 900;
	margin-top: 2rem;
}
.text-about_heading_5 {
	color: var(--red);
	text-decoration: none;
	font-size: 1.8rem;
}
.fbf__image__Community {
	height: 35vw;
}
.our_community_divider {
	display: flex;
	height: .2rem;
	border-width: .5rem;
	color: var(--dark-grey);
	background-color: var(--dark-grey);
	margin: 3rem 33rem;
	margin-top: 2rem;
}
.our-community-charities-content {
	text-align: center;
	justify-content: center;
}
.our-community-charities-heading {
	font-size: 2.5rem;
	color: var(--dark-grey);
	justify-content: center;
	text-transform: uppercase;
	font-weight: 900;
	margin-bottom: -1rem;
}
.charity_images {
	display: flex;
	padding: 3rem 33rem;
	justify-content: space-between;
	align-items: center;
	/* gap: 5rem; */
}
.our_community_charities_image {
	margin-bottom: 2rem;
	width: 20rem;
	cursor: pointer;
}
.mwfbi_products {
	background-color: var(--light-grey2);
	padding-top: 2rem;
	padding-bottom: 2rem;
}
.our-community-heading2 {
	font-size: 2.5rem;
	color: var(--red);
	font-weight: 900;
}
.our-community-shred-section {
	padding: 0rem 33rem;
	display: flex;
	flex-direction: column;
	align-items: center;
}
.our-community-text-about {
	font-size: 1.6rem;
	padding-bottom: 2rem;
}
.our-community-shred-footer {
	font-size: 1.6rem;
	font-weight: 900;
}
.our-community-shred-card {
	margin-top: 1.5rem;
	background-color: var(--red);
	display: flex;
	justify-content: center;
	align-items: center;
	color: white;
	padding: 2rem 3rem;
	grid-gap: 3rem;
	gap: 3rem;
}
.our-community-recycleimage {
	width: 10rem;
}
.our-community-recycle {
	font-size: 1rem;
	font-weight: 400;
}
.our_community_shred_recycle {
	font-size: 3rem;
	font-weight: 700;
}
@media only screen and (max-width: 1500px) {
	.our-community-main-section {
		padding: 1rem 15rem;
	}
	.our_community_divider {
		margin: 1rem 15rem;
	}
	.our-community-shred-section {
		padding: 0rem 15rem;
	}
	.charity_images {
		padding: 1rem 15rem;
	}
}
@media only screen and (max-width: 1400px) {
	.our-community-main-section {
		padding: 1rem 15rem;
	}
	.our_community_divider {
		margin: 1rem 15rem;
	}
	.our-community-shred-section {
		padding: 0rem 15rem;
	}
	.charity_images {
		padding: 1rem 15rem;
	}

	/* .fbf__image__Community {
		height: 40vw;
	} */
}
@media only screen and (max-width: 1200px) {
	.our-community-main-section {
		padding: 1rem 10rem;
	}
	.our_community_divider {
		margin: 1rem 10rem;
	}
	.our-community-shred-section {
		padding: 0rem 10rem;
	}
	.charity_images {
		padding: 1rem 8rem;
	}
}

@media only screen and (max-width: 37.5em) {
	.our-community-main-section {
		padding: 1rem 1rem;
		flex-direction: column;
		height: auto;
		grid-gap: 4rem;
		gap: 4rem;
	}
	.fbf__image__Community {
		width: 90vw;
	}
	.our_community_divider {
		margin: 1rem 1rem;
	}
	.our-community-shred-section {
		padding: 0rem 1rem;
	}
	.mwfbi_products {
		margin-top: 2rem;
	}
	.charity_images {
		display: flex;
		flex-direction: column;
		padding: 1rem 1rem;
	}
	.our_community_charities_image {
		margin-bottom: 2rem;
		width: 70vw;
	}
	.fbf__image__Community {
		height: 50vh;
		margin-right: -1.5rem;
		margin-top: -2rem;
	}
}

.our-mission-main {
	padding: 2rem 33rem;
	display: flex;
	flex-direction: column;
}
 
.our-mission-main-heading {
	font-size: 2.5rem;
	margin-bottom: 2rem;
	font-weight: 900;
	align-items: center;
	text-align: center;
}
.about-how-we-work-desc {
	display: flex;
	padding: 0rem 33rem;
	grid-gap: 2rem;
	gap: 2rem;
}
.about_howwework_image {
	width: 59rem;
}
.about-how-we-work-desc-heading1 {
	font-size: 2.5rem;
	font-weight: 900;
}
.about-how-we-work-desc-heading2 {
	font-size: 1.6rem;
	font-weight: 300;
	margin-bottom: 1rem;
}
.subheading {
	font-size: 1.8rem;
	font-weight: 900;
}
.about_us_heading {
	font-size: 2.5;
}

/* .our-mission-main-heading {
	text-align: center;
	width: 100%;
	flex: 1;
	font-weight: 900;
	font-family: inherit;
} */

.our-mission-main-description {
	width: 60%;
	flex: 1 1;
	text-align: center;
}

.claim__icon {
	fill: white;
	height: 3.5rem;
	width: 3.5rem;
	transition: all 0.4s;
}

.about-how-we-work-right > img {
	border-radius: 0rem;
	height: -webkit-fit-content;
	height: -moz-fit-content;
	height: fit-content;
}

.about-how-we-work-desc-heading {
	font-weight: 700;
	padding: 10px;
}

.about-how-we-work-desc-desc {
	padding: 10px;
	font-weight: 500;
}

.history-main1 {
	background-color: white;
	padding: 0rem 25rem;
	display: flex;
	justify-content: center;
	position: relative;
}
.history_begining {
	/* width: 100%; */
	margin-top: 4rem;
	padding: 2rem;
	padding-left: 55rem;
	padding-right: 55rem;
	text-align: center;
	color: white;
	background-color: var(--dark-grey);
}
.heading_history {
	font-weight: 500;
	font-size: 2.5rem;
}
.history-content {
	width: 80%;
	padding-top: 1.5rem;
	text-align: center;
}

.history-content > .head > label {
	color: var(--red);
	font-family: inherit;
	font-weight: 500;
}

.history-content > .head > h3 {
	font-family: inherit;
	font-weight: 500;
	margin-bottom: 2rem;
}

.spacing {
	padding: 2rem;
}

.history-card-list {
	width: 100%;
	margin-bottom: 1rem;
}

.history-card {
	height: 15rem;
	background-color: #ffffff;
	transition: 0.3s;
	display: flex;
	padding: 2rem;
	text-align: start;
	position: relative;
}

.card-left {
	border-bottom: black solid 1px;
}

.card-left:after {
	content: "";
	position: absolute;
	left: 0;
	z-index: 100;
	bottom: 0;
	width: 3px;
	height: 24%;
	/* or 100px */
	border-left: black solid 1px;
}

.card-left::before {
	content: "";
	position: absolute;
	left: 0;
	z-index: 100;
	top: 0;
	width: 3px;
	height: 23%;
	/* or 100px */
	border-left: black solid 1px;
}

.card-right {
	border-bottom: black solid 1px;
}

.card-right:after {
	content: "";
	position: absolute;
	right: -0.2rem;
	z-index: 100;
	bottom: 0;
	width: 3px;
	height: 24%;
	/* or 100px */
	border-left: black solid 1px;
}

.card-right::before {
	content: "";
	position: absolute;
	right: -0.2rem;
	z-index: 100;
	top: 0;
	width: 3px;
	height: 23%;
	/* or 100px */
	border-left: black solid 1px;
}

.card-right-end:after {
	content: "";
	position: absolute;
	right: -0.2rem;
	z-index: 100;
	bottom: 0;
	width: 3px;
	height: 24%;
	/* or 100px */
	border-left: black solid 1px;
}

.card-right-end::before {
	content: "";
	position: absolute;
	right: -0.2rem;
	z-index: 100;
	top: 0;
	width: 3px;
	height: 23%;
	/* or 100px */
	border-left: black solid 1px;
}

.card-left-end:after {
	content: "";
	position: absolute;
	left: 0;
	z-index: 100;
	bottom: 0;
	width: 3px;
	height: 25%;
	/* or 100px */
	border-left: black solid 1px;
}

.card-left-end::before {
	content: "";
	position: absolute;
	left: 0;
	z-index: 100;
	top: 0;
	width: 3px;
	height: 25%;
	/* or 100px */
	border-left: black solid 1px;
}

.history-year-left {
	height: 8rem;
	width: 8rem;
	margin-top: 1.4rem;
	margin-left: -6rem;
	text-align: center;
	display: flex;
	justify-content: center;
	padding: 2rem;
	border-radius: 50%;
	border: red solid 3px;
	color: red;
	font-size: 2rem;
	font-weight: 700;
}

.history-year-right {
	height: 8rem;
	width: 8rem;
	margin-top: 1.4rem;
	margin-right: -6rem;
	text-align: center;
	display: flex;
	justify-content: center;
	padding: 2rem;
	border-radius: 50%;
	border: red solid 3px;
	color: red;
	font-size: 2rem;
	font-weight: 700;
}

.history-card > .title {
	font-family: inherit;
	font-size: 2rem;
	font-weight: 600;
}

.history-card > .desc {
	font-family: inherit;
	font-size: 1.5rem;
	font-weight: 500;
	width: 80%;
}

.end-message {
	font-family: inherit;
	font-size: 1.5rem;
	font-weight: 500;
	/* background-color: red; */
}

.about-inner-card-content-left {
	width: 100%;
	display: flex;
	flex-direction: column;
	padding: 4rem;
	justify-content: center;
}

.about-inner-card-content-left > .title {
	font-weight: 900;
}

.about-inner-card-content-left > .desc {
	font-weight: 500;
	width: 50%;
}

.about-inner-card-content-right {
	width: 100%;
	display: flex;
	flex-direction: column;
	padding: 4rem;
	text-align: right;
	justify-content: right;
	margin-top: -2.5rem;
}

.about-inner-card-content-right > .title {
	align-self: end;
	font-weight: 900;
}

.about-inner-card-content-right > .desc {
	font-weight: 500;
	width: 50%;
	align-self: end;
}
.label_heading {
	font-size: 2.5rem;
	font-weight: 900;
}
.label_heading_1 {
	font-size: 1.6rem;
	font-weight: 200;
	color: grey;
}
@media only screen and (max-width: 1500px) {
	.about-how-we-work-desc {
		padding: 1rem 15rem;
	}
	.history_begining {
		padding: 2rem 15rem;
	}
	.about_howwework_image {
		width: 25vw;
	}
	.history-main1 {
		padding: 0 10rem;
	}
}

@media only screen and (max-width: 1400px) {
	.about-how-we-work-desc {
		padding: 1rem 15rem;
	}
	.history_begining {
		padding: 2rem 15rem;
	}
	.about_howwework_image {
		width: 25vw;
	}
	.history-main1 {
		padding: 0 10rem;
	}
}

@media only screen and (max-width: 1230px) {
	.our-mission-main {
		display: flex;
		justify-content: flex-start;
		flex-direction: column;
		padding: 2rem 1rem;
	}
	.about-how-we-work-desc {
		padding: 1rem 10rem;
	}
	.history_begining {
		padding: 2rem 10rem;
	}
	.about_howwework_image {
		width: 25vw;
	}
	.history-main1 {
		padding: 0 10rem;
	}
}
@media only screen and (max-width: 37.5em) {
	.our-mission-main {
		display: flex;
		justify-content: flex-start;
		flex-direction: column;
		padding: 2rem 1rem;
	}
	.about_howwework_image {
		width: 85vw;
	}

	.about-how-we-work-desc {
		/* width: 100%; */
		display: flex;
		flex-direction: column;
		padding: 2rem 1rem;
	}
	.heading_3 {
		display: flex;
		/* width: 100%; */
		flex-direction: column;
		justify-content: flex-start;
	}

	.mwfbi__buttons {
		display: flex;
		justify-content: center;
	}
	.button__text {
		box-shadow: none;
		justify-content: center;
		width: auto;
	}
	.history-card-list {
		padding-bottom: 2rem;
	}
	.history-main1 {
		padding: 1rem 1rem;
	}
	.history_begining {
		/* width: 100%; */
		padding-right: 10px;
		padding-left: 10px;
		display: flex;
		justify-content: center;
	}
	/* .spacing {
		padding: 2rem;
		width: 100%;
	} */

	.history-content {
		padding-top: 0;
		width: 100%;
	}
	.history-card {
		display: flex;
		flex-direction: column;
	}
	.card-left {
		border: none;
		display: flex;
		flex-direction: column;
		padding-bottom: 2rem;
		position: relative;
	}
	.card-right {
		border: none;
		display: flex;
		flex-direction: column-reverse;
		/* margin-top: 2rem; */
		position: relative;
	}
	.card-right-end {
		border: none;
		display: flex;
		flex-direction: column-reverse;
		position: relative;
		/* padding-top: 2rem; */
	}
	/* .card-right :nth-child(1) {
		order: 2;
	}
	.card-right :nth-child(2) {
		order: 3;
	}
	.card-right :nth-child(3) {
		order: 1;
	} */

	.about-inner-card-content-left > .desc {
		width: 100%;
		margin-left: 1rem;
	}
	.about-inner-card-content-right > .desc {
		text-align: left;
		width: 100%;
		padding-left: 1rem;
	}
	.about-inner-card-content-right > .title {
		text-align: left;
		padding-left: 1rem;
		width: 100%;
	}
	.about-inner-card-content-left > .title {
		text-align: start;
		margin-left: 1rem;
		width: 100%;
	}
	.history-year-right {
		border: none;
		margin-left: 1rem;
		padding: 0rem;
		margin-top: 0;
		justify-content: start;
		width: 0;
		height: 3rem;
	}
	.history-year-left {
		border: none;
		margin-left: 1rem;
		padding: 0rem;
		margin-top: 0;
		justify-content: start;
		width: 0;
	}
	.card-right:after {
		display: none;
	}
	.card-right-end::before {
		display: none;
	}
	.card-right-end:after {
		display: none;
	}
	.about-inner-card-content-right {
		padding: 0rem;
		margin-top: 0;
	}
	.about-inner-card-content-left {
		padding: 0;
		margin-top: 0;
	}
	.card-right:before {
		display: none;
	}

	.card-left:after {
		display: none;
	}
	.card-left:before {
		display: none;
	}
}
@media only screen and (max-width: 75em) {
	.mwfbi__buttons {
		display: flex;
		justify-content: center;
	}
	.button__text {
		box-shadow: none;
		justify-content: center;
		width: auto;
	}
}
/*
@media only screen and (max-width: 56.25em) {
	.our-mission-main {
		display: flex;
		width: 100%;
		justify-content: start;
		background-color: turquoise;
		flex-direction: column;
	}
	.about-how-we-work-desc {
		width: 100%;
		display: flex;
		justify-content: start;

		flex-direction: column;
	}
	.heading_3 {
		display: flex;
		width: 100%;
		flex-direction: column;
		justify-content: start;
	}
	.history_begining {
		width: 100%;
		padding-right: 0px;
		padding-left: 0px;
		display: flex;
		justify-content: start;
	}
	.about-inner-card-content-right {
		width: 100%;
		display: flex;

		padding: 0rem;
		margin-top: 0rem;

		margin-right: 1rem;
	}
	.about-inner-card-content-left {
		width: 100%;
		display: flex;

		padding: 0rem;

		margin-left: .5rem;
	}
	.history-main {
		display: flex;
		width: 100%;
		justify-content: center;
	}
}

@media only screen and (min-width: 93.75em) {
	.our-mission-main {
		display: flex;
		width: 100%;
		justify-content: start;
		background-color: turquoise;
		flex-direction: column;
	}
	.about-how-we-work-desc {
		width: 100%;
		display: flex;
		justify-content: start;

		flex-direction: column;
	}
	.heading_3 {
		display: flex;
		width: 100%;
		flex-direction: column;
		justify-content: start;
	}
	.history_begining {
		width: 100%;
		padding-right: 0px;
		padding-left: 0px;
		display: flex;
		justify-content: start;
	}
	.spacing {
		padding: 2rem;
		width: 100%;
	}

	.history-card-list {
		width: 100%;
	}
} */

.modal_container_heading {
	display: flex;
	justify-content: space-between;
	padding: 1rem 2rem;
}
.modal_heading_text {
	font-size: 2rem;
	font-weight: 400;
}
.modalContainer_subheading {
	display: flex;
	font-size: 1.4rem;
	padding: 1rem 2rem;
	font-weight: 400;
}
.pre_heading1 {
	display: flex;
	padding: 0 2rem;
	flex-wrap: wrap;
	grid-gap: 3rem;
	gap: 3rem;
	align-items: flex-end;
	padding-bottom: 2rem;
}
.label_heading_quote1 {
	font-weight: 500;
	padding: 0 2rem;
	font-size: 1.6rem;
	color: grey;
	margin-top: 1.8rem;
}
.form_textbox1 {
	width: 115%;
}
.submit_button {
	padding: 0 2rem;
}
.main__form_button1 {
	/* padding: 2rem 2rem; */
	width: 10vh;
	height: 4vh;
	font-size: 2rem;
	margin-bottom: 2rem;
	margin-top: 2rem;
}

.textBox {
	position: relative;
}
.textBox__label {
	position: absolute;
	top: -.5rem;
	left: 0;
	z-index: 10;
	background-color: var(--bg-primary);
	box-shadow: var(--box-shadow1);
	border-radius: 50%;
	padding: 1.3rem;
	display: flex;
}
.textBox__label--icon {
	height: 2.5rem;
	width: 2.5rem;
	fill: var(--black);
}
.textBox__input {
	background-color: var(--bg-primary);
	box-shadow: var(--box-shadow1);
	border-radius: 2rem;
	overflow: hidden;
	width: 30rem;
	display: flex;
	transition: all 0.4s;
}
.textBox__input:focus-within {
	box-shadow: var(--box-shadow2);
}
.textBox__input--box {
	width: 100%;
	margin-left: 5rem;
	background-color: var(--bg-primary);
	font-family: inherit;
	border: none;
	outline: none;
	padding: 1rem 1rem;
}
.textBox__input--show {
	margin-right: 1rem;
	border: none;
	background-color: transparent;
	cursor: pointer;
}
.textBox__input--show:hover .textBox__input--showIcon {
	fill: var(--red);
}
.textBox__input--showIcon {
	height: 2rem;
	width: 2rem;
	fill: var(--dark-grey);
	transition: all .4s;
}

.simple_textBox {
	display: flex;
	flex-direction: column;
	grid-gap: .8rem;
	gap: .8rem;
}
.simple_textBox--label {
	font-size: 1.2rem;
	font-weight: 500;
}
.simple_textBox--input {
	font-family: inherit;
	border: none;
	outline: none;
	background-color: var(--bg-primary);
	box-shadow: var(--box-shadow1);
	padding: 1rem 2rem;
	border-radius: 2rem;
	transition: all 0.4s;
}
.simple_textBox--input:focus-within {
	box-shadow: var(--box-shadow2);
}
.simple_textBox--input::-webkit-input-placeholder {
	color: var(--light-grey);
}

.send_message-contact--maincontact--main{
    padding-left: 10vw;
    padding-right: 10vw;
    display: flex;
    grid-gap: 10rem;
    gap: 10rem;
}
.send_message-contact--container{
    position: absolute;
    top: 10%;
    max-width: 15vw;
    /* padding: 2rem; */
    background-color: var(--dark-grey);
    color: var(--bg-primary);

}

.send_message--container{
    margin-top: 2rem;
    margin-bottom: 2rem;
margin-left: 20vw;
    width: 100%;
}



.send_message-flex--container{
    display: flex;
    grid-gap: 5rem;
    gap: 5rem;
    align-items: flex-end;
    /* background-color: purple; */
}
.checkbox-round {
    width: 1.3em;
    height: 1.3em;
    background-color: white;
    border-radius: 50%;
    vertical-align: middle;
    border: 1px solid #ddd;
    appearance: none;
    -webkit-appearance: none;
    outline: none;
    cursor: pointer;
}
.send_message-input--checkbox--label{
    font-weight: 600;
    font-size: 1.6rem;
    color: rgb(158, 158, 158);
    margin-top: 1.8rem;
}
.checkbox--container{
    display: flex;
    color: rgb(158, 158, 158);
    font-size: 1.2rem;
    grid-gap: 2rem;
    gap: 2rem;
}
.actual--box{
    display: flex ;
    grid-gap: 1rem;
    gap: 1rem;
}
.send_message-commentBox{
    display: flex;
    flex-direction: column;
}
.send_message-input--text--area{
    width: 100%;
    min-height: 15vh;
    font-family: inherit;
	border: 1px solid rgb(207, 207, 207);
    background-color: rgb(247, 246, 255)
}
.submit--button{
    color: rgb(158, 158, 158);
    border: 1px solid rgb(207, 207, 207);
    width: 7rem;
    height: 4rem;
    margin-top: 2rem;
}
.send_message-contact--image {
    width: 100%;
    height: 300px;
    object-fit: cover;
  }
.blogSettings_container__1ta5C{
  padding: 2rem 15rem;
}

.blogSettings_formTitle__26L_9{
  font-weight: 600;
  font-size: 1.4rem;
}

.blogSettings_inputBox__1HFei .blogSettings_inputTitle__27SmA{
  padding-bottom: 0.5rem;
  display: block;
  color: #2E2E2E;
  font-size: 1.4rem;
}
.blogSettings_inputBox1__3Ubw9 .blogSettings_inputTitle__27SmA{
  padding-bottom: 0.5rem;
  display: block;
  color: #2E2E2E;
  font-size: 1.4rem;
}

.blogSettings_inputBox__1HFei{
  padding-bottom: 2rem;
}

.blogSettings_inputBox1__3Ubw9{
  padding-bottom: 2rem;
}

.blogSettings_tooltip__1i59L {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.blogSettings_tooltip__1i59L .blogSettings_tooltiptext__30G5a {
  visibility: hidden;
  width: 120px;
  background-color: #D9D9D9;
  color: #000000;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: 125%; /* Position above the element */
  left: 50%;
  margin-left: -60px; /* Center the tooltip */
  opacity: 0;
  transition: opacity 0.3s;
}

.blogSettings_tooltip__1i59L:hover .blogSettings_tooltiptext__30G5a {
  visibility: visible;
  opacity: 1;
}

.blogSettings_tooltip__1i59L .blogSettings_tooltiptext1__zHVwz {
  visibility: hidden;
  width: 120px;
  background-color: #D9D9D9;
  color: #000000;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: -65%; /* Position above the element */
  left: 0;
  margin-left: -85px; /* Center the tooltip */
  opacity: 0;
  transition: opacity 0.3s;
}

.blogSettings_tooltip__1i59L:hover .blogSettings_tooltiptext1__zHVwz {
  visibility: visible;
  opacity: 1;
}

.blogSettings_inputBox__1HFei:nth-child(2){
  padding-bottom: 0rem;
}

.blogSettings_searchBox__3RfEa .blogSettings_inputTitle__27SmA{
  padding-bottom: 1rem;
  display: block;
  color: #2E2E2E;
  font-size: 1.2rem;
}

.blogSettings_searchBox__3RfEa{
  margin-top: 1.2rem;
  display: flex;
  border-bottom: 1px solid #00000027;
}

.blogSettings_checkbox__19OxW {
  opacity: 0;
}

.blogSettings_checkbox__19OxW:checked + .blogSettings_slider__37pBO {
  background-color: rgb(255, 255, 255);
}

.blogSettings_slider__37pBO {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  border-radius: 32%;
  right: 0;
  bottom: 0;
  background-color:white;
  box-shadow: var(--box-shadow-input);
  transition: 0.4s;
}

.blogSettings_slider__37pBO:before {
  position: absolute;
  content: '';
  height: 1.8rem;
  width: 1.82rem;
  left: 0px;
  bottom: 0.8px;
  background-color:#bcb5b5bf;
  transition: 0.4s;
  border-radius: 50%;
}

.blogSettings_publish_icon_button__2byb9{
  text-decoration: none;
  cursor: pointer;
  background: none;
  border: none;
}

.blogSettings_checkbox__19OxW:checked + .blogSettings_slider__37pBO:before {
  background-color: var(--red);
  -webkit-transform: translateX(1.3rem);
  transform: translateX(1.3rem);
  left: 9px;
}


.blogSettings_switch__WgX28 {
  position: relative;
  display: inline-block;
  box-shadow: 11px 21px 42px -6px #57575826;
  min-width: 4rem;
  height: 2rem;
}

.blogSettings_searchIcon__22WUq{
  height: 6rem;
  width: 6rem;
  padding-top: 1.2rem;
  margin-bottom: -1.4rem;
}

.blogSettings_mainForm__2wdnn .blogSettings_formTitle__26L_9{
  padding-bottom: 2rem;
  display: block;
}

.blogSettings_select_box__2Bvnb{
  width: 40%;
  -webkit-box-decoration-break: none;
  box-decoration-break: none;
  min-height: 4rem;
  margin-bottom: 4rem;
  font-size: 1.4rem;
  background-color: #f2f2f200;
  border-bottom: 1px solid #00000027;
  outline: none;
  border-top: none;
  border-left: none;
  width: 40rem;
  border-right: none;
}

.blogSettings_multi_dropdown__gBHGk {
  border-style: none!important;
  width: 37rem;
  border: none;
  border-bottom: 1px solid #00000027 !important;
  border-radius: 0rem !important;
}


.blogSettings_multi_dropdown__gBHGk div{
  border-style: none!important;
}

.blogSettings_multi_dropdown__gBHGk .blogSettings_css-1rhbuit-multiValue__2sQnk div {
  font-size: 1.2rem;
}

.blogSettings_multi_dropdown__gBHGk div {
  margin-left: 0rem;
}

.blogSettings_multi_dropdown__gBHGk .blogSettings_css-1wa3eu0-placeholder__1ipC9 {
  text-transform: none !important;
  font-size: 1.4rem;
}

.blogSettings_upload_border__ysedb{
  border: 1px dotted rgb(78, 78, 78);
  display: flex;
  flex-direction: column;
  height: 11rem;
  text-align: center;
  justify-content: center;
  width: 34rem;
}

.blogSettings_subform__3HX43{
  justify-content: space-between;
  display: flex;
  grid-gap: 4rem;
  gap: 4rem;
}

.blogSettings_searchSubform__1koZE{
  display: flex;
  align-items: end;
  justify-content: space-between;
  grid-gap: 4rem;
  gap: 4rem;
}

.blogSettings_input__1DWWb{
  border-top: none;
  outline: none;
  width: 40rem;
  border-left: none;
  font-size: 1.4rem ;
  padding-bottom: 1rem;
  border-right: none;
  border-bottom: 1px solid #00000027;
}
.blogSettings_input_textarea__2ahp-{
  border-top: none;
  outline: none;
  width: 40rem;
  resize: none;
  font-size: 1.4rem ;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid #00000027;
}

.blogSettings_input_textarea__2ahp-::-webkit-input-placeholder{
  font-size: 1.3rem ;
}

.blogSettings_input_textarea__2ahp-::placeholder{
  font-size: 1.3rem ;
}

.blogSettings_input__1DWWb::-webkit-input-placeholder{
  font-size: 1.3rem;
}

.blogSettings_input__1DWWb::placeholder{
  font-size: 1.3rem;
}


.blogSettings_buttonContainer__3IdyS{
  display: flex;
  grid-gap: 2rem;
  gap: 2rem;
  justify-content: space-between;
}

.blogSettings_buttonContainer__3IdyS button{
  text-decoration: none;
  color: var(--white);
  text-transform: uppercase;
  background-color: var(--red);
  border: 1px solid var(--red);
  padding: 1.2rem 7.6rem;
  font-family: 'Medium';
  font-size: 1rem;
  border-radius: 0.5rem;
  cursor: pointer;
  transition: all 0.4s;
}

.blogSettings_buttonContainer__3IdyS button:hover {
  background-color: transparent;
  border: 1px solid var(--red);
  color: var(--red);
}

.blogSettings_searchContainer__2f8cr{
  margin-top: 6rem;
}

.blogSettings_searchContainer1__27Hfs{
  margin-top: 10rem;
}

@media only screen and (max-width: 1450px) {
  .blogSettings_container__1ta5C{
    padding: 2rem 10rem!important;
  }
}
.TextEditor_Container__P8Zbt {
	display: flex;
	flex-direction: column;
	padding-top: 4rem;
	/* width: 100%;
	height: 100%; */
}
.TextEditor_textEditor__27qQz {
	/* overflow: auto !important; */
	background-color: var(--white);
	border-radius: 1.5rem;
	height: 50vh !important;
	min-height: 50vh;
	overflow: hidden;
}

.TextEditor_button__3iVvJ {
	display: flex;
	align-items: center;
	justify-content: space-between;
	/* margin-right: .5rem; */
	background-color: var(--white);
	height: 4rem;
	border-radius: 1rem;
}

.TextEditor_bcc_Button__UHd7r {
	background-color: transparent;
	border: none;
	font-size: 15px;
	margin-right: 1rem;
	text-decoration: underline;
	color: gray;
	cursor: pointer;
}

.TextEditor_react-multi-email__32M1O {
	border: none !important;
	border-radius: 1.5rem !important;
	padding: 1rem 2rem !important;
	height: 3rem !important;
}
.TextEditor_react-multi-email__32M1O > input {
	/* font-family: inherit !important; */
	width: 100% !important;
}
.TextEditor_react-multi-email-data__3wVd5 {
	font-size: 1.2rem !important;
}
.TextEditor_react-multi-email__32M1O > span[data-placeholder] {
	/* top: 1rem !important;
	left: 1.5rem !important; */
}
.TextEditor_react-multi-email__32M1O [data-tag] [data-tag-handle] {
	margin-left: 0.5rem !important;
	display: flex;
	align-items: center;
	justify-content: center;
}
.TextEditor_react-multi-email-data-close-icon__2Y_mS {
	height: 1.3rem;
	width: 1.3rem;
	fill: var(--grey);
}
.TextEditor_form_input__error__1LlQt {
	font-size: 1.2rem;
	color: #ff3366;
	margin-top: 0.5rem;
	margin-left: 0.5rem;
}

.TextEditor_Text__1sIqH {
	padding-left: 2rem;
	font-size: 15px;
	font-weight: 600;
	text-align: center;
}

.TextEditor_user_search_container__2DmuT{
	display: flex;
	position: relative;
	width: 100%;
	margin-left: 1rem;
}

.TextEditor_user_serach_input__3VhFE{
	width: 100%;
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	grid-gap: 8px;
	gap: 8px;
}

.TextEditor_user_serach_input__3VhFE input {
	border: none;
	height:  20px;
	padding: 5px;
}

.TextEditor_user_serach_input__3VhFE input:focus {
	outline: none;
}

.TextEditor_Suggestion_List__3OLBJ{
	max-height: 300px;
	max-width: -webkit-max-content;
	max-width: max-content;
	overflow-y: scroll;
	list-style: none;
	margin-left: 2rem;
	position: absolute;
	background-color: #FFF;
	border: 1px solid #ccc;
	border-radius: 1rem;
	/* z-index: 99; */
}

.TextEditor_Image__3nui4{
	background-color: var(--white);
		box-shadow: 0px 0px 0.5rem 1px rgba(0, 0, 0, 0.5);
		border-radius: 50%;
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;
		height: 35px;
		width: 35px;
}

.TextEditor_Suggestion_List__3OLBJ li:hover{
	background-color: #ccc;
}

.TextEditor_user_pill__39aGf {
	height: 28px;
	display: flex;
	align-items: center;
	grid-gap: 5px;
	gap: 5px;
	background-color: #e7e7e7;
	color: rgb(80, 80, 80);
	font-size: 1.3rem;
	padding: 5px 10px;
	cursor: pointer;
}
.auto_insurance {
	padding: 2rem 33rem;
	padding-bottom: 0rem;
}
.auto_insurance_roadside {
	padding: 2rem 33rem;
	background-color: var(--light-grey2);
	margin-top: 3rem;
}
.tab_comprehensive {
	display: flex;
}
.auto_insuracne_image {
	display: 100%;
	padding-top: 1rem;
	width: 63vw;
}
.auto_insurance_services {
	padding: 2rem 0;
}
.auto_inurance_emergency {
	display: flex;
	flex-direction: column;
	text-align: center;
}
.contact_no {
	color: var(--red);
	font-size: 2rem;
	font-weight: 800;
}
.auto_insurance_discounts {
	padding: 2rem 33rem;
	display: flex;
	flex-direction: column;
}
.auto_insurance_discounts-types {
	display: flex;
	grid-gap: 3rem;
	gap: 3rem;
	flex-direction: column;
	padding: 2rem 0;
}
.discounts-types {
	display: flex;
	width: 20vw;
	/* border-right: 1px solid var(--light-grey); */
	/* padding-right: 7rem; */
}
.discounts_images_nails {
	width: 4vw;
	height: 4vw;
}
.discount_image_text {
	display: flex;
	flex-direction: column;
}
.auto_insurance_discounts-grid {
	display: flex;
	grid-gap: 3rem;
	gap: 3rem;
}
.premier_auto {
	padding: 2rem 1rem;
	/* justify-content: start; */
}
.premier_auto_coverage {
	display: flex;
	/* gap: 5rem; */
	align-items: center;
	justify-content: space-between;
	width: 100%;
}

.auto_insurance_premier {
	padding: 3rem 33rem;
	background-color: var(--light-grey2);
}
.auto_insurance_equipment {
	padding: 2rem 33rem;
}
.speical_equipment_outer {
	padding: 2rem 1rem;
}
.phase2_subheading ul li {
	margin-left: 2rem;
	list-style-type: circle;
}
.special_equipment_headers {
	line-height: 3.5rem;
	width: 20rem;
}

.special_equipment_list {
	display: flex;
	grid-gap: 8rem;
	gap: 8rem;
	padding: 2rem 1rem;
}
.special_equipment_uses {
	padding: 2rem;
	border: 2px solid var(--light-grey);
}
/* @media only screen and (min-width: 1500px) {

	.auto_insurance_roadside {
		padding: 2rem 15rem;
	}
	.auto_insurance_premier {
		padding: 2rem 15rem;
	}
	.auto_insurance_equipment {
		padding: 2rem 15rem;
	}
	.quote2 {
		padding: 0 15rem;
	}
} */
@media only screen and (max-width: 1500px) {
	.auto_insurance_roadside {
		padding: 2rem 15rem;
	}
	.auto_insurance_premier {
		padding: 2rem 15rem;
	}
	.auto_insurance_equipment {
		padding: 2rem 15rem;
	}
	.auto_insurance_discounts {
		padding: 2rem 15rem;
	}
	.auto_insurance {
		padding: 1rem 15rem;
	}
}
@media only screen and (max-width: 1400px) {
	.auto_insurance_roadside {
		padding: 2rem 15rem;
	}
	.auto_insurance_premier {
		padding: 2rem 15rem;
	}
	.auto_insurance_equipment {
		padding: 2rem 15rem;
	}
	.auto_insurance_discounts {
		padding: 2rem 15rem;
	}
	.auto_insurance {
		padding: 1rem 15rem;
	}
}
@media only screen and (max-width: 1200px) {
	.auto_insurance_roadside {
		padding: 2rem 10rem;
	}
	.auto_insurance_premier {
		padding: 2rem 10rem;
	}
	.auto_insurance_equipment {
		padding: 2rem 10rem;
	}
	.auto_insurance_discounts {
		padding: 2rem 10rem;
	}
	.auto_insurance {
		padding: 1rem 10rem;
	}
}
@media only screen and (max-width: 780px) {
	.auto_insurance {
		padding: 1rem 0;
	}
	.auto_insurance_roadside {
		padding: 2rem 1rem;
	}

	.auto_insuracne_image {
		width: 90vw;
	}
	.auto_insurance_discounts {
		padding: 2rem 1rem;
	}
	.auto_insurance_discounts-types {
		display: flex;
		flex-direction: column;
	}
	.auto_insurance_discounts-grid {
		display: flex;
		flex-direction: column;
	}
	.discounts-types {
		width: 100%;
	}
	.discounts_images_nails {
		width: 12vw;
		height: 10vw;
	}
	.auto_insurance_premier {
		padding: 2rem 1rem;
	}
	.auto_insurance_equipment {
		padding: 2rem 1rem;
	}

	.special_equipment_headers {
		line-height: 3.2rem;
		width: 30rem;
	}

	.special_equipment_list {
		display: flex;
		grid-gap: 1rem;
		gap: 1rem;
		flex-direction: column;
		padding: 2rem 0;
	}
}

.business_insurance {
	padding: 3rem 33rem;
	/* margin-top: 3rem; */
	padding-bottom: 0;
}
.business_insurance_coverage {
	padding: 3rem 33rem;
	background-color: var(--light-grey2);
	margin-top: 2rem;
}
.business_coverage_insurance {
	padding: 0 1rem;
	padding-top: 1rem;
}
.business_insurance-cover {
	display: flex;
	grid-gap: 10rem;
	gap: 10rem;
	line-height: 3.5rem;
	padding: 0 0;
}
.business_insurance_discounts {
	padding: 3rem 33rem;
}
.line {
	background-color: var(--light-grey);
	color: var(--light-grey);
	display: flex;
	margin-left: 1rem;
	margin-right: -1rem;
	flex-direction: column;
	width: 5px;
	height: 100%;
}
@media only screen and (max-width: 1500px) {
	.business_insurance {
		padding: 3rem 15rem;
	}
	.business_insurance_coverage {
		padding: 3rem 15rem;
	}
	.business_insurance_discounts {
		padding: 3rem 15rem;
	}
}
@media only screen and (max-width: 1400px) {
	.business_insurance {
		padding: 3rem 15rem;
	}
	.business_insurance_coverage {
		padding: 3rem 15rem;
	}

	.business_insurance_discounts {
		padding: 3rem 15rem;
	}
	.quote2 {
		padding: 0 15rem;
	}
}
@media only screen and (max-width: 780px) {
	.business_insurance {
		padding: 3rem 1rem;
	}
	.business_insurance_coverage {
		padding: 3rem 1rem;
	}
	.business_insurance-cover {
		flex-direction: column;
		grid-gap: 0;
		gap: 0;
		line-height: 4rem;
	}
	.business_insurance_discounts {
		padding: 3rem 1rem;
	}
	.line {
		display: none;
	}
}

.homeowner_insurance {
	padding: 2rem 33rem;
	padding-bottom: 0;
}
.country_home_insurance {
	background-color: var(--light-grey2);
	padding: 3rem 33rem;
	margin: 3rem 0;
}
.homeowner-renter {
	padding: 0 33rem;
}
.homeowner-renter-insurance {
	padding: 1rem 1rem;
}
.homeowner_renter_insurance-cover {
	display: flex;
	flex-direction: row;
	padding: 2rem 0;
	grid-gap: 15rem;
	gap: 15rem;
	line-height: 3.5rem;
}
.home-insurance-discounts {
	padding: 5rem 33rem;
	background-color: var(--light-grey2);
}
.homeowner_insurance_discounts-grid {
	display: flex;
	grid-gap: 2rem;
	gap: 2rem;
	padding: 2.5rem 0;
}
@media only screen and (max-width: 1500px) {
	.homeowner_insurance {
		padding: 2rem 15rem;
	}
	.country_home_insurance {
		padding: 2rem 15rem;
	}
	.homeowner-renter {
		padding: 3rem 15rem;
	}
	.home-insurance-discounts {
		padding: 3rem 15rem;
	}
}
@media only screen and (max-width: 1400px) {
	.homeowner_insurance {
		padding: 2rem 15rem;
	}
	.country_home_insurance {
		padding: 3rem 15rem;
	}
	.homeowner-renter {
		padding: 3rem 15rem;
	}
	.home-insurance-discounts {
		padding: 3rem 15rem;
	}
}

@media only screen and (max-width: 780px) {
	.homeowner_insurance {
		padding: 2rem 0;
	}
	.country_home_insurance {
		padding: 3rem 1rem;
	}
	.homeowner-renter {
		padding: 3rem 1rem;
	}
	.homeowner_renter_insurance-cover {
		flex-direction: column;
		width: 100%;
		grid-gap: 0;
		gap: 0;
		padding: 0;
		line-height: 3.5rem;
	}
	.home-insurance-discounts {
		padding: 3rem 1rem;
	}
	.homeowner_insurance_discounts-grid {
		flex-direction: column;
		grid-gap: 4rem;
		gap: 4rem;
	}
}

.annuities {
	padding: 3rem 33rem;
	padding-bottom: 0;
}
.annuities_tabset {
	padding: 3rem 33rem;
}
@media only screen and (max-width: 1500px) {
	.annuities {
		padding: 3rem 15rem;
	}
	.annuities_tabset {
		padding: 3rem 15rem;
	}
}
@media only screen and (max-width: 1400px) {
	.annuities {
		padding: 3rem 15rem;
	}
	.annuities_tabset {
		padding: 3rem 15rem;
	}
}

@media only screen and (max-width: 1200px) {
	.annuities {
		padding: 3rem 10rem;
	}
	.annuities_tabset {
		padding: 3rem 10rem;
	}
}
@media only screen and (max-width: 780px) {
	.annuities {
		padding: 3rem 1rem;
	}
	.annuities_tabset {
		padding: 3rem 1rem;
	}
}

.life_insurance {
	padding: 3rem 33rem;
	padding-bottom: 0;
}
.Life_insurance_link {
	color: var(--red);
}
.life_insurance_starter {
	padding: 3rem 33rem;
	margin: 2rem 0;
	margin-bottom: 0;
	width: 100%;
	background-color: var(--light-grey2);
}
.life_insurance_starter-outer {
	padding: 2rem 1rem;
	display: flex;
	grid-gap: 20rem;
	gap: 20rem;
}
.life_insurance_starter-left {
	width: 50%;
}
.lifebutton {
	margin-top: 5rem;
}
.life_insurance_button {
	background-color: var(--dark-grey);
	color: white;
	padding: 1.5rem 4rem;
	font-size: 1.6rem;
	font-weight: 300;
	outline: none;
	border: none;
	margin-top: 5rem;
	width: 100%;
	border-radius: 5px;
	cursor: pointer;
	text-decoration: none;
}
.life_insurance_button:hover {
	background-color: var(--black);
}
.life_insurance_starter-right {
	width: 50%;
}
.life_insurance_options {
	padding: 3rem 33rem;	
	background-color: var(--light-grey2);
	margin-bottom: 4rem;
}
.nail_icon_center{
	margin-top: 10rem;
}
@media only screen and (max-width: 1200px) {
	.life_insurance {
		padding: 3rem 10rem;
	}
	.life_insurance_starter {
		padding: 3rem 13rem;
	}

	.life_insurance_options {
		padding: 3rem 10rem;
	}
	/* .life_insurance_starter-outer {
		flex-direction: column;
		gap: 2rem;
	} */

	.life_insurance_starter-left {
		width: 100%;
	}
	.quote2 {
		padding: 0 10rem;
	}
	.homeowner_insurance_discounts-grid {
		width: 100%;
		justify-content: space-between;
	}

	/* .life_insurance_button {
		margin-top: 2rem;
		width: 80%;
		justify-content: center;
	} */
	.life_insurance_starter-right {
		width: 100%;
	}
}
@media only screen and (max-width: 760px) {
	.life_insurance {
		padding: 3rem 1rem;
	}
	.life_insurance_starter {
		padding: 3rem 3rem;
	}

	.life_insurance_options {
		padding: 3rem 1rem;
	}
	.life_insurance_starter-outer {
		flex-direction: column;
		grid-gap: 2rem;
		gap: 2rem;
	}

	.life_insurance_starter-left {
		width: 100%;
	}
	.life_insurance_button {
		margin-top: 2rem;
		width: 80%;
		justify-content: center;
	}
	.life_insurance_starter-right {
		width: 100%;
	}
	.quote2 {
		padding: 0 1rem;
	}
}

.other_offerings_options {
	padding: 3rem 33rem;
}
.other_offerings_services {
	padding: 3rem 33rem;
	background-color: var(--light-grey2);
}

.discounts_options {
	padding: 3rem 33rem;
}

@media only screen and (max-width: 1500px) {
	.other_offerings_options,
	.other_offerings_services {
		padding: 3rem 15rem;
	}
}

@media only screen and (max-width: 1400px) {
	.other_offerings_options,
	.other_offerings_services {
		padding: 3rem 15rem;
	}
	/* .homeowner_insurance_discounts-grid {
		width: 100%;
		justify-content: space-between;
	} */
}
@media only screen and (max-width: 1400px) {
	.other_offerings_options,
	.other_offerings_services {
		padding: 3rem 10rem;
	}
}

@media only screen and (max-width: 780px) {
	.other_offerings_options,
	.other_offerings_services {
		padding: 3rem 1rem;
	}
}

.Farm_ranch_insurance {
	padding: 2rem 33rem;
	padding-bottom: 0;
}
.Farm_insurance_coverage {
	background-color: var(--light-grey2);
	padding: 3rem 33rem;
}

@media only screen and (max-width: 1500px) {
	.Farm_ranch_insurance {
		padding: 3rem 15rem;
	}
	.Farm_insurance_coverage {
		padding: 3rem 15rem;
	}
}
@media only screen and (max-width: 1400px) {
	.Farm_ranch_insurance {
		padding: 3rem 15rem;
	}
	.Farm_insurance_coverage {
		padding: 3rem 15rem;
	}
}

@media only screen and (max-width: 780px) {
	.Farm_ranch_insurance {
		padding: 3rem 1rem;
	}
	.Farm_insurance_coverage {
		padding: 3rem 1rem;
	}
}

.ins360_slider {
	position: relative;
	height: 60vh;
	background-size: cover;
	background-repeat: no-repeat;

	display: flex;
	flex-direction: column;
	justify-content: center;
	padding: 0 5rem;
}
.ins360_sider__heading {
	font-size: 2rem;
	font-weight: 400;
}
.ins360_sider__subheading {
	font-size: 1.3rem;
	font-weight: 500;
}
.ins360_slider__img {
	position: absolute;
	top: 0;
	width: 100%;
	z-index: 1;
}

/* section CSS */
.ins360_sections {
	margin-top: 8rem;
	margin-left: 5rem;
}
.ins360_section__heading {
	font-size: 1.4rem;
	font-weight: 600;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}
.ins360_section__heading::after {
	content: '';
	height: .5rem;
	width: 3rem;
	background-color: var(--black);
}
.ins360_section__contents {
	margin-top: 2rem;
	display: flex;
	grid-gap: 2rem;
	gap: 2rem;
	flex-wrap: wrap;
}
.ins360_section {
	text-decoration: none;
	color: var(--black);
	border: none;
	font-family: inherit;
	background-color: var(--bg-primary);
	box-shadow: var(--box-shadow1);
	padding: 2rem 3rem;
	border-radius: 1rem;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	transition: all .4s;
}
.ins360_section:hover {
	-webkit-transform: scale(1.05);
	        transform: scale(1.05);
}
.ins360_section__image {
	height: 8rem;
}
.ins360_section__title {
	margin: .5rem 0;
	font-size: 1.3rem;
	font-weight: 500;
}
.ins360_section__logo {
	height: 3.5rem;
}

/* Contact CSS */
.ins360_contact {
	margin-top: 10rem;
	background-size: cover;
	padding: 0rem 15rem;
	height: 35rem;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
}
.ins360_contact__heading {
	font-size: 1.4rem;
	font-weight: 500;
	color: var(--ins360-primary);
}
.ins360_contact__subheading {
	font-size: 1.2rem;
	margin-bottom: 2rem;
}
.ins360_contact__text {
	font-size: 1.4rem;
	font-weight: 500;
	margin-bottom: 1rem;
}
.ins360_contact__btn {
	border: 1px solid var(--ins360-primary);
	background-color: var(--ins360-primary);
	border-radius: 1rem;
	font-family: inherit;
	padding: 1rem 2rem;
	color: var(--bg-primary);
	cursor: pointer;
	transition: all .4s;
}
.ins360_contact__btn:hover {
	background-color: transparent;
	color: var(--red);
}

.mwefbicontact_maincontainer {
	display: flex;
	flex: 1 1;
	padding: 4rem 33rem;
}

.container-mwefbicontact-left {
	display: flex;
	flex-direction: column;
	overflow: auto;
	position: relative;
	margin-top: -25rem;
	align-items: center;
	width: 30%;
}

.container-mwefbicontact-left-contact {
	background-color: var(--red);
	padding: 30px 8% 30px;
	width: 35vh;
}

.container-mwefbicontact-left-contact .heading_1 {
	color: #fff;
}

.container-mwefbicontact--right {
	display: flex;
	flex-direction: column;
	width: 70%;
}


.contactUs__desc {
	font-size: 1.6rem;
	font-weight: 500;
	padding-bottom: 3.5rem;
	color: #fff;
}

.contact-building-image {
	width: 35vh;
}


.red_star {
	color: var(--red);
	font-weight: 200;
}


.form_textarea {
	background-color: rgb(238, 234, 228);
	height: 8rem;
	width: 86%;
	border: none;
	outline: none;
	border: 1px solid rgb(163, 159, 159);
}



.form_textbox {
	display: flex;
	grid-gap: 3rem;
	gap: 3rem;
}

.label_heading1 {
	display: flex;
	grid-gap: 33.7rem;
	gap: 33.7rem;
	flex-wrap: text;
	margin-top: 2rem;
}

.label_subheading {
	font-weight: 900;
	font-size: 2rem;
	color: grey;
}

.label_heading2 {
	display: flex;
	grid-gap: 38.8rem;
	gap: 38.8rem;
	color: grey;
	font-size: 1.2rem;
	margin-top: .2rem;
}

.label_heading3 {
	margin-top: .3rem;
	display: flex;
	color: grey;
	margin-bottom: .3rem;
	font-size: 1.2rem;
}

.radio-option {
	display: flex;
	grid-gap: 3rem;
	gap: 3rem;
	align-items: center;
}

.radio-custom-label-contact {
	margin-left: 1rem;
}

.heading_contact_1 {
	color: #484848;
	font-size: 4rem;
	font-weight: bolder;
}

.contact-mwfbi--icon {
	height: 2rem;
	width: 2rem;
	fill: #fff;
}

.contact-mwfbi {
	display: flex;
	align-items: center;
	grid-gap: 1rem;
	gap: 1rem;
}
.main_outer_component12{
	padding-left: 10px;
}

@media only screen and (min-width: 1500px) {
	.mwefbicontact_maincontainer {
		position: relative;
		width: 100%;
		flex-direction: row;
	}

	.container-mwefbicontact-left {
		width: 30%;
		position: relative;
	}

	.container-mwefbicontact--right {
		width: 70%;
		position: relative;
	}
}


@media only screen and (max-width: 1400px) {
	.mwefbicontact_maincontainer {
		position: relative;
		width: 100%;
		flex-direction: row;
		padding: 4rem 10rem !important;
	}

	.container-mwefbicontact-left {
		width: 30%;
		position: relative;
	}

	.container-mwefbicontact--right {
		width: 70%;
		position: relative;
	}
}



@media only screen and (max-width: 900px) {
	.mwefbicontact_maincontainer {
		position: relative;
		width: 100%;
		flex-direction: column;
		grid-gap: 5rem;
		gap: 5rem;
	}

	.container-mwefbicontact-left {
		width: 100%;
		position: relative;
	}

	.container-mwefbicontact--right {
		width: 100%;
		position: relative;
	}
}


@media only screen and (max-width: 760px) {
	.mwefbicontact_maincontainer {
		position: relative;
		width: 100%;
		flex-direction: column;
		grid-gap: 5rem;
		gap: 5rem;
	}

	.container-mwefbicontact-left {
		width: 100%;
		position: relative;
		display: flex;
		flex-direction: column;
		align-items: center;
		margin-left: -3rem;
	}

	.container-mwefbicontact--right {
		width: 100%;
		position: relative;
		
		margin-left: -5rem;
	}
}
.login_container__3687S {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
}
.login_centerContainer__DVdLy {
  width: 60%;
  background-color: var(--white);
  border-radius: 0.5rem;
  box-shadow: 0 10px 16px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%) !important;
}
.login_topContainer__1v9bL {
  display: flex;
  padding: 2rem 0rem;
  flex-direction: row;
}
.login_leftContainer__2xMdx {
  width: 50%;
  border-right: 0.2rem solid #80808073;
  height: 100%;
  padding: 2rem 3rem;
}
.login_rightContainer__1NWYQ {
  width: 50%;
  height: 100%;
  padding: 2rem 3rem;
}
.login_line__22AyI {
  background-color: var(--light-grey);
  color: var(--light-grey);
  display: flex;
  margin-left: 1rem;
  margin-right: -1rem;
  flex-direction: column;
  width: 2px;
  height: 84%;
}
.login_input1__3EfWp {
  margin-top: 1rem;
}
.login_input2__1VN9W {
  margin-top: 3rem;
}
.login_buttons__31XQU {
  display: flex;
  flex-direction: row;
  justify-content: CENTER;
    grid-gap: 2rem;
    gap: 2rem;
}
.login_buttons_vertical__2RSaO {
  display: flex;
  flex-direction: column;
  justify-content: CENTER;
    grid-row-gap: 1rem;
    row-gap: 1rem;
    margin-top: 2rem;
}
.login_header__1f6qH {
  color: grey;
  font-size: 1.8rem;
  margin-bottom: 2rem;
}
.login_headerNew__3ff9F {
  padding-top: 20px;
  margin-top: 18px;
  border-top: 1px solid var(--light-grey) ;
  color: grey;
  font-size: 1.8rem;
  margin-bottom: 2rem;
}
.login_desc__2w2ST {
  color: grey;
  font-size: 1.6rem;
}
.login_bottomContainer__3GkB2 {
    /* height: 25%; */
  border-top: 1px solid var(--light-grey);
  margin: 0rem 3rem;
  padding: 2rem 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.login_phone__2fvkh {
  color: var(--red);
}
.login_header2__2NJB3 {
  color: grey;
  font-size: 1.8rem;
}
.login_header3__3SDbZ {
  color: grey;
  font-size: 1.8rem;
}

.login_input3__3UwpJ{
  /* margin-top: 2rem; */
}

.login_labelHeading__2dQAi {
  font-size: 1.8rem;
  color: grey;
}


@media only screen and (max-width: 992px) {
  .login_centerContainer__DVdLy{
    width: 70%;
  }
  .login_input2__1VN9W .login_buttons__31XQU{
    display: block;
  }
  .login_input2__1VN9W .login_buttons__31XQU button{
    margin-bottom: 2rem;
  }
  .login_topContainer__1v9bL{
    height: 45%;
  }
  .login_labelHeading__2dQAi {
    font-size: 2.2rem;
    color: grey;
  }

}

@media only screen and (max-width: 669px) {
  html {
    font-size: 34%;
  }
  .login_centerContainer__DVdLy {
    width: 84%;
    height: 82%;
  }
  .login_desc__2w2ST{
    font-size: 1.5rem;
  }
  .login_input2__1VN9W{
    margin-top: 4rem;
  }
  .login_input3__3UwpJ{
    margin-top: 5rem;
  }
  .login_labelHeading__2dQAi {
    font-size: 2.2rem;
    color: grey;
  }
  .login_line__22AyI {
    width: 90%;
    height: 2px;
  }
  .login_topContainer__1v9bL {
    flex-direction: column;
    height:65%
  }
  .login_leftContainer__2xMdx {
    width: 100%;
    height: 100%;
    padding: 4rem 5rem 0rem;
    border-right: none;
  }
  .login_rightContainer__1NWYQ {
    width: 100%;
    height: 100%;
    padding: 4rem 3rem;
  }
  .login_bottomContainer__3GkB2 {
    border-top: none;
    padding: 10rem 5rem;
  }
  .login_header__1f6qH {
    text-align: center;
    font-size: 2.4rem;
  }
  .login_desc__2w2ST{
    font-size: 1.8rem;
  }
  .login_header3__3SDbZ {
    font-size: 2.4rem;
    margin-top: 1rem;
  }
  .login_header2__2NJB3 {
    font-size: 2.4rem;
    margin-top: 1rem;
  }
  .login_input2__1VN9W .login_buttons__31XQU{
    display: flex;
  }
}

.InfoCard_icon_division__2i52Z {
  padding: 2rem 0rem 4rem;
  width: 30%;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.InfoCard_icon__1NsCi {
  height: 8rem;
}

.InfoCard_icon_division__2i52Z span {
  display: block;
  font-size: 1.4rem;
  font-weight: 600;
  margin-top: 1.8rem;
}

.InfoCard_divisionText__gVGPW {
  text-align: center;
  display: block;
}

.InfoCard_infoCard__1Bw8q {
  background: #f0f0f0;
  border-radius: 12px;
  padding: 2rem 4rem;
  min-height: 18rem;
  min-width: 20rem;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.InfoCard_icon__1NsCi {
  width: 130px;
  height: 10rem;
}

@media (max-width: 767px) {
  .InfoCard_icon_division__2i52Z {
    width: 100%;
  }
  .InfoCard_infoCard__1Bw8q {
    padding: 4rem 6rem;
  }
  .InfoCard_icon_division__2i52Z span {
    display: block;
    font-size: 2rem;
  }
}

.LinkCard_cardContainer__BILRB {
  background-color: #ffffff;
  border-radius: 20px;
  padding: 20px 22px;
  margin-bottom: 6rem;
}

.LinkCard_title__5Dp8e {
  font-size: 20px;
  padding-bottom: 1rem;
  display: block;
  font-weight: 600;
}

@media (min-width: 767px) {
  .LinkCard_cardContainer__BILRB{
    padding: 22px 0px 0px;
  }
}

@media (max-width: 767px) {
  .LinkCard_cardContainer__BILRB{
    padding: 0px 0px 0px;
  }
}
.LinkChip_chipContainer__1zt0R {
  background-color: #ECECEC;
  border-radius: 20px;
  margin-bottom: 1.4rem;
  color: black;
  text-decoration: none;
  cursor: pointer;
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 14px 2px 14px 20px;
}

.LinkChip_title__3n0h0 {
  font-size: 1.4rem;
  display: block;
}

.LinkChip_link_button__3Ju2Q {
  height: 4rem;
  width: 4rem;
  display: block;
  fill: black;
}

@media (min-width: 767px) {
  .LinkChip_title__3n0h0{
    font-size: 1.6rem;
  }
}

@media (max-width: 767px) {
  .LinkChip_title__3n0h0{
    font-size: 2.4rem;
  }
}
.LinkChip_chipContainer__356dQ {
  background-color: #ECECEC;
  border-radius: 20px;
  margin-bottom: 1.4rem;
  cursor: pointer;
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 14px 2px 14px 20px;
}

.LinkChip_title__1rsKZ {
  font-size: 1.4rem;
  display: block;
}

.LinkChip_link_button__HFfBw {
  height: 4rem;
  width: 4rem;
  display: block;
  fill: black;
}

@media (min-width: 767px) {
  .LinkChip_title__1rsKZ{
    font-size: 1.6rem;
  }
}

@media (max-width: 767px) {
  .LinkChip_title__1rsKZ{
    font-size: 2.4rem;
  }
}
.PdfCard_cardContainer__18pHO {
  background-color: #ffffff;
  border-radius: 20px;
  padding: 20px 22px;
  margin-bottom: 6rem;
}

.PdfCard_title__1F9cA {
  font-size: 16px;
  display: block;
  font-weight: 500;
  padding-bottom: 1rem;
}

@media (max-width: 767px) {
 .PdfCard_cardContainer__18pHO{
  padding: 5px 20px;
  margin-bottom: 0rem;
 }
}
.Stepper_container__3YhIf{
    display: flex;
    justify-content: center;
}
.Stepper_bullet__2rWrx span{
  font-size: 16px;
}

.Stepper_step__15wln{
  display: flex;
  align-items: center;
  grid-gap: 1rem;
  grid-gap: 1rem;
  gap: 1rem;
  flex-direction: column;
  width: 270px;
  text-align: center;
}

.Stepper_bullet__2rWrx{
    position: relative;
    width: 6rem;
    height: 6rem;
    background-color: #E5E5E5;
    border: 12px solid #E5E5E5;
    color: white;
    font-size: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
  }

  .Stepper_step__15wln p{
    font-size: 12px;
    text-transform: uppercase;
}
  
  /* .step:last-child .bullet::before,
  .step:last-child .bullet::after{
    display: none;
  } */
  
  .Stepper_bullet__2rWrx::before,
  .Stepper_bullet__2rWrx::after{
    position: absolute;
    content: '';
    bottom: 14px;
    right: 20px;
    height: 0.5px;
    width: 240px;
    background: #E5E5E5;
    z-index: -1;

  }
  
  .Stepper_step__15wln:first-child .Stepper_bullet__2rWrx::before,
  .Stepper_step__15wln:first-child .Stepper_bullet__2rWrx::after{
    display: none;
  }

  .Stepper_Complete__1-ak4 .Stepper_bullet__2rWrx{
    /* background-color: var(--primary);
    border: 2px solid var(--primary);
    color: #fff; */
    width: 6rem;
    height: 6rem;
    background-color: red;
    color: #fff;
    font-size: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
    border: 12px solid #0000;
  }
  
  .Stepper_Complete__1-ak4 .Stepper_bullet__2rWrx::after{
    position: absolute;
    content: '';
    bottom: 14px;
    right: 20px;
    height: 0.5px;
    width: 240px;
    background: red;
    -webkit-transform: scaleX(0);
            transform: scaleX(0);
    -webkit-transform-origin: left;
            transform-origin: left;
    -webkit-animation: Stepper_animate__m-ABW 0.8s linear forwards;
            animation: Stepper_animate__m-ABW 0.8s linear forwards;
  }
  
  @-webkit-keyframes Stepper_animate__m-ABW {
    100%{
      -webkit-transform: scaleX(1);
              transform: scaleX(1)
    }
  }
  
  @keyframes Stepper_animate__m-ABW {
    100%{
      -webkit-transform: scaleX(1);
              transform: scaleX(1)
    }
  }
.EditModal_modalContainer__hWUUn {
  padding-top: 4rem;
}

.EditModal_buttonContainer__morqX {
  display: flex;
  grid-gap: 16px;
  gap: 16px;
  margin-right: 2.2rem;
  align-self: flex-end;
}

.FirstStepForm_mainContainer__3lskQ {
  padding: 2rem 2rem 0.6rem;
  margin-bottom: 2rem;
  display: flex;
  width: 100%;
  border-bottom: 1px solid rgba(162, 162, 162, 0.32);
  justify-content: space-between;
}

.FirstStepForm_inputHeading__Kk9-u {
  font-size: 1.6rem;
  color: grey;
}

.SecondStepForm_mainContainer__1ObgE{
  padding: 2rem  2rem 0.6rem;
  margin-bottom: 2rem;
  display: flex;
  width: 100%;
  border-bottom: 1px solid rgba(162 162 162 0.32);
  justify-content: space-between;
}

.SecondStepForm_inputHeading__3CA3c{
  font-size: 1.6rem;
  color: grey;
}

.SecondStepForm_addOptionButton__2E0N8{
  margin-left: 1rem; 
  font-size: 1.8rem; 
  color: red; 
  background: none; 
  border: none;
  cursor: pointer;
}
.EditForm_mainTitle__3XagW{
  padding-bottom: 1rem;
  margin-bottom: 1rem;
  border-bottom: 1px solid rgba(162, 162, 162, 0.32);
}
.ImageOption_inputHeading__1fyu5 {
  font-size: 1.6rem;
  color: grey;
}

.ImageOption_main__2YGxF {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid rgba(162, 162, 162, 0.32);
  padding-bottom: 2rem;
}
.PdfOption_inputHeading__eVbdg {
  font-size: 1.6rem;
  color: grey;
}

.PdfOption_main__2TTWB {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid rgba(162, 162, 162, 0.32);
  padding-bottom: 2rem;
}

.PdfUploader_supportedFormat__2VSbk {
  font-size: 1.2rem;
  padding-top: 1rem;
  font-weight: 500;
}

.PdfUploader_fileInfo__2cBfy{
    font-size: 1.4rem;
    list-style: none;
}


.PointAddForm_mainContainer__15lGP{
  padding: 2rem  0rem 0.6rem;
  margin-bottom: 2rem;
  display: flex;
  width: 100%;
  border-bottom: 1px solid rgba(162 162 162 0.32);
  justify-content: space-between;
}

.PointAddForm_inputHeading__GEwlU{
  font-size: 1.6rem;
  color: grey;
}

.PointAddForm_addOptionButton__3klSO{
  margin-left: 1rem; 
  font-size: 1.8rem; 
  color: red; 
  background: none; 
  border: none;
  cursor: pointer;
}
.ThirdStepForm_mainContainer__8qWZ6{
  padding: 2rem  2rem 0rem;
  display: flex;
  width: 100%;
  border-bottom: 1px solid rgba(162 162 162 0.32);
  justify-content: space-between;
}

.ThirdStepForm_inputHeading__1LhKh{
  font-size: 1.6rem;
  color: grey;
}

.ThirdStepForm_addOptionButton__2Pz-Q{
  margin-left: 1rem; 
  font-size: 1.8rem; 
  color: red; 
  background: none; 
  border: none;
  cursor: pointer;
}
.quote3 {
  justify-content: center;
  height: 10rem;
  background-color: var(--dark-grey);
  display: flex;
  align-items: center;
}

.landing_selectbox{
  width: 26rem!important;
  font-size: 1.6rem!important;
  padding: 1.4rem 1.4rem!important;
}

.quote__text3 {
  font-size: 2.8rem;
  font-weight: 800;
  color: var(--white);
}

.main_view_landing {
  padding: 2rem 6rem 24rem;
}

.icon_division {
  background-color: aqua;
  padding: 1.4rem 0rem;
  width: 30%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.icon_grid div:nth-child(2) {
  border-left: 0.2rem solid rgba(0, 0, 0, 0.138);
  border-right: 0.2rem solid rgba(0, 0, 0, 0.138);
}
.icon_grid {
  display: flex;
  position: relative;
  border-bottom: 0.3rem solid rgba(0, 0, 0, 0.138);
  justify-content: space-between;
  grid-gap: 2rem;
  gap: 2rem;
  margin-bottom: 2rem;
}

.icon {
  height: 8rem;
}

.icon_division span {
  display: block;
  font-size: 1.1rem;
  font-weight: 600;
  margin-top: 1.8rem;
}

.division_text {
  text-align: center;
  display: block;
}

.options_menu {
  display: flex;
  justify-content: space-between;
}

.edit_icon{
  display: block;
}

.option_section {
  margin-bottom: 5rem;
}

.option_section span {
  line-height: 2rem;
  font-weight: 800;
  margin-bottom: 1.2rem;
  font-size: 2.8rem;
  display: inline-block;
  border-bottom: 0.4rem solid rgba(0, 0, 0, 0.138);
}

.option_section a {
  display: block;
  font-size: 2rem;
}

.option_division {
  flex: 1 1;
  padding-right: 4rem;
}

.options_menu .option_division:nth-child(2) {
  padding: 0rem 2rem;
  border-left: 0.2rem solid rgba(0, 0, 0, 0.138);
  border-right: 0.2rem solid rgba(0, 0, 0, 0.138);
}

.video_image {
  height: 22rem;
  width: 43.2rem;
  text-align: center;
}

.second_options_menu {
  position: relative;
  padding-top: 32px;
  margin-top: 62px;
  border-top: 0.2rem solid rgba(0, 0, 0, 0.138);
}

.second_options_menu .option_division:nth-child(2) {
  border-left: none;
  border-right: none;
}

.vertical_options_menu {
  display: flex;
  flex-direction: column;
}
.vertical_grid{
  display: flex;
  
}
.grid_item{  
  flex: 1 1;
   display: flex;
   flex-direction: column;
}
.grid_item:nth-child(2) {  
  padding: 0rem 4rem;
  border-left: 0.2rem solid rgba(0, 0, 0, 0.138);
  border-right: 0.2rem solid rgba(0, 0, 0, 0.138);
}
.grid_item:nth-child(1) {
  padding: 0rem 2rem;
}
.grid_item:nth-child(3) {
  padding: 0rem 2rem;
}


@media only screen and (min-width: 700px) and (max-width: 1199px) {
  .video_image {
    height: 18rem;
    width: 31.2rem;
    text-align: center;
  }
  .main_view {
    padding: 3rem 6rem 24rem;
  }
  .option_section span {
    line-height: 3rem !important;
  }
}

@media only screen and (max-width: 669px) {
  .quote__text3 {
    font-size: 2.4rem;
  }

  .main_view {
    padding: 3rem 0rem 24rem;
  }

  .option_section span {
    font-size: 2rem !important;
  }

  .video_image {
    height: 10rem;
    width: 15rem;
    text-align: center;
  }

  .option_section {
    margin-bottom: 3rem;
  }
}

@media (min-width: 767px) {
  .main_view_landing {
    padding: 3rem 6rem 7rem;
  }
  .option_division {
    padding-right: 0rem;
  }
  .option_division:nth-child(2) {
    width: 35%;
  }
  .icon_grid div:nth-child(2) {
    width: 35%;
  }

}

@media (max-width: 767px) {
.icon_grid{
  display: block;
}
.icon_grid div:nth-child(2) {
  border-left: none;
  border-right: none;
}
.options_menu{
  display: inherit;
}
.option_division{
  width: 100%;
  padding-top: 4rem;
  border-bottom: 0.2rem solid rgba(0, 0, 0, 0.138);
  padding-right: 0px;
}

.options_menu .option_division:nth-child(2){
  padding: 0px;
  padding-top: 4rem;
  border-left: none;
  border-right: none;
}
.options_menu .option_division:nth-child(3){
  border-bottom: none;
}
.edit_icon{
  display: none;
}
.second_options_menu .option_division{
  border-bottom: none;
}
.video_image{
  height: 29rem;
  width: 56rem;
  margin-bottom: 2rem;
}
.main_view_landing {
  padding: 3rem 6rem 5rem;
}
}

.PdfCard_cardContainer__3EVh- {
  background-color: #ffffff;
  border-radius: 20px;
  padding: 20px 22px;
  margin-bottom: 6rem;
}

.PdfCard_title__Dwgfa {
  font-size: 16px;
  padding-bottom: 1rem;
  display: block;
  font-weight: 600;
}

@media (max-width: 767px) {
 .PdfCard_cardContainer__3EVh-{
  padding: 5px 20px;
  margin-bottom: 0rem;
 }
}
.ImageCard_cardContainer__hVWhD {
  background-color: #ffffff;
  border-radius: 20px;
  padding: 20px 22px;
  margin-bottom: 6rem;
}

.ImageCard_title__2sBnh {
  font-size: 16px;
  padding-bottom: 1rem;
  display: block;
  font-weight: 600;
}

@media (max-width: 767px) {
 .ImageCard_cardContainer__hVWhD{
  padding: 5px 20px;
  margin-bottom: 0rem;
 }
}
.EditFirstSection_title__ry2Kc {
  font-size: 30px;
  color: #808080;
  font-weight: 500;
  display: block;
  font-size: 14px !important;
}

.EditFirstSection_inputTitle__3M96y {
  font-size: 30px;
  color: #808080;
  font-weight: 500;
  display: block;
  font-size: 14px;
}

.EditFirstSection_modal_inner_content_right_landing__3DlOx {
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  justify-content: space-between;
  width: 100%;
  grid-gap: 1rem;
  gap: 1rem;
}

.EditFirstSection_modalContainer__3G4jn {
  padding: 12px 12px 18px;
  width: 100%;
  border-bottom: 1px solid rgba(162, 162, 162, 0.32);
}

.EditFirstSection_agentlanding_selectbox__3bRPf {
  width: 52% !important;
  font-size: 1.6rem !important;
  padding: 1.4rem 1.4rem !important;
}

.EditFirstSection_inputContainer__2MBAv {
  display: flex;
  align-items: end;
  justify-content: space-between;
}

.EditFirstSection_section1Container__36Dob {
  width: 52%;
}

.EditFirstSection_sectionContainer__1CLif {
  width: 39%;
}

.EditFirstSection_imagePreview__1WJ1J {
  padding: 2rem;
  display: inline-block;
  align-items: center;
  border: 1px dashed #000000b9;
}

.ImageOption_inputHeading__Owf1p {
  font-size: 1.6rem;
  color: grey;
}

.ImageOption_main__1BpYi {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid rgba(162, 162, 162, 0.32);
  padding-bottom: 2rem;
}
.PdfOption_inputHeading__AqTrU {
  font-size: 1.6rem;
  color: grey;
}

.PdfOption_main__xt6X2 {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid rgba(162, 162, 162, 0.32);
  padding-bottom: 2rem;
}

.PdfUploader_supportedFormat__2YU-t {
  font-size: 1.2rem;
  padding-top: 1rem;
  font-weight: 500;
}

.PdfUploader_fileInfo__2oY35{
    font-size: 1.4rem;
    list-style: none;
}


.EditPoints_trashIcon__3Y10X {
  height: 32px;
  width: 32px;
  cursor: pointer;
}
.EditPoints_trashIcon1__3xSRp {
  color: red;
}

.PointAddForm_mainContainer__2sjW2{
  padding: 2rem  0rem 0.6rem;
  margin-bottom: 2rem;
  display: flex;
  width: 100%;
  border-bottom: 1px solid rgba(162 162 162 0.32);
  justify-content: space-between;
}

.PointAddForm_inputHeading__Vjxim{
  font-size: 1.6rem;
  color: grey;
}

.PointAddForm_addOptionButton__DgdeF{
  margin-left: 1rem; 
  font-size: 1.8rem; 
  color: red; 
  background: none; 
  border: none;
  cursor: pointer;
}
.quote3 {
  justify-content: center;
  height: 10rem;
  background-color: var(--dark-grey);
  display: flex;
  align-items: center;
}

.landing_selectbox{
  width: 26rem!important;
  font-size: 1.6rem!important;
  padding: 1.4rem 1.4rem!important;
}

.quote__text3 {
  font-size: 2.8rem;
  font-weight: 800;
  color: var(--white);
}

.main_view_landing {
  padding: 3rem 6rem 24rem;
}

.icon_division {
  background-color: aqua;
  padding: 1.4rem 0rem;
  width: 30%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.icon_grid div:nth-child(2) {
  border-left: 0.2rem solid rgba(0, 0, 0, 0.138);
  border-right: 0.2rem solid rgba(0, 0, 0, 0.138);
}

.icon_grid {
  display: flex;
  position: relative;
  border-bottom: 0.3rem solid rgba(0, 0, 0, 0.138);
  justify-content: space-between;
  grid-gap: 2rem;
  gap: 2rem;
}

.icon {
  height: 8rem;
}

.icon_division span {
  display: block;
  font-size: 1.1rem;
  font-weight: 600;
  margin-top: 1.8rem;
}

.division_text {
  text-align: center;
  display: block;
}

.options_menu {
  display: flex;
  justify-content: space-between;
}

.edit_icon{
  display: block;
}

.option_section {
  margin-bottom: 5rem;
}

.option_section span {
  line-height: 2rem;
  font-weight: 800;
  margin-bottom: 1.2rem;
  font-size: 2.8rem;
  display: inline-block;
  border-bottom: 0.4rem solid rgba(0, 0, 0, 0.138);
}

.option_section a {
  display: block;
  font-size: 2rem;
}

.option_division {
  flex: 1 1;
  padding-right: 4rem;
}

.options_menu .option_division:nth-child(2) {
  padding: 0rem 2rem;
  border-left: 0.2rem solid rgba(0, 0, 0, 0.138);
  border-right: 0.2rem solid rgba(0, 0, 0, 0.138);
}

.video_image {
  height: 22rem;
  width: 43.2rem;
  text-align: center;
}

.second_options_menu {
  position: relative;
  padding-top: 32px;
  margin-top: 62px;
  border-top: 0.2rem solid rgba(0, 0, 0, 0.138);
}

.second_options_menu .option_division:nth-child(2) {
  border-left: none;
  border-right: none;
}

.vertical_options_menu {
  display: flex;
  flex-direction: column;
}
.vertical_grid{
  display: flex;
  
}
.grid_item{  
  flex: 1 1;
   display: flex;
   flex-direction: column;
}
.grid_item:nth-child(2) {  
  padding: 0rem 4rem;
  border-left: 0.1rem solid rgba(0, 0, 0, 0.138);
  border-right: 0.1rem solid rgba(0, 0, 0, 0.138);
}
.grid_item:nth-child(1) {
  padding: 0rem 2rem;
}
.grid_item:nth-child(3) {
  padding: 0rem 2rem;
}


@media only screen and (min-width: 700px) and (max-width: 1199px) {
  .video_image {
    height: 18rem;
    width: 31.2rem;
    text-align: center;
  }
  .main_view {
    padding: 3rem 6rem 24rem;
  }
  .option_section span {
    line-height: 3rem !important;
  }
}

@media only screen and (max-width: 669px) {
  .quote__text3 {
    font-size: 2.4rem;
  }

  .main_view {
    padding: 3rem 0rem 24rem;
  }

  .option_section span {
    font-size: 2rem !important;
  }

  .video_image {
    height: 10rem;
    width: 15rem;
    text-align: center;
  }

  .option_section {
    margin-bottom: 3rem;
  }
}

@media (min-width: 767px) {
  .main_view_landing {
    padding: 3rem 6rem 7rem;
  }
  .option_division {
    padding-right: 0rem;
  }
  .option_division:nth-child(2) {
    width: 35%;
  }
  .icon_grid div:nth-child(2) {
    width: 35%;
  }

}

@media (max-width: 767px) {
.icon_grid{
  display: block;
}
.icon_grid div:nth-child(2) {
  border-left: none;
  border-right: none;
}
.options_menu{
  display: inherit;
}
.option_division{
  width: 100%;
  padding-top: 4rem;
  border-bottom: 0.2rem solid rgba(0, 0, 0, 0.138);
  padding-right: 0px;
}

.options_menu .option_division:nth-child(2){
  padding: 0px;
  padding-top: 4rem;
  border-left: none;
  border-right: none;
}
.options_menu .option_division:nth-child(3){
  border-bottom: none;
}
.edit_icon{
  display: none;
}
.second_options_menu .option_division{
  border-bottom: none;
}
.video_image{
  height: 29rem;
  width: 56rem;
  margin-bottom: 2rem;
}
.main_view_landing {
  padding: 3rem 6rem 5rem;
}
}

.login_container__1GFse {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
}
.login_centerContainer__2LDvj {
  width: 60%;
  background-color: var(--white);
  border-radius: 0.5rem;
  box-shadow: 0 10px 16px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%) !important;
}
.login_topContainer__3t2cx {
  display: flex;
  padding: 1rem 0rem;
  flex-direction: row;
}
.login_leftContainer__89RMA {
  width: 50%;
  border-right: 0.2rem solid #80808033;
  height: 100%;
  padding: 2rem 3rem;
}

.login_customerLogin__3UdJ3 {
  padding-bottom: 18px;
}
.login_customerSignup__3YMwf {
  padding-bottom: 18px;
}

.login_rightContainer__11rYS {
  width: 50%;
  height: 100%;
  padding: 2rem 3rem;
}
.login_line__3Hekq {
  background-color: var(--light-grey);
  color: var(--light-grey);
  display: flex;
  margin-left: 1rem;
  margin-right: -1rem;
  flex-direction: column;
  width: 2px;
  height: 84%;
}
.login_input1__3Pygp {
  margin-top: 1rem;
}
.login_input2__2a-9v {
  margin-top: 3rem;
}
.login_buttons__1QpUe {
  display: flex;
  flex-direction: row;
  justify-content: CENTER;
  grid-gap: 2rem;
  gap: 2rem;
}
.login_buttons_vertical__1m9NI {
  display: flex;
  flex-direction: column;
  justify-content: CENTER;
  grid-row-gap: 1rem;
  row-gap: 1rem;
  margin-top: 2rem;
}
.login_header__1APgp {
  color: grey;
  font-size: 2rem;
  margin-bottom: 1rem;
}
.login_header1__rb43k {
  color: grey;
  font-size: 1.8rem;
  text-align: center;
  margin-bottom: 1rem;
}
.login_desc___W-ob {
  color: grey;
  font-size: 1.6rem;
}
.login_bottomContainer__3ExiY {
  /* height: 25%; */
  border-top: 1px solid var(--light-grey);
  margin: 0rem 3rem;
  padding: 2rem 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.login_phone__2UTUC {
  color: var(--red);
}
.login_header2__25gIU {
  color: grey;
  font-size: 1.8rem;
}
.login_header3__3GdQD {
  color: grey;
  font-size: 1.8rem;
}

.login_input3__3rmQJ {
  /* margin-top: 2rem; */
}

.login_labelHeading__lkRo6 {
  font-size: 1.8rem;
  color: grey;
}

.login_quickPay__kDAxE {
  margin-top: 2rem;
}

@media only screen and (max-width: 992px) {
  .login_centerContainer__2LDvj {
    width: 70%;
  }
  .login_input2__2a-9v .login_buttons__1QpUe {
    display: block;
  }
  .login_input2__2a-9v .login_buttons__1QpUe button {
    margin-bottom: 2rem;
  }
  .login_topContainer__3t2cx {
    height: 45%;
  }
  .login_labelHeading__lkRo6 {
    font-size: 2.2rem;
    color: grey;
  }
}

@media only screen and (max-width: 669px) {
  html {
    font-size: 34%;
  }
  .login_quickPay__kDAxE {
    margin-top: 4rem;
  }
  .login_line__3Hekq {
    display: none;
  }
  .login_centerContainer__2LDvj {
    width: 70%;
    height: 82%;
  }
  .login_desc___W-ob {
    font-size: 1.5rem;
  }
  .login_input2__2a-9v {
    margin-top: 4rem;
  }
  .login_input3__3rmQJ {
    margin-top: 5rem;
  }
  .login_labelHeading__lkRo6 {
    font-size: 2.2rem;
    color: grey;
  }
  .login_line__3Hekq {
    width: 90%;
    height: 2px;
  }
  .login_topContainer__3t2cx {
    flex-direction: column;
    height: 65%;
  }
  .login_leftContainer__89RMA {
    width: 100%;
    height: 100%;
    padding: 4rem 5rem 0rem;
    border-right: none;
  }
  .login_rightContainer__11rYS {
    width: 100%;
    height: 100%;
    padding: 6rem 5rem;
  }
  .login_bottomContainer__3ExiY {
    border-top: none;
    padding: 10rem 5rem;
  }
  .login_header1__rb43k {
    color: grey;
    font-size: 1.3rem;
    text-align: center;
    margin-bottom: 1rem;
    margin-top: 5rem;
  }

  .login_header__1APgp {
    text-align: center;
  }
  .login_header3__3GdQD {
    margin-top: 1rem;
  }
  .login_input2__2a-9v .login_buttons__1QpUe {
    display: flex;
  }
  .login_rightContainer__11rYS:nth-child(1) {
    margin-top: 4rem;
  }
}


.maintain_container{
	display: flex;
	justify-content: center;
	height: 100%;
}

.maintain_title{
	font-size: 1.6rem;
	width: 70rem;
	text-align: center;
	align-self: center;
}
.container {
  display: grid;
  grid-template-rows: 3rem 7rem 1fr auto;
  /* gap: 2rem; */
  position: relative;
  margin: 2rem;
  margin-top: -3rem;
}

.top__space {
  grid-row: 1 / 2;
  background-color: #808080;
}

.content {
  grid-row: 3 / 4;
  min-height: 56vh;
  /* min-height: 84vh; */
}

.flex {
  display: flex;
  grid-gap: 1rem;
  gap: 1rem;
  align-items: center;
}

.flex2 {
  display: flex;
  grid-gap: 1rem;
  gap: 1rem;
  align-items: center;
}

.page_content {
  padding: 0 15rem;
}

.page__heading {
  font-size: 1.5rem;
  font-weight: 600;
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;
}

.page__heading span {
  display: block;
  font-size: 1.2rem;
  color: var(--red);
}

.page__heading::after {
  content: "";
  height: 0.5rem;
  width: 3rem;
  background-color: var(--black);
}

.modal_custom_error {
  height: 2rem;
  color: red;
  font-size: 1.3rem !important;
}

/* .paginationTop {
	margin-bottom: '-4rem';
}

.paginationBttns {
	margin-top: 2rem;
	width: 100%;
	height: 0rem;
	list-style: none;
	justify-content: end;
	font-family: Poppins;
	font-style: normal;
	font-weight: 500;
	font-size: 1rem;
	line-height: 6rem;
	flex-direction: row;
	display: flex;
	flex-wrap: wrap;
}

.paginationBttns a {
	padding: 1.3rem 2rem;
	margin: 8px;
	background-color: #464646;
	color: white;
	cursor: pointer;
	border-radius: 40px !important;
	box-shadow: 0px 4px 15px 5px rgb(0 0 0 / 16%);
	font-size: 1.5rem;
}

.paginationBttns a:hover {
	color: white;
	background-color: #e02b21;
}

.paginationActive a {
	color: white;
	background-color: #3f3f3f;
	width: 43px;
	height: 43px;
	left: 1181px;
	top: 933px;
	background: #e02b21;
	border-radius: 5px;
}

.previousBttn,
.nextBttn {
	background: #000000 !important;
}

.paginationDisabled a {
	color: grey;
	background-color: #000000;
} */

.searchListContainer::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
}

.searchListContainer::-webkit-scrollbar {
  width: 12px;
  background-color: #f5f5f5;
}

.searchListContainer::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #000000;
}

/* Toggle Button Start */

.switch {
  position: relative;
  display: inline-block;
  min-width: 5rem;
  max-width: 5rem;
  height: 3.05rem;
}

.switch-slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #c4c4c4;
  box-shadow: 0.5rem 0.2rem 1rem 2px rgb(0 0 0 / 14%);
  transition: 0.4s;
  border-radius: 20px;
}

.switch-slider:before {
  position: absolute;
  content: "";
  height: 2.3rem;
  width: 2.3rem;
  left: 4px;
  bottom: 4px;
  background-color: #676767;
  transition: 0.4s;
  border-radius: 10px;
}

.checkbox {
  opacity: 0;
}

.checkbox:checked + .switch-slider {
  background-color: #c4c4c4;
}

.checkbox:focus + .switch-slider {
  box-shadow: 0 0 1px #e02b21;
}

.checkbox:checked + .switch-slider:before {
  -webkit-transform: translateX(1.8rem);
  transform: translateX(1.8rem);
  background-color: var(--red);
}

.switch-slider .round {
  border-radius: 3.4rem;
}

.switch-slider .round:before {
  border-radius: 50%;
}

@media only screen and (max-width: 37.5em) {
  .top__space {
    display: none;
  }

  .container {
    margin: 2rem;
    max-width: 100vw;
    overflow: hidden;
    display: block;
    grid-template-rows: 5rem 1fr auto;
  }

  .content {
    grid-row: 2 / 3;
  }
}

.css-yk16xz-control {
  height: 4.5rem;
  border: 0px solid;
  margin-left: 8rem;
  border-radius: 4px;
  border-style: solid;
  border-width: 0px !important;
}

.css-2b097c-container {
  position: relative;
  box-sizing: border-box;
  border-radius: 0.5rem;
  border-top: 2px solid;
  border-left: 2px solid;
  border-right: 2px solid #707070;
  border-bottom: 2px solid #707070;
  border-radius: 0.5rem;
}

.css-1hb7zxy-IndicatorsContainer {
  display: none !important;
}

.css-1pahdxg-control {
  margin-left: 8rem;
  height: 4.5rem !important;
  border: none !important;
  box-shadow: none !important;
}

.css-1pahdxg-control:hover {
  border: none !important;
  box-shadow: none !important;
}
.css-1wa3eu0-placeholder {
  font-weight: 400;
  font-size: 1.2rem;
  text-transform: uppercase !important;
}
.css-1uccc91-singleValue {
  max-width: calc(80% - 8px) !important;
}

.react-tel-input .form-control {
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
  border: 1px solid rgb(163, 159, 159) !important;
  background-color: rgb(238, 234, 228) !important;
  width: 100% !important;
  height: 5rem !important;
  outline: none !important;
  padding: 0 1rem !important;
  border-radius: 0 !important;
  font-size: 14px !important;
  padding-left: 5rem !important;
}

.text-input-prefix__formgroup--input {
  background-color: transparent;
  border: none;
  outline: none;
  font-family: inherit;
  width: 100%;
  height: 5vh;
  padding-left: 0.5rem;
}
.textBox {
  position: relative;
}
.text-input-prefix__formgroup--inputBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid rgb(163, 159, 159);
  background-color: rgb(238, 234, 228);
  /* box-shadow: var(--box-shadow-input); */
  /* overflow: hidden; */
  width: 100%;
  height: 5rem;
  outline: none;
  padding: 0 1rem;
}
.text-input-prefix__formgroup--inputBox--date {
  /* display: none; */
  opacity: 0;
}
.text-input-prefix__formgroup--dateBox {
  position: relative;
}
.text-input-prefix__formgroup--dateBox label {
  position: absolute;
  top: 0;
  left: 0;
  border: 1px solid rgb(163, 159, 159);
  background-color: rgb(238, 234, 228);
  width: 100%;
  outline: none;
  padding: 0 1rem;
  /* padding-top: 1rem; */
  font-size: 1.2rem;

  display: flex;
  align-items: center;
  justify-content: space-between;
}
.text-input-prefix__formgroup--dateBox label svg {
  height: 1.5rem;
  width: 1.5rem;
  fill: var(--black);
}

.edit-feature {
  width: 100%;
  position: relative;
}
.search_blog {
  padding: 5px 2rem;
  width: 20vw;
  outline: none;
  border-left: none;
  border-right: none;
  border-top: none;
  border-bottom: rgb(254, 208, 208) solid 1px;
}
.date-icon-over:hover {
  cursor: pointer;
}
.icon-bar {
  position: fixed;
  top: 20%;
  right: 1%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.icon-bar a {
  display: block;
  text-align: center;
  padding-right: 10px;
  padding-bottom: 2px;
  transition: all 0.3s ease;
  color: black;
  font-size: 20px;
}
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="8px"]::before {
  content: "8";
  font-size: 8px !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="10px"]::before {
  content: "10";
  font-size: 10px !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="12px"]::before {
  content: "12";
  font-size: 12px !important;
}
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="14px"]::before {
  content: "14";
  font-size: 14px !important;
}
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="16px"]::before {
  content: "16";
  font-size: 16px !important;
}
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="18px"]::before {
  content: "18";
  font-size: 18px !important;
}
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="20px"]::before {
  content: "20";
  font-size: 20px !important;
}
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="22px"]::before {
  content: "22";
  font-size: 22px !important;
}
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="24px"]::before {
  content: "24";
  font-size: 24px !important;
}
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="30px"]::before {
  content: "30";
  font-size: 30px !important;
}
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="35px"]::before {
  content: "35";
  font-size: 35px !important;
}
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="40px"]::before {
  content: "40";
  font-size: 40px !important;
}
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="45px"]::before {
  content: "45";
  font-size: 45px !important;
}
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="50px"]::before {
  content: "50";
  font-size: 50px !important;
}
.search_blog_container {
  display: flex;
  justify-content: center;
  padding: 2rem 2rem;
}
.search_blog {
  padding: 5px 2rem;
  width: 20vw;
  outline: none;
  border-left: none;
  border-right: none;
  border-top: none;
  border-bottom: rgb(254, 208, 208) solid 1px;
}

.quote-main-box {
	padding: 3rem 33rem;
	background-color: var(--light-grey2);
}
.my-quote{
	margin: 2rem 22rem;
}
.quote_heading {
	font-size: 2rem;
	font-weight: 700;
}
.my-quote-orientation{
	text-align: center;
}
.red_star {
	color: var(--red);
	font-weight: 200;
}

.quote_subheading {
	font-size: 1.8rem;
	font-weight: 300;
}

.main_outer_component {
	padding: 2rem 55rem;
}

.Select_agent_statewise {
	display: flex;
	grid-gap: 2rem;
	gap: 2rem;
}

.pre_heading_agentwise {
	/* margin-top: -2rem; */
}

.select_state {
	display: block;
}

.label_heading {
	font-weight: 900;
	font-size: 2rem;
	color: grey;
	margin-top: 1.8rem;
}

.label_heading_quote {
	font-weight: 600;
	font-size: 1.6rem;
	color: rgb(158, 158, 158);
	margin-top: 1.8rem;
}

.select__state__textbox {
	width: 20rem;
	-webkit-box-decoration-break: none;
	        box-decoration-break: none;
	min-height: 4rem;
	margin-bottom: 4rem;
	font-size: 1.4rem;
	background-color: var(--light-grey2);
	border: 1px solid rgb(163, 159, 159);
	outline: none;
}

.select__state__textbox1 {
	width: 210%;
	-webkit-box-decoration-break: none;
	        box-decoration-break: none;
	min-height: 4rem;
	margin-bottom: 4rem;
	font-size: 1.4rem;
	background-color: var(--light-grey2);
	border: 1px solid rgb(163, 159, 159);
	outline: none;
}

.dash_heading_2 {
	font-size: 2.5rem;
	font-weight: 300;
	margin-bottom: 1rem;
}

.form_textbox {
	display: flex;
	grid-gap: 3rem;
	gap: 3rem;
}

.label_heading1 {
	display: flex;
	grid-gap: 33.7rem;
	gap: 33.7rem;
	flex-wrap: text;
	margin-top: 2rem;
}

.label_subheading {
	font-weight: 900;
	font-size: 2rem;
	color: grey;
}

.label_heading2 {
	display: flex;
	grid-gap: 38.8rem;
	gap: 38.8rem;
	color: grey;
	font-size: 1.2rem;
	margin-top: .2rem;
}

.label_heading3 {
	margin-top: .3rem;
	display: flex;
	color: grey;
	margin-bottom: .3rem;
	font-size: 1.2rem;
}

.horizontal_line {
	width: 100%;
	height: 2px;
	color: grey;
	background-color: grey;
	display: flex;
}

.Insurance__list {
	display: flex;
	flex-direction: column;
}

.list__of__contents {
	display: flex;
	flex-direction: row;
	grid-gap: 4rem;
	gap: 4rem;
}

.list__of__contents1 {
	grid-gap: 10rem;
	gap: 10rem;
	padding: 1rem;
	display: inline-flex;
	border: 1px solid #d5d5d5 !important;
	margin: 1rem;
	border-radius: 4rem;
	cursor: pointer;
}


.list__of__contents1_active {
	border: 1px solid #d5d5d5 !important;
	background: #000;
	color: #fff;
	cursor: pointer;
}

.list__of__contents1:hover {
	color: var(--red);
}

.list__of__contents2 li {
	grid-gap: 10rem;
	gap: 10rem;
	padding: 1rem;
	display: inline-flex;
}

.form_textarea {
	background-color: rgb(238, 234, 228);
	height: 6rem;

	border: none;
	outline: none;
	border: 1px solid rgb(163, 159, 159);
}

.main__form_button {
	width: 10vw;
	height: 4vh;
	font-size: 1.5rem;
	margin-bottom: 4rem;
	margin-top: 2rem;
}

.pre_heading {
	display: flex;
	flex-wrap: wrap;
	grid-gap: 3rem;
	gap: 3rem;
	align-items: flex-end;
	padding-bottom: 2rem;
}

.upload_border {
	border: 1px dotted rgb(78, 78, 78);
	display: flex;
	flex-direction: column;
	height: 10rem;
	text-align: center;
	justify-content: center;
}


.upload-btn-wrapper {
	position: relative;
	overflow: hidden;
	display: inline-block;
	width: 48%;
}

.upload-image {
	height: 20rem;
}

.upload-btn-wrapper input[type=date]::-webkit-calendar-picker-indicator {
	font-size: 200px;
	position: absolute;
	left: 0;
	top: 0;
	opacity: 0;
}

@media only screen and (min-width: 1500px) {
	.quote-main-box {
		padding: 3rem 33rem;
	}

	.quote_heading {
		display: flex;
	}

	.quote_subheading {
		display: flex;
	}

	.main_outer_component {
		padding: 3rem 55rem;
		grid-gap: .5rem;
		gap: .5rem;
	}
}

@media only screen and (max-width: 1400px) {
	.quote-main-box {
		padding: 3rem 33rem;
	}

	.quote_heading {
		display: flex;
	}

	.quote_subheading {
		display: flex;
	}

	.main_outer_component {
		padding: 2rem 33rem;
		grid-gap: .5rem;
		gap: .5rem;
	}

	.pre_heading {
		grid-gap: 2.5rem;
		gap: 2.5rem;
		align-items: flex-end;
		padding-bottom: 2rem;
		width: 100%;
	}

	.select__state__textbox1 {
		width: 100%;
	}
}

@media only screen and (max-width: 900px) {
	.quote-main-box {
		padding: 2rem 10rem;
	}

	.quote_heading {
		display: flex;
		text-align: center;
	}

	.quote_subheading {
		display: flex;
		text-align: center;
	}

	.main_outer_component {
		padding: 2rem 1rem;
	}

	.label_heading2 {
		display: flex;
		flex-wrap: wrap;
	}

	.form_textbox {
		display: flex;
	}

	.pre_heading {
		grid-gap: 3.3rem;
		gap: 3.3rem;
		align-items: flex-end;
		padding-bottom: 2rem;
		width: 100%;
	}

	.main_outer_component {
		padding: 2rem 25rem;
		grid-gap: .5rem;
		gap: .5rem;
	}

	.select__state__textbox1 {
		width: 100%;
	}
}

@media only screen and (max-width: 760px) {
	.quote-main-box {
		padding: 2rem 2rem;
	}

	.quote_heading {
		display: flex;
		text-align: center;
	}

	.quote_subheading {
		display: flex;
		text-align: center;
	}

	.main_outer_component {
		padding: 2rem 1rem;
	}

	.label_heading2 {
		display: flex;
		flex-wrap: wrap;
	}

	.form_textbox {
		display: flex;
	}

	.pre_heading {
		grid-gap: 1.5rem;
		gap: 1.5rem;
		align-items: flex-end;
		padding-bottom: 2rem;
		width: 100%;
	}

	.select__state__textbox1 {
		width: 100%;
	}
}
.text-input-prefix__formgroup--input {
  background-color: transparent;
  border: none;
  outline: none;
  font-family: inherit;
  width: 100%;
  height: 5vh;
  padding-left: 0.5rem;
}

.textBox {
  position: relative;
}

.standard-input {
  border: 1px solid rgb(207, 207, 207) !important;
  background-color: rgb(247, 246, 255) !important;
  border-radius: 2px !important;
}

.text-input-prefix__formgroup--inputBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid rgb(207, 207, 207);
  background-color: rgb(247, 246, 255);
  border-radius: 2px;
  /* box-shadow: var(--box-shadow-input); */
  /* overflow: hidden; */
  width: 100%;
  height: 5rem;
  outline: none;
  padding: 0 1rem;
}

.text-input-prefix__formgroup--inputBox--date {
  /* display: none; */
  opacity: 0;
}
.text-input-prefix__formgroup--dateBox {
  position: relative;
}
.text-input-prefix__formgroup--dateBox label {
  position: absolute;
  top: 0;
  left: 0;
  border: 1px solid rgb(163, 159, 159);
  background-color: rgb(238, 234, 228);
  width: 100%;
  outline: none;
  padding: 0 1rem;
  /* padding-top: 1rem; */
  font-size: 1.2rem;

  display: flex;
  align-items: center;
  justify-content: space-between;
}
.text-input-prefix__formgroup--dateBox label svg {
  height: 1.5rem;
  width: 1.5rem;
  fill: var(--black);
}

/* 
.text-input-prefix--iconBox {
	padding: 2rem;
	border-radius: 3rem;
	box-shadow: var(--box-shadow-input);
	height: 6.5rem;
	width: 6.5rem;
	color: white;
	background-color: rgb(255, 255, 255);
	border: none;
	outline: none;
	position: absolute;
	z-index: 999;
	cursor: pointer;
	transform: translateX(2px);
	transition: 0.3s;
	display: flex;
	align-items: center;
	text-align: center;
} */

/* 
.text-input-prefix_postfix--iconBox {
	padding: 1.5rem 2rem;
	display: flex;
	align-items: center;
	border-radius: 3.5rem;
}

.text-input-prefix__formgroup--icon {
	height: 2rem;
	width: 2rem;
	fill: var(--white);
}

.text-input-prefix__formgroup_postfix--icon {
	height: 2rem;
	width: 2rem;
	fill: var(--white);
} */

/* .textBox__label {
	position: absolute;
	top: -.5rem;
	left: 0;
	z-index: 10;
	background-color: var(--bg-primary);
	box-shadow: var(--box-shadow1);
	border-radius: 50%;
	padding: 1.3rem;
	display: flex;
}

.textBox__label--icon {
	height: 2.5rem;
	width: 2.5rem;
	fill: var(--black);
}

.textBox__input {
	background-color: var(--bg-primary);
	box-shadow: var(--box-shadow1);
	border-radius: 2rem;
	overflow: hidden;
	width: 100%;
	display: flex;
}

.textBox__input--box {
	width: 100%;
	margin-left: 5rem;
	background-color: var(--bg-primary);
	font-family: inherit;
	border: none;
	outline: none;
	padding: 1rem 1rem;
}

.textBox__input--show {
	margin-right: 1rem;
	border: none;
	background-color: transparent;
	cursor: pointer;
}

.textBox__input--show:hover .textBox__input--showIcon {
	fill: var(--red);
}

.textBox__input--showIcon {
	height: 2rem;
	width: 2rem;
	fill: var(--dark-grey);
	transition: all .4s;
} */

.faqs {
	margin: 2rem 0;
	display: flex;
	flex-direction: column;
	align-items: center;
	display: flex;
	flex-direction: column;
	grid-gap: 2rem;
	gap: 2rem;
	padding: 3rem 40rem;
}
.faq {
	background-color: var(--light-grey2);
	/* box-shadow: var(--box-shadow1); */
	border-radius: 1rem;
	padding: 3rem 2rem;
	overflow: hidden;
	transition: all .4s;
	width: 55vw;
	display: flex;
	grid-gap: 1rem;
	gap: 1rem;
	align-items: center;
	position: relative;
}
.faq__number {
	font-size: 4rem;
	font-weight: 600;
	color: var(--dark-grey);
	padding: 4.5rem 2rem;
	background-color: var(--light-grey);
	/* width: 9%; */
	text-align: center;
}
.accordion {
	color: var(--dark-grey);
	cursor: pointer;
	font-size: 1.7rem;
	font-weight: 600;
	transition: all .4s;

	/* margin-top: -5rem; */
}
.accordion::after {
	content: '+';
	font-size: 2.5rem;
	position: absolute;
	top: .5rem;
	right: .5rem;
}
.accordion.active::after {
	content: '-';
	font-size: 2.5rem;
	top: .5rem;
	right: .5rem;
}

.accordion_icon {
	height: 2.5rem;
	width: 2.5rem;
	color: var(--light-grey);
	position: absolute;
	top: -2rem;
	right: .5rem;
}
.accordion_heading {
	color: var(--dark-grey);
	font-size: 1.7rem;
	font-weight: 600;
	transition: all .4s;
}
.active {
	/* margin-top: 2rem; */
}
p.accordion:hover {
	color: var(--red);
}
/* .faq:hover {
	color: red;
} */
.faq_side {
	display: flex;
	flex-direction: row-reverse;
	justify-content: space-around;
}
.FAQ_button {
	background-color: var(--dark-grey);
	padding: 1rem 2rem;
	border: none;
	outline: none;
	color: var(--pure-white);
	text-transform: uppercase;
	font-size: 1.3rem;
	font-weight: 700;
	border-radius: 5px;
	cursor: pointer;
	text-decoration: none;
	/* margin-right: 2rem; */
}

/* Unicode character for "plus" sign (+) */
/* p.accordion:after {
	content: '\2795';
	font-size: 13px;
	color: #777;
	float: right;
	margin-left: 5px;
} */

/* Unicode character for "minus" sign (-) */
/* p.accordion.active:after {
	content: "\2796";
} */

/* Style the element that is used for the panel class */

.panel {
	font-size: 1.3rem;
	font-weight: 500;
	max-height: 0;
	/* overflow: hidden; */
	transition: 0.4s ease-in-out;
	opacity: 0;
	display: flex;
	flex-direction: column;
	grid-gap: 1rem;
	gap: 1rem;
	align-items: flex-start;
}

.panel.show {
	opacity: 1;
	max-height: 500px;
	/* padding: 1.5rem 0; */
}
.link-to-claims {
	font-size: 1.6rem;
	text-decoration: none;
}
@media only screen and (max-width: 1400px) {
	.faqs {
		padding: 3rem 10rem;
	}
}

@media only screen and (max-width: 780px) {
	.faqs {
		padding: 3rem 1rem;
	}
	.faq {
		width: 100%;
	}
	.faq__number {
		padding: 7rem 2rem;
	}
}

.faqs {
	margin: 2rem 0;
	display: flex;
	flex-direction: column;
	align-items: center;
	display: flex;
	flex-direction: column;
	grid-gap: 2rem;
	gap: 2rem;
	padding: 3rem 40rem;
}
.faq {
	background-color: var(--light-grey2);
	/* box-shadow: var(--box-shadow1); */
	border-radius: 1rem;
	padding: 3rem 2rem;
	overflow: hidden;
	transition: all .4s;
	width: 55vw;
	display: flex;
	grid-gap: 1rem;
	gap: 1rem;
	align-items: center;
	position: relative;
}
.faq__number {
	font-size: 4rem;
	font-weight: 600;
	color: var(--dark-grey);
	padding: 4.5rem 2rem;
	background-color: var(--light-grey);
	/* width: 9%; */
	text-align: center;
}
.accordion {
	color: var(--dark-grey);
	cursor: pointer;
	font-size: 1.7rem;
	font-weight: 600;
	transition: all .4s;

	/* margin-top: -5rem; */
}
.accordion::after {
	content: '+';
	font-size: 2.5rem;
	position: absolute;
	top: .5rem;
	right: .5rem;
}
.accordion.active::after {
	content: '-';
	font-size: 2.5rem;
	top: .5rem;
	right: .5rem;
}

.accordion_icon {
	height: 2.5rem;
	width: 2.5rem;
	color: var(--light-grey);
	position: absolute;
	top: -2rem;
	right: .5rem;
}
.accordion_heading {
	color: var(--dark-grey);
	font-size: 1.7rem;
	font-weight: 600;
	transition: all .4s;
}
.active {
	/* margin-top: 2rem; */
}
p.accordion:hover {
	color: var(--red);
}
/* .faq:hover {
	color: red;
} */
.faq_side {
	display: flex;
	flex-direction: row-reverse;
	justify-content: space-around;
}
.FAQ_button {
	background-color: var(--dark-grey);
	padding: 1rem 2rem;
	border: none;
	outline: none;
	color: var(--pure-white);
	text-transform: uppercase;
	font-size: 1.3rem;
	font-weight: 700;
	border-radius: 5px;
	cursor: pointer;
	text-decoration: none;
	/* margin-right: 2rem; */
}

/* Unicode character for "plus" sign (+) */
/* p.accordion:after {
	content: '\2795';
	font-size: 13px;
	color: #777;
	float: right;
	margin-left: 5px;
} */

/* Unicode character for "minus" sign (-) */
/* p.accordion.active:after {
	content: "\2796";
} */

/* Style the element that is used for the panel class */

.panel {
	font-size: 1.3rem;
	font-weight: 500;
	max-height: 0;
	/* overflow: hidden; */
	transition: 0.4s ease-in-out;
	opacity: 0;
	display: flex;
	flex-direction: column;
	grid-gap: 1rem;
	gap: 1rem;
	align-items: flex-start;
}

.panel.show {
	opacity: 1;
	max-height: 500px;
	/* padding: 1.5rem 0; */
}
.link-to-claims {
	font-size: 1.6rem;
	text-decoration: none;
}
@media only screen and (max-width: 1400px) {
	.faqs {
		padding: 3rem 10rem;
	}
}

@media only screen and (max-width: 780px) {
	.faqs {
		padding: 3rem 1rem;
	}
	.faq {
		width: 100%;
	}
	.faq__number {
		padding: 7rem 2rem;
	}
}

.faqs {
	margin: 2rem 0;
	display: flex;
	flex-direction: column;
	align-items: center;
	display: flex;
	flex-direction: column;
	grid-gap: 2rem;
	gap: 2rem;
	padding: 3rem 40rem;
}

.faq {
	background-color: var(--light-grey2);
	/* box-shadow: var(--box-shadow1); */
	border-radius: 1rem;
	padding: 3rem 2rem;
	overflow: hidden;
	transition: all .4s;
	width: 55vw;
	display: flex;
	grid-gap: 1rem;
	gap: 1rem;
	align-items: center;
	position: relative;
}

.faq__number {
	font-size: 4rem;
	font-weight: 600;
	color: var(--dark-grey);
	padding: 4.5rem 2rem;
	background-color: var(--light-grey);
	/* width: 9%; */
	text-align: center;
}

.accordion {
	color: var(--dark-grey);
	cursor: pointer;
	font-size: 1.7rem;
	font-weight: 600;
	transition: all .4s;

	/* margin-top: -5rem; */
}

.accordion::after {
	content: '+';
	font-size: 2.5rem;
	position: absolute;
	top: .5rem;
	right: .5rem;
}

.accordion.active::after {
	content: '-';
	font-size: 2.5rem;
	top: .5rem;
	right: .5rem;
}

.accordion_icon {
	height: 2.5rem;
	width: 2.5rem;
	color: var(--light-grey);
	position: absolute;
	top: -2rem;
	right: .5rem;
}

.accordion_heading {
	color: var(--dark-grey);
	font-size: 1.7rem;
	font-weight: 600;
	transition: all .4s;
}

.active {
	/* margin-top: 2rem; */
}

p.accordion:hover {
	color: var(--red);
}

/* .faq:hover {
	color: red;
} */
.faq_side {
	display: flex;
	flex-direction: row-reverse;
	justify-content: space-around;
}

.faq_sub_title {
	font-weight: bold;
}

.hr_height {
	height: 4px;
	width: 100rem;
	fill: black;
	background-color: black;
}

.dis_table {
	width: 100%;
	border-collapse: collapse;
	border: 1px solid black;
}

.dis_table>tr {
	width: 100%;
}

.dis_table>tr>.title {
	font-weight: bold;
	width: 25rem;
	padding: 1rem;
}

.dis_table>tr>.desc {
	font-weight: normal;
	width: 75rem;
	padding: 1rem;
}

.FAQ_button {
	background-color: var(--dark-grey);
	padding: 1rem 2rem;
	border: none;
	outline: none;
	color: var(--pure-white);
	text-transform: uppercase;
	font-size: 1.3rem;
	font-weight: 700;
	border-radius: 5px;
	cursor: pointer;
	text-decoration: none;
	/* margin-right: 2rem; */
}

/* Unicode character for "plus" sign (+) */
/* p.accordion:after {
	content: '\2795';
	font-size: 13px;
	color: #777;
	float: right;
	margin-left: 5px;
} */

/* Unicode character for "minus" sign (-) */
/* p.accordion.active:after {
	content: "\2796";
} */

/* Style the element that is used for the panel class */

.panel {
	font-size: 1.3rem;
	font-weight: 500;
	max-height: 0;
	/* overflow: hidden; */
	transition: 0.4s ease-in-out;
	opacity: 0;
	display: flex;
	flex-direction: column;
	grid-gap: 1rem;
	gap: 1rem;
	align-items: flex-start;
}


.panel.show {
	opacity: 1;
	max-height: 400rem;
	/* padding: 1.5rem 0; */
}

.link-to-claims {
	font-size: 1.6rem;
	text-decoration: none;
}

@media only screen and (max-width: 1400px) {
	.faqs {
		padding: 3rem 10rem;
	}
}


@media (max-width: 600px) {

	.dis_table {
		width: 90%;
		border-collapse: collapse;
		border: 1px solid black;
	}

	.hr_height {
		height: 4px;
		width: 95%;
		fill: black;
		background-color: black;
	}
}

@media (min-width: 600px) {
	.dis_table {
		width: 95%;
		border-collapse: collapse;
		border: 1px solid black;
	}

	.hr_height {
		height: 4px;
		width: 95%;
		fill: black;
		background-color: black;
	}

}

@media (min-width: 2950px) {
	.dis_table {
		width: 100%;
		border-collapse: collapse;
		border: 1px solid black;
	}

	.hr_height {
		height: 4px;
		width: 98%;
		fill: black;
		background-color: black;
	}
}

@media only screen and (max-width: 780px) {
	.faqs {
		padding: 3rem 1rem;
	}

	.faq {
		width: 100%;
	}

	.faq__number {
		padding: 7rem 2rem;
	}
}
.blogSettings_container__alQnG{
  padding: 2rem 15rem;
}

.blogSettings_formTitle__3mCvG{
  font-weight: 600;
  font-size: 1.4rem;
}

.blogSettings_inputBox__2LDpu .blogSettings_inputTitle__16z4l{
  padding-bottom: 1rem;
  display: block;
  color: #2E2E2E;
  font-size: 1.4rem;
}

.blogSettings_inputBox__2LDpu{
  padding-bottom: 2rem;
}

.blogSettings_tooltip__hjc3q {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.blogSettings_tooltip__hjc3q .blogSettings_tooltiptext__1woA- {
  visibility: hidden;
  width: 120px;
  background-color: #D9D9D9;
  color: #000000;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: 125%; /* Position above the element */
  left: 50%;
  margin-left: -60px; /* Center the tooltip */
  opacity: 0;
  transition: opacity 0.3s;
}

.blogSettings_tooltip__hjc3q:hover .blogSettings_tooltiptext__1woA- {
  visibility: visible;
  opacity: 1;
}

.blogSettings_tooltip__hjc3q .blogSettings_tooltiptext1__3XUrw {
  visibility: hidden;
  width: 120px;
  background-color: #D9D9D9;
  color: #000000;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: -65%; /* Position above the element */
  left: 0;
  margin-left: -85px; /* Center the tooltip */
  opacity: 0;
  transition: opacity 0.3s;
}

.blogSettings_tooltip__hjc3q:hover .blogSettings_tooltiptext1__3XUrw {
  visibility: visible;
  opacity: 1;
}

.blogSettings_inputBox__2LDpu:nth-child(2){
  padding-bottom: 0rem;
}

.blogSettings_searchBox__1DMI4 .blogSettings_inputTitle__16z4l{
  padding-bottom: 1rem;
  display: block;
  color: #2E2E2E;
  font-size: 1.2rem;
}

.blogSettings_searchBox__1DMI4{
  margin-top: 1.2rem;
  display: flex;
  border-bottom: 1px solid #00000027;
}

.blogSettings_checkbox__1lEvV {
  opacity: 0;
}

.blogSettings_checkbox__1lEvV:checked + .blogSettings_slider__3iQeP {
  background-color: rgb(255, 255, 255);
}

.blogSettings_slider__3iQeP {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  border-radius: 32%;
  right: 0;
  bottom: 0;
  background-color:white;
  box-shadow: var(--box-shadow-input);
  transition: 0.4s;
}

.blogSettings_slider__3iQeP:before {
  position: absolute;
  content: '';
  height: 1.8rem;
  width: 1.82rem;
  left: 0px;
  bottom: 0.8px;
  background-color:#bcb5b5bf;
  transition: 0.4s;
  border-radius: 50%;
}

.blogSettings_publish_icon_button__lXT2R{
  text-decoration: none;
  cursor: pointer;
  background: none;
  border: none;
}

.blogSettings_checkbox__1lEvV:checked + .blogSettings_slider__3iQeP:before {
  background-color: var(--red);
  -webkit-transform: translateX(1.3rem);
  transform: translateX(1.3rem);
  left: 9px;
}


.blogSettings_switch__1lr4v {
  position: relative;
  display: inline-block;
  z-index: 0;
  box-shadow: 11px 21px 42px -6px #57575826;
  min-width: 4rem;
  height: 2rem;
}

.blogSettings_searchIcon__2vkUc{
  height: 6rem;
  width: 6rem;
  padding-top: 1.2rem;
  margin-bottom: -1.4rem;
}

.blogSettings_mainForm__3Z6qA .blogSettings_formTitle__3mCvG{
  padding-bottom: 2rem;
  display: block;
}

.blogSettings_subform__3vaWb{
  display: flex;
  align-items: end;
  grid-gap: 4rem;
  gap: 4rem;
}

.blogSettings_searchSubform__2Ge-i{
  display: flex;
  align-items: end;
  justify-content: space-between;
  grid-gap: 4rem;
  gap: 4rem;
}

.blogSettings_input__1rBoo{
  border-top: none;
  outline: none;
  width: 40rem;
  border-left: none;
  padding-bottom: 1rem;
  border-right: none;
  border-bottom: 1px solid #00000027;
}

.blogSettings_input__1rBoo::-webkit-input-placeholder{
  font-size: 1.3rem;
}

.blogSettings_input__1rBoo::placeholder{
  font-size: 1.3rem;
}


.blogSettings_buttonContainer__37zYQ{
  display: flex;
  grid-gap: 1rem;
  gap: 1rem;
}

.blogSettings_buttonContainer__37zYQ button{
  text-decoration: none;
  color: var(--white);
  text-transform: uppercase;
  background-color: var(--red);
  border: 1px solid var(--red);
  padding: 0.6rem 2rem;
  font-family: 'Medium';
  font-size: 1rem;
  border-radius: 0.5rem;
  cursor: pointer;
  transition: all 0.4s;
}

.blogSettings_buttonContainer__37zYQ button:hover {
  background-color: transparent;
  border: 1px solid var(--red);
  color: var(--red);
}

.blogSettings_searchContainer__3RZem{
  margin-top: 8rem;
}

@media only screen and (max-width: 1450px) {
  .blogSettings_container__alQnG{
    padding: 2rem 10rem!important;
  }
}
.quote3 {
  justify-content: center;
  height: 10rem;
  background-color: var(--dark-grey);
  display: flex;
  align-items: center;
}

.quote__text3 {
  font-size: 2.8rem;
  font-weight: 800;
  color: var(--white);
}

.main_view {
  padding: 3rem 14rem;
}

.icon_division {
  padding: 1.4rem 0rem;
  width: 30%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.icon_grid div:nth-child(2) {
  border-left: 0.1rem solid rgba(0, 0, 0, 0.138);
  border-right: 0.1rem solid rgba(0, 0, 0, 0.138)
}

.icon_grid {
  display: flex;
  padding-bottom: 1.8rem;
  border-bottom: 0.1rem solid rgba(0, 0, 0, 0.138);
  justify-content: center;
  grid-gap: 0.2rem;
  gap: 0.2rem;
}

.icon {
  height: 8rem;
}

.icon_division span {
  display: block;
  font-size: 1.1rem;
  font-weight: 600;
  margin-top: 1.8rem;
}

.division_text {
  text-align: center;
  display: block;
}

.options_menu {
  display: flex;
  justify-content: space-between;
  padding: 3rem 0rem;
}

.option_section {
  margin-bottom: 5rem;
}

.option_section span {
  line-height: 2rem;
  font-weight: 800;
  margin-bottom: 1.2rem;
  font-size: 2.8rem;
  display: inline-block;
  border-bottom: 0.4rem solid rgb(0, 0, 0);
}

.option_section a {
  display: block;
  font-size: 2rem;
}

.option_division {
  width: 30%;
  padding-right: 4rem;
}

.options_menu .option_division:nth-child(2) {
  padding: 0rem 2rem;
  border-left: 0.2rem solid rgb(0, 0, 0);
  border-right: 0.2rem solid rgb(0, 0, 0);
}

.video_image {
  height: 22rem;
  width: 43.2rem;
  text-align: center;
}

.option_title {
  font-size: 1.8rem !important;
  line-height: 0.8rem !important;
  border-bottom: 0.3rem solid rgb(0, 0, 0) !important;
}

.secondary_section {
  text-align: center;
}

.secondary_section > a {
  display: flex;
  justify-content: center;
  grid-gap: 1rem;
  gap: 1rem;
}

.secondary_menu {
  border-top: 0.3rem solid rgb(0, 0, 0);
}

.customer_section {
  margin-bottom: 1rem !important;
}

.agent_call {
  text-align: center;
  padding-bottom: 2.4rem;
}

.form_image {
  margin-top: 1.4rem;
  text-align: center;
}

.document_archive {
  text-align: center;
  margin-bottom: 4rem;
}

.document_archive p {
  color: #313944;
  font-weight: 800;
  font-size: 3rem !important;
}

.document_archive input{
  padding: 2rem 11rem;
  border: 0.3rem solid rgba(0, 0, 0, 0.407);
}

.document_archive strong {
  display: block;
  font-size: 2.8rem;
  border-bottom: 0.4rem solid rgb(0, 0, 0);
  margin: 1.2rem 9.2rem;
  line-height: 2.2rem;
}

.agent_call span {
  font-size: 2rem !important;
  line-height: 4.2rem !important;
  text-align: center;
  font-weight: 600;
  display: block;
}

@media only screen and (min-width: 700px) and (max-width: 1199px) {
  .video_image {
    height: 18rem;
    width: 31.2rem;
    text-align: center;
  }
  .main_view {
    padding: 3rem 6rem 24rem;
  }
}

.document_archive strong {
  margin: 2.2rem 3.2rem;
}

.document_archive input {
  padding: 2rem 5rem;
}

.form_image{
  height: 16rem;
  width: 24rem;
}

.blue_options {
  color: #3b6bcb;
}

@media only screen and (max-width: 669px) {
  .quote__text3 {
    font-size: 2.4rem;
  }

  .secondary_section span {
    line-height: 2rem !important;
  }

  .form_image {
    width: 15rem;
  }

  .search_doc{
    height: 12rem;
    width: 12rem;
  }

 
.document_archive input {
  width: 16rem;
  padding: 0rem;
}

  .document_archive strong {
    font-size: 1.8rem;
  }

  .main_view {
    padding: 3rem 0rem 24rem;
  }

  .option_section span {
    font-size: 2rem;
  }

  .video_image {
    height: 10rem;
    width: 15rem;
    text-align: center;
  }

  .option_section {
    margin-bottom: 3rem;
  }
}

