
.maintain_container{
	display: flex;
	justify-content: center;
	height: 100%;
}

.maintain_title{
	font-size: 1.6rem;
	width: 70rem;
	text-align: center;
	align-self: center;
}