.tooltip {
  height: 38rem;
  padding: 2rem 3rem 6.8rem;
  width: 84rem;
  position: absolute;
  z-index: 999;
  left: 50%;
  transform: translate(-95%, 4%);
  background: white;
  box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.3);
}

.tooltip__arrow {
  width: 5rem;
  height: 2.5rem;
  position: absolute;
  top: 100%;
  left: 20%;
  transform: translateX(-50%);
  overflow: hidden;
}

.tooltip::after {
  content: "";
  z-index: -1;
  position: absolute;
  width: 2rem;
  height: 2rem;
  background: white;
  transform: translateX(-50%) translateY(-40%) rotate(45deg);
  box-shadow: -15px -8px 29px 0px rgba(0, 0, 0, 0.2);
  top: 0;
  left: 94%;
}

@media only screen and (max-width: 669px) {
  .tooltip {
    height: 58rem;
    width: 58rem;
  }
  .tooltip::after {
    transform: none;
  }
  .tooltip__arrow {
    display: none;
  }
}
