ul {
	list-style: none;
	margin: 0;
	padding: 0;
}
.logout_css{
	display: block;
	font-size: 1.3rem;
	color: black;
	text-decoration-style: none;
	text-decoration: none;
	font-weight: 600;
}
.mega-menu__link:link,
.mega-menu__link:visited {
	color: var(--black);
	font-size: 1.3rem;
	font-weight: 500;
	text-decoration: none;
	margin-top: 2rem;
	display: flex;
	gap: .7rem;
	flex-direction: column;
	transition: all .4s;
}

.mega-menu__link:hover,
.mega-menu__link:active,
.mega-menu__link:focus {
	transform: translateX(.3rem);
}

.megaMenu {
	grid-row: 2 / 3;
	z-index: 1000;
	background-color: var(--bg-primary);
	/* box-shadow: var(--box-shadow1); */
	padding: 1rem 33rem;
	position: sticky;
	top: 0rem;
	width: 100%;
}

.menu {
	/* background-color: Var(--bg-primary); */
	/* display: flex; */
	justify-content: space-between;
	align-items: center;
	/* padding-left: 18rem; */
	width: 100%;
	/* gap: 3rem; */
}
.left__menu {
	/* margin-left: 5rem; */
	display: flex;
}
.exo-menu {
	background-color: var(--bg-primary);
	list-style: none;
	display: flex;
	gap: 1rem;
	justify-content: space-between;
	width: 100%;
	margin-left: 12rem;
	align-self: center;
}

.exo-menu > li {
	display: inline-block;
}
.exo-menu > li > .exo-menu__head,
li.drop-down a {
	font-size: 1.4rem;
	font-weight: 600;
	color: black;
	text-decoration: none;
	-webkit-transition: color 0.2s linear, background 0.2s linear;
	-moz-transition: color 0.2s linear, background 0.2s linear;
	-o-transition: color 0.2s linear, background 0.2s linear;
	transition: color 0.2s linear, background 0.2s linear;
	cursor: pointer;
	width: 100%;

	border: none;
	background-color: transparent;
	font-family: inherit;
}
.exo-menu > li > a.active,
.exo-menu > li > .exo-menu__head:hover,
li.drop-down a:hover {
	color: var(--red);
}

.user__data {
	font-size: 1.3rem;
	font-weight: 600;
}

/*mega menu*/
.mega-menu {
	left: 0;
	right: 0;
	/* top: 4rem; */
	display: none;
	min-height: 100%;
	padding-top: 2rem;

	animation: fadeInAnimation ease 1s;
	animation-iteration-count: 1;
	animation-fill-mode: forwards;
}
.mega-menu__item {
	width: 100%;
}
.drop-down-ul {
	display: none;
	box-shadow: var(--box-shadow1);
	border-radius: 1.5rem;
	background-color: var(--bg-primary);
}

@keyframes fadeInAnimation {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

.row {
	/* display: grid;
	grid-template-columns: repeat(4, 1fr); */
	/* justify-content: space-between; */

	display: flex;
	/* gap: 1rem; */
	justify-content: space-between;
	padding-bottom: 4rem;
	margin-left: 2rem;
	margin-right: 2rem;
	background-color: var(--bg-primary);
	box-shadow: 0 5px 3rem 1rem rgba(0, 0, 0, 0.2);
	border-radius: 1.5rem;
	overflow: hidden;
}

.mega-title {
	color: var(--bg-primary);
	margin-bottom: 2rem;
	font-size: 1.2rem;
	padding-left: 1.5rem;
	padding-bottom: 1.3rem;
	text-transform: uppercase;
	border-bottom: 1px solid var(--black);
	padding-top: 1.5rem;
	background-color: var(--black);
}
.mega-menu ul li a {
	font-size: 1.2rem;
}
ul.mega-menu__data li a {
	padding: 3px 0px;
}

ul.description li {
	margin-left: 1.5rem;
	padding-bottom: 1.2rem;
	line-height: .8rem;
}

ul.description li span {
	color: var(--black);
	font-size: 1.1rem;
	font-weight: 500;
}

ul.icon-des li {
	width: 100%;
	display: table;
	margin-bottom: 11px;
}

/*hover*/
.mega-menu:hover,
.flyout-mega-wrap:hover,
.mega-drop-down a:hover + .mega-menu {
	display: block;
	width: 100vw;
}
.drop-down-ul:hover,
li.drop-down > a:hover + .drop-down-ul {
	display: block;
}

li.drop-down,
.flyout-right,
.flyout-left {
	position: relative;
}
li.drop-down a {
	padding-right: 1rem;
}
/* li.drop-down::after {
	content: "\2193";
	color: var(--black);
	color: var(--red);
	font-size: 1.2rem;
	position: absolute;
	top: 0;
	right: 0;
} */
li.drop-down > ul {
	left: 0px;
	min-width: 230px;
}

li.drop-down > ul > li > a,
.flyout-right ul > li > a,
.flyout-left ul > li > a {
	color: var(--black);
	display: block;
	padding: 2rem 2rem;
	text-decoration: none;
}
li.drop-down > ul > li:not(:last-child) {
	border-bottom: 1px solid var(--light-grey);
}

li.drop-down > ul > li > a {
	top: 0;
	min-width: 230px;
	display: block;
}
.flyout-right > ul,
.flyout-left > ul {
	top: 0;
	min-width: 230px;
	display: none;
	background-color: var(--bg-primary);
}
.flyout-right > ul {
	left: 100%;
}
.flyout-left > ul {
	right: 100%;
}
.flyout-right ul > li > a,
.flyout-left ul > li > a {
	color: #fff;
	display: block;
	padding: 20px 22px;
	text-decoration: none;
	background-color: #365670;
	border-bottom: 1px solid #547787;
	-webkit-transition: color 0.2s linear, background 0.2s linear;
	-moz-transition: color 0.2s linear, background 0.2s linear;
	-o-transition: color 0.2s linear, background 0.2s linear;
	transition: color 0.2s linear, background 0.2s linear;
}
.flyout-right ul > li > a,
.flyout-left ul > li > a {
	border-bottom: 1px solid #b8c7bc;
}
.flyout-right ul > li > a,
.flyout-left ul > li > a {
	background-color: #547787;
}

li.drop-down > a:hover + ul > li > a,
li.flyout-left > ul:hover,
li.flyout-right > ul:hover,
li.flyout-left a:hover + ul,
li.flyout-right a:hover + ul {
	display: block;
}
.exo-menu__buttons_mobile {
	display: none;
	position: absolute;
	right: 0px;
	top: 1rem;
	display: flex;
	gap: 1rem;
	align-items: center;
}
.exo-menu__buttons_mobile--btn {
	font-family: inherit;
	font-size: 1.1rem;
	background-color: var(--red);
	color: var(--bg-primary);
	padding: 0.5rem 2.5rem;
	border-radius: 0.5rem;
	transition: all .4s;
	display: none;
	border: none;
	display: none;
}
.toggle-menu {
	text-decoration: none;
	background-color: transparent;
	border: none;
	display: none;

	cursor: pointer;
	/* transform: rotate(90deg); */
}
.toggle-menu-line {
	margin: .5rem;
	height: .3rem;
	width: 5rem;
	background-color: var(--dark-grey);
}

.menu__right {
	display: flex;
	gap: 1rem;
	align-items: center;
	margin-right: 5rem;
}

.menu__input {
	background-color: transparent;
	color: var(--bg-primary);
	border: none;
	border-bottom: 1px solid var(--black);
	padding-bottom: .3rem;
	padding-left: .5rem;
	font-family: inherit;
	font-size: 1rem;
	outline: none;
	transition: all .4s;
}

.menu__input::-webkit-input-placeholder {
	text-transform: uppercase;
	color: var(--black);
}

.menu__input:focus,
.menu__input:hover {
	border-bottom: 1px solid var(--bg-primary);
}

.menu__link {
	background-color: transparent;
	border: none;
	font-size: 1rem;
	color: var(--light-grey);
	text-decoration: none;
	text-transform: uppercase;
	cursor: pointer;
	transition: all .4s;
}

.menu__link:hover {
	color: var(--bg-primary);
}

.logout_css {

}

.logout_css:hover {
	cursor: pointer;
}
/*responsive*/

@media only screen and (max-width: 1499px) {
	.megaMenu {
		padding: 1rem 10rem;
	}
	.exo-menu {
		width: 100%;
		gap: 1rem;
		margin-left: 6rem;

		justify-content: space-between;
	}
}

@media only screen and (max-width: 1200px) {
	.megaMenu {
		padding: 1rem 10rem;
	}
	.exo-menu {
		width: 100%;
		gap: 1rem;
		margin-left: 2rem;

		justify-content: space-between;
	}
}


@media (max-width: 767px) {
	.logout_css{
		display: block;
		padding: 1rem 22px;
		border-bottom: 1px solid var(--light-grey);
		font-size: 1.8rem;
		color: black;
		text-decoration-style: none;
		text-decoration: none;
		font-weight: 600;
	}
	.left__menu {
		width: 100%;
		gap: 0rem;
		margin-top: 1rem;
	}
	.megaMenu {
		grid-row: 1 / 2;
		padding: 1rem 1rem;
		height: 8rem;
	}
	.menu {
		padding-left: 0;
	}
	.exo-menu {
		width: 100%;
		gap: 0;
	}
	.exo-menu li a.navigation__link2 {
		display: none !important;
	}

	.display.exo-menu {
		margin-right: -1.5rem;
		margin-top: 3rem;
		flex-direction: column;
		align-items: stretch;
		gap: 0;
		overflow: scroll;
	}
	.display.exo-menu > li > a {
		display: block;
		padding: 20px 22px;
	}
	.display.exo-menu .drop-down .drop-down_link,
	.display.exo-menu .mega-drop-down .exo-menu__head {
		position: relative;
	}
	.display.exo-menu .drop-down .drop-down_link::after,
	.display.exo-menu .mega-drop-down .exo-menu__head::after {
		content: '+';
		font-size: 2.5rem;
		position: absolute;
		top: .5rem;
		right: 2rem;
	}
	.display.exo-menu .drop-down .dropdown-visible.drop-down_link::after,
	.display.exo-menu .mega-drop-down .dropdown-visible.exo-menu__head::after {
		content: '-';
	}
	.mega-menu:hover,
	.flyout-mega-wrap:hover,
	.mega-drop-down a:hover + .mega-menu {
		display: none;
		width: 100%;
		padding-top: 0;
	}
	.display .mega-drop-down .dropdown-visible + .mega-menu {
		display: block !important;
		width: 100%;
		padding-top: 0;
	}
	.row {
		flex-direction: column;
		margin-right: 0;
		margin-left: 0;
		padding-bottom: 0;
		background-color: transparent;
		border-radius: 0;
		box-shadow: unset;
	}
	.mega-menu__item {
		padding: 1rem 0;
		padding-left: 2rem;
		border-bottom: 1px solid var(--light-grey);
		background-color: var(--light-grey2);
	}
	.mega-title {
		background-color: transparent;
		color: var(--black);
		border-bottom: none;
		padding-top: 0;
		margin-bottom: 0;
		font-size: 1.6rem;
	}
	.mega-menu__link:link,
	.mega-menu__link:visited {
		margin-top: 0;
		margin-bottom: 2.5rem;
		font-size: 1.6rem;
	}
	ul.description li span {
		/* font-size: 1.4rem; */
		display: none;
	}
	ul.description li {
		padding-bottom: 0;
	}

	.drop-down-ul:hover,
	li.drop-down > a:hover + .drop-down-ul {
		display: none;
	}
	.display .dropdown-visible + .drop-down-ul {
		display: block !important;
	}
	li.drop-down > ul,
	li.drop-down > ul > li > a {
		min-width: 10rem;
		background-color: var(--light-grey2);
	}
	li.drop-down > ul > li > a {
		padding: 1rem 0;
		padding-left: 3rem;
		font-size: 1.6rem;
		font-weight: 500;
	}
	.drop-down-ul {
		box-shadow: unset;
		border-radius: 0;
		background-color: transparent;
	}
	li.drop-down > ul > li {
		border-bottom: 1px solid var(--light-grey);
	}

	.visible-xs-block {
		display: block !important;
	}
	.exo-menu > li > a {
		width: 100%;
		display: none;
	}
	.display.exo-menu > li > a {
		display: block;
		padding: 1rem 22px;
		border-bottom: 1px solid var(--light-grey);
		font-size: 1.8rem;
	}
	.display.mega-menu > li:hover {
		display: flex;
	}

	.flyout-right > ul,
	.flyout-left > ul,
	li.drop-down > ul {
		position: relative;
	}

	.exo-menu__buttons_mobile--btn {
		display: inline;
	}
	.mega-menu__link .description > li {
		display: flex;
	}
}
@media (min-width: 767px) {
	.exo-menu > li > a {
		display: block;
	}
	.mega-menu,
	.flyout-mega-wrap,
	.flyout-right > ul,
	.flyout-left > ul,
	li.drop-down > ul {
		position: absolute;
	}
	.flyout-right > ul {
		left: 100%;
	}
	.flyout-left > ul {
		right: 100%;
	}
	.mega-menu__link {
		display: flex;
	}
}
@media only screen and (min-width: 1500px) {
	.megaMenu {
		padding: 1rem 22rem;
	}

	.exo-menu {
		width: 100%;
		gap: 1rem;
		margin-left: 10rem;
		align-items: end;
		justify-content: space-between;
	}
}
