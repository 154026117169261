.mainContainer {
  padding: 2rem 2rem 0.6rem;
  margin-bottom: 2rem;
  display: flex;
  width: 100%;
  border-bottom: 1px solid rgba(162, 162, 162, 0.32);
  justify-content: space-between;
}

.inputHeading {
  font-size: 1.6rem;
  color: grey;
}
