.switchContainer {
	display: flex;
	align-items: center;
	margin-top: 1rem;
}

.checkboxContainer {
	font-size: small;
	width: 80%;
}

.switch-text {
	margin-left: 1.5rem;
	font-weight: 400;
}