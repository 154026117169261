.modalContainer {
  padding-top: 4rem;
}

.buttonContainer {
  display: flex;
  gap: 16px;
  margin-right: 2.2rem;
  align-self: flex-end;
}
