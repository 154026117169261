.mainView {
  display: flex;
  width: "100%";
  justify-content: space-between;
}

.modal_close_icon {
  height: 3rem;
  width: 3rem;
  cursor: pointer;
  fill: var(--red);
  transition: all 0.4s;
  justify-self: center;
  align-self: flex-end;
}

.mainTitle {
  font-size: 2.2rem;
  line-height: 3.2rem;
  font-weight: 300;
  margin-bottom: 1.6rem;
  color: var(--red);
  display: block;
}

.infoContainer {
  text-align: center;
  padding-top: 1rem;
  width: 48%;
}

.loginDescription {
  font-size: 1.8rem;
  line-height: 3.4rem;
  margin-bottom: 1.4rem;
  font-weight: 100;
  color: #5d5d5d;
}

.loginButton {
  display: inline-block;
  padding: 1.2rem 2.4rem;
  margin-top: 2rem;
  background-color: var(--red);
  border: 0;
  border-radius: 0.6rem;
  font-size: 1.6rem;
  font-weight: 600;
  transition: background 0.4s, color 0.4s;
  color: #fff;
  text-align: center;
  user-select: none;
  vertical-align: middle;
  cursor: pointer;
  transition: background-color 0.3s;
}

.loginButton:hover {
  background-color: #b4811b;
}

.dash {
  background-color: hsla(0, 0%, 36%, 0.347);
  height: 30.2rem;
  padding: 0 0.4px;
}

@media only screen and (max-width: 669px) {
  .mainView {
    flex-direction: column;
    gap: 3rem;
  }
  .mainTitle {
    font-size: 3.4rem;
  }
  .infoContainer {
    text-align: left;
    width: 100%;
  }
  .dash {
    height: 0.2rem;
  }
  .loginDescription {
    font-size: 2rem;
    margin-bottom: 0rem;
    line-height: 3.4rem;
  }
}
