.quote3 {
  justify-content: center;
  height: 10rem;
  background-color: var(--dark-grey);
  display: flex;
  align-items: center;
}

.landing_selectbox{
  width: 26rem!important;
  font-size: 1.6rem!important;
  padding: 1.4rem 1.4rem!important;
}

.quote__text3 {
  font-size: 2.8rem;
  font-weight: 800;
  color: var(--white);
}

.main_view_landing {
  padding: 3rem 6rem 24rem;
}

.icon_division {
  background-color: aqua;
  padding: 1.4rem 0rem;
  width: 30%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.icon_grid div:nth-child(2) {
  border-left: 0.2rem solid rgba(0, 0, 0, 0.138);
  border-right: 0.2rem solid rgba(0, 0, 0, 0.138);
}

.icon_grid {
  display: flex;
  position: relative;
  border-bottom: 0.3rem solid rgba(0, 0, 0, 0.138);
  justify-content: space-between;
  gap: 2rem;
}

.icon {
  height: 8rem;
}

.icon_division span {
  display: block;
  font-size: 1.1rem;
  font-weight: 600;
  margin-top: 1.8rem;
}

.division_text {
  text-align: center;
  display: block;
}

.options_menu {
  display: flex;
  justify-content: space-between;
}

.edit_icon{
  display: block;
}

.option_section {
  margin-bottom: 5rem;
}

.option_section span {
  line-height: 2rem;
  font-weight: 800;
  margin-bottom: 1.2rem;
  font-size: 2.8rem;
  display: inline-block;
  border-bottom: 0.4rem solid rgba(0, 0, 0, 0.138);
}

.option_section a {
  display: block;
  font-size: 2rem;
}

.option_division {
  flex: 1;
  padding-right: 4rem;
}

.options_menu .option_division:nth-child(2) {
  padding: 0rem 2rem;
  border-left: 0.2rem solid rgba(0, 0, 0, 0.138);
  border-right: 0.2rem solid rgba(0, 0, 0, 0.138);
}

.video_image {
  height: 22rem;
  width: 43.2rem;
  text-align: center;
}

.second_options_menu {
  position: relative;
  padding-top: 32px;
  margin-top: 62px;
  border-top: 0.2rem solid rgba(0, 0, 0, 0.138);
}

.second_options_menu .option_division:nth-child(2) {
  border-left: none;
  border-right: none;
}

.vertical_options_menu {
  display: flex;
  flex-direction: column;
}
.vertical_grid{
  display: flex;
  
}
.grid_item{  
  flex: 1;
   display: flex;
   flex-direction: column;
}
.grid_item:nth-child(2) {  
  padding: 0rem 4rem;
  border-left: 0.1rem solid rgba(0, 0, 0, 0.138);
  border-right: 0.1rem solid rgba(0, 0, 0, 0.138);
}
.grid_item:nth-child(1) {
  padding: 0rem 2rem;
}
.grid_item:nth-child(3) {
  padding: 0rem 2rem;
}


@media only screen and (min-width: 700px) and (max-width: 1199px) {
  .video_image {
    height: 18rem;
    width: 31.2rem;
    text-align: center;
  }
  .main_view {
    padding: 3rem 6rem 24rem;
  }
  .option_section span {
    line-height: 3rem !important;
  }
}

@media only screen and (max-width: 669px) {
  .quote__text3 {
    font-size: 2.4rem;
  }

  .main_view {
    padding: 3rem 0rem 24rem;
  }

  .option_section span {
    font-size: 2rem !important;
  }

  .video_image {
    height: 10rem;
    width: 15rem;
    text-align: center;
  }

  .option_section {
    margin-bottom: 3rem;
  }
}

@media (min-width: 767px) {
  .main_view_landing {
    padding: 3rem 6rem 7rem;
  }
  .option_division {
    padding-right: 0rem;
  }
  .option_division:nth-child(2) {
    width: 35%;
  }
  .icon_grid div:nth-child(2) {
    width: 35%;
  }

}

@media (max-width: 767px) {
.icon_grid{
  display: block;
}
.icon_grid div:nth-child(2) {
  border-left: none;
  border-right: none;
}
.options_menu{
  display: inherit;
}
.option_division{
  width: 100%;
  padding-top: 4rem;
  border-bottom: 0.2rem solid rgba(0, 0, 0, 0.138);
  padding-right: 0px;
}

.options_menu .option_division:nth-child(2){
  padding: 0px;
  padding-top: 4rem;
  border-left: none;
  border-right: none;
}
.options_menu .option_division:nth-child(3){
  border-bottom: none;
}
.edit_icon{
  display: none;
}
.second_options_menu .option_division{
  border-bottom: none;
}
.video_image{
  height: 29rem;
  width: 56rem;
  margin-bottom: 2rem;
}
.main_view_landing {
  padding: 3rem 6rem 5rem;
}
}
