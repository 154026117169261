.mwefbicontact_maincontainer {
	display: flex;
	flex: 1 1;
	padding: 4rem 33rem;
}

.container-mwefbicontact-left {
	display: flex;
	flex-direction: column;
	overflow: auto;
	position: relative;
	margin-top: -25rem;
	align-items: center;
	width: 30%;
}

.container-mwefbicontact-left-contact {
	background-color: var(--red);
	padding: 30px 8% 30px;
	width: 35vh;
}

.container-mwefbicontact-left-contact .heading_1 {
	color: #fff;
}

.container-mwefbicontact--right {
	display: flex;
	flex-direction: column;
	width: 70%;
}


.contactUs__desc {
	font-size: 1.6rem;
	font-weight: 500;
	padding-bottom: 3.5rem;
	color: #fff;
}

.contact-building-image {
	width: 35vh;
}


.red_star {
	color: var(--red);
	font-weight: 200;
}


.form_textarea {
	background-color: rgb(238, 234, 228);
	height: 8rem;
	width: 86%;
	border: none;
	outline: none;
	border: 1px solid rgb(163, 159, 159);
}



.form_textbox {
	display: flex;
	gap: 3rem;
}

.label_heading1 {
	display: flex;
	gap: 33.7rem;
	flex-wrap: text;
	margin-top: 2rem;
}

.label_subheading {
	font-weight: 900;
	font-size: 2rem;
	color: grey;
}

.label_heading2 {
	display: flex;
	gap: 38.8rem;
	color: grey;
	font-size: 1.2rem;
	margin-top: .2rem;
}

.label_heading3 {
	margin-top: .3rem;
	display: flex;
	color: grey;
	margin-bottom: .3rem;
	font-size: 1.2rem;
}

.radio-option {
	display: flex;
	gap: 3rem;
	align-items: center;
}

.radio-custom-label-contact {
	margin-left: 1rem;
}

.heading_contact_1 {
	color: #484848;
	font-size: 4rem;
	font-weight: bolder;
}

.contact-mwfbi--icon {
	height: 2rem;
	width: 2rem;
	fill: #fff;
}

.contact-mwfbi {
	display: flex;
	align-items: center;
	gap: 1rem;
}
.main_outer_component12{
	padding-left: 10px;
}

@media only screen and (min-width: 1500px) {
	.mwefbicontact_maincontainer {
		position: relative;
		width: 100%;
		flex-direction: row;
	}

	.container-mwefbicontact-left {
		width: 30%;
		position: relative;
	}

	.container-mwefbicontact--right {
		width: 70%;
		position: relative;
	}
}


@media only screen and (max-width: 1400px) {
	.mwefbicontact_maincontainer {
		position: relative;
		width: 100%;
		flex-direction: row;
		padding: 4rem 10rem !important;
	}

	.container-mwefbicontact-left {
		width: 30%;
		position: relative;
	}

	.container-mwefbicontact--right {
		width: 70%;
		position: relative;
	}
}



@media only screen and (max-width: 900px) {
	.mwefbicontact_maincontainer {
		position: relative;
		width: 100%;
		flex-direction: column;
		gap: 5rem;
	}

	.container-mwefbicontact-left {
		width: 100%;
		position: relative;
	}

	.container-mwefbicontact--right {
		width: 100%;
		position: relative;
	}
}


@media only screen and (max-width: 760px) {
	.mwefbicontact_maincontainer {
		position: relative;
		width: 100%;
		flex-direction: column;
		gap: 5rem;
	}

	.container-mwefbicontact-left {
		width: 100%;
		position: relative;
		display: flex;
		flex-direction: column;
		align-items: center;
		margin-left: -3rem;
	}

	.container-mwefbicontact--right {
		width: 100%;
		position: relative;
		
		margin-left: -5rem;
	}
}