.faqs {
	margin: 2rem 0;
	display: flex;
	flex-direction: column;
	align-items: center;
	display: flex;
	flex-direction: column;
	gap: 2rem;
	padding: 3rem 40rem;
}

.faq {
	background-color: var(--light-grey2);
	/* box-shadow: var(--box-shadow1); */
	border-radius: 1rem;
	padding: 3rem 2rem;
	overflow: hidden;
	transition: all .4s;
	width: 55vw;
	display: flex;
	gap: 1rem;
	align-items: center;
	position: relative;
}

.faq__number {
	font-size: 4rem;
	font-weight: 600;
	color: var(--dark-grey);
	padding: 4.5rem 2rem;
	background-color: var(--light-grey);
	/* width: 9%; */
	text-align: center;
}

.accordion {
	color: var(--dark-grey);
	cursor: pointer;
	font-size: 1.7rem;
	font-weight: 600;
	transition: all .4s;

	/* margin-top: -5rem; */
}

.accordion::after {
	content: '+';
	font-size: 2.5rem;
	position: absolute;
	top: .5rem;
	right: .5rem;
}

.accordion.active::after {
	content: '-';
	font-size: 2.5rem;
	top: .5rem;
	right: .5rem;
}

.accordion_icon {
	height: 2.5rem;
	width: 2.5rem;
	color: var(--light-grey);
	position: absolute;
	top: -2rem;
	right: .5rem;
}

.accordion_heading {
	color: var(--dark-grey);
	font-size: 1.7rem;
	font-weight: 600;
	transition: all .4s;
}

.active {
	/* margin-top: 2rem; */
}

p.accordion:hover {
	color: var(--red);
}

/* .faq:hover {
	color: red;
} */
.faq_side {
	display: flex;
	flex-direction: row-reverse;
	justify-content: space-around;
}

.faq_sub_title {
	font-weight: bold;
}

.hr_height {
	height: 4px;
	width: 100rem;
	fill: black;
	background-color: black;
}

.dis_table {
	width: 100%;
	border-collapse: collapse;
	border: 1px solid black;
}

.dis_table>tr {
	width: 100%;
}

.dis_table>tr>.title {
	font-weight: bold;
	width: 25rem;
	padding: 1rem;
}

.dis_table>tr>.desc {
	font-weight: normal;
	width: 75rem;
	padding: 1rem;
}

.FAQ_button {
	background-color: var(--dark-grey);
	padding: 1rem 2rem;
	border: none;
	outline: none;
	color: var(--pure-white);
	text-transform: uppercase;
	font-size: 1.3rem;
	font-weight: 700;
	border-radius: 5px;
	cursor: pointer;
	text-decoration: none;
	/* margin-right: 2rem; */
}

/* Unicode character for "plus" sign (+) */
/* p.accordion:after {
	content: '\2795';
	font-size: 13px;
	color: #777;
	float: right;
	margin-left: 5px;
} */

/* Unicode character for "minus" sign (-) */
/* p.accordion.active:after {
	content: "\2796";
} */

/* Style the element that is used for the panel class */

.panel {
	font-size: 1.3rem;
	font-weight: 500;
	max-height: 0;
	/* overflow: hidden; */
	transition: 0.4s ease-in-out;
	opacity: 0;
	display: flex;
	flex-direction: column;
	gap: 1rem;
	align-items: flex-start;
}


.panel.show {
	opacity: 1;
	max-height: 400rem;
	/* padding: 1.5rem 0; */
}

.link-to-claims {
	font-size: 1.6rem;
	text-decoration: none;
}

@media only screen and (max-width: 1400px) {
	.faqs {
		padding: 3rem 10rem;
	}
}


@media (max-width: 600px) {

	.dis_table {
		width: 90%;
		border-collapse: collapse;
		border: 1px solid black;
	}

	.hr_height {
		height: 4px;
		width: 95%;
		fill: black;
		background-color: black;
	}
}

@media (min-width: 600px) {
	.dis_table {
		width: 95%;
		border-collapse: collapse;
		border: 1px solid black;
	}

	.hr_height {
		height: 4px;
		width: 95%;
		fill: black;
		background-color: black;
	}

}

@media (min-width: 2950px) {
	.dis_table {
		width: 100%;
		border-collapse: collapse;
		border: 1px solid black;
	}

	.hr_height {
		height: 4px;
		width: 98%;
		fill: black;
		background-color: black;
	}
}

@media only screen and (max-width: 780px) {
	.faqs {
		padding: 3rem 1rem;
	}

	.faq {
		width: 100%;
	}

	.faq__number {
		padding: 7rem 2rem;
	}
}