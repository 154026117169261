.title {
  font-size: 30px;
  color: #808080;
  font-weight: 500;
  display: block;
  font-size: 14px !important;
}

.inputTitle {
  font-size: 30px;
  color: #808080;
  font-weight: 500;
  display: block;
  font-size: 14px;
}

.modal_inner_content_right_landing {
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  justify-content: space-between;
  width: 100%;
  gap: 1rem;
}

.modalContainer {
  padding: 12px 12px 18px;
  width: 100%;
  border-bottom: 1px solid rgba(162, 162, 162, 0.32);
}

.agentlanding_selectbox {
  width: 52% !important;
  font-size: 1.6rem !important;
  padding: 1.4rem 1.4rem !important;
}

.inputContainer {
  display: flex;
  align-items: end;
  justify-content: space-between;
}

.section1Container {
  width: 52%;
}

.sectionContainer {
  width: 39%;
}

.imagePreview {
  padding: 2rem;
  display: inline-block;
  align-items: center;
  border: 1px dashed #000000b9;
}
