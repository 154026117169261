.Farm_ranch_insurance {
	padding: 2rem 33rem;
	padding-bottom: 0;
}
.Farm_insurance_coverage {
	background-color: var(--light-grey2);
	padding: 3rem 33rem;
}

@media only screen and (max-width: 1500px) {
	.Farm_ranch_insurance {
		padding: 3rem 15rem;
	}
	.Farm_insurance_coverage {
		padding: 3rem 15rem;
	}
}
@media only screen and (max-width: 1400px) {
	.Farm_ranch_insurance {
		padding: 3rem 15rem;
	}
	.Farm_insurance_coverage {
		padding: 3rem 15rem;
	}
}

@media only screen and (max-width: 780px) {
	.Farm_ranch_insurance {
		padding: 3rem 1rem;
	}
	.Farm_insurance_coverage {
		padding: 3rem 1rem;
	}
}
