/* Modal */

.modalContainer-dark {
  display: block;
  pointer-events: auto;
  background-color: rgba(0, 0, 0, 0.4) !important;
  -moz-transition: all 0.5s ease-out;
  -webkit-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.4);
  z-index: 99999;
}

.modal-dark {
  position: absolute;
  z-index: 1000;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: var(--white);
  border-radius: 2rem;
  overflow: hidden;
  transition: 1.1s ease-out;
  box-shadow: var(--box-shadow1);
  filter: blur(0);
  opacity: 1;
  visibility: visible;
  justify-content: center;
  min-width: 50vw;
}

.modal__container-dark {
  display: flex;
  flex-direction: column;
  /* gap: 2rem; */
  align-items: flex-start;
}

.modal_heading__container-dark {
  width: 100%;
  display: flex;
  color: white;
  align-items: center;
  justify-content: space-between;
}

.modal_heading__text-dark {
  font-size: 1.3rem;
  font-weight: 600;
}

.modal__heading--button-dark {
  background-color: white;
  height: 2.5rem;
  width: 2.5rem;
  border-radius: 0.5rem;
  border: none;
  outline: none;
  cursor: pointer;
  transition: all 0.4s;
  display: flex;
  justify-items: center;
  align-items: center;
  justify-content: center;
}

.modal_heading__icon-dark {
  height: 2rem;
  width: 2rem;
  fill: black;
  transition: all 0.4s;
}

.modal_upload__icon-dark {
  height: 3rem;
  width: 3rem;
  fill: black;
  transition: all 0.4s;
  justify-self: center;
  align-self: center;
}

.modal__heading--button-dark:hover {
  transform: scale(1.1);
}

.modal__heading--button-dark:hover .modal_heading__icon-dark {
  fill: none;
}

.modal__form-dark {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  padding: 2rem;
}

.modal__form_line {
  width: 100%;
  height: 0.2rem;
  background-color: var(--grey-border);
}

.modal__form--label-dark {
  font-size: 1.2rem;
  font-weight: 500;
  color: var(--light-border);
}

.modal__form--input-dark {
  border: none;
  background-color: transparent;
  font-family: inherit;
  border-bottom: 1px solid var(--light-border);
  outline: none;
}

.modal__button-dark {
  font-family: inherit;
  border: 1px solid var(--red);
  background-color: var(--red);
  color: var(--white);
  font-size: 1.5rem;
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  cursor: pointer;
  transition: all 0.4s;
}

.modal__button-dark:hover {
  background-color: transparent;
  color: var(--red);
}

.modal__button-reset-dark {
  font-family: inherit;
  border: 1px solid var(--red);
  background-color: white;
  color: var(--red);
  font-size: 1.5rem;
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  cursor: pointer;
  transition: all 0.4s;
}

.modal__button-reset-dark:hover {
  background-color: transparent;
  color: var(--red);
}

.modal__form--error-dark {
  font-size: 1.2rem;
  color: var(--red);
}

.modal_dark_buttons {
  display: flex;
  width: 100%;
  column-gap: 1rem;
  justify-content: right;
  padding: 2rem;
}

.modal_heading__container-dark {
  width: 100%;
  height: 5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #343b45;
  padding: 2rem;
}

.modal_inner_content {
  display: flex;
}

.modal_inner_content--left {
  display: flex;
  flex-direction: column;
  flex: 1.5;
}

.modal_inner_content--equal {
  display: flex;
  flex-direction: column;
  flex: 1;
  row-gap: 1rem;
  margin-left: 1rem;
}

.modal_inner_content--right {
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-items: center;
  text-align: center;
  padding: 1rem;
  row-gap: 1rem;
}

.modal_inner_content--right2 {
  display: flex;
  flex-direction: column;
  flex: 1.5;
}

.modal_inner_content--right3 {
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  gap: 1rem;
}

.modal_inner_content--left4 {
  display: flex;
  flex-direction: column;
  width: 44%;
}

.modal_inner_content--right4 {
  display: flex;
  flex-direction: column;
  width: 56%;
  justify-content: space-between;
  align-items: center;
}

.upload_preview_modal {
  height: 5rem;
}

.modal_form_container {
  width: 100%;
  height: 15rem;
  overflow-y: auto;
}

.modal__form-table {
  width: 100%;
  border-collapse: collapse;
  border: none !important;
  outline: none;
  appearance: none;
  position: relative;
}

.modal__form-sticky {
  position: sticky;
  top: 0;  
  z-index: 1;
}

.modal__form-tr {
  height: 3rem;
  background-color: #c4c4c4;
  border: none !important;
  outline: none;
  appearance: none;
}

.modal__form-tr2 {
  height: 3rem;
  background-color: #c4c4c4;
  border: none !important;
  outline: none;
  appearance: none;
}

.modal__form-tr2:nth-child(odd) {
  background-color: #e4e4e4;
  border: none !important;
  outline: none;
  appearance: none;
}

.modal__form-tr2:nth-child(even) {
  background-color: #fff;
  border: none !important;
  outline: none;
  appearance: none;
}

.modal__form-tr th {
  /* font-family: 'Inter'; */
  font-style: normal;
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 1.6rem;
  letter-spacing: 0.01em;
  color: #4b4b4b;
  text-align: center;
  border: none !important;
  outline: none;
  appearance: none;
}

.modal__form-tr2 td {
  /* font-family: 'Inter'; */
  font-style: normal;
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 1.4rem;
  letter-spacing: 0.01em;
  color: #4b4b4b;
  text-align: center;

  border: none !important;
  outline: none;
  appearance: none;
}

.modal__button-edit {
  font-family: inherit;
  background-color: black;
  color: var(--white);
  font-size: 1.2rem;
  padding: 0.4rem 1.4rem;
  border-radius: 0.5rem;
  cursor: pointer;
  transition: all 0.4s;
  text-transform: capitalize;
}

.modal__button-delete {
  font-family: inherit;
  border: 1px solid var(--red);
  background-color: var(--white);
  color: var(--red);
  font-size: 1.2rem;
  padding: 0.4rem 1.4rem;
  border-radius: 0.5rem;
  cursor: pointer;
  transition: all 0.4s;
  text-transform: capitalize;
}

.modal__button-delete:hover {
  background-color: var(--red);
  color: var(--white);
}

.modal__form-buttons {
  display: flex;
  gap: 1rem;
  justify-content: right;
  align-items: center;
  align-self: center;
  padding-top: 0.1rem;
  padding-right: 0.5rem;
}

.modal__form_description {
  width: 35rem;
  word-break: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  line-height: 16px;
  /* fallback */
  max-height: 32rem;
  /* fallback */
  -webkit-line-clamp: 1;
  /* number of lines to show */
  -webkit-box-orient: vertical;
}

.modal__form_link {
  word-break: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 16px;
  /* fallback */
  max-height: 32px;
  /* fallback */
  -webkit-line-clamp: 1;
  /* number of lines to show */
  -webkit-box-orient: vertical;
  padding: 0.2rem;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 1.4rem;
  letter-spacing: 0.01em;
  color: #4b4b4b;
}

table,
thead,
tbody,
tr,
td,
th {
  border: none;
}