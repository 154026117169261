.text-input-prefix__formgroup--input {
  background-color: transparent;
  border: none;
  outline: none;
  font-family: inherit;
  width: 100%;
  height: 5vh;
  padding-left: 0.5rem;
}

.textBox {
  position: relative;
}

.standard-input {
  border: 1px solid rgb(207, 207, 207) !important;
  background-color: rgb(247, 246, 255) !important;
  border-radius: 2px !important;
}

.text-input-prefix__formgroup--inputBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid rgb(207, 207, 207);
  background-color: rgb(247, 246, 255);
  border-radius: 2px;
  /* box-shadow: var(--box-shadow-input); */
  /* overflow: hidden; */
  width: 100%;
  height: 5rem;
  outline: none;
  padding: 0 1rem;
}

.text-input-prefix__formgroup--inputBox--date {
  /* display: none; */
  opacity: 0;
}
.text-input-prefix__formgroup--dateBox {
  position: relative;
}
.text-input-prefix__formgroup--dateBox label {
  position: absolute;
  top: 0;
  left: 0;
  border: 1px solid rgb(163, 159, 159);
  background-color: rgb(238, 234, 228);
  width: 100%;
  outline: none;
  padding: 0 1rem;
  /* padding-top: 1rem; */
  font-size: 1.2rem;

  display: flex;
  align-items: center;
  justify-content: space-between;
}
.text-input-prefix__formgroup--dateBox label svg {
  height: 1.5rem;
  width: 1.5rem;
  fill: var(--black);
}

/* 
.text-input-prefix--iconBox {
	padding: 2rem;
	border-radius: 3rem;
	box-shadow: var(--box-shadow-input);
	height: 6.5rem;
	width: 6.5rem;
	color: white;
	background-color: rgb(255, 255, 255);
	border: none;
	outline: none;
	position: absolute;
	z-index: 999;
	cursor: pointer;
	transform: translateX(2px);
	transition: 0.3s;
	display: flex;
	align-items: center;
	text-align: center;
} */

/* 
.text-input-prefix_postfix--iconBox {
	padding: 1.5rem 2rem;
	display: flex;
	align-items: center;
	border-radius: 3.5rem;
}

.text-input-prefix__formgroup--icon {
	height: 2rem;
	width: 2rem;
	fill: var(--white);
}

.text-input-prefix__formgroup_postfix--icon {
	height: 2rem;
	width: 2rem;
	fill: var(--white);
} */

/* .textBox__label {
	position: absolute;
	top: -.5rem;
	left: 0;
	z-index: 10;
	background-color: var(--bg-primary);
	box-shadow: var(--box-shadow1);
	border-radius: 50%;
	padding: 1.3rem;
	display: flex;
}

.textBox__label--icon {
	height: 2.5rem;
	width: 2.5rem;
	fill: var(--black);
}

.textBox__input {
	background-color: var(--bg-primary);
	box-shadow: var(--box-shadow1);
	border-radius: 2rem;
	overflow: hidden;
	width: 100%;
	display: flex;
}

.textBox__input--box {
	width: 100%;
	margin-left: 5rem;
	background-color: var(--bg-primary);
	font-family: inherit;
	border: none;
	outline: none;
	padding: 1rem 1rem;
}

.textBox__input--show {
	margin-right: 1rem;
	border: none;
	background-color: transparent;
	cursor: pointer;
}

.textBox__input--show:hover .textBox__input--showIcon {
	fill: var(--red);
}

.textBox__input--showIcon {
	height: 2rem;
	width: 2rem;
	fill: var(--dark-grey);
	transition: all .4s;
} */
