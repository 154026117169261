.our-mission-main {
	padding: 2rem 33rem;
	display: flex;
	flex-direction: column;
}
 
.our-mission-main-heading {
	font-size: 2.5rem;
	margin-bottom: 2rem;
	font-weight: 900;
	align-items: center;
	text-align: center;
}
.about-how-we-work-desc {
	display: flex;
	padding: 0rem 33rem;
	gap: 2rem;
}
.about_howwework_image {
	width: 59rem;
}
.about-how-we-work-desc-heading1 {
	font-size: 2.5rem;
	font-weight: 900;
}
.about-how-we-work-desc-heading2 {
	font-size: 1.6rem;
	font-weight: 300;
	margin-bottom: 1rem;
}
.subheading {
	font-size: 1.8rem;
	font-weight: 900;
}
.about_us_heading {
	font-size: 2.5;
}

/* .our-mission-main-heading {
	text-align: center;
	width: 100%;
	flex: 1;
	font-weight: 900;
	font-family: inherit;
} */

.our-mission-main-description {
	width: 60%;
	flex: 1;
	text-align: center;
}

.claim__icon {
	fill: white;
	height: 3.5rem;
	width: 3.5rem;
	transition: all 0.4s;
}

.about-how-we-work-right > img {
	border-radius: 0rem;
	height: fit-content;
}

.about-how-we-work-desc-heading {
	font-weight: 700;
	padding: 10px;
}

.about-how-we-work-desc-desc {
	padding: 10px;
	font-weight: 500;
}

.history-main1 {
	background-color: white;
	padding: 0rem 25rem;
	display: flex;
	justify-content: center;
	position: relative;
}
.history_begining {
	/* width: 100%; */
	margin-top: 4rem;
	padding: 2rem;
	padding-left: 55rem;
	padding-right: 55rem;
	text-align: center;
	color: white;
	background-color: var(--dark-grey);
}
.heading_history {
	font-weight: 500;
	font-size: 2.5rem;
}
.history-content {
	width: 80%;
	padding-top: 1.5rem;
	text-align: center;
}

.history-content > .head > label {
	color: var(--red);
	font-family: inherit;
	font-weight: 500;
}

.history-content > .head > h3 {
	font-family: inherit;
	font-weight: 500;
	margin-bottom: 2rem;
}

.spacing {
	padding: 2rem;
}

.history-card-list {
	width: 100%;
	margin-bottom: 1rem;
}

.history-card {
	height: 15rem;
	background-color: #ffffff;
	transition: 0.3s;
	display: flex;
	padding: 2rem;
	text-align: start;
	position: relative;
}

.card-left {
	border-bottom: black solid 1px;
}

.card-left:after {
	content: "";
	position: absolute;
	left: 0;
	z-index: 100;
	bottom: 0;
	width: 3px;
	height: 24%;
	/* or 100px */
	border-left: black solid 1px;
}

.card-left::before {
	content: "";
	position: absolute;
	left: 0;
	z-index: 100;
	top: 0;
	width: 3px;
	height: 23%;
	/* or 100px */
	border-left: black solid 1px;
}

.card-right {
	border-bottom: black solid 1px;
}

.card-right:after {
	content: "";
	position: absolute;
	right: -0.2rem;
	z-index: 100;
	bottom: 0;
	width: 3px;
	height: 24%;
	/* or 100px */
	border-left: black solid 1px;
}

.card-right::before {
	content: "";
	position: absolute;
	right: -0.2rem;
	z-index: 100;
	top: 0;
	width: 3px;
	height: 23%;
	/* or 100px */
	border-left: black solid 1px;
}

.card-right-end:after {
	content: "";
	position: absolute;
	right: -0.2rem;
	z-index: 100;
	bottom: 0;
	width: 3px;
	height: 24%;
	/* or 100px */
	border-left: black solid 1px;
}

.card-right-end::before {
	content: "";
	position: absolute;
	right: -0.2rem;
	z-index: 100;
	top: 0;
	width: 3px;
	height: 23%;
	/* or 100px */
	border-left: black solid 1px;
}

.card-left-end:after {
	content: "";
	position: absolute;
	left: 0;
	z-index: 100;
	bottom: 0;
	width: 3px;
	height: 25%;
	/* or 100px */
	border-left: black solid 1px;
}

.card-left-end::before {
	content: "";
	position: absolute;
	left: 0;
	z-index: 100;
	top: 0;
	width: 3px;
	height: 25%;
	/* or 100px */
	border-left: black solid 1px;
}

.history-year-left {
	height: 8rem;
	width: 8rem;
	margin-top: 1.4rem;
	margin-left: -6rem;
	text-align: center;
	display: flex;
	justify-content: center;
	padding: 2rem;
	border-radius: 50%;
	border: red solid 3px;
	color: red;
	font-size: 2rem;
	font-weight: 700;
}

.history-year-right {
	height: 8rem;
	width: 8rem;
	margin-top: 1.4rem;
	margin-right: -6rem;
	text-align: center;
	display: flex;
	justify-content: center;
	padding: 2rem;
	border-radius: 50%;
	border: red solid 3px;
	color: red;
	font-size: 2rem;
	font-weight: 700;
}

.history-card > .title {
	font-family: inherit;
	font-size: 2rem;
	font-weight: 600;
}

.history-card > .desc {
	font-family: inherit;
	font-size: 1.5rem;
	font-weight: 500;
	width: 80%;
}

.end-message {
	font-family: inherit;
	font-size: 1.5rem;
	font-weight: 500;
	/* background-color: red; */
}

.about-inner-card-content-left {
	width: 100%;
	display: flex;
	flex-direction: column;
	padding: 4rem;
	justify-content: center;
}

.about-inner-card-content-left > .title {
	font-weight: 900;
}

.about-inner-card-content-left > .desc {
	font-weight: 500;
	width: 50%;
}

.about-inner-card-content-right {
	width: 100%;
	display: flex;
	flex-direction: column;
	padding: 4rem;
	text-align: right;
	justify-content: right;
	margin-top: -2.5rem;
}

.about-inner-card-content-right > .title {
	align-self: end;
	font-weight: 900;
}

.about-inner-card-content-right > .desc {
	font-weight: 500;
	width: 50%;
	align-self: end;
}
.label_heading {
	font-size: 2.5rem;
	font-weight: 900;
}
.label_heading_1 {
	font-size: 1.6rem;
	font-weight: 200;
	color: grey;
}
@media only screen and (max-width: 1500px) {
	.about-how-we-work-desc {
		padding: 1rem 15rem;
	}
	.history_begining {
		padding: 2rem 15rem;
	}
	.about_howwework_image {
		width: 25vw;
	}
	.history-main1 {
		padding: 0 10rem;
	}
}

@media only screen and (max-width: 1400px) {
	.about-how-we-work-desc {
		padding: 1rem 15rem;
	}
	.history_begining {
		padding: 2rem 15rem;
	}
	.about_howwework_image {
		width: 25vw;
	}
	.history-main1 {
		padding: 0 10rem;
	}
}

@media only screen and (max-width: 1230px) {
	.our-mission-main {
		display: flex;
		justify-content: flex-start;
		flex-direction: column;
		padding: 2rem 1rem;
	}
	.about-how-we-work-desc {
		padding: 1rem 10rem;
	}
	.history_begining {
		padding: 2rem 10rem;
	}
	.about_howwework_image {
		width: 25vw;
	}
	.history-main1 {
		padding: 0 10rem;
	}
}
@media only screen and (max-width: 37.5em) {
	.our-mission-main {
		display: flex;
		justify-content: flex-start;
		flex-direction: column;
		padding: 2rem 1rem;
	}
	.about_howwework_image {
		width: 85vw;
	}

	.about-how-we-work-desc {
		/* width: 100%; */
		display: flex;
		flex-direction: column;
		padding: 2rem 1rem;
	}
	.heading_3 {
		display: flex;
		/* width: 100%; */
		flex-direction: column;
		justify-content: flex-start;
	}

	.mwfbi__buttons {
		display: flex;
		justify-content: center;
	}
	.button__text {
		box-shadow: none;
		justify-content: center;
		width: auto;
	}
	.history-card-list {
		padding-bottom: 2rem;
	}
	.history-main1 {
		padding: 1rem 1rem;
	}
	.history_begining {
		/* width: 100%; */
		padding-right: 10px;
		padding-left: 10px;
		display: flex;
		justify-content: center;
	}
	/* .spacing {
		padding: 2rem;
		width: 100%;
	} */

	.history-content {
		padding-top: 0;
		width: 100%;
	}
	.history-card {
		display: flex;
		flex-direction: column;
	}
	.card-left {
		border: none;
		display: flex;
		flex-direction: column;
		padding-bottom: 2rem;
		position: relative;
	}
	.card-right {
		border: none;
		display: flex;
		flex-direction: column-reverse;
		/* margin-top: 2rem; */
		position: relative;
	}
	.card-right-end {
		border: none;
		display: flex;
		flex-direction: column-reverse;
		position: relative;
		/* padding-top: 2rem; */
	}
	/* .card-right :nth-child(1) {
		order: 2;
	}
	.card-right :nth-child(2) {
		order: 3;
	}
	.card-right :nth-child(3) {
		order: 1;
	} */

	.about-inner-card-content-left > .desc {
		width: 100%;
		margin-left: 1rem;
	}
	.about-inner-card-content-right > .desc {
		text-align: left;
		width: 100%;
		padding-left: 1rem;
	}
	.about-inner-card-content-right > .title {
		text-align: left;
		padding-left: 1rem;
		width: 100%;
	}
	.about-inner-card-content-left > .title {
		text-align: start;
		margin-left: 1rem;
		width: 100%;
	}
	.history-year-right {
		border: none;
		margin-left: 1rem;
		padding: 0rem;
		margin-top: 0;
		justify-content: start;
		width: 0;
		height: 3rem;
	}
	.history-year-left {
		border: none;
		margin-left: 1rem;
		padding: 0rem;
		margin-top: 0;
		justify-content: start;
		width: 0;
	}
	.card-right:after {
		display: none;
	}
	.card-right-end::before {
		display: none;
	}
	.card-right-end:after {
		display: none;
	}
	.about-inner-card-content-right {
		padding: 0rem;
		margin-top: 0;
	}
	.about-inner-card-content-left {
		padding: 0;
		margin-top: 0;
	}
	.card-right:before {
		display: none;
	}

	.card-left:after {
		display: none;
	}
	.card-left:before {
		display: none;
	}
}
@media only screen and (max-width: 75em) {
	.mwfbi__buttons {
		display: flex;
		justify-content: center;
	}
	.button__text {
		box-shadow: none;
		justify-content: center;
		width: auto;
	}
}
/*
@media only screen and (max-width: 56.25em) {
	.our-mission-main {
		display: flex;
		width: 100%;
		justify-content: start;
		background-color: turquoise;
		flex-direction: column;
	}
	.about-how-we-work-desc {
		width: 100%;
		display: flex;
		justify-content: start;

		flex-direction: column;
	}
	.heading_3 {
		display: flex;
		width: 100%;
		flex-direction: column;
		justify-content: start;
	}
	.history_begining {
		width: 100%;
		padding-right: 0px;
		padding-left: 0px;
		display: flex;
		justify-content: start;
	}
	.about-inner-card-content-right {
		width: 100%;
		display: flex;

		padding: 0rem;
		margin-top: 0rem;

		margin-right: 1rem;
	}
	.about-inner-card-content-left {
		width: 100%;
		display: flex;

		padding: 0rem;

		margin-left: .5rem;
	}
	.history-main {
		display: flex;
		width: 100%;
		justify-content: center;
	}
}

@media only screen and (min-width: 93.75em) {
	.our-mission-main {
		display: flex;
		width: 100%;
		justify-content: start;
		background-color: turquoise;
		flex-direction: column;
	}
	.about-how-we-work-desc {
		width: 100%;
		display: flex;
		justify-content: start;

		flex-direction: column;
	}
	.heading_3 {
		display: flex;
		width: 100%;
		flex-direction: column;
		justify-content: start;
	}
	.history_begining {
		width: 100%;
		padding-right: 0px;
		padding-left: 0px;
		display: flex;
		justify-content: start;
	}
	.spacing {
		padding: 2rem;
		width: 100%;
	}

	.history-card-list {
		width: 100%;
	}
} */
