.header {
	grid-row: 1 / 2;
	background-color: var(--bg-primary);
	box-shadow: var(--box-shadow1);
	padding: 2rem 5rem;
	position: -webkit-sticky;
	position: sticky;
	width: 100%;
	z-index: 1000;
	display: flex;
	align-items: center;
  }
  .tooltipViewNaviagtion {
	position: relative;
	z-index: 1;
	right: 14.4rem;
	top: 1.4rem;
  }
  .tooltipViewNaviagtion1 {
	display: none;
  }
  .logo {
	height: 4.5rem;
	margin-top: 1rem;
  }
  .navigation {
	margin-left: 2rem;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
  }
  .navigation__links {
	list-style: none;
	font-size: 1.2rem;
	display: flex;
	gap: 1rem;
	align-items: center;
  }
  .navigation__link {
	text-decoration: none;
	color: currentColor;
	font-weight: 600;
  }
  .navigation__active {
	color: var(--red) !important;
  }
  .navigation__link2 {
	text-decoration: none;
	color: currentColor;
	font-family: "Medium";
	font-size: 1.1rem;
	background-color: var(--red);
	border: 1px solid var(--red);
	color: var(--bg-primary);
	padding: 0.3rem 2.5rem;
	border-radius: 0.5rem;
	transition: all 0.4s;
  }
  
  .navigation__link2:hover {
	background-color: transparent;
	color: var(--red);
  }
  @media only screen and (max-width: 37.5em) {
	.logo {
	  height: 4rem;
	  margin-top: 0;
	}
  }
  
  @media only screen and (min-width: 1500px) {
	.logo {
	  height: 4.8rem;
	}
  }
  
  @media only screen and (max-width: 669px) {
	.tooltipViewNaviagtion {
	  left: 26rem;
	  top: 1.8rem;
	  display: none;
	}
	.tooltipViewNaviagtion1 {
	  display: block;
	  position: relative;
	  z-index: 1;
	  left: 28rem;
	  top: 2.2rem;
	}
  }
  