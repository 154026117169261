.homeowner_insurance {
	padding: 2rem 33rem;
	padding-bottom: 0;
}
.country_home_insurance {
	background-color: var(--light-grey2);
	padding: 3rem 33rem;
	margin: 3rem 0;
}
.homeowner-renter {
	padding: 0 33rem;
}
.homeowner-renter-insurance {
	padding: 1rem 1rem;
}
.homeowner_renter_insurance-cover {
	display: flex;
	flex-direction: row;
	padding: 2rem 0;
	gap: 15rem;
	line-height: 3.5rem;
}
.home-insurance-discounts {
	padding: 5rem 33rem;
	background-color: var(--light-grey2);
}
.homeowner_insurance_discounts-grid {
	display: flex;
	gap: 2rem;
	padding: 2.5rem 0;
}
@media only screen and (max-width: 1500px) {
	.homeowner_insurance {
		padding: 2rem 15rem;
	}
	.country_home_insurance {
		padding: 2rem 15rem;
	}
	.homeowner-renter {
		padding: 3rem 15rem;
	}
	.home-insurance-discounts {
		padding: 3rem 15rem;
	}
}
@media only screen and (max-width: 1400px) {
	.homeowner_insurance {
		padding: 2rem 15rem;
	}
	.country_home_insurance {
		padding: 3rem 15rem;
	}
	.homeowner-renter {
		padding: 3rem 15rem;
	}
	.home-insurance-discounts {
		padding: 3rem 15rem;
	}
}

@media only screen and (max-width: 780px) {
	.homeowner_insurance {
		padding: 2rem 0;
	}
	.country_home_insurance {
		padding: 3rem 1rem;
	}
	.homeowner-renter {
		padding: 3rem 1rem;
	}
	.homeowner_renter_insurance-cover {
		flex-direction: column;
		width: 100%;
		gap: 0;
		padding: 0;
		line-height: 3.5rem;
	}
	.home-insurance-discounts {
		padding: 3rem 1rem;
	}
	.homeowner_insurance_discounts-grid {
		flex-direction: column;
		gap: 4rem;
	}
}
