.quote-main-box {
	padding: 3rem 33rem;
	background-color: var(--light-grey2);
}
.my-quote{
	margin: 2rem 22rem;
}
.quote_heading {
	font-size: 2rem;
	font-weight: 700;
}
.my-quote-orientation{
	text-align: center;
}
.red_star {
	color: var(--red);
	font-weight: 200;
}

.quote_subheading {
	font-size: 1.8rem;
	font-weight: 300;
}

.main_outer_component {
	padding: 2rem 55rem;
}

.Select_agent_statewise {
	display: flex;
	gap: 2rem;
}

.pre_heading_agentwise {
	/* margin-top: -2rem; */
}

.select_state {
	display: block;
}

.label_heading {
	font-weight: 900;
	font-size: 2rem;
	color: grey;
	margin-top: 1.8rem;
}

.label_heading_quote {
	font-weight: 600;
	font-size: 1.6rem;
	color: rgb(158, 158, 158);
	margin-top: 1.8rem;
}

.select__state__textbox {
	width: 20rem;
	box-decoration-break: none;
	min-height: 4rem;
	margin-bottom: 4rem;
	font-size: 1.4rem;
	background-color: var(--light-grey2);
	border: 1px solid rgb(163, 159, 159);
	outline: none;
}

.select__state__textbox1 {
	width: 210%;
	box-decoration-break: none;
	min-height: 4rem;
	margin-bottom: 4rem;
	font-size: 1.4rem;
	background-color: var(--light-grey2);
	border: 1px solid rgb(163, 159, 159);
	outline: none;
}

.dash_heading_2 {
	font-size: 2.5rem;
	font-weight: 300;
	margin-bottom: 1rem;
}

.form_textbox {
	display: flex;
	gap: 3rem;
}

.label_heading1 {
	display: flex;
	gap: 33.7rem;
	flex-wrap: text;
	margin-top: 2rem;
}

.label_subheading {
	font-weight: 900;
	font-size: 2rem;
	color: grey;
}

.label_heading2 {
	display: flex;
	gap: 38.8rem;
	color: grey;
	font-size: 1.2rem;
	margin-top: .2rem;
}

.label_heading3 {
	margin-top: .3rem;
	display: flex;
	color: grey;
	margin-bottom: .3rem;
	font-size: 1.2rem;
}

.horizontal_line {
	width: 100%;
	height: 2px;
	color: grey;
	background-color: grey;
	display: flex;
}

.Insurance__list {
	display: flex;
	flex-direction: column;
}

.list__of__contents {
	display: flex;
	flex-direction: row;
	gap: 4rem;
}

.list__of__contents1 {
	gap: 10rem;
	padding: 1rem;
	display: inline-flex;
	border: 1px solid #d5d5d5 !important;
	margin: 1rem;
	border-radius: 4rem;
	cursor: pointer;
}


.list__of__contents1_active {
	border: 1px solid #d5d5d5 !important;
	background: #000;
	color: #fff;
	cursor: pointer;
}

.list__of__contents1:hover {
	color: var(--red);
}

.list__of__contents2 li {
	gap: 10rem;
	padding: 1rem;
	display: inline-flex;
}

.form_textarea {
	background-color: rgb(238, 234, 228);
	height: 6rem;

	border: none;
	outline: none;
	border: 1px solid rgb(163, 159, 159);
}

.main__form_button {
	width: 10vw;
	height: 4vh;
	font-size: 1.5rem;
	margin-bottom: 4rem;
	margin-top: 2rem;
}

.pre_heading {
	display: flex;
	flex-wrap: wrap;
	gap: 3rem;
	align-items: flex-end;
	padding-bottom: 2rem;
}

.upload_border {
	border: 1px dotted rgb(78, 78, 78);
	display: flex;
	flex-direction: column;
	height: 10rem;
	text-align: center;
	justify-content: center;
}


.upload-btn-wrapper {
	position: relative;
	overflow: hidden;
	display: inline-block;
	width: 48%;
}

.upload-image {
	height: 20rem;
}

.upload-btn-wrapper input[type=date]::-webkit-calendar-picker-indicator {
	font-size: 200px;
	position: absolute;
	left: 0;
	top: 0;
	opacity: 0;
}

@media only screen and (min-width: 1500px) {
	.quote-main-box {
		padding: 3rem 33rem;
	}

	.quote_heading {
		display: flex;
	}

	.quote_subheading {
		display: flex;
	}

	.main_outer_component {
		padding: 3rem 55rem;
		gap: .5rem;
	}
}

@media only screen and (max-width: 1400px) {
	.quote-main-box {
		padding: 3rem 33rem;
	}

	.quote_heading {
		display: flex;
	}

	.quote_subheading {
		display: flex;
	}

	.main_outer_component {
		padding: 2rem 33rem;
		gap: .5rem;
	}

	.pre_heading {
		gap: 2.5rem;
		align-items: flex-end;
		padding-bottom: 2rem;
		width: 100%;
	}

	.select__state__textbox1 {
		width: 100%;
	}
}

@media only screen and (max-width: 900px) {
	.quote-main-box {
		padding: 2rem 10rem;
	}

	.quote_heading {
		display: flex;
		text-align: center;
	}

	.quote_subheading {
		display: flex;
		text-align: center;
	}

	.main_outer_component {
		padding: 2rem 1rem;
	}

	.label_heading2 {
		display: flex;
		flex-wrap: wrap;
	}

	.form_textbox {
		display: flex;
	}

	.pre_heading {
		gap: 3.3rem;
		align-items: flex-end;
		padding-bottom: 2rem;
		width: 100%;
	}

	.main_outer_component {
		padding: 2rem 25rem;
		gap: .5rem;
	}

	.select__state__textbox1 {
		width: 100%;
	}
}

@media only screen and (max-width: 760px) {
	.quote-main-box {
		padding: 2rem 2rem;
	}

	.quote_heading {
		display: flex;
		text-align: center;
	}

	.quote_subheading {
		display: flex;
		text-align: center;
	}

	.main_outer_component {
		padding: 2rem 1rem;
	}

	.label_heading2 {
		display: flex;
		flex-wrap: wrap;
	}

	.form_textbox {
		display: flex;
	}

	.pre_heading {
		gap: 1.5rem;
		align-items: flex-end;
		padding-bottom: 2rem;
		width: 100%;
	}

	.select__state__textbox1 {
		width: 100%;
	}
}