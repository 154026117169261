:root {
  --bg-primary: #fff;
  --white: #fff;
  --black: rgba(0, 0, 0);
  --black-hover: rgb(17, 17, 17, 0.2);
  /* --dark-grey: #36363F; */
  --dark-grey: #2f2362;
  --grey-border: #36363f;
  /* --light-grey: #e6e6e6; */
  --light-grey: #f2f2f2;
  --light-grey2: #f2f2f2;
  --light-border: #9e8d8d;
  --menu-item: #555555;
  --menu-item-hover: #555555;
  --menu-item-selected: #3b3b3b;
  --dark-green-border: #46812b;
  --red: #e49f15;
  --red-transparent: #e49f15b6;
  --pure-white: #ffffff;
  --box-shadow1: 0.5rem 0.5rem 2rem 2px rgb(0 0 0 / 14%);
  --box-shadow-input: 0.5rem 0.2rem 1rem 2px rgb(0 0 0 / 14%);
  --bp-largest: 75em;
  /* 1200px/16 */
  --bp-large: 68.75em;
  /* 1100px/16 */
  --bp-medium: 56.25em;
  /* 900px/16 */
  --bp-small: 37.5em;
  /* 600px/16 */
  --bp-smallest: 25em;
  /* 400px/16 */
}

*,
*::before,
*::after {
  margin: 0px;
  padding: 0px;
  box-sizing: inherit;
}

@font-face {
  font-family: 'Black';
  src: local('Metropolis-Black'),
    url('../src/fonts/Metropolis-Black.otf') format('opentype');
}

@font-face {
  font-family: 'ExtraBold';
  src: local('Metropolis-ExtraBold'),
    url('../src/fonts/Metropolis-ExtraBold.otf') format('opentype');
}

@font-face {
  font-family: 'SemiBold';
  src: local('Metropolis-SemiBold'),
    url('../src/fonts/Metropolis-SemiBold.otf') format('opentype');
}

@font-face {
  font-family: 'Bold';
  src: local('Metropolis-Bold'),
    url('../src/fonts/Metropolis-Bold.otf') format('opentype');
}

@font-face {
  font-family: 'Medium';
  src: local('Metropolis-Medium'),
    url('../src/fonts/Metropolis-Medium.otf') format('opentype');
}

@font-face {
  font-family: 'Regular';
  src: local('Metropolis-Regular'),
    url('../src/fonts/Metropolis-Regular.otf') format('opentype');
}

html {
  font-size: 62.5%;
}

.mwfbi_banner__edit {
  font-size: 1.6rem;
  font-weight: 200;
  color: white;
  text-align: center;
  position: absolute;
  right: 3rem;
  top: 12rem;
  height: 3rem;
  width: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  border-radius: 0.5rem;
  transition: all 0.4s;
}

.mwfbi_banner__edit:hover {
  cursor: pointer;
  transform: scale(1.1);
}

.mwfbi_banner__edit:hover ~ .edit-effect {
  color: red !important;
}

.mwfbi_banner__edit > svg {
  fill: none;
  color: black;
  height: 2rem;
  width: 2rem;
}

.mwfbi_banner__edit2 {
  font-size: 1.6rem;
  font-weight: 200;
  color: white;
  text-align: center;
  position: absolute;
  right: 3rem;
  top: 1rem;
  height: 3rem;
  width: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  border-radius: 0.5rem;
  transition: all 0.4s;
}

.mwfbi_banner__edit2:hover {
  cursor: pointer;
  transform: scale(1.1);
}

.mwfbi_banner__edit2:hover ~ .edit-effect {
  color: red !important;
}

.mwfbi_banner__edit2 > svg {
  fill: none;
  color: black;
  height: 2rem;
  width: 2rem;
}

/* @media only screen and (max-width: 68.75em) {
	html {
		font-size: 40%;
	}
}

@media only screen and (max-width: 56.25em) {
	html {
		font-size: 40%;
	}
} */

@media only screen and (max-width: 68.75em) {
  html {
    font-size: 40%;
  }
}

@media only screen and (max-width: 56.25em) {
  html {
    font-size: 40%;
  }
}

@media only screen and (min-width: 670px) and (max-width: 1000px) {
  html {
    font-size: 40%;
  }
}

@media only screen and (min-width: 1001px) and (max-width: 1083px) {
  html {
    font-size: 40%;
  }
}

@media only screen and (min-width: 1084px) and (max-width: 1200px) {
  html {
    font-size: 50%;
  }
}

@media only screen and (min-width: 1201px) and (max-width: 1300px) {
  html {
    font-size: 55%;
  }
}

@media only screen and (min-width: 1301px) and (max-width: 1490px) {
  html {
    font-size: 60%;
  }
}

@media only screen and (min-width: 1491px) and (max-width: 1700px) {
  html {
    font-size: 56%;
  }
}

@media only screen and (min-width: 1701px) and (max-width: 1950px) {
  html {
    font-size: 62%;
  }
}

@media only screen and (min-width: 1951px) and (max-width: 2000px) {
  html {
    font-size: 68%;
  }
}

@media only screen and (min-width: 2001px) and (max-width: 2150px) {
  html {
    font-size: 72%;
  }
}

@media only screen and (min-width: 2151px) and (max-width: 2300px) {
  html {
    font-size: 78%;
  }
}

@media only screen and (min-width: 2301px) and (max-width: 2500px) {
  html {
    font-size: 80%;
  }
}

@media only screen and (min-width: 2501px) and (max-width: 2800px) {
  html {
    font-size: 88%;
  }
}

@media only screen and (min-width: 2801px) and (max-width: 2900px) {
  html {
    font-size: 92%;
  }
}

@media only screen and (min-width: 2801px) and (max-width: 3000px) {
  html {
    font-size: 98%;
  }
}

@media only screen and (min-width: 3001px) and (max-width: 3200px) {
  html {
    font-size: 104%;
  }
}

@media only screen and (min-width: 3201px) and (max-width: 3400px) {
  html {
    font-size: 108%;
  }
}

@media only screen and (min-width: 3401px) and (max-width: 3600px) {
  html {
    font-size: 114%;
  }
}

@media only screen and (min-width: 3601px) and (max-width: 3800px) {
  html {
    font-size: 120%;
  }
}

@media only screen and (min-width: 3801px) and (max-width: 4000px) {
  html {
    font-size: 128%;
  }
}

@media only screen and (min-width: 4001px) and (max-width: 4600px) {
  html {
    font-size: 140%;
  }
}

@media only screen and (min-width: 4601px) and (max-width: 5000px) {
  html {
    font-size: 160%;
  }
}

@media only screen and (min-width: 5001px) {
  html {
    font-size: 200%;
  }
}

@media only screen and (min-width: 670px) and (max-width: 1000px) {
	html {
		font-size: 40%;
	}
}

@media only screen and (min-width: 1001px) and (max-width: 1083px) {
	html {
		font-size: 40%;
	}
}

@media only screen and (min-width: 1084px) and (max-width: 1200px) {
	html {
		font-size: 50%;
	}
}

@media only screen and (min-width: 1201px) and (max-width: 1300px) {
	html {
		font-size: 55%;
	}
}

@media only screen and (min-width: 1301px) and (max-width: 1490px) {
	html {
		font-size: 60%;
	}
}

@media only screen and (min-width: 1491px) and (max-width: 1700px) {
	html {
		font-size: 56%;
	}
}

@media only screen and (min-width: 1701px) and (max-width: 1950px) {
	html {
		font-size: 62%;
	}
}

@media only screen and (min-width: 1951px) and (max-width: 2000px) {
	html {
		font-size: 68%;
	}
}

@media only screen and (min-width: 2001px) and (max-width: 2150px) {
	html {
		font-size: 72%;
	}
}

@media only screen and (min-width: 2151px) and (max-width: 2300px) {
	html {
		font-size: 78%;
	}
}

@media only screen and (min-width: 2301px) and (max-width: 2500px) {
	html {
		font-size: 80%;
	}
}

@media only screen and (min-width: 2501px) and (max-width: 2800px) {
	html {
		font-size: 88%;
	}
}

@media only screen and (min-width: 2801px) and (max-width: 2900px) {
	html {
		font-size: 92%;
	}
}

@media only screen and (min-width: 2801px) and (max-width: 3000px) {
	html {
		font-size: 98%;
	}
}

@media only screen and (min-width: 3001px) and (max-width: 3200px) {
	html {
		font-size: 104%;
	}
}

@media only screen and (min-width: 3201px) and (max-width: 3400px) {
	html {
		font-size: 108%;
	}
}

@media only screen and (min-width: 3401px) and (max-width: 3600px) {
	html {
		font-size: 114%;
	}
}

@media only screen and (min-width: 3601px) and (max-width: 3800px) {
	html {
		font-size: 120%;
	}
}

@media only screen and (min-width: 3801px) and (max-width: 4000px) {
	html {
		font-size: 128%;
	}
}

@media only screen and (min-width: 4001px) and (max-width: 4600px) {
	html {
		font-size: 140%;
	}
}

@media only screen and (min-width: 4601px) and (max-width: 5000px) {
	html {
		font-size: 160%;
	}
}

@media only screen and (min-width: 5001px) {
	html {
		font-size: 200%;
	}
}

body {
  box-sizing: border-box;
  font-family: 'Medium';
  line-height: 1.7;
  background-color: var(--bg-primary);
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

body::-webkit-scrollbar {
  /* display: none; */
}

.main-content-margin {
  padding: 3rem 20rem;
}

.main-content-padding {
  padding-left: 15rem !important;
  padding-right: 15rem !important;
}

label {
  font-weight: 500;
}

.heading_1 {
  font-size: 4rem;
}

.dash_heading_1 {
  font-size: 1.8rem;
}

.heading_2 {
  font-size: 2rem;
}

.heading_3 {
  font-size: 1.3rem;
}

.heading_4 {
  font-size: 1.3rem;
}

.heading_5 {
  font-size: 2rem;
  color: #2e1363;
  text-decoration: none;
}

.phase1_heading {
  font-size: 3rem;
  font-weight: 700;
}

.phase2_heading {
  font-size: 2rem;
  font-weight: 800;
  /* color: green; */
}

.phase2_heading1 {
  font-size: 1.6rem;
  font-weight: 800;
  /* color: rebeccapurple; */
}

.phase2_heading2 {
  font-size: 0.75rem;
  font-weight: bold;
}

.phase2_heading3 {
  color: var(--red);
  width: 30%;
  font-size: 1.6rem;
  font-weight: 700;
  cursor: pointer;
  /* padding-right: 10rem; */
}

.phase2_subheading {
  font-size: 1.6rem;
  font-weight: 300;
  /* color: rgb(238, 16, 146); */
}

.phase2_subheading2 {
  color: var(--red);
  cursor: pointer;
  text-transform: uppercase;
  font-size: 1.6rem;
  font-weight: 300;
  text-decoration: none;
}

.phase2_subheading1 {
  font-size: 1.6rem;
  font-weight: 300;
  width: 70%;
  /* color: rgb(238, 16, 146); */
}

/* .phase2_subheading1 {
	font-size: 0.75rem;
	font-weight: 400;
} */

@media only screen and (max-width: 75em) {
  .main-content-margin {
    display: flex;
    margin-left: 0rem !important;
    margin-right: 0rem !important;
  }

  .main-content-padding {
    display: flex;
    /* justify-content: start; */
    padding-left: 0rem !important;
    padding-right: 0rem !important;
  }
}
