.simple_textBox {
	display: flex;
	flex-direction: column;
	gap: .8rem;
}
.simple_textBox--label {
	font-size: 1.2rem;
	font-weight: 500;
}
.simple_textBox--input {
	font-family: inherit;
	border: none;
	outline: none;
	background-color: var(--bg-primary);
	box-shadow: var(--box-shadow1);
	padding: 1rem 2rem;
	border-radius: 2rem;
	transition: all 0.4s;
}
.simple_textBox--input:focus-within {
	box-shadow: var(--box-shadow2);
}
.simple_textBox--input::-webkit-input-placeholder {
	color: var(--light-grey);
}
