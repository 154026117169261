.how-to-file-claim-main {
	width: 100%;
	position: absolute;
	top: 60%;
	display: flex;
	justify-content: center;
}

.how-to-file-claim-box {
	height: 12rem;
	background-color: #00000000;
	width: 80%;
	display: flex;
	justify-content: center;
	text-align: center;
	border-radius: 25px;
	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
	transition: 0.3s;
}

.how-to-file-claim-box:hover {
	box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

.how-to-file-claim-box-left {
	flex: 1;
	padding-top: 2rem;
	color: #ffffff;
	background-color: #000000;
	border-top-left-radius: 25px;
	border-bottom-left-radius: 25px;
	display: inline-table;
}

.how-to-file-claim-box-right {
	flex: 1;
	background-color: #ffffff;
	border-top-right-radius: 25px;
	border-bottom-right-radius: 25px;
	padding-top: 2rem;
	flex-direction: row;
	position: relative;
	justify-content: center;
}

.claim__icon {
	fill: white;
	height: 3.5rem;
	width: 3.5rem;
	transition: all 0.4s;
}

.dash {
	background-color: #000000;
	height: 5px;
	width: 30px;
}

.how-we-work-inside {
	width: 80%;
}

.how-we-work-main {
	padding: 3rem 33rem;
}
.how-we-work-main__heading {
	font-size: 2.5rem;
	text-transform: uppercase;
	font-weight: 800;
	text-align: center;
}
.how-we-work-main__steps {
	display: flex;
	gap: 2rem;

	/* justify-content: space-between; */
	margin-top: 2rem;
}
.grid-card {
	display: flex;
	flex-direction: column;
	align-items: center;
	background-color: #e8e8e8;
	padding: 2rem 3rem;
	transition: all .4s;
	width: 100%;
}

.grid-card:hover {
	transform: translateY(-.5rem);
}
.grid-card-image {
	height: 6rem;
}
.grid-card-title {
	margin-top: 2rem;
	font-size: 1.8rem;
	text-transform: uppercase;
	font-weight: 600;
}
.grid-card-sub-title {
	font-size: 1.3rem;
	/* font-weight: 600; */
	text-align: center;
	font-family: bold;
}

.grid-card-desc {
	font-size: 1.2rem;
	text-align: center;
}

.how-we-work-desc {
	padding: 6rem 33rem;
	display: flex;
	gap: 18rem;
	justify-content: space-between;
	
	background-color: #e8e8e8;
}
.how-we-work-desc-heading {
	font-size: 2.5rem;
	font-weight: 600;
	color: var(--red);
	margin-bottom: .3rem;
}
.how-we-work-desc-heading span {
	display: block;
	color: var(--black);
	font-size: 1.8rem;
}
.how-we-work-desc-desc {
	font-size: 1.3rem;
	color: var(--dark-grey);
}
.fbf__image__bus {
	height: 30rem;
}

.contract-main {
	display: flex;
	margin-top: 2rem;
	padding: 2rem 33rem;
}
.how-we-work-desc-heading1 {
	font-size: 1.7rem;
	font-weight: 500;
	margin-bottom: 1rem;
}

/* .contract-content {
	padding: 3rem 20rem;
	display: flex;
} */

/* .spacing {
	padding: 2rem;
} */

/* .contract-card-list {
	width: 100%;
} */

.contract-card {
	/* height: 15rem; */
	background-color: #ffffff;
	transition: 0.3s;
	display: flex;
	flex-direction: column;
	position: relative;
	margin-bottom: 2rem;
	padding: 1rem;
	padding-left: 1.5rem;
	height: 9rem;
}

.contract-card:hover {
	box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

.contract-card > .title {
	font-family: inherit;
	font-size: 1.5rem;
	font-weight: 600;
}

.contract-card > .desc {
	font-family: inherit;
	font-weight: 500;
	/* width: 80%; */
}
@media only screen and (max-width: 1400px) {
	.how-we-work-main {
		padding: 3rem 15rem;
	}
	.how-we-work-desc {
		gap: 5rem;
		padding: 3rem 15rem;
	}
	.contract-main {
		padding: 3rem 15rem;
	}
}

@media only screen and (max-width: 1200px) {
	.quote {
		padding: 3rem 10rem;
	}
	.how-we-work-main {
		padding: 3rem 10rem;
		gap: 5rem;
	}
	.how-we-work-desc {
		padding: 3rem 10rem;
		gap: 1rem;
	}
	.fbf__image__bus {
		height: 20vw;
	}
	.contract-main {
		padding: 3rem 10rem;
	}
	.contract-card {
		height: auto;
	}
	.how-we-work-main__steps {
		gap: 5rem;
		width: 100%;
	}
	.grid-card {
		width: 30%;
	}
}
@media only screen and (max-width: 760px) {
	.quote {
		display: flex;
		flex-direction: column;
		padding: 3rem 1rem;
	}
	.how-we-work-main {
		padding: 3rem 1rem;
		display: flex;
		flex-direction: column;
	}
	.how-we-work-desc {
		padding: 3rem 1rem;
		gap: 1rem;
		flex-direction: column;
	}
	.fbf__image__bus {
		height: 50vw;
	}
	.contract-main {
		padding: 3rem 1rem;
	}
	.contract-card {
		height: auto;
	}
	.how-we-work-main__steps {
		display: flex;
		flex-direction: column;
		gap: 1rem;
		width: 100%;
	}
	.grid-card {
		width: auto;
	}
}
/* @media only screen and (min-width: 1500px) {
	.how-we-work-main {
		padding: 3rem 33rem;
	}
	.how-we-work-main__steps {
		width: 100%;
		width: 3rem 33rem;
	}
	.how-we-work-desc {
		padding: 3rem 33rem;
	}
	.contract-main {
		padding: 3rem 33rem;
	}
	.grid-card {
		width: 30rem;
	}
	.quote {
		padding: 3rem 33rem;
	}
} */

/* }

@media only screen and (min-width: 93.75em) {
	.how-we-work-main,
	.how-we-work-desc {
		padding: 3rem 33rem;
	}
	.contract-main {
		padding: 3rem 33rem;
	}
	.grid-card {
		width: 30rem;
	}
	.quote {
		padding: 3rem 33rem;
	}
}

@media only screen and (max-width: 48em) {
	.how-we-work-main {
		padding: 2rem 1rem;
		width: 100%;
		display: flex;
		flex-direction: column;
	}
	.how-we-work-main__steps {
		display: flex;
		flex-direction: column;
		padding: 2rem 0rem;
	}
	.how-we-work-desc {
		padding: 2rem 0rem;
		width: 100%;
		display: flex;
		flex-direction: column;
	}
	.fbf__image__bus {
		height: 40rem;
	}
	.contract-main {
		padding: 2rem 1rem;
	}
	.grid-card {
		display: flex;
		width: 100%;
	}
	.contract-card {
		height: 100%;
	}
	.quote {
		padding: 2rem 0rem;
		display: flex;
		flex-direction: column;
		gap: 1rem;
	}
	.Logo_container {
		background-color: aqua;
	}
} */
