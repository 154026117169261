
.ms-pagination1 {
    display: none;
  }
  
  .paginator_head {
    font-size: 1.3rem;
    color: rgb(107, 103, 103);
    font-weight: 300;
  }
  
  .pagenator {
    display: flex;
    /* gap: 1rem; */
    cursor: pointer;
  
    /* border: 1px solid var(--light-grey); */
  }
  
  .paginationActive {
    background-color: red;
    color: white;
  }
  
  .paginationActive a {
    color: white!important;
  }
  
  .paginationBttns {
    list-style: none;
    font-family: Poppins;
    gap: 1rem;
    font-style: normal;
    font-weight: 500;
    font-size: 1rem;
    display: flex;
    justify-content: end;
    align-items: center;
    color: black;
  }

  .previousBttn{
    color:  var(--red)
  }
  
  .paginationBttns li {
    height: 3rem;
    width: 3rem;
    border: 1px solid #e7e5e5;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.4s;
    overflow: hidden;
    color: black;
    background-color:  var(--red);
  }
  
  .paginationBttns a {
    padding: 0.5rem 0.4rem;
    /* margin: .8rem; */
    border: 1px solid var(--primary-color);
    color: black;
    cursor: pointer;
    transition: all 0.4s;
  }
  
  .paginationBttns li:hover {
    color: var(--white)!important;
    background-color:  var(--red);
  }
  
  .paginationBttns a:hover {
    color: var(--white);
  }