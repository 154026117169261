.container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
}
.centerContainer {
  width: 60%;
  background-color: var(--white);
  border-radius: 0.5rem;
  box-shadow: 0 10px 16px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%) !important;
}
.topContainer {
  display: flex;
  padding: 1rem 0rem;
  flex-direction: row;
}
.leftContainer {
  width: 50%;
  border-right: 0.2rem solid #80808033;
  height: 100%;
  padding: 2rem 3rem;
}

.customerLogin {
  padding-bottom: 18px;
}
.customerSignup {
  padding-bottom: 18px;
}

.rightContainer {
  width: 50%;
  height: 100%;
  padding: 2rem 3rem;
}
.line {
  background-color: var(--light-grey);
  color: var(--light-grey);
  display: flex;
  margin-left: 1rem;
  margin-right: -1rem;
  flex-direction: column;
  width: 2px;
  height: 84%;
}
.input1 {
  margin-top: 1rem;
}
.input2 {
  margin-top: 3rem;
}
.buttons {
  display: flex;
  flex-direction: row;
  justify-content: CENTER;
  gap: 2rem;
}
.buttons_vertical {
  display: flex;
  flex-direction: column;
  justify-content: CENTER;
  row-gap: 1rem;
  margin-top: 2rem;
}
.header {
  color: grey;
  font-size: 2rem;
  margin-bottom: 1rem;
}
.header1 {
  color: grey;
  font-size: 1.8rem;
  text-align: center;
  margin-bottom: 1rem;
}
.desc {
  color: grey;
  font-size: 1.6rem;
}
.bottomContainer {
  /* height: 25%; */
  border-top: 1px solid var(--light-grey);
  margin: 0rem 3rem;
  padding: 2rem 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.phone {
  color: var(--red);
}
.header2 {
  color: grey;
  font-size: 1.8rem;
}
.header3 {
  color: grey;
  font-size: 1.8rem;
}

.input3 {
  /* margin-top: 2rem; */
}

.labelHeading {
  font-size: 1.8rem;
  color: grey;
}

.quickPay {
  margin-top: 2rem;
}

@media only screen and (max-width: 992px) {
  .centerContainer {
    width: 70%;
  }
  .input2 .buttons {
    display: block;
  }
  .input2 .buttons button {
    margin-bottom: 2rem;
  }
  .topContainer {
    height: 45%;
  }
  .labelHeading {
    font-size: 2.2rem;
    color: grey;
  }
}

@media only screen and (max-width: 669px) {
  html {
    font-size: 34%;
  }
  .quickPay {
    margin-top: 4rem;
  }
  .line {
    display: none;
  }
  .centerContainer {
    width: 70%;
    height: 82%;
  }
  .desc {
    font-size: 1.5rem;
  }
  .input2 {
    margin-top: 4rem;
  }
  .input3 {
    margin-top: 5rem;
  }
  .labelHeading {
    font-size: 2.2rem;
    color: grey;
  }
  .line {
    width: 90%;
    height: 2px;
  }
  .topContainer {
    flex-direction: column;
    height: 65%;
  }
  .leftContainer {
    width: 100%;
    height: 100%;
    padding: 4rem 5rem 0rem;
    border-right: none;
  }
  .rightContainer {
    width: 100%;
    height: 100%;
    padding: 6rem 5rem;
  }
  .bottomContainer {
    border-top: none;
    padding: 10rem 5rem;
  }
  .header1 {
    color: grey;
    font-size: 1.3rem;
    text-align: center;
    margin-bottom: 1rem;
    margin-top: 5rem;
  }

  .header {
    text-align: center;
  }
  .header3 {
    margin-top: 1rem;
  }
  .input2 .buttons {
    display: flex;
  }
  .rightContainer:nth-child(1) {
    margin-top: 4rem;
  }
}
