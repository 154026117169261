.annuities {
	padding: 3rem 33rem;
	padding-bottom: 0;
}
.annuities_tabset {
	padding: 3rem 33rem;
}
@media only screen and (max-width: 1500px) {
	.annuities {
		padding: 3rem 15rem;
	}
	.annuities_tabset {
		padding: 3rem 15rem;
	}
}
@media only screen and (max-width: 1400px) {
	.annuities {
		padding: 3rem 15rem;
	}
	.annuities_tabset {
		padding: 3rem 15rem;
	}
}

@media only screen and (max-width: 1200px) {
	.annuities {
		padding: 3rem 10rem;
	}
	.annuities_tabset {
		padding: 3rem 10rem;
	}
}
@media only screen and (max-width: 780px) {
	.annuities {
		padding: 3rem 1rem;
	}
	.annuities_tabset {
		padding: 3rem 1rem;
	}
}
