.supportedFormat {
  font-size: 1.2rem;
  padding-top: 1rem;
  font-weight: 500;
}

.fileInfo{
    font-size: 1.4rem;
    list-style: none;
}

