.quote3 {
  justify-content: center;
  height: 10rem;
  background-color: var(--dark-grey);
  display: flex;
  align-items: center;
}

.quote__text3 {
  font-size: 2.8rem;
  font-weight: 800;
  color: var(--white);
}

.main_view {
  padding: 3rem 14rem;
}

.icon_division {
  padding: 1.4rem 0rem;
  width: 30%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.icon_grid div:nth-child(2) {
  border-left: 0.1rem solid rgba(0, 0, 0, 0.138);
  border-right: 0.1rem solid rgba(0, 0, 0, 0.138)
}

.icon_grid {
  display: flex;
  padding-bottom: 1.8rem;
  border-bottom: 0.1rem solid rgba(0, 0, 0, 0.138);
  justify-content: center;
  gap: 0.2rem;
}

.icon {
  height: 8rem;
}

.icon_division span {
  display: block;
  font-size: 1.1rem;
  font-weight: 600;
  margin-top: 1.8rem;
}

.division_text {
  text-align: center;
  display: block;
}

.options_menu {
  display: flex;
  justify-content: space-between;
  padding: 3rem 0rem;
}

.option_section {
  margin-bottom: 5rem;
}

.option_section span {
  line-height: 2rem;
  font-weight: 800;
  margin-bottom: 1.2rem;
  font-size: 2.8rem;
  display: inline-block;
  border-bottom: 0.4rem solid rgb(0, 0, 0);
}

.option_section a {
  display: block;
  font-size: 2rem;
}

.option_division {
  width: 30%;
  padding-right: 4rem;
}

.options_menu .option_division:nth-child(2) {
  padding: 0rem 2rem;
  border-left: 0.2rem solid rgb(0, 0, 0);
  border-right: 0.2rem solid rgb(0, 0, 0);
}

.video_image {
  height: 22rem;
  width: 43.2rem;
  text-align: center;
}

.option_title {
  font-size: 1.8rem !important;
  line-height: 0.8rem !important;
  border-bottom: 0.3rem solid rgb(0, 0, 0) !important;
}

.secondary_section {
  text-align: center;
}

.secondary_section > a {
  display: flex;
  justify-content: center;
  gap: 1rem;
}

.secondary_menu {
  border-top: 0.3rem solid rgb(0, 0, 0);
}

.customer_section {
  margin-bottom: 1rem !important;
}

.agent_call {
  text-align: center;
  padding-bottom: 2.4rem;
}

.form_image {
  margin-top: 1.4rem;
  text-align: center;
}

.document_archive {
  text-align: center;
  margin-bottom: 4rem;
}

.document_archive p {
  color: #313944;
  font-weight: 800;
  font-size: 3rem !important;
}

.document_archive input{
  padding: 2rem 11rem;
  border: 0.3rem solid rgba(0, 0, 0, 0.407);
}

.document_archive strong {
  display: block;
  font-size: 2.8rem;
  border-bottom: 0.4rem solid rgb(0, 0, 0);
  margin: 1.2rem 9.2rem;
  line-height: 2.2rem;
}

.agent_call span {
  font-size: 2rem !important;
  line-height: 4.2rem !important;
  text-align: center;
  font-weight: 600;
  display: block;
}

@media only screen and (min-width: 700px) and (max-width: 1199px) {
  .video_image {
    height: 18rem;
    width: 31.2rem;
    text-align: center;
  }
  .main_view {
    padding: 3rem 6rem 24rem;
  }
}

.document_archive strong {
  margin: 2.2rem 3.2rem;
}

.document_archive input {
  padding: 2rem 5rem;
}

.form_image{
  height: 16rem;
  width: 24rem;
}

.blue_options {
  color: #3b6bcb;
}

@media only screen and (max-width: 669px) {
  .quote__text3 {
    font-size: 2.4rem;
  }

  .secondary_section span {
    line-height: 2rem !important;
  }

  .form_image {
    width: 15rem;
  }

  .search_doc{
    height: 12rem;
    width: 12rem;
  }

 
.document_archive input {
  width: 16rem;
  padding: 0rem;
}

  .document_archive strong {
    font-size: 1.8rem;
  }

  .main_view {
    padding: 3rem 0rem 24rem;
  }

  .option_section span {
    font-size: 2rem;
  }

  .video_image {
    height: 10rem;
    width: 15rem;
    text-align: center;
  }

  .option_section {
    margin-bottom: 3rem;
  }
}
