.cardContainer {
  background-color: #ffffff;
  border-radius: 20px;
  padding: 20px 22px;
  margin-bottom: 6rem;
}

.title {
  font-size: 20px;
  padding-bottom: 1rem;
  display: block;
  font-weight: 600;
}

@media (min-width: 767px) {
  .cardContainer{
    padding: 22px 0px 0px;
  }
}

@media (max-width: 767px) {
  .cardContainer{
    padding: 0px 0px 0px;
  }
}