.fbf__content {
	display: flex;
	gap: 10rem;
	padding: 10rem 33rem;
	justify-content: center;
}
.fbf_heading {
	font-size: 2.5rem;
	font-weight: 600;
	margin-bottom: 2.5rem;
}
.fbf__desc {
	font-size: 1.6rem;
	/* flex: 1; */
	font-weight: 500;
	padding-bottom: 3.5rem;
}

.fbf__image {
	width: 50rem;
}
.mwfbi__buttons {
	display: flex;
	flex-direction: row;
	gap: 1rem;
	margin-top: 2rem;
	padding: 0rem;
	width: 100%;
	justify-content: center;
	align-items: center;
	text-align: center;
	align-self: center;
}

.mwfbi__quote {
	background-color: var(--dark-grey);
	padding: 2.5rem 0;
	font-size: 2.5rem;
	color: var(--white);
	justify-content: center;
	text-align: center;
	width: '100%';
}
.learn_more {
	margin-top: 2rem;
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
}
.learn_more__heading {
	color: red;
	margin-bottom: 2rem;
	font-size: 2.5rem;
	font-weight: 600;
}
.learn_more__subheading {
	max-width: 45vw;
	font-size: 1.6rem;
	font-weight: 300;
	text-align: center;
	margin-bottom: 5rem;
}
.learn_more__logos {
	margin-top: 2rem;
	display: flex;
	gap: 12rem;
	align-items: center;
	padding-bottom: 4rem;
}
.learn_more__logo {
	width: 40rem;
	cursor: pointer;
}
.learn_more__logo1 {
	width: 30rem;
	cursor: pointer;
}

@media only screen and (max-width: 1400px) {
	.fbf__content {
		padding: 2rem 15rem;
	}
}
@media only screen and (max-width: 1200px) {
	.fbf__content {
		padding: 2rem 10rem;
	}
}
@media only screen and (max-width: 56em) {
	.fbf__content {
		padding: 2rem 5rem;
	}
}

@media only screen and (max-width: 768px) {
	.fbf__content {
		padding: 2rem 0rem;
		display: inline-flex;
		flex-direction: column;
		/* max-width: 100%; */
	}

	.mwfbi__buttons {
		display: flex;
		flex-direction: column;
		width: 100%;
		justify-content: center;
		align-items: center;
	}
	.fbf__image {
		width: 90vw;
		margin-top: -5rem;
	}
	.mwfbi__quote {
		max-width: 100%;
	}
	.learn_more {
		max-width: 100%;
	}
	.learn_more__logos {
		/* margin-top: 2rem; */
		display: inline;
		gap: 1rem;
		/* flex-direction: column; */
		/* align-items: center; */
		/* padding-bottom: 4rem; */
		/* max-width: 100%; */
		width: 90vw;
	}
	.learn_more__logo {
		display: flex;
		width: 90vw;
	}
}
/* @media only screen and (min-width: 1500px) {
	.fbf__content {
		padding: 2rem 15rem;
	}
} */

/* @media only screen and (max-width: 17.5em) {
	.fbf__content {
		padding: 2rem 0rem;
		display: inline-flex;
		
	}

	.mwfbi__buttons {
		display: flex;
		flex-direction: column;
		width: 100%;
	}
	.fbf__image {
		width: 100%;
	}
	.mwfbi__quote {
		max-width: 60%;
	}
	.learn_more {
		max-width: 60%;
	}
	.learn_more__logos {
		
		display: inline;
		gap: 1rem;
	
		max-width: 100%;
	}
	.learn_more__logo {
		display: flex;
		max-width: 100%;
	}
}

@media only screen and (max-width: 22.5em) {
	.fbf__content {
		padding: 2rem 0rem;
		display: inline-flex;
		flex-direction: column;
		max-width: 80%;
	}

	.mwfbi__buttons {
		display: flex;
		flex-direction: column;
		width: 100%;
	}
	.fbf__image {
		width: 100%;
	}
	.mwfbi__quote {
		max-width: 80%;
	}
	.learn_more {
		max-width: 90%;
	}
	.learn_more__logos {
		
		display: inline;
		gap: 1rem;
		
		max-width: 90%;
	}
	.learn_more__logo {
		display: flex;
		max-width: 90%;
	}
} */
